import { ChevronLeft } from "@mui/icons-material";
import {
  Divider,
  Container,
  Paper,
  LinearProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  IconButton,
  TablePagination,
  Box,
  styled,
  Typography,
  Button,
  TableFooter,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect, Fragment } from "react";
import EmptyIcon from "src/assets/emptyBox.png";
import { useSelector } from "react-redux";
import labelDisplayedRows from "src/utils/pagination";
import apiMasterPlanService from "src/services/masterPlanService";
import { toDecimal, formatCurrency } from "src/utils/toDecimal";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2162C0",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  }
}));

const MasterPlanInfo = ({ masterPlan, isView, setView }) => {
  const [loading, setLoading] = useState(true);
  const [matrizSummaryPMEst, setMatrizSummaryPMEst] = useState([]);
  const [colmDetalle, setColDetalle] = useState([]);
  const [keysIds, setKeysIds] = useState([]);
  const [mastrizDetailEst, setMastrizDetailEst] = useState([]);
  const [mastrizDetailAccEst, setMastrizDetailAccEst] = useState([]);
  const Project = useSelector((state) => state?.setUserLogin?.project?.id);
  const colResumen = [
    { id: "name", label: "Descripción de la Obra" },
    { id: "startDate", label: "Fecha Est. de Inicio" },
    { id: "endDate", label: "Fecha Est. de Terminación" },
    { id: "monto", label: "Monto Total en $" },
    { id: "percentage", label: "% Part" },
  ];

  useEffect(() => {
    try {
      const colDetalle = [
        { id: "name", label: "Descripción de la Obra" },
        { id: "total", label: "Total" },
      ];
      const keysId = [];
      masterPlan.project = Project;

      apiMasterPlanService.postByMasterPlan(masterPlan).then((data) => {
        setMatrizSummaryPMEst(data?.data?.matrizSummaryPMEst || []);
        setMastrizDetailEst(data?.data?.mastrizDetailEst);
        setMastrizDetailAccEst(data?.data?.mastrizDetailAccEst);
        if (data?.data?.mastrizDetailEst?.length > 0) {
          const keys = Object.keys(data?.data?.mastrizDetailEst[0] || []);
          for (let i = 2; i < keys.length; i++) {
            keysId.push(keys[i]);
            colDetalle.push({
              id: keys[i],
              label: keys[i],
            });
          }
        }

        setKeysIds(keysId);
        setColDetalle(colDetalle);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Error al obtener las obras", { variant: "error" });
    }
  }, [Project, masterPlan]);


  return (
    <Container>
      <div className="d-flex justify-content-between  mb-4">
        <IconButton
          style={{ background: "none" }}
          aria-label="edit"
          color="primary"
          onClick={() => {
            setView(false);
          }}
        >
          <ChevronLeft color="primary" fontSize="large" />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            color="primary"
            className="m-1"
          >
            Plan Maestro Estimado Inicial
          </Typography>
        </IconButton>

        {/* <Tooltip title="Filtrar">
          <IconButton aria-label="" color="primary">
            <FilterAlt />
          </IconButton>
        </Tooltip> */}
      </div>
      {!loading ? (
        <>
          <Divider className="mb-3" />
          <Grid container={2} className="mt-3">
            <Container maxWidth="lg">
              <div className="d-flex justify-content-between">
                <Divider />
              </div>

              {matrizSummaryPMEst.length > 0 ? (
                <>
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    color="primary"
                    className="mb-2 fw-bold"
                  >
                    Resumen del Plan Maestro
                  </Typography>
                  <Divider />
                  <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {colResumen.map((column) => (
                            <StyledTableCell
                              width={400}
                              style={{ border: "1px solid white" }}
                            >
                              <b>{column.label}</b>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {matrizSummaryPMEst?.slice(0, -1)?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.name === "Total" ? (
                                  <b> {row.name}</b>
                                ) : (
                                  <>{row.name}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.name === "Total" ? (
                                  <b> {row.startDateEst}</b>
                                ) : (
                                  <>{row.startDateEst}</>
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.name === "Total" ? (
                                  <b> {row.endDateEst}</b>
                                ) : (
                                  <>{row.endDateEst}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.name === "Total" ? (
                                  <b> {formatCurrency(row.Total)}</b>
                                ) : (
                                  <>{formatCurrency(row.Total)}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.name === "Total" ? (
                                  <b> {toDecimal(row.percentage) + "%"}</b>
                                ) : (
                                  <>{toDecimal(row.percentage) + "%"}</>
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter style={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 800 }}>
                      <TableRow>
                          <TableCell width={225}>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSummaryPMEst[matrizSummaryPMEst.length - 1].name}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSummaryPMEst[matrizSummaryPMEst.length - 1].startDateEst}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSummaryPMEst[matrizSummaryPMEst.length - 1].endDateEst}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{formatCurrency(matrizSummaryPMEst[matrizSummaryPMEst.length - 1].Total)}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{toDecimal(matrizSummaryPMEst[matrizSummaryPMEst.length - 1].percentage) + "%"}</b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>

                    </Table>

                  </TableContainer>
                  

                  <br />
                  <div style={{ position: 'relative', height: '640px' }}>
                    <Typography
                      variant="h6"
                      component="h5"
                      gutterBottom
                      color="primary"
                      className="mb-2 fw-bold"
                    >
                      Detalle Mensual
                    </Typography>
                    <Divider />
                    <TableContainer component={Paper} style={{ maxHeight: 'calc(100% - 44px)', overflowY: 'auto' }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {colmDetalle.map((column, index) => (
                              <StyledTableCell
                                key={index}
                                style={index === 0 ? {
                                  left: 0,
                                  zIndex: 800,
                                } : {}}
                              >
                                <b>{column.label}</b>
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {mastrizDetailEst.slice(0, -2).map((row, index) => (
                            <TableRow key={index}>
                              <TableCell style={{
                                position: 'sticky',
                                left: 0,
                                background: 'white',
                                zIndex: 700,
                              }}>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {row.name === "Total Mensual Estimado Inicial" || row.name === "Porcentaje Mes" ? (
                                    <b> {row.name}</b>
                                  ) : (
                                    <> {row.name}</>
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {row.name === "Total Mensual Estimado Inicial" || row.name === "Porcentaje Mes" ? (
                                    <b>
                                      {row.name === "Porcentaje Mes" ? (
                                        <>{formatCurrency(row.total) + "%"}</>
                                      ) : (
                                        <>{formatCurrency(row.total)}</>
                                      )}
                                    </b>
                                  ) : (
                                    <> {formatCurrency(row.total)}</>
                                  )}
                                </Typography>
                              </TableCell>
                              {keysIds.map((snp, idx) => (
                                <TableCell key={idx}>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {row.name === "Total Mensual Estimado Inicial" || row.name === "Porcentaje Mes" ? (
                                      <>
                                        <b>
                                          {row.name === "Porcentaje Mes" ? (
                                            <>
                                              {row[snp] !== 0 ? formatCurrency(row[snp]) + "%" : ""}
                                            </>
                                          ) : (
                                            <>
                                              {row[snp] !== 0 ? formatCurrency(row[snp]) : ""}
                                            </>
                                          )}
                                        </b>
                                      </>
                                    ) : (
                                      <>
                                        {row[snp] !== 0 ? formatCurrency(row[snp]) : ""}
                                      </>
                                    )}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter style={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 800 }}>
                          {mastrizDetailEst.slice(-2).map((row, index) => (
                            <TableRow key={index}>
                              <TableCell style={{
                                position: 'sticky',
                                left: 0,
                                background: 'white',
                                zIndex: 700,
                              }}>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  <b>{row.name}</b>
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  <b>
                                    {row.name === "Porcentaje Mes"
                                      ? `${formatCurrency(row.total)}%`
                                      : formatCurrency(row.total)}
                                  </b>
                                </Typography>
                              </TableCell>
                              {keysIds.map((snp, idx) => (
                                <TableCell key={idx}>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    <b>
                                      {row.name === "Porcentaje Mes"
                                        ? row[snp] !== 0
                                          ? `${formatCurrency(row[snp])}%`
                                          : ""
                                        : row[snp] !== 0
                                          ? formatCurrency(row[snp])
                                          : ""}
                                    </b>
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                  <br />
                  <div style={{ position: 'relative', height: '640px' }}>
                    <Typography
                      variant="h6"
                      component="h5"
                      gutterBottom
                      color="primary"
                      className="mb-2 mt-3 fw-bold"
                    >
                      Detalle Mensual Acumulado
                    </Typography>
                    <Divider />
                    <TableContainer component={Paper} style={{ maxHeight: 'calc(100% - 24px)', overflowY: 'auto' }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {colmDetalle.map((column, index) => (
                              <StyledTableCell
                                key={index}
                                style={index === 0 ? {
                                  left: 0,
                                  zIndex: 800,
                                } : {}}
                              >
                                <b>{column.label}</b>
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {mastrizDetailAccEst?.slice(0, -2).map((row, index) => (
                            <TableRow key={index}>
                              <TableCell style={{
                                position: 'sticky',
                                left: 0,
                                background: 'white',
                                zIndex: 700,
                              }}>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {row.name === "Total Mensual Estimado Inicial" || row.name === "Porcentaje Mes" ? (
                                    <b> {row.name}</b>
                                  ) : (
                                    <> {row.name}</>
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {row.name === "Total Mensual Estimado Inicial" || row.name === "Porcentaje Mes" ? (
                                    <b>
                                      {row.total !== 0
                                        ? formatCurrency(row.total) + (row.name === "Porcentaje Mes" ? "%" : "")
                                        : ""}
                                    </b>
                                  ) : (
                                    <>
                                      {row.total !== 0
                                        ? formatCurrency(row.total)
                                        : ""}
                                    </>
                                  )}
                                </Typography>
                              </TableCell>
                              {keysIds.map((snp, idx) => (
                                <TableCell key={idx}>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {row.name === "Total Mensual Estimado Inicial" || row.name === "Porcentaje Mes" ? (
                                      <b>
                                        {row[snp] !== 0
                                          ? formatCurrency(row[snp]) + (row.name === "Porcentaje Mes" ? "%" : "")
                                          : ""}
                                      </b>
                                    ) : (
                                      <>
                                        {row[snp] !== 0
                                          ? formatCurrency(row[snp])
                                          : ""}
                                      </>
                                    )}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter style={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 800 }}>
                          {mastrizDetailAccEst.slice(-2).map((row, index) => (
                            <TableRow key={index}>
                              <TableCell style={{
                                position: 'sticky',
                                left: 0,
                                background: 'white',
                                zIndex: 700,
                              }}>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  <b>{row.name}</b>
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  <b>
                                    {row.name === "Porcentaje Mes"
                                      ? `${formatCurrency(row.total)}%`
                                      : formatCurrency(row.total)}
                                  </b>
                                </Typography>
                              </TableCell>
                              {keysIds.map((snp, idx) => (
                                <TableCell key={idx}>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    <b>
                                      {row.name === "Porcentaje Mes"
                                        ? row[snp] !== 0
                                          ? `${formatCurrency(row[snp])}%`
                                          : ""
                                        : row[snp] !== 0
                                          ? formatCurrency(row[snp])
                                          : ""}
                                    </b>
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </>
              ) : (
                <Box textAlign="center">
                  <img src={EmptyIcon} alt="Imagen de no data" width={150} />
                  <Typography variant="h6" color="GrayText">
                    No hay datos disponibles.
                  </Typography>
                </Box>
              )}
            </Container>
          </Grid>
        </>
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Container>
  );
};

export default MasterPlanInfo;
