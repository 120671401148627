export const clientsPermissions = {
    "Super Administrador": [
        'view',
        'add', 
        'edit', 
        'delete'
    ],
    "Administrador TPIG": [
       'view',
       'add', 
       'edit', 
       'delete'
    ],
    "Administrador Proyecto": [],
    "Aprobador": [],
    "Gestionador": [],
    "Usuario": []
};

