export const masterPlanPermissions = {
    "Super Administrador": [
        'add',
        'add_works',
        'update_works',
        'delete_works',
        'update_estimates',
        'add_estimates',
        'edit',
        'delete'
    ],
    "Administrador TPIG": [
        'add',
        'add_works',
        'update_works',
        'delete_works',
        'update_estimates',
        'add_estimates',
        'manage_works',
        'edit',
        'delete'
    ],
    "Administrador Proyecto": [
        'add',
        'edit',
        'add_works',
        'update_works',
        'update_estimates',
        'add_estimates',
        'delete_works',
        'manage_works'
    ],
    "Aprobador": [],
    "Gestionador": [],
    "Usuario": []
};
