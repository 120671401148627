import { withStyles } from '@mui/styles';
import { InputBase } from '@mui/material';

const NoBorderInput = withStyles({
    root: {
      'label + &': {
        marginTop: '8px',
      },
    },
    input: {
      margin: 0,
      position: 'relative',
      backgroundColor: 'transparent',
      fontSize: '14px !important',
      padding: '10px 26px 10px 12px !important',
      transition: 'background-color 300ms',
      color: 'rgb(138,142,146) !important',
      '&:focus': {
        backgroundColor: 'transparent',
      },
      fontWeight: 'bold !important'
    },
  })(InputBase);

export default NoBorderInput