/* eslint-disable no-unused-vars */
import { ChevronLeft, Save } from "@mui/icons-material";

import {
  Button,
  Divider,
  Container,
  Paper,
  styled,
  LinearProgress,
  TablePagination,
  Table,
  TextField,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  DialogActions,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import { Formik } from "formik";
import useStyles from "../styles";
import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import EmptyIcon from "src/assets/emptyBox.png";
import { formatCurrency } from "src/utils/toDecimal";
import { useSelector } from "react-redux";
import labelDisplayedRows from "src/utils/pagination";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import apiMasterPlanService from "src/services/masterPlanService";
import permissionsService from "src/services/permissionsService";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2162C0",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const MasterPlanInfoWorks = ({ masterPlan, work, isView, setView }) => {
  const classes = useStyles();
  const [toggleDialog, setToggleDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingsave, setLoadingSave] = useState(false);
  const User = useSelector((state) => state?.setUserLogin?.user);
  const [allWorks, setAllWorks] = useState(false);
  const [contractFile, setContractFile] = useState(null);
  const [NumEstimated, setNumEstimated] = useState(null);
  const [NumReal, setNumReal] = useState(null);
  const [importDialog, setImportDialog] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false)
  const [importDialogReal, setImportDialogReal] = useState(false);
  const [dialogUpdate, setDialogUpdate] = useState(false);
  const Project = useSelector((state) => state?.setUserLogin?.project?.id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [pageReal, setPageReal] = useState(0);
  const [rowsPerPageReal, setRowsPerPageReal] = useState(10);

  const [selectamount, setSelectAmount] = useState([]);
  const [newValues, setNewValues] = useState([]);
  let columns = [
    { id: "date", label: "Periodo" },
    { id: "monto", label: "Monto mensual" },
  ];
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 100); // 100 years from today
  const myRol =
    User?.role?.label !== "Super Administrador" &&
    User?.role?.label !== "Administrador TPIG"
      ? User?.projects?.find((user) => user?.project?.id === Project?.id)
      : User;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePageReal = (event, newPage) => {
    setPageReal(newPage);
  };
  const handleChangeRowsPerPageReal = (event) => {
    setRowsPerPageReal(+event.target.value);
    setPageReal(0);
  };

  const handleToggleDialog = () => {
    setToggleDialog(!toggleDialog);
  };

  const handleToggleImport = () => {
    setImportDialog(!importDialog);
    setNumEstimated(null);
  };

  const handleToggleImportReal = () => {
    setImportDialogReal(!importDialogReal);
    setNumReal(null);
  };

  const handleToggleUpdate = () => {
    setDialogUpdate(!dialogUpdate);
  };

  const updateAllWorks = async () => {
    setLoadingSave(true);
    try {
      apiMasterPlanService.postUpdateMPNumbers(newValues).then(() => {
        enqueueSnackbar("Valores de Obra actualizados con exito", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
          handleToggleUpdate();
          setNewValues([]);
          setLoadingSave(false);
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Error al guardar los datos", { variant: "error" });
    }
  };

  useEffect(() => {
    try {
      const currentDate = new Date();
      const updatedData = work.PMReal.map((item) => {
        const [day, month, year] = item.date.split("/");
        const itemDate = moment(new Date(`${year}-${month}-${day}`)).add(
          2,
          "months"
        );
        return {
          ...item,
          isActive: itemDate > currentDate,
        };
      });
      work.PMReal = updatedData;
      masterPlan.project = Project;
      apiMasterPlanService.postByMasterPlan(masterPlan).then((data) => {
        setAllWorks(data?.data?.newMasterPlanOrder);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Error al obtener las obras", { variant: "error" });
    }
  }, [Project, masterPlan, work]);

  const WorksDialog = (
    <Dialog
      open={toggleDialog}
      onClose={handleToggleDialog}
      aria-labelledby="nuevo-kpi-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {"Actualizar valor " + selectamount.type}
      </DialogTitle>
      <Divider variant="middle" orientation="horizontal" />
      <DialogContent>
        <Formik
          initialValues={{
            period: selectamount?.date,
            amount: selectamount?.amount || "",
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            values.project = Project;
            const newWork = work;
            if (selectamount.type === "Estimado") {
              newWork.PMEstimate = work.PMEstimate.map((item) => {
                if (item.date === values.period) {
                  return { date: values.period, amount: values.amount };
                }
                return item;
              });
            }
            if (selectamount.type === "Real") {
              newWork.PMReal = work.PMReal.map((item) => {
                if (item.date === values.period) {
                  return { date: values.period, amount: values.amount };
                }
                return item;
              });
            }
            values.id = work.id;
            values.Updatework = newWork;
            values.masterPlan = masterPlan.id;
            values.idUser = User.id;
            values.responsible = masterPlan.responsible;
            values.masterPlanName = masterPlan.name;
            try {
              const updatedData = work.PMReal.map((item) => {
                const [day, month, year] = item.date.split("/");
                const itemDate = moment(
                  new Date(`${year}-${month}-${day}`)
                ).add(2, "months");
                return {
                  ...item,
                  isActive: itemDate > new Date(),
                };
              });
              work.PMReal = updatedData;
              setNewValues(values);
              enqueueSnackbar("Valores de Obra pendientes de actualizar", {
                variant: "info",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              handleToggleDialog();
         
            } catch (error) {
              enqueueSnackbar("Error al actualizar los Valores de Obra", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              console.error("Error fetching data:", error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container={2}>
                <Grid item xs={6}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    className="border-0"
                  >
                    <InputLabel shrink htmlFor="period">
                      Período:
                    </InputLabel>
                    <br />
                    {selectamount.date}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="amount"
                    label={"Número : *"}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="number"
                    name="amount"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.amount}
                    onChange={handleChange}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                </Grid>
              </Grid>
              <Divider variant="middle" orientation="horizontal" />
              <br />
              <Grid container={2} className="text-end">
                <Button
                  color="primary"
                  variant="contained"
                  className="rounded-4 me-2"
                  onClick={handleToggleDialog}
                >
                  Cerrar
                </Button>
                <Button
                  startIcon={<Save />}
                  color="primary"
                  className="rounded-4 me-2"
                  variant="contained"
                  type="submit"
                  autoFocus
                  disabled={!dirty}
                >
                  Guardar Valor
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      setNumEstimated(data);
    });
  };

  const readExcelReal = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      setNumReal(data);
    });
  };

  const handleContractFileChange = (event) => {
    const file = event;
    if (file) {
      const sanitizedFile = new File([file], file?.name, {
        type: file.type,
      });
      setContractFile(sanitizedFile);
      readExcel(file);
    }
  };

  const handleContractFileChangeReal = (event) => {
    const file = event;
    if (file) {
      const sanitizedFile = new File([file], file?.name, {
        type: file.type,
      });
      setContractFile(sanitizedFile);
      readExcelReal(file);
    }
  };

  const exportDataToFile = async () => {
    const newObject = work.PMEstimate.reduce((acc, item) => {
      acc[item.date] = "";
      return acc;
    }, {});
    const ws1 = XLSX.utils?.json_to_sheet([newObject]);
    /* add to workbook */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Estimados");
    /* generate an XLSX file */
    XLSX.writeFile(wb, "Formato Estimados.xlsx");
  };

  const exportDataToFileReal = async () => {
    const newObject = work.PMReal.reduce((acc, item) => {
      acc[item.date] = "";
      return acc;
    }, {});
    const ws1 = XLSX.utils?.json_to_sheet([newObject]);
    /* add to workbook */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Real");
    /* generate an XLSX file */
    XLSX.writeFile(wb, "Formato Reales.xlsx");
  };

  const UpdateNumEstimate = () => {
    setLoadingImport(true);
    const values = {};
    values.project = Project;
    const newWork = { ...work }; // Hacemos una copia del objeto work
    const newPMEstimate = [...work.PMEstimate]; // Hacemos una copia del array PMEstimate
    const newData = NumEstimated[0];
  
    newPMEstimate.forEach((item) => {
      if (newData[item.date] !== undefined) {
        item.amount = newData[item.date];
      }
    });
  
    newWork.PMEstimate = newPMEstimate;
    values.id = work.id;
    values.Updatework = newWork;
    values.masterPlan = masterPlan.id;
    values.responsible = masterPlan.responsible;
    values.masterPlanName = masterPlan.name;
  
    try {
      apiMasterPlanService.postUpdateMPNumbers(values).then(() => {
        enqueueSnackbar("Valores de Obra actualizados con éxito", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setLoadingImport(false);
        handleToggleImport();
      });
    } catch (error) {
      setLoadingImport(false);
      enqueueSnackbar("Error al actualizar los valores de Obra", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      console.error("Error fetching data:", error);
    }
  };
  

  const UpdateNumReal = () => {
    setLoadingImport(true);
    const values = {};
    values.project = Project;
    const newWork = work;
    const newPMReal = work.PMReal;
    const newData = NumReal[0];
    newPMReal.forEach((item) => {
      if (newData[item.date] !== undefined) {
        item.amount = newData[item.date];
      }
    });
    newWork.PMReal = newPMReal;
    values.id = work.id;
    values.Updatework = newWork;
    values.masterPlan = masterPlan.id;
    values.responsible = masterPlan.responsible;
    values.masterPlanName = masterPlan.name;
    try {
      apiMasterPlanService.postUpdateMPNumbers(values).then(() => {
        enqueueSnackbar("Valores de Obra actualizados con exito", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setLoadingImport(false);
        handleToggleImportReal();
        
      });
    } catch (error) {
      setLoadingImport(false);
      enqueueSnackbar("Error al actualizar los valores de Obra", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      console.error("Error fetching data:", error);
    }
  };
  const importNumEstimate = (
    <Dialog
      open={importDialog}
      onClose={handleToggleImport}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        Importar Valores Estimados
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container className="mb-5">
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="GrayText">
              Formato de ejemplo con los periodos de los Valores estimados
            </Typography>
          </Grid>
          <Grid item xs={6} justifyContent="end" display="flex">
            <Button
              variant="contained"
              onClick={exportDataToFile}
              size="small"
              className={classes.downloadButton}
            >
              Descargar Ejemplo
            </Button>
          </Grid>
        </Grid>
        <Divider
          variant="fullWidth"
          orientation="horizontal"
          sx={{ backgroundColor: "gray" }}
        />
        <Grid container className="mt-2">
          <Grid item xs={12} display="flex" justifyContent="center" my={2}>
            <Typography variant="subtitle2" color="GrayText">
              Para importar valores estimados de la obra, cargue un archivo de
              Excel (.xls, .xlsx)
            </Typography>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" my={2}>
            {NumEstimated ? (
              <Button
                component="label"
                variant="contained"
                className="me-2"
                startIcon={<FileUploadIcon />}
                style={{ backgroundColor: "#902E30" }}
              >
                {contractFile?.name}
              </Button>
            ) : (
              <Button
                component="label"
                role={undefined}
                variant="contained"
                className="me-2"
                tabIndex={-1}
                onChange={(e) => handleContractFileChange(e.target.files[0])}
                startIcon={<FileUploadIcon />}
              >
                Selecciona un archivo
                <VisuallyHiddenInput
                  type="file"
                  accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                />
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleToggleImport}
          variant="contained"
          color="primary"
        >
          Cancelar
        </Button>
        <LoadingButton
          loadingPosition="end"
          loading={loadingImport}
          disabled={!NumEstimated}
          onClick={() => UpdateNumEstimate()}
          variant="contained"
          color="primary"
        >
          Importar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
  const importNumReal = (
    <Dialog
      open={importDialogReal}
      onClose={handleToggleImportReal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        Importar Valores Reales
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container className="mb-5">
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="GrayText">
              Formato de ejemplo con los periodos de los Valores reales
            </Typography>
          </Grid>
          <Grid item xs={6} justifyContent="end" display="flex">
            <Button
              variant="contained"
              onClick={exportDataToFileReal}
              size="small"
              className={classes.downloadButton}
            >
              Descargar Ejemplo
            </Button>
          </Grid>
        </Grid>
        <Divider
          variant="fullWidth"
          orientation="horizontal"
          sx={{ backgroundColor: "gray" }}
        />
        <Grid container className="mt-2">
          <Grid item xs={12} display="flex" justifyContent="center" my={2}>
            <Typography variant="subtitle2" color="GrayText">
              Para importar valores reales de la obra, cargue un archivo de
              Excel (.xls, .xlsx)
            </Typography>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" my={2}>
            {NumReal ? (
              <Button
                component="label"
                variant="contained"
                className="me-2"
                startIcon={<FileUploadIcon />}
                style={{ backgroundColor: "#902E30" }}
              >
                {contractFile?.name}
              </Button>
            ) : (
              <Button
                component="label"
                role={undefined}
                variant="contained"
                className="me-2"
                tabIndex={-1}
                onChange={(e) =>
                  handleContractFileChangeReal(e.target.files[0])
                }
                startIcon={<FileUploadIcon />}
              >
                Selecciona un archivo
                <VisuallyHiddenInput
                  type="file"
                  accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                />
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleToggleImportReal}
          variant="contained"
          color="primary"
        >
          Cancelar
        </Button>
        <LoadingButton
          loadingPosition="end"
          loading={loadingImport}
          disabled={!NumReal}
          onClick={() => UpdateNumReal()}
          variant="contained"
          color="primary"
        >
          Importar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  const updateWorks = (
    <Dialog
      open={dialogUpdate}
      onClose={handleToggleUpdate}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        Actualizar los valores guardados de las Obras
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="subtitle2" color="GrayText">
          Se actualizarán todos los valores pendientes que fueron guardados en
          cada una de las obras.
        </Typography>

        <Divider
          variant="fullWidth"
          orientation="horizontal"
          sx={{ backgroundColor: "gray" }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleToggleUpdate}
          variant="contained"
          style={{ backgroundColor: "#2196F3" }}
        >
          Cancelar
        </Button>
        <LoadingButton
          startIcon={<Save />}
          loadingPosition="center"
          className="me-2"
          loading={loadingsave}
          onClick={() => updateAllWorks()}
          variant="contained"
          color="primary"
          autoFocus
        >
          confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container>
      <div className="d-flex justify-content-between  mb-4">
        <IconButton
          style={{ background: "none" }}
          aria-label="edit"
          disableRipple
          color="primary"
          onClick={() => {
            setView(false);
          }}
        >
          <ChevronLeft color="primary" fontSize="large" />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            color="primary"
            className="m-1"
          >
            {(work?.name).length < 50 ? work?.name : (work?.name).slice(0, 50) + '...' }
          </Typography>
        </IconButton>
      </div>
      <Grid item xs={12} className="text-end">
        <Button
          color="primary"
          startIcon={<Save />}
          disabled={newValues?.length === 0}
          onClick={() => {
            handleToggleUpdate();
          }}
          variant="contained"
          size="small"
        >
          Guardar Cambios
        </Button>
      </Grid>
      <br />
      {!loading ? (
        <>
          <Divider className="mb-3" />
          <Grid container={2} className="mt-3">
            <Container maxWidth="lg">
              {work?.PMEstimate.length > 0 ? (
                <>
                  {permissionsService(
                    "masterPlan",
                    User.role.label,
                    "add_estimates"
                  ) && (
                    <Grid item xs={12} className="text-end">
                      <Button
                        onClick={() => {
                          handleToggleImport();
                        }}
                        variant="contained"
                        style={{ backgroundColor: "#2196F3" }}
                        className="me-2"
                      >
                        Importar Valores estimados
                      </Button>
                    </Grid>
                  )}
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    color="primary"
                    className="mb-2 fw-bold"
                  >
                    Plan Maestro Estimado Inicial
                  </Typography>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <StyledTableCell
                              width={400}
                              style={{ border: "1px solid white" }}
                            >
                              <b> {column.label}</b>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {work?.PMEstimate.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ).map((row) => (
                          <TableRow>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.date}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Grid container="3">
                                <Grid xs={11}>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {formatCurrency(row.amount)}
                                  </Typography>
                                </Grid>

                                {permissionsService(
                                  "masterPlan",
                                  User.role.label,
                                  "update_estimates"
                                ) && (
                                  <Grid xs={1}>
                                    <Tooltip title="Editar">
                                      <IconButton
                                        aria-label="settings"
                                        aria-controls="clauses-menu"
                                        disableRipple
                                        aria-haspopup="true"
                                        onClick={(e) => {
                                          handleToggleDialog();
                                          setSelectAmount({
                                            ...row,
                                            type: "Estimado",
                                          });
                                        }}
                                      >
                                        <EditIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      labelRowsPerPage="Filas por página"
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={work?.PMEstimate?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={labelDisplayedRows}
                    />
                  </TableContainer>
                  <br />

                  <Grid item xs={12} className="text-end">
                    <Button
                      onClick={() => {
                        handleToggleImportReal();
                      }}
                      variant="contained"
                      style={{ backgroundColor: "#2196F3" }}
                      className="me-2"
                    >
                      Importar Valores Reales
                    </Button>
                  </Grid>
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    color="primary"
                    className="mb-2 fw-bold"
                  >
                    Plan Maestro Real
                  </Typography>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <StyledTableCell
                              width={400}
                              style={{ border: "1px solid white" }}
                            >
                              <b>{column.label}</b>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {work?.PMReal.slice(
                          pageReal * rowsPerPageReal,
                          pageReal * rowsPerPageReal + rowsPerPageReal
                        )?.map((row) => (
                          <TableRow>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.date}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Grid container="3">
                                <Grid xs={11}>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {formatCurrency(row.amount)}
                                  </Typography>
                                </Grid>
                                {row.isActive && (
                                  <Grid xs={1}>
                                    <Tooltip title="Editar">
                                      <IconButton
                                        aria-label="settings"
                                        aria-controls="clauses-menu"
                                        disableRipple
                                        aria-haspopup="true"
                                        onClick={(e) => {
                                          handleToggleDialog();
                                          setSelectAmount({
                                            ...row,
                                            type: "Real",
                                          });
                                        }}
                                      >
                                        <EditIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      labelRowsPerPage="Filas por página"
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={work?.PMReal?.length || 0}
                      rowsPerPage={rowsPerPageReal}
                      page={pageReal}
                      onPageChange={handleChangePageReal}
                      onRowsPerPageChange={handleChangeRowsPerPageReal}
                    />
                  </TableContainer>
                </>
              ) : (
                <Box textAlign="center">
                  <img src={EmptyIcon} alt="Imagen de no data" width={150} />
                  <Typography variant="h6" color="GrayText">
                    No hay datos disponibles.
                  </Typography>
                </Box>
              )}
            </Container>
          </Grid>
        </>
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {WorksDialog}
      {importNumEstimate}
      {importNumReal}
      {updateWorks}
    </Container>
  );
};

export default MasterPlanInfoWorks;
