import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { PdfMakeWrapper, Table, Txt, Canvas, Line, Columns} from "pdfmake-wrapper";

// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);

const ReportsPdf = async ({data2}) => {
  const pdf = new PdfMakeWrapper();
  pdf.pageSize("A4")
  pdf.pageOrientation("landscape")
  
  pdf.add(
    new Txt('The Project Prueba 1')
      .color('#4A6B9B')
      .fontSize(20)
      .margin([0, 0, 0, 10])/* Izquierda, Arriba , Derecha , Abajo */
      .bold()
      .end
  );

  pdf.add(
    new Canvas([
      new Line([0, 0], [780, 0]).color('gray').end
    ])
    .margin([0, 0, 0, 10])
    .end
  );

  pdf.add(
    new Columns([
      'The Project International Group Prueba 1',
      { text: 'Número de contrato: 001', alignment: 'center' },
      { text: 'Zona Horaria/País: América/Costa Rica', alignment: 'right' }
    ])
    .color('gray')
    .end
  );

  pdf.add(
    new Canvas([
      new Line([0, 0], [780, 0]).color('gray').end
    ])
    .margin([0, 10, 0, 10])
    .end
  );


  pdf.add(
    new Txt([
      { text: 'Generado el: ', color: '#4A6B9B', bold: true },
      { text: '12 de Octubre, 2021 Hora: 14:45', color: 'gray' }
    ])
    .margin([0, 0, 0, 20])
    .end
  );
  
  
  pdf.add(
    new Table([
      [
        new Txt('Cláusula').color('#4A6B9B').bold().end, 
        new Txt('Número de Cláusula').color('#4A6B9B').bold().end, 
        new Txt('Fecha de Vencimiento').color('#4A6B9B').bold().end, 
        new Txt('Fecha de Apro/Rech').color('#4A6B9B').bold().end, 
        new Txt('Tiempo de Retraso').color('#4A6B9B').bold().end, 
        new Txt('Tiempo de Aprobación').color('#4A6B9B').bold().end, 
        new Txt('Responsable').color('#4A6B9B').bold().end, 
        new Txt('Estado').color('#4A6B9B').bold().end, 
        new Txt('Criticidad').color('#4A6B9B').bold().end, 
        new Txt('Categoría').color('#4A6B9B').bold().end
      ],
      ...extractData(data2)
    ])
    .layout({
      fillColor: (rowIndex) => '#EBEFF3',
      hLineColor: (i, node) => 'gray',
      vLineColor: (i, node) => 'gray'
    })
    .headerRows(1)
    .widths([ 80, 65, '*', '*', '*', '*', '*', 55, 55, 80 ])
    .end
  );

  pdf.create().open();
};

function extractData(data) {
    return data.map((row) => [
      {text: row.Clause ||'', color: 'gray'},
      {text: row.NumClause ||'', color: 'gray', alignment: 'center'},
      {text: row.DateExpiration ||'', color: 'gray'},
      {text: row.DateStatus ||'', color: 'gray', alignment: 'center'},
      {text: row.TimeExpiration ||'', color: 'gray'},
      {text: row.TimeExpiration ||'', color: 'gray'},
      {text: row.Responsible ||'', color: 'gray'},
      {text: row.Status ||'', color: row.Status === 'En Revisión' ? '#63B4CD' : '#E7A759', bold: true},
      {text: row.StatusCritic ||'', color: 'red', bold: true},
      {text: row.Category ||'', color: 'gray'},
    ]);
  }

  

export default ReportsPdf;