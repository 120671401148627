import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: 2,
    position: 'absolute',
    top: 5,
  },
  dialogTitle: {
    paddingRight: '0px',
    textAlign: 'center',
    fontSize: '1.5em',
    color: '#99B4D1',
    fontWeight: 'bold'
  },
  NewButton: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingRight: '1em'
  },
  menuItemIcon: {
    color: '#2361c6 !important'
  },
  tabStyle: {
    width: '13em !important',
    textTransform: 'none !important',
    borderRadius: '1.5em !important',
    backgroundColor: 'white !important',
    color: '#99B4D1 !important',
    marginRight: '1em !important',
    '&.Mui-selected':
    {
      backgroundColor: '#2162C0 !important',
      color: 'white !important'
    }
  },
  buttonStyle: {
    marginRight: '1em !important',
    textTransform: 'none !important',
    background: '#2162C0 !important',
    borderRadius: '10em !important'
  },
  projectStyle: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingRight: '1em'
  },
  selectedItem: {
    color: '#a7bcd5 !important',
    '&.Mui-selected': {
      backgroundColor: '#1976D2 !important',
      color: 'white !important',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '& .MuiListItemIcon-root': {
        color: 'white !important',
      },
    },
  },
  icon: {
    color: '#a7bcd5 !important'

  },
  tableContainer: {
    maxHeight: '600px',
    overflow: 'auto',
    background: "#F0F4F7"
  },
  columnCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    background: "#F0F4F7"
  },
  blueColumn: {
    backgroundColor: '#1976D2',
    color: 'white',
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center"
  },
  blueColumn2: {
    backgroundColor: '#F0F4F7',
    color: 'black',
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center"
  },
  ApprovalCheck: {
    color: "white",
    backgroundColor: "#b6dc87",
    borderRadius: "50%",
    border: "1px solid #b6dc87",
    margin: "5px",
    fontSize: "30px !important"
  },
  DefaultCheck: {
    color: "rgb(220,228,231)",
    backgroundColor: "#f9fafc",
    borderRadius: "50%",
    margin: "5px",
    fontSize: "30px !important"
  },
  columnsStyle: {
    color: "#5a6770", 
    fontWeight: "500", 
    textAlign: "justify"
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
  },
  downloadButton: {
    backgroundColor: '#E0E0E0',
    color: '#333',
    marginBottom: theme.spacing(2),
  },
  fileInput: {
    display: 'none',
  },
  stickyHeader: {
    backgroundColor: "white",
    width: "100%",
    position: "sticky",
    height: "fit-content",
    zIndex: 1,
    paddingBottom: "5px"
  }
}));

export default useStyles