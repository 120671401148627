import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ redirectPath = "/" }) => {
  const User = useSelector((state) => state?.setUserLogin?.user);
  if (User?.length === 0 || !User) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};
export default ProtectedRoute;
