import moment from 'moment-timezone';
import { format, addDays, addMonths } from 'date-fns';

export const fileDateToDate = (numberDate) => {
  const milliseconds =
    numberDate?._seconds * 1000 + numberDate?._nanoseconds / 1000000;
  return new Date(milliseconds);
};

export const dateFormat = (date) => {
  return moment.unix(date?._seconds).format("DD/MM/YYYY HH:mm");
};

export const dateFormat2 = (date) => {
  return date?.replace('T', ' ');
};

export const dateFormatEs = (date) => {
  return moment(date?.replace('T', ' ') || date).format('DD/MM/YYYY HH:mm');
};

export const AddDaysToDate = (dateString, days, timezone) => {
  const date = moment.tz(dateString, timezone).toDate();

  // Añadir días
  const newDate = addDays(date, days);

  // Convertir de vuelta a string en formato "yyyy-MM-dd"
  const newDateString = format(newDate, 'yyyy-MM-dd HH:mm');

  return newDateString
}

export const AddMonthsToDate = (dateString, months, timezone) => {
  const date = moment.tz(dateString, timezone).toDate();

  // Añadir meses
  const newDate = addMonths(date, months);

  // Convertir de vuelta a string en formato "yyyy-MM-dd"
  const newDateString = format(newDate, 'yyyy-MM-dd HH:mm');

  return newDateString
}

export const excelDateToJSDate = (serial, timezone) => {
  // Calcular los días UTC desde el 1 de enero de 1970
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400; // Convertir días a segundos
  var date_info = new Date(utc_value * 1000); // Crear objeto de fecha en UTC

  // Calcular la fracción del día para horas, minutos y segundos
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;
  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  // Crear una fecha ajustada con los valores calculados
  var adjustedDate = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate() + 1, hours, minutes, seconds);

  // Convertir la fecha ajustada a la zona horaria especificada usando Moment Timezone
  var formattedDate = moment(adjustedDate).format('YYYY-MM-DDTHH:mm');

  return formattedDate;
};




export const formatDateToDateTimeLocal = (dateString) => {
  return `${dateString}T00:00`;
};
