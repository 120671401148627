export const usersPermissions = {
    "Super Administrador": [
        'view',
        'add', 
        'edit', 
        'delete'
    ],
    "Administrador TPIG": [
        'view', // excepto admin tpig o super admin
        'add',  
        'edit', 
        'delete'
    ],
    "Administrador Proyecto": [],
    "Aprobador": [],
    "Gestionador": [],
    "Usuario": []
};
