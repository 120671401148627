import axiosInstance from "src/utils/axios";

const api = '/api/clauses'

const apiClausesService = {
  post: (data, projectId) => {
    return axiosInstance.post(api + '/' + projectId, data);
  },
  update: (projectId, clauseId,data) => {
    return axiosInstance.put(api + '/' + projectId + '/' + clauseId, data);
  },
  newReview: (data,projectId, clauseId) => {
    const formData = new FormData();
    formData.append('oficioFile', data.oficioFile, data.oficioFile.name);
    formData.append('data', JSON.stringify(data));

    if (data.generalFiles.length > 0) {
      for (const file of data.generalFiles) {
        formData.append('generalFiles', file, file.name);
      }
    }

    return axiosInstance.post(api + '/review/' + projectId + '/' + clauseId, formData);
  },
  import: (data, projectId) => {
    return axiosInstance.post(api + '/import/' + projectId, data);
  },
  getByProject: (projectId) => {
    return axiosInstance.get(api + '/' + projectId);
  },

  getByProjectGrafic: (projectId) => {
    return axiosInstance.post(api + '/byProjectId/grafic' , projectId);
  },

  getByStatus: (projectId, status) => {
    return axiosInstance.get(api + '/byStatus/' + status + '/' + projectId);
  },
  updateStatus: (projectId, clauseId, data) => {
    return axiosInstance.put(api + '/status/' + projectId + '/' + clauseId, data);
  },
  getByReview: (projectId, clauseId) => {
    return axiosInstance.get(api + '/byReview/' + projectId + '/' + clauseId);
  },
  delete: (projectId, clauseId) => {
    return axiosInstance.delete(api + '/' + projectId + '/' + clauseId)
  }
};

export default apiClausesService;
