import axiosInstance from "src/utils/axios";

const api = "/api/masterPlan";

const apiMasterPlanService = {
  postCreate: (data) => {
    return axiosInstance.post(api + "/", data);
  },

  postUpdate: (data) => {
    return axiosInstance.post(api + "/update", data);
  },
  postByProject: (data) => {
    return axiosInstance.post(api + "/masterPlan", data);
  },

  postByMasterPlan: (data) => {
    return axiosInstance.post(api + "/works", data);
  },

  postUpdateMPNumbers: (data) => {
    return axiosInstance.post(api + "/worksUpdateNumber", data);
  },

  postUpdateMPWorks: (data) => {
    return axiosInstance.post(api + "/worksUpdate", data);
  },

  postByMasterPlanWork: (data) => {
    return axiosInstance.post(api + "/newWorks", data);
  },

  delete: (projectId, id) => {
    return axiosInstance.delete(api + "/" + projectId + "/" + id);
  },

  deleteWork: (projectId, masterPlan, id) => {
    return axiosInstance.delete(
      api + "/" + projectId + "/" + masterPlan + "/" + id
    );
  },
};

export default apiMasterPlanService;
