export const settingsPermissions = {
    "Super Administrador": [
        'add', 
        'delete'
    ],
    "Administrador TPIG": [
        'add', 
        'delete'
    ],
    "Administrador Proyecto": [
        'add'
    ],
    "Aprobador": [],
    "Gestionador": [],
    "Usuario": []
};
