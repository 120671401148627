import { ChevronLeft } from "@mui/icons-material";
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import { Formik } from "formik";
import ReactApexChart from "react-apexcharts";
import { useState, useEffect } from "react";
import { fileDateToDate, dateFormat } from "src/utils/timeLocal";
import { useSelector } from "react-redux";
import apiKPIService from "src/services/kpisService";
import { enqueueSnackbar } from "notistack";
import moment from "moment";

const FormKPI = ({
  kpis,
  isUpdate,
  setUpdate,
  KPIDeviation,
  KPIFrequency,
  KPIType,
  Users,
}) => {
  const [accReal, setAccReal] = useState(0);
  const [accEstimated, setAccEstimated] = useState(0);
  useEffect(() => {
    const date = new Date();
    let accEstimatedTotal = 0;
    let accRealtotal = 0;
    for (let i = 0; i < kpis.datePeriod.length; i++) {
      if (
        date >=
        new Date(
          moment(fileDateToDate(kpis?.datePeriod[i])).format("YYYY-MM-DD")
        )
      ) {
        accRealtotal += kpis.realNum[i];
        accEstimatedTotal += kpis.estimatedNum[i];
      }
    }
    setAccEstimated(accEstimatedTotal);
    setAccReal(accRealtotal);
  }, []);

  const lineChart = {
    series: [
      {
        name: "Estimado",
        data: kpis.estimatedNum || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Reales",
        data: kpis.realNum || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3],
        curve: "straight",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - <strong>" +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            "</strong>"
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (mins)";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " per session";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };

  const columnChart = {
    series: [
      {
        data: [accEstimated, accReal],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          vertical: true,
          distributed: true,
        },
      },
      colors: ["#35486a", "#44648b"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ["Estimado", "Reales"],
      },
    },
  };
  let columns = [{ id: "name", label: "Período" }];

  for (let i = 0; i < kpis?.datePeriod?.length; i++) {
    columns.push({
      id: i,
      label:
        moment(fileDateToDate(kpis?.datePeriod[i])).format("YYYY-MM-DD") + "",
    });
  }

  const Project = useSelector((state) => state?.setUserLogin?.project.id);
  const SaveNewPKI = (values) => {
    try {
      values.id = kpis.id;
      apiKPIService.postUpdate(values).then(() => {
        enqueueSnackbar("KPI actualizada con exito", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
    } catch (error) {
      enqueueSnackbar("Error al actualizar el KPI", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      console.error("Error fetching data:", error);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <IconButton
          style={{ background: "none" }}
          aria-label="edit"
          color="primary"
          onClick={() => {
            setUpdate(false);
          }}
        >
          <ChevronLeft color="primary" fontSize="large" />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            color="primary"
            className="m-1"
          >
            {kpis?.name || ""}
          </Typography>
        </IconButton>
      </Grid>

      <Grid item xs={12} className="overflow-y-scroll">
        <Formik
          initialValues={{
            name: kpis?.name || "",
            type: kpis?.type || [],
            frequency: kpis?.frequency || [],
            responsible: kpis?.responsible || [],
            assignment: kpis?.assignment || [],
            unit: kpis?.unit || "",
            closingPeriod: kpis?.closingPeriod || "",
            typeDeviation: kpis?.typeDeviation || [],
            startDate: dateFormat(kpis?.startDate) || null,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("El nombre es requerido"),
            type: Yup.string().required("El tipo es requerido"),
            frequency: Yup.string().required("La frecuencia es requerida"),
            responsible: Yup.string().required("El responsable es requerido"),
            assignment: Yup.array().required("El campo es requerido"),
            typeDeviation: Yup.string().required(
              "El tipo de desviación es requerido"
            ),
            startDate: Yup.string().required("La fecha de inicio es requerido"),
            unit: Yup.string().required("La unidad de medida es requerida"),
            closingPeriod: Yup.string().required(
              "El Periodo de cierre requerido"
            ),
          })}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            values.project = Project;
            SaveNewPKI(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    id="name"
                    label="Nombre de cuadro de KPI: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    name="name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    required
                    className="border-0"
                  >
                    <InputLabel shrink htmlFor="type">
                      Tipo:
                    </InputLabel>
                    <Select
                      id="type"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                    >
                      {KPIType.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    required
                    className="border-0"
                  >
                    <InputLabel shrink htmlFor="frequency">
                      Frecuencia:
                    </InputLabel>
                    <Select
                      id="frequency"
                      name="frequency"
                      value={values.frequency}
                      onChange={handleChange}
                    >
                      {KPIFrequency.map((frequency) => (
                        <MenuItem key={frequency} value={frequency}>
                          {frequency}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    required
                    className="border-0"
                  >
                    <InputLabel shrink htmlFor="responsible">
                      Responsable:{" "}
                    </InputLabel>
                    <br />
                    <Autocomplete
                      options={Users}
                      id="responsible"
                      name="responsible"
                      size="small"
                      noOptionsText="sin opciones"
                      defaultValue={
                        kpis?.responsible &&
                        Users.find((usr) => kpis?.responsible === usr.id)
                      }
                      onChange={(e, user) => {
                        if (user) {
                          const { id } = user;
                          values.responsible = id;
                        } else {
                          values.responsible = "";
                        }
                      }}
                      getOptionLabel={(user) => user.fullname}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          size="small"
                          fullWidth
                          error={
                            touched.responsible && Boolean(errors.responsible)
                          }
                          helperText={touched.responsible && errors.responsible}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    required
                    className="border-0"
                  >
                    <InputLabel shrink htmlFor="assignment">
                      Asignación:{" "}
                    </InputLabel>
                    <Select
                      id="assignment"
                      multiple
                      name="assignment"
                      value={values.assignment}
                      onChange={handleChange}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                Users.find((usr) => value === usr.id)
                                  ?.fullname || value.fullname
                              }
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {Users.map((user) => (
                        <MenuItem key={user.id} value={user}>
                          {user.fullname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    required
                    className="border-0"
                  >
                    <InputLabel shrink htmlFor="unit">
                      Unidad de medida:{" "}
                    </InputLabel>
                    <Input
                      id="unit"
                      name="unit"
                      value={values.unit}
                      onChange={handleChange}
                    />
                  </FormControl>

                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    required
                    className="border-0"
                  >
                    <InputLabel shrink htmlFor="closingPeriod">
                      Periodo de cierre:{" "}
                    </InputLabel>
                    <Input
                      id="closingPeriod"
                      type="number"
                      name="closingPeriod"
                      value={values.closingPeriod}
                      onChange={handleChange}
                    />
                  </FormControl>

                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    required
                    className="border-0"
                  >
                    <InputLabel shrink htmlFor="typeDeviation">
                      Tipo de Desviación:{" "}
                    </InputLabel>
                    <Select
                      id="typeDeviation"
                      name="typeDeviation"
                      value={values.typeDeviation}
                      onChange={handleChange}
                    >
                      {KPIDeviation.map((deviation) => (
                        <MenuItem key={deviation} value={deviation}>
                          {deviation}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    id="startDate"
                    label="Fecha de inicio: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="date"
                    name="startDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.startDate}
                    onChange={handleChange}
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                </Grid>

                <Grid item xs={9}>
                  <Grid container spacing={2} className="mb-4">
                    <Grid item xs={8}>
                      <TextField
                        id="name"
                        label="Nombre de cuadro de KPI: *"
                        fullWidth
                        margin="dense"
                        variant="standard"
                        name="name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.name}
                        onChange={handleChange}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={4} className="text-end">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!dirty}
                      >
                        Actualizar
                      </Button>
                    </Grid>
                  </Grid>

                  <Typography
                    variant="subtitle1"
                    color="primary"
                    fontWeight="bold"
                    className="mt-4"
                  >
                    Tablas y gráficos
                  </Typography>

                  <Box display="flex" justifyContent="space-between">
                    <div id="chart">
                      <center>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          className="mt-4"
                        >
                          {values.name + " " + values.frequency}
                        </Typography>
                      </center>
                      <ReactApexChart
                        name
                        options={lineChart.options}
                        series={lineChart.series}
                        type="line"
                        height={350}
                        width={400}
                      />
                    </div>

                    <div id="chart">
                      <center>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          className="mt-4"
                        >
                          {"Acumulado al " +
                            moment(new Date()).format("DD/M/YYYY")}
                        </Typography>
                      </center>
                      <ReactApexChart
                        options={columnChart.options}
                        series={columnChart.series}
                        type="bar"
                        height={350}
                        width={450}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default FormKPI;
