import { useEffect, useState } from "react";
import TopBar from "../../../components/topbar/topbar";
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  LinearProgress,
  Grid,
  Divider,
  Box,
  CardHeader,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getSelectProject } from "../../../redux/actions/projectAction";
import apiProjectsService from "src/services/projectsService";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
const Home = () => {
  const navigate = useNavigate();
  const User = useSelector((state) => state?.setUserLogin?.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const data = await apiProjectsService.get(User.id);
      const { active } = data.data.reduce(
        (acc, project) => {
          if (project.status === "active") {
            acc.active.push(project);
          }
          return acc;
        },
        { active: [] }
      );
      active.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

      setProjects(active);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar("Error al obtener los datos", { variant: "error" });
      setLoading(false);
    }
  };

  const handleProject = (project) => {
    sessionStorage.setItem("Project", JSON.stringify(project));
    dispatch(getSelectProject(project || "", User));
    navigate("/projects/details");
  };

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Panel Principal";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  return (
    <div>
      <TopBar />
      <Box className={classes.stickyHeader}>
        <Grid container>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            color="primary"
            className="m-3"
          >
            Proyectos
          </Typography>
        </Grid>
        <Divider className="mb-1" />
        <Typography variant="body2" component="h2" className="m-3">
          {projects.length} Proyecto(s)
        </Typography>
      </Box>

      <Grid container spacing={2} className="p-4">
        {!loading && projects.length > 0
          ? projects.map((project) => (
              <Grid item lg={4} key={project.id}>
                <Card variant="outlined" className={classes.card}>
                  <CardActionArea
                    className={classes.cardActionArea}
                    onClick={() => handleProject(project)}
                  >
                    <CardHeader
                      title={project?.name}
                      subheader={`${project?.responsible?.contactName} / ${project?.responsible?.companyName}`}
                      titleTypographyProps={{ color: "primary" }}
                      subheaderTypographyProps={{
                        color: "GrayText",
                        className: "mt-2",
                        fontWeight: "bolder",
                      }}
                    />
                    <Divider orientation="horizontal" flexItem />
                    <CardContent className={classes.cardContent}>
                      <Typography color="textSecondary" className="mb-2">
                        Fecha de inicio: {project.startDate}
                      </Typography>
                      <Typography color="textSecondary" className="mb-2">
                        Años de delegación del proyecto:{" "}
                        {project.delegationPeriod}
                      </Typography>
                      <Typography color="textSecondary" className="mb-2">
                        Zona: {project.timeZone}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          : !loading &&
            projects.length === 0 && (
              <>
                <Typography variant="h5" className="ms-4" color="GrayText">
                  No se han encontrado resultados
                </Typography>
                <Divider variant="fullWidth" orientation="horizontal" />
              </>
            )}

        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Grid>
    </div>
  );
};

export default Home;
