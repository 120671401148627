import { useEffect, useState, useRef } from "react";
import {
  IconButton,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  Typography,
  Divider,
  Button,
  Box,
  Conta,
  TextFieldiner,
  InputAdornment,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { LocalizationProvider } from "@mui/x-date-pickers";
import esLocale from "date-fns/locale/es";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Add, Close, Delete, Edit, Email, Save, Send } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import countries from "src/utils/countries";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import apiUserService from "src/services/usersService";
import apiProjectsService from "src/services/projectsService";
import { dateFormat } from "../../../utils/timeLocal";
import { flags } from "src/utils/flags";
import { useSelector } from "react-redux";
import permissionsService from "src/services/permissionsService";

const FormUsers = ({ data, setEdit, isEdit, roles }) => {
  const User = useSelector((state) => state?.setUserLogin?.user);
  const formikRef = useRef(null);
  const [projects, setProyects] = useState([])
  const [showDialog, setShowDialog] = useState(false);
  const [changedBlock, setChangedBlock] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);

  const auxEmail = data.email;

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    await apiProjectsService.get(User.id).then((res) => {
      setProyects(res.data);
    }).catch((error) => {
      enqueueSnackbar("No se han podido cargar los proyectos", { variant: "error" })
    })
  }
  const sendEmailVerification = async (email) => {
    setLoadingVerification(true);
    try {
      await apiUserService.verificationEmail({ email });
      enqueueSnackbar("Se ha enviado el mensaje de verificación con éxito al usuario", { variant: 'success' });
    } catch (err) {
      // Verifica si el error tiene un mensaje específico o un código que puedas usar
      const errorMessage = err.response?.data?.message || err.message || "Ha ocurrido un error al enviar el mensaje de verificación.";
      
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setLoadingVerification(false);
    }
  };
  

  const projectsAndRoles = (values, handleChange, setFieldValue) => {
    return (
      <Grid container spacing={2}>
        <Grid item lg={12} className="mt-2">
          <Typography variant="h5" color="primary">
            Administración de Roles
          </Typography>
        </Grid>

        {permissionsService("projects", User?.role?.label, "add") && <><Grid item lg={2}>
          <Typography variant="subtitle1" color="black" fontWeight="bold">
            Rol principal
          </Typography>
        </Grid><Grid item lg={4}>
            <FormControl
              variant="standard"
              fullWidth
              margin="dense"
              required
              className="border-0"
            >
              <Select
                id="role"
                name="role"
                value={values.role.value}
                onChange={(e) => {
                  const selectedRole = roles.find(
                    (role) => role.value === e.target.value
                  );
                  setFieldValue("role", selectedRole);
                }}
              >
                {roles?.map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid></>}
        <Grid item lg={6}></Grid>
        {/* Primer rol */}
        {values?.projects?.map((obj, index) => (
          <Grid container spacing={2} className="ps-4" alignItems="center">
            <Grid item lg={2}>
              <Typography variant="subtitle1" color="black" fontWeight="bold">
                Rol
              </Typography>
            </Grid>
            <Grid item lg={3}>
              <FormControl
                variant="standard"
                fullWidth
                margin="dense"
                required
                className="border-0"
              >
                <Select
                  id={`projects.role${index}`}
                  name={`projects.role${index}`}
                  value={obj.role.value}
                  onChange={(e) => {
                    const selectedRole = roles.find(
                      (role) => role.value === e.target.value
                    );
                    const updatedProjects = [...values.projects];
                    updatedProjects[index] = {
                      ...updatedProjects[index],
                      role: selectedRole,
                    };
                    setFieldValue("projects", updatedProjects);
                  }}
                >
                  {roles?.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={2}>
              <Typography variant="subtitle1" color="black" fontWeight="bold">
                Proyecto
              </Typography>
            </Grid>
            <Grid item lg={3}>
              <FormControl
                variant="standard"
                fullWidth
                margin="dense"
                required
                className="border-0"
              >
                <Select
                  id={`projects.project${index}`}
                  name={`projects.project${index}`}
                  value={obj.project.id}
                  onChange={(e) => {
                    const selectedProject = projects.find(
                      (project) => project.id === e.target.value
                    );
                    const updatedProjects = [...values.projects];
                    updatedProjects[index] = {
                      ...updatedProjects[index],
                      project: selectedProject,
                    };
                    setFieldValue("projects", updatedProjects);
                  }}
                >
                  {projects?.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={2}>
              <IconButton
                aria-label=""
                onClick={() => {
                  const updatedProjects = [...values.projects];
                  updatedProjects.splice(index, 1);
                  setFieldValue("projects", updatedProjects);
                }}
              >
                <Delete color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          type="button"
          variant="contained"
          className="d-flex justify-content-center m-auto mt-3 mb-3"
          endIcon={<Add />}
          onClick={() => {
            setFieldValue("projects", [
              ...values.projects,
              {
                project: {
                  id: "",
                  name: "",
                },
                role: {
                  label: "",
                  value: "",
                },
              },
            ]);
          }}
        >
          Nuevo Rol / Proyecto
        </Button>
      </Grid>
    );
  };

  const infoProjects = (
    <Grid container spacing={2} className="mt-2">
      <Grid item xs={2}>
        <Typography variant="subtitle1" color="black" fontWeight="bold">
          Cantidad de proyectos
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle1" color="primary">
          {data?.projects?.length || 0} proyectos
        </Typography>
      </Grid>

      <Divider className="mb-1" />
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="black" fontWeight="bold">
          Proyectos y roles
        </Typography>
      </Grid>

      {data?.projects?.map((obj, index) => (
        <>
          <Grid item xs={2} key={index}>
            <Typography variant="subtitle1" color="primary">
              {obj.project.name}:{" "}
            </Typography>
          </Grid>
          <Grid item xs={4} >
            <Typography variant="subtitle1" color="gray">
              {obj.role.label}
            </Typography>
          </Grid>
        </>
      ))}
    </Grid>
  );

  const validateDate = (value) => {
    const date = moment(value, "YYYY-MM-DD", true); // Parsing date using Moment.js
    const age = moment().diff(date, "years");

    if (!date.isValid()) {
      return false; // Invalid date format
    }

    return age >= 18 && age < 100;
  };
  const handleDialog = () => {
    setShowDialog(!showDialog);
  };


  const handleCancelEdit = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEdit(false);
    handleDialog()
  }

  const DialogReturn = (
    <Dialog open={showDialog} onClose={handleDialog}>
      <DialogTitle>Tiene cambios sin guardar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Está seguro de cancelar los cambios?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialog} color="primary">
          Seguir editando
        </Button>
        <Button onClick={() => handleCancelEdit()} color="primary">
          Cancelar cambios
        </Button>
      </DialogActions>
    </Dialog>
  )

  const handleDirty = (dirty) => {
    if (dirty) {
      handleDialog()
    } else {
      setEdit(false)
    }

  }
  const formUser = (
    <>
      {data && data?.email && <Formik
        innerRef={formikRef}
        initialValues={{
          email: data.email,
          role: data?.role || "",
          fullname: data?.fullname || data?.contactName || "",
          identification: data?.identification || "",
          birthdate: data?.birthdate || dateFormat(data?.birthdate) || null,
          phone: {
            country: data?.phone?.country || "EC",
            value: data?.phone?.value || null,
            areaCode: data?.phone?.areaCode || "593",
          },
          cellphone: {
            country: data?.cellphone?.country || "EC",
            value: data?.cellphone?.value || null,
            areaCode: data?.cellphone?.areaCode || "593",
          },
          charge: data?.charge || "",
          institution: data?.institution || "",
          projects:
            data?.projects?.length > 0 ? data?.projects : [],
          password: "",
          blocked: data?.blocked || false,
          confirmPassword: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Por favor ingresa un correo electrónico válido")
            .required("El correo electrónico es obligatorio"),
          fullname: Yup.string().required("El nombre completo es obligatorio"),
          identification: Yup.string().required(
            "La identificación es obligatoria"
          ),
          birthdate: Yup.date()
            .max(
              new Date(),
              "La fecha de nacimiento no puede estar en el futuro"
            )
            .test(
              "valid-date",
              "Fecha inválida, debes tener entre 18 y 100 años",
              (value) => validateDate(value)
            )
            .required("La fecha de nacimiento es obligatoria"),
          phone: Yup.object().shape({
            country: Yup.string().required(
              "El país del teléfono es obligatorio"
            ),
            value: Yup.number()
              .typeError("Solo se admiten números")
              .required(
                "El número de teléfono es obligatorio"
              ),
          }),
          cellphone: Yup.object().shape({
            country: Yup.string().required(
              "El país del celular es obligatorio"
            ),
            value: Yup.number()
              .typeError("Solo se admiten números")
              .required("El número de celular es obligatorio"),
          }),
          charge: Yup.string().required("El cargo es obligatorio"),
          institution: Yup.string().required("La empresa es obligatoria"),
          password: Yup.string().matches(
            /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
            "La contraseña debe tener al menos 8 caracteres compuestos por números y letras"
          ),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Las contraseñas deben coincidir"
          ),
        })}
        onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
          values.id = data.id;
          if (values.email !== auxEmail) {
            values.newEmail = values.email;
            values.oldEmail = auxEmail;
          }
          if (changedBlock) {
            const user = {
              email: data.email,
              id: data.id,
              blocked: values.blocked
            };
            apiUserService.block(user).then((res) => {
              if (res.status !== 200) {
                enqueueSnackbar("Ha ocurrido un error al realizar el bloqueo", {
                  variant: "error",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
              }
            });
          }
          apiUserService.updateData(values).then((res) => {
            enqueueSnackbar("Usuario actualizado con éxito", {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            resetForm({ values })
            setChangedBlock(false);
            setSubmitting(false);

          }).catch((res) => {
            console.log(res);
            resetForm()
            enqueueSnackbar(res?.response?.data?.message || 'Error en la actualización', {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            setSubmitting(false);
          });

        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          dirty,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={7}>
                <Typography variant="h5" color="primary">
                  Información General
                </Typography>
              </Grid>


              <Grid item lg={5} justifyContent="end" display="flex">

                {isEdit && <LoadingButton
                  size="medium"
                  variant="contained"
                  color="error"
                  className="me-2"
                  startIcon={<Close />}
                  onClick={() => handleDirty(dirty)}
                >
                  Cancelar
                </LoadingButton>
                }
                {!isEdit && <Button variant="contained" color="primary" startIcon={<Edit />} onClick={() => setEdit(true)}>
                  Editar
                </Button>}

                {isEdit && <LoadingButton
                  size="medium"
                  variant="contained"
                  type="submit"
                  startIcon={<Save />}
                  disabled={!dirty}
                  loading={isSubmitting}
                  loadingPosition="start"

                >
                  {"Guardar cambios"}
                </LoadingButton>}
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Nombre completo
                </Typography>
              </Grid>
              <Grid item lg={4}>
                {isEdit ? (
                  <TextField
                    id="fullname"
                    name="fullname"
                    className="w-100"
                    variant="standard"
                    value={values.fullname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.fullname && errors.fullname)}
                    helperText={touched.fullname && errors.fullname}
                  />
                ) : (
                  <Typography variant="subtitle1" color="gray">
                    {values.fullname}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  No. de Identificación
                </Typography>
              </Grid>
              <Grid item lg={4}>
                {isEdit ? (
                  <TextField
                    id="identification"
                    name="identification"
                    variant="standard"
                    className="w-100"
                    value={values.identification}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.identification && errors.identification
                    )}
                    helperText={touched.identification && errors.identification}
                  />
                ) : (
                  <Typography variant="subtitle1" color="gray">
                    {values?.identification}
                  </Typography>
                )}
              </Grid>

              {/*  */}

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Nombre de usuario
                </Typography>
              </Grid>
              <Grid item lg={4}>
                <Typography variant="subtitle1" color="gray">
                  {data.username || data.email}
                </Typography>
              </Grid>

              {/*  */}

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Fecha de nacimiento
                </Typography>
              </Grid>
              <Grid item lg={4}>
                {isEdit ? (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <TextField
                      type="date"
                      id="birthdate"
                      name="birthdate"
                      variant="standard"
                      className="w-100"
                      value={values.birthdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.birthdate && errors.birthdate)}
                      helperText={touched.birthdate && errors.birthdate}
                    />
                  </LocalizationProvider>
                ) : (
                  <Typography variant="subtitle1" color="gray">
                    {values.birthdate}
                  </Typography>
                )}
              </Grid>

              {/*  */}

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Correo electrónico
                </Typography>
              </Grid>
              <Grid item lg={4}>
                {isEdit ? (
                  <TextField
                    id="email"
                    className="w-100"
                    variant="standard"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                ) : (
                  <Typography variant="subtitle1" color="gray">
                    {values?.email}
                  </Typography>
                )}
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Teléfono
                </Typography>
              </Grid>
              <Grid item lg={4}>
                {isEdit ? (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "8px",
                    }}
                  >
                    <FormControl variant="standard" style={{ width: "5em" }}>
                      <Select
                        labelId="phone-country"
                        id="phone.country"
                        value={values.phone}
                        onChange={(e) => {
                          const selectedCountry = countries.find(
                            (country) => country?.code === e.target.value
                          );
                          setFieldValue("phone.country", e.target.value);
                          setFieldValue(
                            "phone.areaCode",
                            selectedCountry.areaCode
                          );
                        }}
                        renderValue={(selected) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={flags(selected?.country, 20)}
                              srcSet={flags(selected?.country, 20)}
                              alt=""
                            />
                          </div>
                        )}
                      >
                        {countries?.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            <img
                              loading="lazy"
                              width="20"
                              src={flags(country.code, 20)}
                              srcSet={flags(country.code, 40)}
                              alt={country.name}
                              style={{ marginRight: "10px" }}
                            />
                            {country?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      id="phone.value"
                      name="phone.value"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            +{values.phone.areaCode}
                          </InputAdornment>
                        ),
                      }}
                      value={values.phone.value}
                      onChange={(e) =>
                        setFieldValue("phone.value", e.target.value)
                      }
                      style={{ flexGrow: 1 }}
                      onBlur={handleBlur}
                      variant="standard"
                      error={Boolean(
                        touched.phone?.value && errors.phone?.value
                      )}
                      helperText={touched.phone?.value && errors.phone?.value}
                    />
                  </Box>
                ) : (
                  <Typography variant="subtitle1" color="gray">
                    {values?.phone?.value}
                  </Typography>
                )}
              </Grid>

              {/*  */}
              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Cargo
                </Typography>
              </Grid>
              <Grid item lg={4}>
                {isEdit ? (
                  <TextField
                    id="charge"
                    name="charge"
                    variant="standard"
                    className="w-100"
                    value={values.charge}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.charge && errors.charge)}
                    helperText={touched.charge && errors.charge}
                  />
                ) : (
                  <Typography variant="subtitle1" color="gray">
                    {values.charge}
                  </Typography>
                )}
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Celular
                </Typography>
              </Grid>
              <Grid item lg={4}>
                {isEdit ? (
                  <FormControl fullWidth margin="dense" required>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: "16px",
                      }}
                    >
                      <FormControl variant="standard" style={{ width: "5em" }}>
                        <Select
                          labelId="cellphone-country"
                          id="cellphone.country"
                          value={values.cellphone}
                          onChange={(e) => {
                            const selectedCountry = countries.find(
                              (country) => country.code === e.target.value
                            );
                            setFieldValue("cellphone.country", e.target.value);
                            setFieldValue(
                              "cellphone.areaCode",
                              selectedCountry.areaCode
                            );
                          }}
                          renderValue={(selected) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={flags(selected.country, 20)}
                                srcSet={flags(selected.country, 40)}
                                alt=""
                              />
                            </div>
                          )}
                        >
                          {countries?.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                              <img
                                loading="lazy"
                                width="20"
                                src={flags(country.code, 20)}
                                srcSet={flags(country.code, 40)}
                                alt={country.name}
                                style={{ marginRight: "10px" }}
                              />
                              {country?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        id="cellphone.value"
                        name="cellphone.value"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +{values.cellphone.areaCode}
                            </InputAdornment>
                          ),
                        }}
                        value={values.cellphone.value}
                        onChange={(e) =>
                          setFieldValue("cellphone.value", e.target.value)
                        }
                        style={{ flexGrow: 1 }}
                        onBlur={handleBlur}
                        variant="standard"
                        error={Boolean(
                          touched.cellphone?.value && errors.cellphone?.value
                        )}
                        helperText={
                          touched.cellphone?.value && errors.cellphone?.value
                        }
                      />
                    </Box>
                  </FormControl>
                ) : (
                  <Typography variant="subtitle1" color="gray">
                    {values.cellphone.value}
                  </Typography>
                )}
              </Grid>

              {/*  */}

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Empresa
                </Typography>
              </Grid>
              <Grid item lg={4}>
                {isEdit ? (
                  <TextField
                    id="institution"
                    className="w-100"
                    variant="standard"
                    value={values.institution}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.institution && errors.institution)}
                    helperText={touched.institution && errors.institution}
                  />
                ) : (
                  <Typography variant="subtitle1" color="gray">
                    {values.institution}
                  </Typography>
                )}
              </Grid>

              {/* Seguridad */}
              {isEdit && (
                <>
                  <Grid item lg={12} className="mt-2">
                    <Typography variant="h5" color="primary">
                      Seguridad
                    </Typography>
                  </Grid>
                  <Grid item lg={2}>
                    <Typography
                      variant="subtitle1"
                      color="black"
                      fontWeight="bold"
                    >
                      Contraseña
                    </Typography>
                  </Grid>
                  <Grid item sm={12} lg={4}>
                    <TextField
                      margin="dense"
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      type="password"
                      fullWidth
                      variant="standard"
                      onBlur={handleBlur}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <Typography
                      variant="subtitle1"
                      color="black"
                      fontWeight="bold"
                    >
                      Verificar Contraseña
                    </Typography>
                  </Grid>
                  <Grid item sm={12} lg={4}>
                    <FormControl fullWidth margin="dense" required>
                      <TextField
                        margin="dense"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        type="password"
                        fullWidth
                        variant="standard"
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={2}>
                    <Typography
                      variant="subtitle1"
                      color="black"
                      fontWeight="bold"
                    >
                      Bloquear Usuario
                    </Typography>
                  </Grid>

                  <Grid item lg={4}>
                    <Switch
                      name="blocked"
                      checked={values.blocked}
                      onChange={(event) => {
                        setFieldValue("blocked", event.target.checked);
                        setChangedBlock(true);
                      }}
                    />
                  </Grid>
                  {isEdit && permissionsService("users", User?.role?.label, "edit") &&
                    <Grid item lg={6}>
                      <LoadingButton
                        size="medium"
                        variant="contained"
                        className="me-2"
                        type="submit"
                        onClick={() => sendEmailVerification(values.email)}
                        startIcon={<Email />}
                        loading={loadingVerification}
                        loadingPosition="start"

                      >
                        {"Enviar Verificación"}
                      </LoadingButton>
                    </Grid>}



                </>
              )}
            </Grid>

            <Divider
              variant="middle"
              style={{ backgroundColor: "gray !important" }}
              className="mt-4"
            />
            {isEdit && permissionsService("projects", User?.role?.label, "edit")
              ? projectsAndRoles(values, handleChange, setFieldValue)
              : infoProjects}
          </form>
        )}
      </Formik>}
    </>
  );

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Editar Usuarios";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  return (
    <div>
      {DialogReturn}
      {isEdit ? (
        <>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <IconButton
                style={{ background: "none" }}
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setEdit(false);
                }}
              >
                <ChevronLeftIcon color="primary" fontSize="large" />
                <Typography
                  variant="h4"
                  component="h1"
                  gutterBottom
                  color="primary"
                  className="m-3"
                >
                  Regresar
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
          <Divider className="mb-1" />
        </>
      ) : null}
      <Box className="p-4">{formUser}</Box>
    </div>
  );
};

export default FormUsers;
