
import { makeStyles } from '@material-ui/core/styles';
import backgroundImage from '../../assets/background.jpg'; 

export const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
      background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.6)), url("${backgroundImage}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        height: '100%'
      },
    },
    header: {
      backgroundSize: 'contain',
      height: '100px',
      width: '100%',
    },
    textArea: {
      color: '#fff',
      padding: '4em',
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      }
    },
    loginPanel: {
      padding: '4em',
      marginRight: '3em', 
      backgroundColor: 'rgba(255, 255, 255)',
      [theme.breakpoints.down('sm')]: {
        margin: '0', 
      },
      [theme.breakpoints.down('md')]: {
        margin: '2em', 
      },
    },
    welcomeText: {
      fontWeight: 'bold !important',
      marginBottom: '1em !important',
      textAlign: 'center !important',
      fontSize: '2.5em !important'
    },
    headerLogin: {
        textAlign: 'center',
        marginBottom: '1em'
    },
    communicationText: {
      marginBottom: theme.spacing(2),
      textAlign: 'center !important',
      marginLeft: '1em !important',
      fontSize: '1.8em !important',
      fontWeight: 'lighter !important'
    },
    PoweredbyText: {
      textAlign: 'center !important',
      marginLeft: '1em !important',
      fontSize: '1.5em !important',
      fontWeight: 'bold !important',
      color: '#fff',
    },

    logoPanel:{
        backgroundColor: 'white',
        padding: '0.2em'
    },
    
  }));