import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  menuItemText: {
    color: 'gray'
  },
  menuItemIcon: {
    color: '#1976D2 !important'
  },
  iconText: {
    marginRight: '4px',
    marginBottom: '2px',
    fontSize: '1em !important',
  }, 
  drawer: {
    width: '250px !important',
    flexShrink: 0,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: 'rgb(242,245,249) !important'
    },
    '&.Mui-selected': {
      backgroundColor: '#1976D2 !important',
      color: 'white !important'
    },
    '&.Mui-selected > .MuiListItemIcon-root *': {
      color: 'white !important'
    },
    marginBottom: '0.5em !important',
    color: '#99B4D1 !important',
    padding: '0.8em !important',
    borderRadius: '10px !important'
  },
  menuIcon: {
    fontSize: '1.3em !important',
    color: '#99B4D1'
  },
  toolBar: {
    justifyContent: 'space-between !important',
    margin: '0 !important',
    padding: '0 !important',
    display: 'flex !important',
  },
  boxItem: {
    display: 'flex !important',
    flexGrow: '1 !important'
  },
  imgStyle: {
    marginRight: '20px',
    background: 'white',
    paddingRight: '3em',
    paddingLeft: '1em',
    paddingTop: '1em',
    paddingBottom: '1em',
    borderRadius: '0px 50px 50px 0px'
  },
  selected: {
    color: '#1976D2 !important',
    background: 'white !important'
  },
   notificationText: {
    marginLeft: theme.spacing(2),
  },
  notificationDate: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
  },
  unreadNotification: {
    backgroundColor: 'rgb(245,245,245) !important',
    '&:hover': {
      backgroundColor: 'rgb(235,235,235) !important'
    }
  },
  readNotification: {
    backgroundColor: 'white !important',
  },
  marginBottom: {
    marginBottom: "60px"
  }
}));
