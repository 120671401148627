import { ChevronLeft, FilterAlt } from "@mui/icons-material";
import { Formik } from "formik";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  TextField,
  Container,
  MenuItem,
  Paper,
  LinearProgress,
  ListItemIcon,
  Table,
  Menu,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  DialogActions,
  styled,
  Box,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as XLSX from "xlsx";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import useStyles from "../styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { enqueueSnackbar } from "notistack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { fileDateToDate } from "src/utils/timeLocal";
import apiKPIService from "src/services/kpisService";
import ModeIcon from "@mui/icons-material/Mode";
import { useSelector } from "react-redux";
import { formatCurrency } from "src/utils/toDecimal";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2162C0",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const KPISInfo = ({ kpis, isView, setView }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [desde, setDesde] = useState(null);
  // const [hasta, setHasta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [importDialog, setImportDialog] = useState(false);
  const [contractFile, setContractFile] = useState(null);
  const [NumEstimated, setNumEstimated] = useState(null);
  const [selectUpdate, setSelectUpdate] = useState({});
  const Project = useSelector((state) => state?.setUserLogin?.project?.id);
  const [toggleDialog, setToggleDialog] = useState(false);
  const [accReal, setAccReal] = useState(0);
  const [accEstimated, setAccEstimated] = useState(0);
  const open = Boolean(anchorEl);
  let columns = [{ id: "name", label: "Período" }];
  let categories = [];
  let nominalVariation = [];
  let percentageVariation = [];
  const handleToggleDialog = () => {
    setToggleDialog(!toggleDialog);
  };

  const handleToggleImport = () => {
    setImportDialog(!importDialog);
    setNumEstimated(null);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  for (let i = 0; i < kpis?.datePeriod?.length; i++) {
    columns.push({
      id: i,
      label:
        moment(fileDateToDate(kpis?.datePeriod[i])).format("YYYY-MM-DD") + "",
    });
    categories.push(
      moment(fileDateToDate(kpis?.datePeriod[i])).format("YYYY-MM-DD") + ""
    );
  }

  for (let i = 0; i < kpis?.estimatedNum.length; i++) {
    nominalVariation.push(kpis?.realNum[i] - kpis?.estimatedNum[i]);
  }

  for (let i = 0; i < kpis?.estimatedNum.length; i++) {
    let percent = (nominalVariation[i] / kpis?.estimatedNum[i]) * 100;
    if (!isNaN(percent)) {
      percentageVariation.push(percent);
    } else {
      percentageVariation.push(0);
    }
  }
  useEffect(() => {
    setLoading(true);
    const date = new Date();
    let accEstimatedTotal = 0;
    let accRealtotal = 0;
    for (let i = 0; i < kpis.datePeriod.length; i++) {
      if (
        date >=
        new Date(
          moment(fileDateToDate(kpis?.datePeriod[i])).format("YYYY-MM-DD")
        )
      ) {
        accRealtotal += kpis.realNum[i];
        accEstimatedTotal += kpis.estimatedNum[i];
      }
    }
    setAccEstimated(accEstimatedTotal);
    setAccReal(accRealtotal);
    setLoading(false);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    setNumEstimated(null);
  };
  const lineChart = {
    series: [
      {
        name: "Estimado",
        data: kpis.estimatedNum || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Reales",
        data: kpis.realNum || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3],
        curve: "straight",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - <strong>" +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            "</strong>"
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: categories || [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (mins)";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " per session";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };
  const columnChart = {
    series: [
      {
        data: [accEstimated, accReal],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          vertical: true,
          distributed: true,
        },
      },
      colors: ["#35486a", "#44648b"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ["Estimado", "Reales"],
      },
    },
  };

  const handleClick = (event, column, index, title) => {
    setSelectUpdate({
      column,
      index,
      title,
      datePeriod: kpis?.datePeriod[index],
    });
    setAnchorEl(event.currentTarget);
  };
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      setNumEstimated(data);
    });
  };

  const handleContractFileChange = (event) => {
    const file = event;
    if (file) {
      const sanitizedFile = new File([file], file?.name, {
        type: file.type,
      });
      setContractFile(sanitizedFile);
      readExcel(file);
    }
  };
  const UpdateNumEstimate = () => {
    const dateUpdate = [];
    for (let i = 0; i < kpis.datePeriod.length; i++) {
      dateUpdate.push(
        moment(fileDateToDate(kpis?.datePeriod[i])).format("YYYY-MM-DD") + ""
      );
    }
    const newObj = {};
    dateUpdate.forEach((date) => {
      newObj[date] = NumEstimated[0].hasOwnProperty(date)
        ? NumEstimated[0][date]
        : 0;
    });
    const newNumEstimatedIndex = Object.values(newObj);
    const newNumEstimated = newNumEstimatedIndex.map((value) =>
      isNaN(value) ? 0 : value
    );
    const values = {};
    values.id = kpis.id;
    values.project = Project;
    values.NewRealNum = kpis.realNum;
    if (kpis.datePeriod.length === newNumEstimated.length) {
      values.NewEstimatedNum = newNumEstimated;
    } else if (kpis.datePeriod.length < newNumEstimated.length) {
      values.NewEstimatedNum = newNumEstimated.slice(0, kpis.datePeriod.length);
    } else if (kpis.datePeriod.length > newNumEstimated.length) {
      values.NewEstimatedNum = newNumEstimated.concat(
        new Array(kpis.datePeriod.length - newNumEstimated.length).fill(0)
      );
    }
    apiKPIService.postUpdateNumbers(values).then(() => {
      enqueueSnackbar("Valores de KPI actualizados con exito", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setView(false);
      setNumEstimated(null);
    });
  };

  const KPIDialog = (
    <Dialog
      open={toggleDialog}
      onClose={handleToggleDialog}
      aria-labelledby="nuevo-kpi-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {"Actualizar valores " + selectUpdate.title}
      </DialogTitle>
      <Divider variant="middle" orientation="horizontal" />
      <DialogContent>
        <Formik
          initialValues={{
            period: "",
            estimatedPeriod: selectUpdate?.column || "",
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            if (selectUpdate.title === "estimados") {
              let allEstimatedPeriod = kpis.estimatedNum;
              const index = kpis.datePeriod.indexOf(selectUpdate.datePeriod);
              allEstimatedPeriod[index] = values.estimatedPeriod;
              values.NewEstimatedNum = allEstimatedPeriod;
              values.NewRealNum = kpis.realNum;
            } else if (selectUpdate.title === "reales") {
              let allRealPeriod = kpis.realNum;
              const index = kpis.datePeriod.indexOf(selectUpdate.datePeriod);
              allRealPeriod[index] = values.estimatedPeriod;
              values.NewRealNum = allRealPeriod;
              values.NewEstimatedNum = kpis.estimatedNum;
            }
            values.id = kpis.id;
            values.project = Project;
            apiKPIService.postUpdateNumbers(values).then(() => {
              enqueueSnackbar("Valores de KPI actualizados con exito", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              handleToggleDialog();
            });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container={2}>
                <Grid item xs={6}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    className="border-0"
                  >
                    <InputLabel shrink htmlFor="period">
                      Período:
                    </InputLabel>
                    <br />
                    {moment(fileDateToDate(selectUpdate.datePeriod)).format(
                      "YYYY-MM-DD"
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="estimatedPeriod"
                    label={"Número " + selectUpdate.title + "	: *"}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="number"
                    name="estimatedPeriod"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.estimatedPeriod}
                    onChange={handleChange}
                    error={Boolean(
                      touched.estimatedPeriod && errors.estimatedPeriod
                    )}
                    helperText={
                      touched.estimatedPeriod && errors.estimatedPeriod
                    }
                  />
                </Grid>
              </Grid>
              <Divider variant="middle" orientation="horizontal" />
              <br />
              <Grid container={2} className="text-end">
                <Button
                  color="primary"
                  variant="contained"
                  className="rounded-4 me-2"
                  onClick={handleToggleDialog}
                >
                  Cerrar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  autoFocus
                  className="rounded-4 me-2"
                >
                  Actualizar Valor
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );

  const exportDataToFile = async () => {
    const objetDate = categories.reduce((obj, fecha) => {
      obj[fecha] = "";
      return obj;
    }, {});
    const arrayDate = Object.entries(objetDate).map(([key, value]) => ({
      [key]: value,
    }));

    const ws1 = XLSX.utils.json_to_sheet(arrayDate);
    /* add to workbook */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Estimados");
    /* generate an XLSX file */
    XLSX.writeFile(wb, "Formato Estimados.xlsx");
  };

  const importNumEstimate = (
    <Dialog
      open={importDialog}
      onClose={handleToggleImport}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        Importar Valores Estimados
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container className="mb-5">
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="GrayText">
              Formato de ejemplo con los periodos de los Valores estimados
            </Typography>
          </Grid>
          <Grid item xs={6} justifyContent="end" display="flex">
            <Button
              variant="contained"
              onClick={exportDataToFile}
              size="small"
              className={classes.downloadButton}
            >
              Descargar Ejemplo
            </Button>
          </Grid>
        </Grid>
        <Divider
          variant="fullWidth"
          orientation="horizontal"
          sx={{ backgroundColor: "gray" }}
        />
        <Grid container className="mt-2">
          <Grid item xs={12} display="flex" justifyContent="center" my={2}>
            <Typography variant="subtitle2" color="GrayText">
              Para importar valores estimados de KPI, cargue un archivo de Excel
              (.xls, .xlsx)
            </Typography>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" my={2}>
            {NumEstimated ? (
              <Button
                component="label"
                variant="contained"
                className="me-2"
                startIcon={<FileUploadIcon />}
                style={{ backgroundColor: "#902E30" }}
              >
                {contractFile?.name}
              </Button>
            ) : (
              <Button
                component="label"
                role={undefined}
                variant="contained"
                className="me-2"
                tabIndex={-1}
                onChange={(e) => handleContractFileChange(e.target.files[0])}
                startIcon={<FileUploadIcon />}
              >
                Selecciona un archivo
                <VisuallyHiddenInput
                  type="file"
                  accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                />
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleToggleImport}
          variant="contained"
          color="primary"
        >
          Cancelar
        </Button>
        <LoadingButton
          loadingPosition="end"
          loading=""
          disabled={!NumEstimated}
          onClick={() => UpdateNumEstimate()}
          variant="contained"
          color="primary"
        >
          Importar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
  return (
    <Container>
      <div className="d-flex justify-content-between  mb-4">
        <IconButton
          style={{ background: "none" }}
          aria-label="edit"
          color="primary"
          onClick={() => {
            setView(false);
          }}
        >
          <ChevronLeft color="primary" fontSize="large" />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            color="primary"
            className="m-1"
          >
          {kpis?.name|| " "}
          </Typography>
        </IconButton>

        {/* <Tooltip title="Filtrar">
          <IconButton aria-label="" color="primary">
            <FilterAlt />
          </IconButton>
        </Tooltip> */}
      </div>

      {!loading ? (
        <>
          <Grid container={2} className="mb-4">
            <Grid item xs={8}>
              <FormControl fullWidth margin="dense" required>
                <center>
                  <h5>{kpis.name + " " + kpis.frequency}</h5>
                </center>
                <br />
                <h6>{"Unidad de medida " + kpis.unit}</h6>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="text-end">
              <Button variant="contained" color="primary" className="me-2">
                Reporte
              </Button>

              <Button
                onClick={() => {
                  handleToggleImport();
                }}
                variant="contained"
                style={{ backgroundColor: "#2196F3" }}
                className="me-2"
              >
                Importar Valores estimados
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      width={400}
                      style={{ border: "1px solid white" }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <StyledTableCell
                    width={400}
                    style={{ border: "1px solid white" }}
                  >
                    Número Estimados
                  </StyledTableCell>
                  {kpis?.estimatedNum.map((column, index) => (
                    <StyledTableCell
                      width={400}
                      style={{ border: "1px solid white" }}
                    >
                      <Grid container="3">
                        <Grid xs={11}> {formatCurrency(column)}</Grid>
                        <Grid xs={1}>
                          <IconButton
                            aria-label="settings"
                            aria-controls="clauses-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClick(e, column, index, "estimados");
                            }}
                          >
                            <MoreVertIcon color="primary" align="right" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    width={400}
                    style={{ border: "1px solid white" }}
                  >
                    Número Reales
                  </StyledTableCell>
                  {kpis?.realNum.map((column, index) => (
                    <StyledTableCell
                      width={400}
                      style={{ border: "1px solid white" }}
                    >
                      <Grid container="3">
                        <Grid xs={11}> {formatCurrency(column)}</Grid>
                        <Grid xs={1}>
                          <IconButton
                            aria-label="settings"
                            aria-controls="clauses-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClick(e, column, index, "reales");
                            }}
                          >
                            <MoreVertIcon color="primary" align="right" />
                          </IconButton>
                          <Menu
                            id="clauses-menu"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            elevation={1}
                          >
                            <MenuItem
                              onClick={() => {
                                handleToggleDialog();
                              }}
                            >
                              <ListItemIcon className={classes.menuItemIcon}>
                                <ModeIcon />
                              </ListItemIcon>
                              Actualizar
                            </MenuItem>
                          </Menu>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    width={400}
                    style={{ border: "1px solid white" }}
                  >
                    Variación Nominal
                  </StyledTableCell>
                  {nominalVariation.map((column) => (
                    <StyledTableCell
                      width={400}
                      style={{ border: "1px solid white" }}
                    >
                      {formatCurrency(column)}
                    </StyledTableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    width={400}
                    style={{ border: "1px solid white" }}
                  >
                    Variación %
                  </StyledTableCell>
                  {percentageVariation.map((column) => (
                    <StyledTableCell
                      width={400}
                      style={{ border: "1px solid white" }}
                    >
                      {formatCurrency(column) + " %"}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            variant="subtitle1"
            color="primary"
            fontWeight="bold"
            className="mt-4"
          >
            Tablas y gráficos
          </Typography>
          <Menu
            id="clauses-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            elevation={1}
          >
            <MenuItem
              onClick={() => {
                handleToggleDialog();
              }}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <ModeIcon />
              </ListItemIcon>
              Actualizar
            </MenuItem>
          </Menu>
          <Box display="flex">
            <div id="chart">
              <center>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  className="mt-4"
                >
                  {kpis.name + " " + kpis.frequency}
                </Typography>
              </center>
              {/*
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Desde"
                name="Desde"
                onChange={(e) => {
                  setDesde(e.target.value)
                }}
                select
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                value={desde}
                variant="standard"
              >
                <option value=""> </option>
                {categories.map((Categoria,index) => (
                  <option key={Categoria} value={index}>
                    {Categoria}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
            <TextField
                fullWidth
                label="Hasta"
                name="Hsta"
                onChange={(e) => {
                  setHasta(e?.target?.value)
                  nominalVariation=[]
                  categories=[]
                  percentageVariation=[]
                  for (let i = 0; i < kpis?.datePeriod?.length; i++) {

                    if(i>=desde && i<= e?.target?.value ){
                    columns.push({
                      id: i,
                      label:
                        moment(fileDateToDate(kpis?.datePeriod[i])).format("YYYY-MM-DD") + "",
                    });
                    categories.push(
                      moment(fileDateToDate(kpis?.datePeriod[i])).format("YYYY-MM-DD") + ""
                    );
                  }
                  }
                
                  for (let i = 0; i < kpis?.estimatedNum.length; i++) {
                    if(i>=desde && i<= e?.target?.value ){
                    nominalVariation.push(kpis?.realNum[i] - kpis?.estimatedNum[i]);}
                  }
                
                  for (let i = 0; i < kpis?.estimatedNum.length; i++) {
                    if(i>=desde && i<= e?.target?.value ){
                    let percent = (nominalVariation[i] / kpis?.estimatedNum[i]) * 100;
                    if (!isNaN(percent)) {
                      percentageVariation.push(percent);
                    } else {
                      percentageVariation.push(0);
                    }
                  }}
              
                }}
                select
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                value={hasta}
                variant="standard"
                disabled={!desde}
              >
                <option value=""> </option>
                {categories.map((Categoria,index) => (
                  <option key={Categoria} value={index}>
                    {Categoria}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>*/}
              <ReactApexChart
                options={lineChart.options}
                series={lineChart.series}
                type="line"
                height={300}
                width={1100}
              />
            </div>
          </Box>
          <center>
            <Box display="flex" justifyContent="center">
              <div id="chart">
                <center>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    className="mt-4"
                  >
                    {"Acumulado al " + moment(new Date()).format("DD/M/YYYY")}
                  </Typography>
                </center>
                <ReactApexChart
                  options={columnChart.options}
                  series={columnChart.series}
                  type="bar"
                  height={350}
                  width={600}
                />
              </div>
            </Box>
          </center>
          {KPIDialog}
          {importNumEstimate}
        </>
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Container>
  );
};

export default KPISInfo;
