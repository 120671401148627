import { db } from '../config/firebaseConfig';
import { collection, query, orderBy, limit, onSnapshot, getDocs, getDoc, doc } from 'firebase/firestore';

const subscribeToNotifications = (userId, onUpdate) => {
  const userNotificationsRef = collection(db, `users/${userId}/notifications`);

  // Consulta para obtener las notificaciones no leídas en tiempo real
  const q = query(userNotificationsRef,
    orderBy('date', 'desc'),
    limit(10)
  );

  const unsubscribe = onSnapshot(q, async (snapshot) => {
    const updatedNotifications = [];
    for (const notificationDoc of snapshot.docs) {
      const notifyData = notificationDoc.data();
      const userDocRef = doc(db, 'users', notifyData.user);
      const userGet = await getDoc(userDocRef);
      const userData = userGet.data();

      notifyData.id = notificationDoc.id;
      notifyData.userName = userData ? userData.fullname : 'Usuario desconocido';
      updatedNotifications.push(notifyData);
    }

    onUpdate(updatedNotifications);
  }, (error) => {
    console.error('Error en la suscripción a notificaciones:', error);
  });

  return unsubscribe;
};

const getNotifications = async (userId) => {
  try {
    const userNotificationsRef = collection(db, `users/${userId}/notifications`);
    
    // Consulta para obtener todas las notificaciones
    const allNotificationsQuery = query(userNotificationsRef,
      orderBy('date', 'desc'),
      limit(10)
    );
    const allNotificationsSnapshot = await getDocs(allNotificationsQuery);
    const notifications = [];

    // Obtener todas las notificaciones
    for (const notificationDoc of allNotificationsSnapshot.docs) {
      const notifyData = notificationDoc.data();
      const userDocRef = doc(db, 'users', notifyData.user);
      const userGet = await getDoc(userDocRef);
      const userData = userGet.data();

      notifyData.id = notificationDoc.id;
      notifyData.userName = userData ? userData.fullname : 'Usuario desconocido';
      notifications.push(notifyData);
    }

    // Contar solo las notificaciones con 'read' == false
    const totalNotifications  = notifications.filter(notification => !notification.read)?.length;
    return { totalNotifications , notifications };
  } catch (error) {
    console.error('Error obteniendo notificaciones:', error);
    throw error;
  }
};

const apiNotificationService = {
  subscribeToNotifications,
  getNotifications,
};

export default apiNotificationService;
