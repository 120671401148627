/* eslint-disable react-hooks/exhaustive-deps */
import { Delete,  Save } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  TablePagination,
  Box,
  DialogContentText,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  styled,
  LinearProgress,
  TableBody,
} from "@mui/material";
import moment from "moment";
import momentTime from "moment-timezone";
import { fileDateToDate } from "src/utils/timeLocal";
import EditIcon from "@mui/icons-material/Edit";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import useStyles from "../styles";
import { Formik } from "formik";
import MasterPlanInfo from "./infoMasterPlan";
import QueueIcon from "@mui/icons-material/Queue";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MasterPlanEstimate from "./masterPlanEstimate";
import MasterPlanReal from "./masterPlanReal";
import MasterPlanComparative from "./masterPlanComparative";
import MasterPlanSummary from "./masterPlanSummary";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import apiMasterPlanService from "src/services/masterPlanService";
import Autocomplete from "@mui/material/Autocomplete";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { LoadingButton } from "@mui/lab";
import EmptyIcon from "src/assets/emptyBox.png";
import apiProjectsService from "src/services/projectsService";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import permissionsService from "src/services/permissionsService";
import labelDisplayedRows from "src/utils/pagination"
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2162C0",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const MasterPlanView = () => {
  const User = useSelector((state) => state?.setUserLogin?.user);
  const Project = useSelector((state) => state?.setUserLogin?.project?.id);
  const Role = useSelector((state) => state?.setUserLogin?.project?.role);
  const [toggleDialog, setToggleDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Users, setUsers] = useState([]);
  const [allMasterPlan, setAllMasterPlan] = useState([]);
  const [selectMasterPlan, setSelectMasterPlan] = useState([]);
  const [selectEdit, setSelectEdit] = useState([]);
  const [view, setView] = useState(false);
  const [viewEstime, setViewEstimate] = useState(false);
  const [viewReal, setViewReal] = useState(false);
  const [viewComparative, setViewComparative] = useState(false);
  const [viewSummary, setViewSummary] = useState(false);
  const [loadingMasterPlan, setLoadingMasterPlan] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const myRol =
    User?.role?.label !== "Super Administrador" &&
    User?.role?.label !== "Administrador TPIG"
      ? User?.projects?.find((user) => user?.project?.id === Project?.id)
      : User;
  const classes = useStyles();
  const columns = [
    { id: "name", label: "Nombre" },
    { id: "projectStart", label: "Inicio" },
    { id: "responsible", label: "Responsable" },
    { id: "closingPeriod", label: "Periodo de cierre" },
    { id: "actions", label: "Acciones" },
  ];
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 100); // 100 years from today
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleToggleDialog = () => {
    setToggleDialog(!toggleDialog);
  };

  const SaveNewMasterPlan = (values) => {
    setLoadingMasterPlan(true);
    apiMasterPlanService.postCreate(values).then(() => {
      enqueueSnackbar("Plan maestro creado con exito", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      allProjectMasterPlan();
      handleToggleDialog(false);
      setLoadingMasterPlan(false);
      
    });
  };

  const UpdateMasterPlan = (values) => {
    setLoadingMasterPlan(true);
    apiMasterPlanService.postUpdate(values).then(() => {
      enqueueSnackbar("Plan maestro actualizado con exito", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      allProjectMasterPlan();
      handleToggleDialog(false);
      setLoadingMasterPlan(false);
   
    });
  };

  useEffect(() => {
    setLoading(true);
    allProjectMasterPlan();
  }, [loadingMasterPlan, selectMasterPlan, view]);

  const allProjectMasterPlan = async () => {
    try {
      const response = await apiMasterPlanService.postByProject({
        project: Project,
      });
      setAllMasterPlan(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      await apiProjectsService.getUsers(Project).then((data) => {
        setUsers(data.data);
      });
    } catch (error) {
      enqueueSnackbar("Error al obtener los usuarios", { variant: "error" });
    }
  };

  const handleToggleDelete = () => {
    setDeleteDialog(!deleteDialog);
  };
  const MasterPlanDialog = (
    <Dialog
      open={toggleDialog}
      onClose={handleToggleDialog}
      aria-labelledby="nuevo-masterPlan-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {selectEdit.length <= 0 ? "Nuevo Plan Maestro" : "Editar Plan Maestro"}
      </DialogTitle>
      <Divider variant="middle" orientation="horizontal" />
      <DialogContent>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            name: selectEdit?.name || "",
            responsible: selectEdit?.responsible || "",
            unit: selectEdit?.unit || "",
            endDate:
              moment(
                moment(fileDateToDate(selectEdit.endDate)).format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD") || "",
            startDate:
              moment(
                moment(fileDateToDate(selectEdit.startDate)).format(
                  "DD/MM/YYYY"
                ),
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD") || "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("El nombre es requerido"),
            responsible: Yup.string().required("El responsable es requerido"),
            startDate: Yup.date().required("La fecha de inicio es requerido"),
            endDate: Yup.date()
              .required("La fecha de finalización es obligatoria")
              .min(
                Yup.ref("startDate"),
                "La fecha de finalización debe ser después de la fecha de inicio"
              )
              .max(
                maxDate,
                "La fecha de finalización no puede pasar los 100 años desde la actualidad"
              ),
            unit: Yup.string().required("La unidad de medida es requerida"),
          })}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            values.project = Project;
            values.startDate = values.startDate + " 00:00:01";
            values.endDate = values.endDate + " 23:59:59";
            if (selectEdit <= 0) {
              SaveNewMasterPlan(values);
            } else {
              values.id = selectEdit.id;

              UpdateMasterPlan(values);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            touched,
            values,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} rowSpacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="name"
                    label="Nombre del Plan Maestro: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    name="name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    className="border-0"
                    error={Boolean(touched.responsible && errors.responsible)}
                    helperText={touched.responsible && errors.responsible}
                  >
                    <InputLabel shrink htmlFor="responsible">
                      Responsable:{" "}
                    </InputLabel>
                    <br />
                    <Autocomplete
                      options={Users}
                      id="responsible"
                      name="responsible"
                      size="small"
                      noOptionsText="sin opciones"
                      onBlur={handleBlur}
                      defaultValue={
                        selectEdit?.responsible &&
                        Users.find((usr) => selectEdit?.responsible === usr.id)
                      }
                      onChange={(e, user) => {
                        if (user) {
                          const { id } = user;
                          values.responsible = id;
                        } else {
                          values.responsible = "";
                        }
                      }}
                      getOptionLabel={(user) => user.fullname}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          size="small"
                          fullWidth
                          error={
                            touched.responsible && Boolean(errors.responsible)
                          }
                          helperText={touched.responsible && errors.responsible}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="unit"
                    label="Unidad de medida: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    name="unit"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.unit}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.unit && errors.unit)}
                    helperText={touched.unit && errors.unit}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="startDate"
                    label="Fecha inicio: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="date"
                    name="startDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.startDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="endDate"
                    label="Fecha cierre: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="date"
                    name="endDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.endDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                  />
                </Grid>
              </Grid>
              <Divider variant="middle" orientation="horizontal" />
              <Grid item sm={6}>
                <Typography variant="caption">
                  Los campos marcados con{" "}
                  <span className="text-danger"> * </span> son obligatorios
                </Typography>
              </Grid>

              <Grid
                container={2}
                item
                className="text-end"
                spacing={2}
                rowSpacing={3}
              >
                <Grid
                  item
                  sm={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    className="rounded-4 me-2"
                    onClick={handleToggleDialog}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    startIcon={<Save />}
                    color="primary"
                    variant="contained"
                    type="submit"
                    autoFocus
                    className="rounded-4 me-2"
                    loading={isSubmitting}
                    disabled={
                      !isValid ||
                      (!dirty &&
                        selectEdit?.responsible === values?.responsible)
                    }
                  >
                    {selectEdit.length <= 0 ? "Crear nuevo" : "Actualizar"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );

  const confirmDeleteDialog = (
    <>
      <Dialog
        open={deleteDialog}
        onClose={handleToggleDelete}
        aria-labelledby="alert-delete-dialog-title"
        aria-describedby="alert-delete-dialog-description"
      >
        <DialogTitle id="alert-delete-dialog-title">
          {"¿Estás seguro de eliminar el Plan Maestro?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-dialog-description">
            Si das click en borrar, se eliminará y toda su configuración
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleToggleDelete()}>Cancelar</Button>
          <LoadingButton
            size="small"
            loading={loading}
            variant="contained"
            loadingPosition="end"
            endIcon={<Delete />}
            autoFocus
            onClick={() => {
              setLoadingMasterPlan(true);
              apiMasterPlanService.delete(Project, selectMasterPlan.id);

              enqueueSnackbar("Plan maestro eliminado con exito", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              handleToggleDelete();
              setLoadingMasterPlan(false);         
            }}
          >
            <span>Eliminar</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
  return (
    <Container>
      {!view &&
        !viewEstime &&
        !viewReal &&
        !viewComparative &&
        !viewSummary && (
          <>
            {!loading ? (
              <>
                <Grid container>
                  {permissionsService("masterPlan", User.role.label, "add") && <Grid item sm={12} className={classes.projectStyle}>
                    <Button
                      onClick={() => {
                        handleToggleDialog();
                        setSelectEdit([]);
                      }}
                      className="mb-2"
                      variant="contained"
                    >
                      Crear Plan maestro
                    </Button>
                  </Grid>}
                </Grid>
                <Divider className="mb-3" />
                <Grid container={2} className="mt-3">
                  <Container maxWidth="lg">
                    <div className="d-flex justify-content-between">
                      <Typography
                        variant="body2"
                        color="rgb(138,142,146)"
                        className="mb-2 fw-bold"
                      >
                        {allMasterPlan?.length || 0} Plan Maestro
                      </Typography>
                      <Divider />
                      {/*
                      <Box display="inline-flex">
                        <IconButton
                          aria-label="settings"
                          aria-controls="project-menu"
                          aria-haspopup="true"
                        >
                          <FilterAlt color="primary" />
                        </IconButton>
                      </Box>*/}
                    </div>

                    {allMasterPlan.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <StyledTableCell
                                  width={400}
                                  style={{ border: "1px solid white" }}
                                >
                                  <b> {column.label}</b>
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {allMasterPlan.map((row) => (
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {row.name}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {momentTime(
                                      fileDateToDate(row.startDate)
                                    ).tz('America/Guayaquil').format("DD/MM/YYYY")}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {Users.find(
                                      (snp) => snp.id === row.responsible
                                    )?.fullname}
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {momentTime(fileDateToDate(row.endDate)).tz('America/Guayaquil').format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {permissionsService("masterPlan", User.role.label, "edit") && <Tooltip title="Editar Plan Maestro">
                                    <IconButton
                                      style={{ background: "none" }}
                                      aria-label="edit"
                                      color="primary"
                                      onClick={() => {
                                        //setView(true);
                                        setSelectEdit(row);
                                        handleToggleDialog();
                                      }}
                                    >
                                        <EditIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  }

                                  <Tooltip title="Agregar Obras">
                                    <IconButton
                                      style={{ background: "none" }}
                                      aria-label="edit"
                                      color="primary"
                                      onClick={() => {
                                        setView(true);
                                        setSelectMasterPlan(row);
                                      }}
                                    >
                                      <QueueIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Ver P.M Estimados">
                                    <IconButton
                                      style={{ background: "none" }}
                                      aria-label="edit"
                                      color="primary"
                                      onClick={() => {
                                        setViewEstimate(true);
                                        setSelectMasterPlan(row);
                                      }}
                                    >
                                      <AnalyticsIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Ver P.M Real">
                                    <IconButton
                                      style={{ background: "none" }}
                                      aria-label="edit"
                                      color="primary"
                                      onClick={() => {
                                        setViewReal(true);
                                        setSelectMasterPlan(row);
                                      }}
                                    >
                                      <AssessmentIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Ver P.M Comparativo">
                                    <IconButton
                                      style={{ background: "none" }}
                                      aria-label="edit"
                                      color="primary"
                                      onClick={() => {
                                        setViewComparative(true);
                                        setSelectMasterPlan(row);
                                      }}
                                    >
                                      <CompareArrowsIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip title="Ver Resumen">
                                    <IconButton
                                      style={{ background: "none" }}
                                      aria-label="edit"
                                      color="primary"
                                      onClick={() => {
                                        setViewSummary(true);
                                        setSelectMasterPlan(row);
                                      }}
                                    >
                                      <TrendingUpIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                {permissionsService("masterPlan", User.role.label , "delete") &&  <Tooltip title="Eliminar Plan Maestro">
                                    <IconButton
                                      style={{ background: "none" }}
                                      aria-label="edit"
                                      color="primary"
                                      onClick={() => {
                                        setSelectMasterPlan(row);
                                        handleToggleDelete();
                                      }}
                                    >
                                        <DeleteForeverIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  }
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          labelRowsPerPage="Filas por página"
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={allMasterPlan.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelDisplayedRows={labelDisplayedRows}
                        />
                      </TableContainer>
                    ) : (
                      <Box textAlign="center">
                        <img
                          src={EmptyIcon}
                          alt="Imagen de no data"
                          width={150}
                        />
                        <Typography variant="h6" color="GrayText">
                          No hay datos disponibles.
                        </Typography>
                      </Box>
                    )}
                  </Container>
                </Grid>
              </>
            ) : (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </>
        )}
      {view && (
        <MasterPlanInfo
          masterPlan={selectMasterPlan}
          isView={true}
          setView={setView}
        />
      )}

      {viewEstime && (
        <MasterPlanEstimate
          masterPlan={selectMasterPlan}
          isView={true}
          setView={setViewEstimate}
        />
      )}

      {viewReal && (
        <MasterPlanReal
          masterPlan={selectMasterPlan}
          isView={true}
          setView={setViewReal}
        />
      )}

      {viewComparative && (
        <MasterPlanComparative
          masterPlan={selectMasterPlan}
          isView={true}
          setView={setViewComparative}
        />
      )}

      {viewSummary && (
        <MasterPlanSummary
          masterPlan={selectMasterPlan}
          isView={true}
          setView={setViewSummary}
        />
      )}

      {MasterPlanDialog}
      {confirmDeleteDialog}
    </Container>
  );
};

export default MasterPlanView;
