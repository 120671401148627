import { Fragment, useEffect, useState } from "react";
import TopBar from "../../../components/topbar/topbar";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Card,
  Tabs,
  InputLabel,
  Tooltip,
  Tab,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  CardContent,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Divider,
  Box,
  CardHeader,
  Grid,
  Container,
  Chip,
  LinearProgress,
  DialogContentText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import List from "@mui/material/List";
import * as Yup from "yup";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArchiveIcon from "@mui/icons-material/Archive";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import Autocomplete from "@mui/material/Autocomplete";
import InventoryIcon from "@mui/icons-material/Inventory";
import timezones from "src/utils/timezones";
import useStyles from "./styles";
import { FilterAlt, Save } from "@mui/icons-material";
import { Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import apiUserService from "src/services/usersService";
import apiProjectsService from "src/services/projectsService";
import { useDispatch } from "react-redux";
import { getSelectProject } from "../../../redux/actions/projectAction";
import apiCustomersService from "src/services/customersService";
import permissionsService from "src/services/permissionsService";

const listItems = [
  { id: 0, text: "Activos", Icon: ArchiveIcon },
  { id: 1, text: "Completados", Icon: LibraryAddCheckIcon },
  { id: 2, text: "Archivados", Icon: InventoryIcon },
];

const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() + 100); // 100 years from today
const MAX_FILENAME_LENGTH = 100;

const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Project = useSelector((state) => state?.setUserLogin?.project?.id);
  const User = useSelector((state) => state?.setUserLogin?.user);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [contractFile, setContractFile] = useState(null);
  const [documentsFiles, setDocumentsFiles] = useState([]);
  const open = Boolean(anchorEl);
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [checkData, setCheckData] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState({
    active: [],
    completed: [],
    archived: [],
  });
  const [settingsProjects, setSettingsProjects] = useState({
    type: [],
    industry: [],
    criticality: [],
  });

  const [openConfirmDialog, setConfirmOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [currentProject, setCurrentProject] = useState(null);
  const myRol = User?.role?.label !== "Super Administrador" && User?.role?.label !== "Administrador TPIG" ? User?.projects?.find(user => user?.project?.id === Project?.id) : User;

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  function relativeDate(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);

    // Calcular la diferencia total en años, meses y días
    const yearsTotal = end.diff(start, "years");

    // Calcular el residuo de años
    const startPlusYears = moment(startDate).add(yearsTotal, "years");
    const monthsAfterYears = end.diff(startPlusYears, "months");

    // Calcular el residuo de meses
    const startPlusYearsAndMonths = startPlusYears.add(
      monthsAfterYears,
      "months"
    );
    const daysAfterMonths = end.diff(startPlusYearsAndMonths, "days");

    // Construir la cadena de tiempo con los residuos
    let time = "";
    if (yearsTotal > 0) {
      time += `${yearsTotal} año(s), `;
    }
    if (monthsAfterYears > 0) {
      time += `${monthsAfterYears} mes(es), `;
    }
    if (daysAfterMonths > 0) {
      time += `${daysAfterMonths} día(s)`;
    }

    // Eliminar la coma adicional si existe
    if (time.endsWith(", ")) {
      time = time.slice(0, -2);
    }

    return time;
  }

  const fetchUsers = async () => {
    try {
      apiUserService.get().then((data) => {
        setUsers(data.data);
      });
    } catch (error) {
      enqueueSnackbar("Error al obtener los datos", { variant: "error" });
    }
  };

  const fetchCustomers = async () => {
    try {
      apiCustomersService.get().then((data) => {
        setCustomers(data.data);
      });
    } catch (error) {
      enqueueSnackbar("Error al obtener los datos", { variant: "error" });
    }
  };

  const handleConfirmDelete = () => {
    apiProjectsService
      .delete(currentProject.id)
      .then(() => {
        enqueueSnackbar("Proyecto eliminado con éxito", { variant: "success" });
        setCheckData(!checkData);
      })
      .catch(() => {
        enqueueSnackbar("Ha ocurrido un error al eliminar el proyecto", {
          variant: "error",
        });
      });
    handleCloseConfirmDialog();
  };

  const handleConfirmArchive = () => {
    let status = "";
    let statusSpanish = "";
    if (dialogType === "archive" && selectedIndex === 2) {
      status = "active";
      statusSpanish = "Activo";
    } else {
      if (dialogType === "archive" && selectedIndex !== 2) {
        status = "archived";
        statusSpanish = "Archivado";
      } else {
        if (dialogType === "completed" && selectedIndex === 1) {
          status = "active";
          statusSpanish = "Activo";
        } else {
          if (dialogType === "completed" && selectedIndex !== 1) {
            status = "completed";
            statusSpanish = "Completado";
          }
        }
      }
    }

    apiProjectsService
      .toggleStatus(currentProject.id, status)
      .then(() => {
        enqueueSnackbar(
          `El proyecto ha cambiado de estado a: ` + statusSpanish,
          { variant: "info" }
        );
        setCheckData(!checkData);
      })
      .catch(() => {
        enqueueSnackbar(
          "Ha ocurrido un error al cambiar de estado al proyecto",
          {
            variant: "error",
          }
        );
      });
    handleCloseConfirmDialog();
  };

  const fetchSettingsProjects = async () => {
    try {
      const data = await apiUserService.getSettings(User.id);
      setSettingsProjects(data.data);
    } catch (error) {
      if (error?.response?.data === "No settings found for the user") {
        enqueueSnackbar(
          "No se han agregado industrias o tipos, agrégalas en el módulo de AJUSTES",
          { variant: "info" }
        );
      } else {
        enqueueSnackbar("Error al obtener las configuraciones", {
          variant: "error",
        });
      }
    }
  };

  const handleClick = (project) => (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentProject(project);
    sessionStorage.setItem("Project", JSON.stringify(project));
  };

  const handleContractFileChange = (event) => {
    const file = event;
    if (file) {
      const sanitizedFilename = sanitizeFilename(file.name);
      const truncatedFilename = truncateFilename(sanitizedFilename);
      const sanitizedFile = new File([file], truncatedFilename, {
        type: file.type,
      });
      setContractFile(sanitizedFile);
    }
  };

  const handleDocumentsFileChange = (selectedFiles) => {
    const sanitizedFiles = Array.from(selectedFiles).map((file) => {
      const sanitizedFilename = sanitizeFilename(file.name);
      const truncatedFilename = truncateFilename(sanitizedFilename);
      return new File([file], truncatedFilename, { type: file.type });
    });
    setDocumentsFiles(sanitizedFiles);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Box>
    );
  }

  const handleProject = () => {
    // Cerrar el menú (si es necesario)
    setAnchorEl(false);
  
    // Navegar a la página de detalles del proyecto
    navigate("/projects/details");
  
    // Obtener los proyectos del usuario
    const projects = User?.projects || [];
  
    // Encontrar el rol del proyecto actual
    const currentProjectId = currentProject?.id;
    const Rol = projects
      .map((item) => item?.project)
      .find((project) => project?.id === currentProjectId)?.role?.value;
  
    // Actualizar el rol del proyecto actual
    currentProject.role = Rol || null;
  
    // Despachar la acción para obtener el proyecto seleccionado
    dispatch(getSelectProject(currentProject || "", User));
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sanitizeFilename = (filename) => {
    return filename.replace(/[^a-zA-Z0-9._-]/g, "_");
  };

  const truncateFilename = (filename) => {
    if (filename.length > MAX_FILENAME_LENGTH) {
      const extensionIndex = filename.lastIndexOf(".");
      const extension =
        extensionIndex !== -1 ? filename.substring(extensionIndex) : "";
      const baseName =
        extensionIndex !== -1
          ? filename.substring(0, extensionIndex)
          : filename;
      return (
        baseName.substring(0, MAX_FILENAME_LENGTH - extension.length) +
        extension
      );
    }
    return filename;
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmOpenDialog(false);
  };

  const handleReset = (resetForm) => {
    setTabValue(0);
    setContractFile(null);
    setDocumentsFiles([]);
    resetForm();
  };

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Proyectos";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [checkData]);

  const fetchProjects = async () => {
    try {
      if (
        projects?.active?.length === 0 &&
        projects?.archived?.length === 0 &&
        projects?.completed?.length === 0
      ) {
        setLoading(true);
      }
      await apiProjectsService.get(User.id).then((data) => {
        const allProjects = data.data;
        allProjects.sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );
        const { active, completed, archived } = data.data.reduce(
          (acc, project) => {
            switch (project.status) {
              case "active":
                acc.active.push(project);
                break;
              case "completed":
                acc.completed.push(project);
                break;
              case "archived":
                acc.archived.push(project);
                break;
              default:
                break;
            }
            return acc;
          },
          { active: [], completed: [], archived: [] }
        );
        setProjects({ active, completed, archived });
      });
      setLoading(false);
    } catch (error) {
      enqueueSnackbar("Error al obtener los datos", { variant: "error" });
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const dialogProject = (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        contractNumber: "",
        clientNameCompany: [],
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        projectScope: "",
        projectType: settingsProjects.type[0] || "",
        industry: settingsProjects.industry[0] || "",
        responsible: "",
        generalAdmin: "",
        startDate: null,
        endDate: null,
        delegationPeriod: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Este campo es obligatorio"),
        contractNumber: Yup.number().typeError("Porfavor ingrese solo números"),
        clientNameCompany: Yup.array()
          .min(1, "Debe contener al menos un elemento") // Add a custom error message for the min validation
          .required("Este campo es obligatorio"),
        timeZone: Yup.string().required("Este campo es obligatorio"),
        projectScope: Yup.string().required("Este campo es obligatorio"),
        projectType: Yup.string().required("Este campo es obligatorio"),
        industry: Yup.string()
          .min(1, "Seleccione al menos una opción")
          .required("Este campo es obligatorio"),
        generalAdmin: Yup.string().required("Este campo es obligatorio"),
        responsible: Yup.string().required("Este campo es obligatorio"),
        startDate: Yup.date()
          .required("La fecha de inicio es obligatoria")
          .max(
            maxDate,
            "La fecha de inicio no puede pasar los 100 años desde la actualidad"
          ),
        endDate: Yup.date()
          .required("La fecha de finalización es obligatoria")
          .min(
            Yup.ref("startDate"),
            "La fecha de finalización debe ser después de la fecha de inicio"
          )
          .max(
            maxDate,
            "La fecha de finalización no puede pasar los 100 años desde la actualidad"
          ),
        delegationPeriod: Yup.string().required("Este campo es obligatorio"),
      })}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);

        if (documentsFiles.length > 10) {
          enqueueSnackbar("Se ha superado el límite de 10 archivos permitidos por carga.", {
            variant: "error",
          });
          setSubmitting(false); 
          return;
        }

        await apiProjectsService
          .post(values, contractFile, documentsFiles)
          .then((res) => {
            setCheckData(!checkData);
            enqueueSnackbar("Proyecto añadido con éxito", {
              variant: "success",
            });
            setOpenDialog(false);
            handleReset(resetForm);
          })
          .catch((res) => {
            console.log(res);
            enqueueSnackbar("Ha ocurrido un error al crear el proyecto", {
              variant: "error",
            });
          });
        setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        isValid,
        touched,
        values,
      }) => (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle className={classes.dialogTitle}>
              Nuevo Proyecto
            </DialogTitle>

            <DialogContent>
              <InputLabel shrink id="client-name-company-label">
                ¿Cuál es el nombre del proyecto?{" "}
                <span className="text-danger"> * </span>
              </InputLabel>
              <TextField
                margin="dense"
                id="name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mt-2 mb-2"
                fullWidth
                variant="standard"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <Box
                sx={{ backgroundColor: "#EEF0F2", padding: "1em" }}
                justifyContent="center"
                display="flex"
              >
                <Tabs
                  indicatorColor="transparent"
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="project tabs"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab
                    className={classes.tabStyle}
                    label="Información General"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={classes.tabStyle}
                    label="Sobre Proyecto"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className={classes.tabStyle}
                    label="Fechas y alcances"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>

              <CustomTabPanel value={tabValue} index={0}>
                <Grid container spacing={2} className="mb-3">
                  <Grid item sm={12} md={6}>
                    <InputLabel shrink id="client-name-company-label">
                      Número de contrato:{" "}
                    </InputLabel>
                    <TextField
                      id="contractNumber"
                      name="contractNumber"
                      fullWidth
                      variant="standard"
                      value={values.contractNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.contractNumber && errors.contractNumber
                      )}
                      helperText={
                        touched.contractNumber && errors.contractNumber
                      }
                    />
                  </Grid>

                  <Grid item md={6} sm={12}>
                    <InputLabel shrink id="time-zone-country-label">
                      Zona Horaria / País:{" "}
                      <span className="text-danger"> * </span>
                    </InputLabel>
                    <Autocomplete
                      clearIcon={false}
                      options={timezones}
                      autoHighlight
                      noOptionsText="No se han encontrado resultados"
                      defaultValue={
                        timezones.find(
                          (time) => time.name === values.timeZone
                        ) || ""
                      }
                      onChange={(e, value) => {
                        setFieldValue("timeZone", value?.name || "");
                      }}
                      onBlur={handleBlur}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          id="timeZone"
                          name="timeZone"
                          variant="standard"
                          error={touched.timeZone && Boolean(errors.timeZone)}
                          helperText={touched.timeZone && errors.timeZone}
                        />
                      )}
                    ></Autocomplete>
                  </Grid>
                  <Grid item sm={12} md={12} xs={12}>
                    <InputLabel shrink id="client-name-company-label">
                      Nombre de firmantes:{" "}
                      <span className="text-danger"> * </span>
                    </InputLabel>

                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      autoSelect
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      value={values.clientNameCompany}
                      onChange={(e, newValue) => {
                        setFieldValue("clientNameCompany", newValue);
                      }}
                      onBlur={(e) => {
                        if (e.target.value.length === 0) {
                          setFieldTouched("clientNameCompany", true);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          name="clientNameCompany"
                          id="clientNameCompany"
                          className="w-100"
                          error={
                            touched.clientNameCompany &&
                            Boolean(errors.clientNameCompany)
                          }
                          helperText={
                            touched.clientNameCompany &&
                            errors.clientNameCompany
                          }
                        />
                      )}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>

                <InputLabel shrink id="client-name-company-label">
                  Alcance del proyecto: <span className="text-danger"> * </span>
                </InputLabel>
                <TextField
                  id="projectScope"
                  multiline
                  name="projectScope"
                  fullWidth
                  variant="standard"
                  value={values.projectScope}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.projectScope && errors.projectScope)}
                  helperText={touched.projectScope && errors.projectScope}
                />
              </CustomTabPanel>

              <CustomTabPanel value={tabValue} index={1}>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={6}>
                    <InputLabel shrink id="project-type-label">
                      Tipo de proyecto: <span className="text-danger"> * </span>
                    </InputLabel>
                    <Autocomplete
                      className="mt-2"
                      clearIcon={false}
                      options={settingsProjects.type}
                      value={values.projectType}
                      autoHighlight
                      noOptionsText="No se han encontrado resultados"
                      onChange={(e, value) => {
                        setFieldValue("projectType", value || "");
                      }}
                      onBlur={handleBlur}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          id="projectType"
                          name="projectType"
                          variant="standard"
                          error={
                            touched.projectType && Boolean(errors.projectType)
                          }
                          helperText={touched.projectType && errors.projectType}
                        />
                      )}
                    ></Autocomplete>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <InputLabel shrink id="industry-label">
                      Industria: <span className="text-danger"> * </span>
                    </InputLabel>
                    <Autocomplete
                      className="mt-2"
                      clearIcon={false}
                      options={settingsProjects.industry}
                      value={values.industry}
                      autoHighlight
                      noOptionsText="No se han encontrado resultados"
                      onChange={(e, value) => {
                        setFieldValue("industry", value || "");
                      }}
                      onBlur={handleBlur}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          id="industry"
                          name="industry"
                          variant="standard"
                          error={touched.industry && Boolean(errors.industry)}
                          helperText={touched.industry && errors.industry}
                        />
                      )}
                    ></Autocomplete>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <InputLabel shrink id="responsible-label">
                      Responsable: <span className="text-danger"> * </span>
                    </InputLabel>
                    <Autocomplete
                      className="mt-2"
                      clearIcon={false}
                      options={customers}
                      autoHighlight
                      noOptionsText="No se han encontrado resultados"
                      value={
                        customers.find(
                          (obj) => obj.id === values?.responsible
                        ) || null
                      }
                      onChange={(e, value) => {
                        setFieldValue("responsible", value?.id || "");
                      }}
                      onBlur={handleBlur}
                      getOptionLabel={(option) => option?.contactName || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{ ...params.inputProps }}
                          id="responsible"
                          name="responsible"
                          variant="standard"
                          error={
                            touched.responsible && Boolean(errors.responsible)
                          }
                          helperText={touched.responsible && errors.responsible}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <InputLabel shrink id="general-admin-label">
                      Administrador general:{" "}
                      <span className="text-danger"> * </span>
                    </InputLabel>
                    <Autocomplete
                      className="mt-2"
                      clearIcon={false}
                      options={users} // Modifica las opciones para que solo contengan id y fullname
                      autoHighlight
                      noOptionsText="No se han encontrado resultados"
                      value={
                        users.find((obj) => obj.id === values.generalAdmin) ||
                        null
                      }
                      onChange={(e, value) => {
                        setFieldValue("generalAdmin", value?.id || "");
                      }}
                      onBlur={handleBlur}
                      getOptionLabel={(option) => option?.fullname || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          id="generalAdmin"
                          name="generalAdmin"
                          variant="standard"
                          error={
                            touched.generalAdmin && Boolean(errors.generalAdmin)
                          }
                          helperText={
                            touched.generalAdmin && errors.generalAdmin
                          }
                        />
                      )}
                    ></Autocomplete>
                  </Grid>
                </Grid>
              </CustomTabPanel>

              <CustomTabPanel value={tabValue} index={2}>
                <Grid container spacing={2} rowSpacing={3}>
                  <Grid item sm={4}>
                    <InputLabel shrink id="date-label">
                      Fecha de Inicio *:{" "}
                    </InputLabel>

                    <TextField
                      type="date"
                      id="startDate"
                      name="startDate"
                      className="w-100"
                      value={values.startDate}
                      onChange={(e) => {
                        setFieldValue("startDate", e.target.value);
                        if (values.endDate !== "") {
                          setFieldValue(
                            "delegationPeriod",
                            relativeDate(e.target.value, values.endDate)
                          );
                        }
                      }}
                      onBlur={handleBlur}
                      variant="standard"
                      error={Boolean(touched.startDate && errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <InputLabel shrink id="date-label">
                      Fecha de Finalización *:{" "}
                    </InputLabel>

                    <TextField
                      id="endDate"
                      name="endDate"
                      value={values.endDate}
                      onChange={(e) => {
                        setFieldValue("endDate", e.target.value);
                        if (values.startDate !== "") {
                          setFieldValue(
                            "delegationPeriod",
                            relativeDate(values.startDate, e.target.value)
                          );
                        }
                      }}
                      className="w-100"
                      onBlur={handleBlur}
                      type="date"
                      variant="standard"
                      error={Boolean(touched.endDate && errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                    />
                  </Grid>

                  <Grid item sm={4}>
                    <InputLabel shrink id="delegationPeriod">
                      Plazo:{" "}
                    </InputLabel>
                    <TextField
                      id="delegationPeriod"
                      name="delegationPeriod"
                      fullWidth
                      disabled
                      value={values.delegationPeriod}
                      variant="standard"
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <InputLabel
                      shrink
                      className="mb-2"
                      id="general-admin-label"
                    >
                      Contrato: <span className="text-danger"> * </span>
                    </InputLabel>

                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      onChange={(e) =>
                        handleContractFileChange(e.target.files[0])
                      }
                      startIcon={<FileUploadIcon />}
                    >
                      {contractFile?.name || "Selecciona un archivo"}
                      <VisuallyHiddenInput
                        type="file"
                        accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                      />
                    </Button>
                  </Grid>

                  <Grid item sm={6}>
                    <InputLabel
                      shrink
                      className="mb-2"
                      id="general-admin-label"
                    >
                      Documentos asociados y enmiendas:
                    </InputLabel>
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<FileUploadIcon />}
                    >
                      {documentsFiles.length > 0
                        ? `${documentsFiles.length} archivo(s) seleccionado(s)`
                        : "Selecciona archivos"}
                      <input
                        type="file"
                        accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                        multiple
                        hidden
                        onChange={(e) =>
                          handleDocumentsFileChange(e.target.files)
                        }
                      />
                    </Button>
                  </Grid>
                </Grid>
              </CustomTabPanel>
            </DialogContent>
            <DialogActions className="mb-3">
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Typography variant="caption" component="ul">
                    <li>Los campos marcados con * son obligatorios</li>
                    <li>
                      Recuerda llenar la pestaña de <b>Sobre Proyecto</b> ,{" "}
                      <b>Fecha y Alcances</b>
                    </li>
                    <li>
                      Recuerda de configurar previamente las opciones para
                      elegir el <b>tipo</b>, <b>industria</b> y{" "}
                      <b>administrador general</b> en el módulo de{" "}
                      <b>Ajustes</b>
                    </li>
                  </Typography>
                </Grid>

                <Grid
                  item
                  sm={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Button
                    variant="contained"
                    className="rounded-4 me-3"
                    onClick={handleCloseDialog}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    variant="contained"
                    size="medium"
                    startIcon={<Save />}
                    loading={isSubmitting}
                    disabled={!isValid || contractFile === null}
                    loadingPosition="start"
                    className="rounded-4 me-3"
                    type="submit"
                  >
                    Crear nuevo
                  </LoadingButton>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );

  const confirmDialog = (
    <Dialog
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {dialogType === "delete"
          ? "Eliminar Proyecto"
          : dialogType === "archive"
            ? "Archivar Proyecto"
            : "Cambiar de estado"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogType === "delete"
            ? "¿Estás seguro de que deseas eliminar este proyecto? Esta acción no se puede deshacer."
            : dialogType === "archive"
              ? `¿Estás seguro de que deseas ${selectedIndex === 2 ? "Desarchivar" : "Archivar"
              } este proyecto?`
              : `¿Estás seguro de que deseas marcar como ${selectedIndex === 1 ? "No completado" : "Completado"
              } este proyecto?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseConfirmDialog}>Cancelar</Button>
        <Button
          onClick={
            dialogType === "delete" ? handleConfirmDelete : handleConfirmArchive
          }
          autoFocus
        >
          {dialogType === "delete"
            ? "Eliminar"
            : dialogType === "archive"
              ? `${selectedIndex === 2 ? "Desarchivar" : "Archivar"}`
              : `${selectedIndex === 1
                ? "Marcar como No completado"
                : "Marcar como Completado"
              }`}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const projectSidebar = (
    <Box width={"100%"}>
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{
          width: "100%",
          position: "sticky",
          top: "140px",
          height: "fit-content",
        }}
      >
        {listItems.map((item, index) => (
          <ListItemButton
            key={item.id}
            classes={{ root: classes.selectedItem }}
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemIcon className={classes.icon}>
              <item.Icon />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  const getCurrentProjectList = () => {
    switch (selectedIndex) {
      case 0: // Activos
        return projects.active;
      case 1: // Completados
        return projects.completed;
      case 2: // Archivados
        return projects.archived;
      default:
        return [];
    }
  };

  const handleOpenDialog = (type) => {
    setDialogType(type);
    setConfirmOpenDialog(true);
    setAnchorEl(false);
  };

  return (
    <>
      <TopBar />
      <Grid container className={classes.stickyHeader} top={60}>
        <Grid item sm={6}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            color="primary"
            className="m-3"
          >
            Proyectos
          </Typography>
        </Grid>
        <Grid item sm={6} className={classes.projectStyle}>
          {permissionsService("projects", myRol?.role?.label, "add") && <Button
            onClick={() => {
              fetchSettingsProjects();
              fetchUsers();
              fetchCustomers();
              setOpenDialog(true);
            }}
            variant="contained"
          >
            Nuevo proyecto
          </Button>}
        </Grid>
      </Grid>
      {/*       <Divider />
       */}{" "}
      <Grid container spacing={2}>
        <Grid item lg={2} md={3} sm={4} xs={12} className="d-flex">
          {projectSidebar}
          <Divider orientation="vertical" />
        </Grid>
        <Grid item lg={10} md={9} sm={8} xs={12}>
          <Container maxWidth="lg">
            <Box mx={1} my={2}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" color="gray" className="fw-bold">
                  {getCurrentProjectList().length} Proyectos
                </Typography>

                {/* <Tooltip title="Filtrar">
                  <IconButton
                    aria-label="settings"
                    aria-controls="project-menu"
                    aria-haspopup="true"
                  >
                    <FilterAlt color="primary" />
                  </IconButton>
                </Tooltip> */}
              </Box>
            </Box>

            {!loading ? (
              <Grid container spacing={2}>
                {(() => {
                  // Objeto para almacenar temporalmente las tarjetas de cada persona
                  const groupedProjects = {};

                  // Iterar sobre la lista de proyectos y agrupar las tarjetas por generalAdmin.fullname
                  getCurrentProjectList().forEach((project) => {
                    const fullName = project?.responsible?.contactName;
                    if (!groupedProjects[fullName]) {
                      groupedProjects[fullName] = [];
                    }
                    groupedProjects[fullName].push(project);
                  });

                  // Renderizar los nombres de las personas y las tarjetas agrupadas consecutivamente
                  return Object.entries(groupedProjects).map(
                    ([fullName, projects], index) => (
                      <Fragment key={fullName}>
                        <Grid item xs={12}>
                          <Typography
                            fontSize={16}
                            color="rgb(111,118,121)"
                            className="fw-bold mb-2"
                          >
                            {fullName}
                          </Typography>
                        </Grid>
                        {projects.map((project, projectIndex) => (
                          <Grid item xs={6} key={project.id}>
                            <Card
                              variant="outlined"
                              className={
                                projectIndex !== projects.length - 1
                                  ? "mb-2"
                                  : ""
                              }
                            >
                              <CardHeader
                                action={
                                  <>
                                    <IconButton
                                      aria-label="settings"
                                      aria-controls="project-menu"
                                      aria-haspopup="true"
                                      onClick={handleClick(project)}
                                    >
                                      <MoreVertIcon color="primary" />
                                    </IconButton>
                                    <Menu
                                      id="project-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={open}
                                      onClose={handleClose}
                                      elevation={1}
                                    >
                                      <MenuItem onClick={handleProject}>
                                        Gestionar
                                      </MenuItem>
                                     { permissionsService("projects", myRol?.role?.label, "archived") && <MenuItem
                                        onClick={() =>
                                          handleOpenDialog("archive")
                                        }
                                      >
                                        {selectedIndex === 2
                                          ? "Desarchivar"
                                          : "Archivar"}
                                      </MenuItem>}
                                      {permissionsService("projects", myRol?.role?.label, "completed") && <MenuItem
                                        onClick={() =>
                                          handleOpenDialog("completed")
                                        }
                                      >
                                        {selectedIndex === 1
                                          ? "No completado"
                                          : "Completado"}
                                      </MenuItem>}
                                      {permissionsService("projects", myRol?.role?.label, "delete") && <MenuItem
                                        onClick={() =>
                                          handleOpenDialog("delete")
                                        }
                                      >
                                        Eliminar
                                      </MenuItem>}
                                    </Menu>
                                  </>
                                }
                                title={project.name}
                                subheader={
                                  project?.responsible?.contactName +
                                  " / " +
                                  project?.responsible?.companyName
                                }
                                titleTypographyProps={{ color: "primary" }}
                                subheaderTypographyProps={{
                                  color: "GrayText",
                                  className: "mt-2",
                                  fontWeight: "bolder",
                                }}
                              />
                              <Divider orientation="horizontal" flexItem />
                              <CardContent>
                                <Typography color="GrayText" className="mb-2">
                                  <span className={classes.textprimary}>
                                    Fecha de inicio:
                                  </span>{" "}
                                  {project.startDate}
                                </Typography>
                                <Typography color="GrayText" className="mb-2">
                                  <span className={classes.textprimary}>
                                    Años de delegación del proyecto:
                                  </span>{" "}
                                  {project.delegationPeriod}
                                </Typography>
                                <Typography color="GrayText" className="mb-2">
                                  <span className={classes.textprimary}>
                                    Zona:
                                  </span>{" "}
                                  {project.timeZone}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                        {/* Espacio entre grupos de proyectos */}
                        {index !==
                          Object.entries(groupedProjects).length - 1 && (
                            <Grid item xs={12} style={{ marginBottom: "16px" }} />
                          )}
                      </Fragment>
                    )
                  );
                })()}

                {getCurrentProjectList().length === 0 && (
                  <>
                    <Typography variant="h5" className="ms-4" color="GrayText">
                      No se han encontrado resultados
                    </Typography>
                    <Divider variant="fullWidth" orientation="horizontal" />
                  </>
                )}
              </Grid>
            ) : (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </Container>
          {dialogProject}
          {confirmDialog}
        </Grid>
      </Grid>
    </>
  );
};

export default Projects;
