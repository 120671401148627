import { useEffect, useRef, useState } from "react";
import { AccessTimeOutlined, Add, AddCircle, Archive, ArrowDropDown, AttachFile, Autorenew, Check, CheckCircle, CheckCircleOutlined, ChevronLeft, ContentCopy, ContentCopySharp, ContentCopyTwoTone, Delete, DriveFileRenameOutline, Edit, ErrorOutlineOutlined, Expand, ExpandMore, FileCopy, FileUpload, HelpOutlineOutlined, HighlightOffOutlined, History, HistoryOutlined, HourglassEmptyOutlined, Info, InfoOutlined, Message, MoreVert, RemoveCircleOutline, ReportProblem, Save, ScheduleOutlined, Send, TableChartOutlined, TextSnippet, Visibility } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import * as Yup from "yup";
import {
  Box,
  Paper,
  DialogActions,
  Dialog,
  Typography,
  Button,
  DialogContent,
  InputLabel,
  DialogTitle,
  IconButton,
  TableRow,
  Menu,
  Tabs,
  Tab,
  MenuItem,
  Table,
  Select,
  Grid,
  Divider,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  TableHead,
  Input,
  InputAdornment,
  ListItem,
  ListItemText,
  List,
  Card,
  ListItemIcon,
  CardHeader,
  CardContent,
  Container,
  Tooltip,
  LinearProgress,
  Autocomplete,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  Avatar,
  Chip,
  ListItemAvatar,
  ListItemSecondaryAction,
  ButtonBase,
  ButtonGroup
} from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useStyles from "./styles";
import { styled } from '@mui/styles';
import SearchIcon from "@mui/icons-material/Search";
import confirmar from "../../../../assets/confirmar.png";
import ExcelIcon from "../../../../assets/icons/excel.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import FileIcon from "../../../../assets/icons/file.svg";
import WordIcon from "../../../../assets/icons/word.svg";
import * as excelJs from 'exceljs';
import * as XLSX from 'xlsx';
import CommentIcon from "@mui/icons-material/Comment";
import { FieldArray, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import EmptyIcon from "../../../../assets/emptyBox.png"
import apiClausesService from "src/services/clausesService";
import apiUserService from "src/services/usersService";
import { AddDaysToDate, AddMonthsToDate, dateFormat, dateFormat2, dateFormatEs, excelDateToJSDate, formatDateToDateTimeLocal } from "src/utils/timeLocal";
import apiProjectsService from "src/services/projectsService";
import apiClauseServiceRealTime from "src/services/clausesServicesRealTime";
import permissionsService from "src/services/permissionsService";

const typesClauses = ["Acción de una única vez", "Acción ligada a otra acción o fecha", "Acción recurrente", "No necesita gestión"]
const states = ["En gestión", "Aprobado", "Atrasada", "Rechazada", "Presentada Tarde", "Pendiente de Aprobación", "Por vencer", "Sin gestión"]

const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() + 100);

const Clauses = () => {
  const User = useSelector((state) => state?.setUserLogin?.user);
  const Project = useSelector((state) => state?.setUserLogin?.project);
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElClause, setAnchorElClause] = useState(null);
  const [openDialogApprove, setOpenDialogApprove] = useState(false);
  const [openDialogDecline, setOpenDialogDecline] = useState(false);
  const [openDialogSend, setOpenDialogSend] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogDuplicate, setOpenDialogDuplicate] = useState(false);
  const [openDialogNewId, setOpenDialogNewId] = useState(false);
  const [openDialogNewName, setOpenDialogNewName] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [openDialogConfir, setOpenDialogConfir] = useState(false);
  const [loadingDeleteClause, setLoadingDeleteClause] = useState(false);

  const [viewDetalle, setViewDetalle] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const open = Boolean(anchorEl);
  const openClause = Boolean(anchorElClause);
  const [page, setPage] = useState(0);
  const [loadingClauses, setLoadingClauses] = useState(false);
  const [selectedClause, setSelectedClause] = useState([]);
  const [loaderMessages, setLoaderMessages] = useState(false);
  const formikRef = useRef(null);
  const [openFile, setOpenFile] = useState(null);
  const [selectedReview, setSelectedReview] = useState(null);
  const [rawClauses, setRawClauses] = useState([])
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(true)
  const [clauses, setClauses] = useState([]);
  const [messages, setMessages] = useState([])
  const [errors, setErrors] = useState([]);
  const [selectedDeclineComment, setSelectedDeclineComment] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const anchorRef = useRef(null);
  const [dialogDetails, setDialogDetails] = useState(false);
  const classes = useStyles();
  const [settingsProjects, setSettingsProjects] = useState({
    type: [],
    category: [],
    industry: [],
    criticality: [],
    frequency: []
  });
  const [users, setUsers] = useState([]);
  const [file, setFile] = useState(null);
  const [roles, setRoles] = useState([]);
  const [importDialog, setImportDialog] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showFullClause, setShowFullClause] = useState({});
  const MAX_FILENAME_LENGTH = 100;
  const myRol = User?.role?.label !== "Super Administrador" && User?.role?.label !== "Administrador TPIG" ? User?.projects?.find(user => user?.project?.id === Project?.id) : User;


  const handleCloseDialog = () => {
    setOpenDialogApprove(false);
    setOpenDialogDecline(false);
    setOpenDialogSend(false);
    setOpenDialogCreate(false);
    setOpenDialogDuplicate(false);
    setOpenDialogNewId(false);
    setOpenDialogNewName(false);
    setOpenDialogConfir(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpenFile(false);
  };

  const handleToggle = () => {
    setOpenFile((prevOpen) => !prevOpen);
  };

  const handleCloseFile = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenFile(false);
  };





  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Cláusulas";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  const convertToDate = (timestamp) => {
    return new Date(timestamp?._seconds * 1000 + timestamp?._nanoseconds / 1000000);
  };


  useEffect(() => {
    setFilteredUsers(
      users.filter((user) =>
        user.fullname.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);


  const getStateParams = (state) => {
    switch (state) {
      case "Atrasada":
        return "#CC8319"
      case "Rechazada":
        return "#FC4343"
      case "Presentada Tarde":
        return "#5645A1"
      case "Pendiente de Aprobación":
        return "#20629C"
      case "Por vencer":
        return "#F57C00"
      case "Aprobado Tarde":
        return "#248236"
      case "Aprobado":
        return "#42B555"
      case "En gestión":
        return "rgb(23,59,109)"
      default:
        return "#000"
    }
  };


  const getCriticalityColor = (criticality) => {
    switch (criticality) {
      case "Alta":
        return "#FC4343";
      case "Media":
        return "#EAB137";
      case "Baja":
        return "#42B555";
      default:
        return "#000";
    }
  };
  const headers = ['Nombre' /* A */, 'Detalles' /* B */, 'Número' /* C */, "Tipo" /* D */, "Grupo" /* E */, "Fecha de inicio (dd/mm/aaaa HH:mm)" /* F */, "Fecha de vencimiento (dd/mm/aaaa HH:mm)" /* G */, "Criticidad" /* H */, "Categoría" /* I */, "Estado" /* J */, "Usuario (Aprobador)" /* K */, "Usuario (Gestionador)" /* L */, "Usuario (Responsable)" /* M */, "Cláusula Ligada" /* N */, "Plazo de vencimiento (días)" /* O */, "Periodo de recurrencia (meses)" /* P */, "Notificación pre-vencimiento (días)" /* Q */, "Notificación post-vencimiento (días)" /* R */]
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleToggleImport = () => {
    setFile(null);
    setErrors([])
    setImportDialog(!importDialog)
  }

  const clausesTemplate = async () => {
    const groups = Object.keys(clauses) ? Object.keys(clauses) : [];
    const workbook = new excelJs.Workbook();
    const ws = workbook.addWorksheet('Clausulas');
    const clausesLinked = rawClauses.filter(clause => clause.clauseType !== "No necesita gestión")
      .sort((a, b) => a.clauseName.localeCompare(b.clauseName));
    const optionsSheet = workbook.addWorksheet('Options', {
      state: 'hidden',
    });

    ws.views = [
      { state: 'frozen', xSplit: 1, ySplit: 2 }
    ];

    typesClauses.forEach((option, index) => {
      optionsSheet.getCell(`D${index + 1}`).value = option;
    });

    if (groups.length > 0) {
      groups.forEach((option, index) => {
        optionsSheet.getCell(`E${index + 1}`).value = option;
      });
    }

    (settingsProjects?.criticality).forEach((option, index) => {
      optionsSheet.getCell(`H${index + 1}`).value = option;
    });

    (settingsProjects?.category).forEach((option, index) => {
      optionsSheet.getCell(`I${index + 1}`).value = option;
    });

    (states).forEach((option, index) => {
      optionsSheet.getCell(`J${index + 1}`).value = option;
    });

    (users).forEach((option, index) => {
      optionsSheet.getCell(`K${index + 1}`).value = {
        "nombre": option.fullname,
        "id": option.id
      };
    });

    (users).forEach((option, index) => {
      optionsSheet.getCell(`L${index + 1}`).value = {
        "nombre": option.fullname,
        "id": option.id
      };
    });

    (users).forEach((option, index) => {
      optionsSheet.getCell(`M${index + 1}`).value = {
        "nombre": option.fullname,
        "id": option.id
      };
    });

    if (rawClauses.length > 0) {
      (clausesLinked || []).forEach((option, index) => {
        optionsSheet.getCell(`N${index + 1}`).value = option?.clauseNumber;
      });
    }

    const optionsRange1 = `Options!$D$1:$D${typesClauses.length}`;
    let optionsRange2;
    if (groups.length > 0) {
      optionsRange2 = `Options!$E$1:$E${groups.length}`;
    }
    const optionsRange3 = `Options!$H$1:$H${(settingsProjects?.criticality).length}`;
    const optionsRange4 = `Options!$I$1:$I${(settingsProjects?.category).length}`;
    const optionsRange6 = `Options!$J$1:$J${(states).length}`;
    const optionsRange7 = `Options!$K$1:$K${(users).length}`;
    const optionsRange8 = `Options!$L$1:$L${(users).length}`;
    const optionsRange9 = `Options!$M$1:$M${(users).length}`;

    let optionsRange10;
    if (rawClauses.length > 0) {
      optionsRange10 = `Options!$N$1:$N${(rawClauses).length}`;
    }
    const titleRow = ws.getRow(1);
    titleRow.getCell(1).value = 'Carga inicial cláusulas';
    titleRow.font = { name: 'Inter', size: 9, bold: true };
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' };
    titleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      bgColor: { argb: 'FFFFFF' },
      fgColor: { argb: '1976D2' },
    };
    ws.mergeCells('A1:E1');

    ws.addRow(headers);

    const maxRow = 100;

    for (let rowNumber = 2; rowNumber <= 11; rowNumber++) {
      ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'].forEach((columnLetter) => {
        const cell = ws.getCell(`${columnLetter}${rowNumber}`);
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        cell.font = { name: 'Inter', size: 8, bold: true };
        cell.alignment = { horizontal: 'center' };
      });
    }

    for (let rowNumber = 3; rowNumber <= maxRow; rowNumber++) {
      ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'].forEach((columnLetter) => {
        const cell = ws.getCell(`${columnLetter}${rowNumber}`);
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        cell.font = { name: 'Inter', size: 7 };
        cell.alignment = { horizontal: 'left' };
      });
    }

    ws.getColumn('A').width = 35;
    ws.getColumn('B').width = 50;
    ws.getColumn('C').width = 30;
    ws.getColumn('D').width = 30;
    ws.getColumn('E').width = 30;
    ws.getColumn('F').width = 30;
    ws.getColumn('G').width = 30;
    ws.getColumn('H').width = 40;
    ws.getColumn('I').width = 30;
    ws.getColumn('J').width = 30;
    ws.getColumn('K').width = 40;
    ws.getColumn('L').width = 40;
    ws.getColumn('M').width = 40;
    ws.getColumn('N').width = 40;
    ws.getColumn('O').width = 30;
    ws.getColumn('P').width = 30;
    ws.getColumn('Q').width = 30;
    ws.getColumn('R').width = 30;

    ws.getRow(1).height = 40;

    for (let rowNumber = 3; rowNumber <= maxRow; rowNumber++) {
      const startDateCell = ws.getCell(`F${rowNumber}`);
      const endDateCell = ws.getCell(`G${rowNumber}`);
      startDateCell.numFmt = 'dd/mm/yyyy HH:mm';
      endDateCell.numFmt = 'dd/mm/yyyy HH:mm';

      ws.dataValidations.add(startDateCell.address, {
        type: 'date',
        operator: 'between',
        formula1: 'DATE(1900,1,1)',
        formula2: 'DATE(9999,12,31)',
        showErrorMessage: true,
        errorStyle: 'stop',
        errorTitle: 'Fecha Inválida',
        error: 'Por favor, ingrese una fecha válida en el formato dd/mm/yyyy HH:mm.',
      });

      ws.dataValidations.add(endDateCell.address, {
        type: 'date',
        operator: 'between',
        formula1: 'DATE(1900,1,1)',
        formula2: 'DATE(9999,12,31)',
        showErrorMessage: true,
        errorStyle: 'stop',
        errorTitle: 'Fecha Inválida',
        error: 'Por favor, ingrese una fecha válida en el formato dd/mm/yyyy HH:mm.',
      });
    }

    ['O', 'P', 'Q', 'R'].forEach((columnLetter) => {
      for (let rowNumber = 3; rowNumber <= maxRow; rowNumber++) {
        const cell = ws.getCell(`${columnLetter}${rowNumber}`);
        ws.dataValidations.add(cell.address, {
          type: 'whole',
          operator: 'greaterThan',
          formula1: '0',
          showErrorMessage: true,
          errorStyle: 'stop',
          errorTitle: 'Entrada Inválida',
          error: 'Por favor, ingrese un número entero mayor que 0.',
        });
      }
    });

    ws.dataValidations.add('D3:D99999', {
      type: 'list',
      allowBlank: true,
      formulae: [optionsRange1],
      showErrorMessage: true,
      errorStyle: 'stop',
      errorTitle: 'Entrada Inválida',
      error: 'Por favor, seleccione un valor de la lista.',
    });

    if (groups.length > 0) {
      ws.dataValidations.add('E3:E99999', {
        type: 'list',
        allowBlank: true,
        formulae: [optionsRange2]
      });
    }

    ws.dataValidations.add('H3:H99999', {
      type: 'list',
      allowBlank: true,
      formulae: [optionsRange3],
      showErrorMessage: true,
      errorStyle: 'stop',
      errorTitle: 'Entrada Inválida',
      error: 'Por favor, seleccione un valor de la lista.',
    });


    ws.dataValidations.add('I3:I99999', {
      type: 'list',
      allowBlank: true,
      formulae: [optionsRange4],
      showErrorMessage: true,
      errorStyle: 'stop',
      errorTitle: 'Entrada Inválida',
      error: 'Por favor, seleccione un valor de la lista.',
    });

    ws.dataValidations.add('J3:J99999', {
      type: 'list',
      allowBlank: true,
      formulae: [optionsRange6],
      showErrorMessage: true,
      errorStyle: 'stop',
      errorTitle: 'Entrada Inválida',
      error: 'Por favor, seleccione un valor de la lista.',
    });

    ws.dataValidations.add('K3:K99999', {
      type: 'list',
      allowBlank: true,
      formulae: [optionsRange7],
      showErrorMessage: true,
      errorStyle: 'stop',
      errorTitle: 'Entrada Inválida',
      error: 'Por favor, seleccione un valor de la lista.',
    });

    ws.dataValidations.add('L3:L99999', {
      type: 'list',
      allowBlank: true,
      formulae: [optionsRange8],
      showErrorMessage: true,
      errorStyle: 'stop',
      errorTitle: 'Entrada Inválida',
      error: 'Por favor, seleccione un valor de la lista.',
    });


    ws.dataValidations.add('M3:M99999', {
      type: 'list',
      allowBlank: true,
      formulae: [optionsRange9],
      showErrorMessage: true,
      errorStyle: 'stop',
      errorTitle: 'Entrada Inválida',
      error: 'Por favor, seleccione un valor de la lista.'
    });

    if (rawClauses.length > 0) {
      ws.dataValidations.add('N3:N99999', {
        type: 'list',
        allowBlank: true,
        formulae: [optionsRange10]
      });
    }

    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
      new Blob([excelBlob], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
    );
    const link = document.createElement('a');
    link.href = excelUrl;
    link.download = 'Carga inicial de cláusulas MGC.xlsx';
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);

  }

  const sanitizeFilename = (filename) => {
    return filename.replace(/[^a-zA-Z0-9._-]/g, '_');
  };

  const truncateFilename = (filename) => {
    if (filename.length > MAX_FILENAME_LENGTH) {
      const extensionIndex = filename.lastIndexOf('.');
      const extension = extensionIndex !== -1 ? filename.substring(extensionIndex) : '';
      const baseName = extensionIndex !== -1 ? filename.substring(0, extensionIndex) : filename;
      return baseName.substring(0, MAX_FILENAME_LENGTH - extension.length) + extension;
    }
    return filename;
  };

  const handleApprove = () => {
    let state;
    if (selectedClause.state === "Presentada Tarde") {
      state = "Aprobado Tarde"
    } else {
      state = "Aprobado"
    }
    const data = {
      idReview: selectedReview.id,
      idUser: User.id,
      state: state,
      comment: "La cláusula ha sido aprobada satisfactoriamente."
    }
    apiClausesService.updateStatus(Project.id, selectedClause.id, data).then((res) => {
      enqueueSnackbar("Cláusula aprobada con éxito", { variant: "success" })
      setOpenDialogApprove(false);
    }).catch((error) => {
      const errorMessage = error.response?.data?.message || "Ha ocurrido un error en la aprobación";
      enqueueSnackbar(errorMessage, { variant: "error" });
    })
  }

  const handleDecline = () => {
    const data = {
      idReview: selectedReview.id,
      idUser: User.id,
      state: "Rechazada",
      comment: selectedDeclineComment
    }
    apiClausesService.updateStatus(Project.id, selectedClause.id, data).then((res) => {
      enqueueSnackbar("Cláusula rechazada con éxito", { variant: "success" })
      setOpenDialogDecline(false);
    }).catch((error) => {
      const errorMessage = error.response?.data?.message || "Ha ocurrido un error al realizar el rechazo";
      enqueueSnackbar(errorMessage, { variant: "error" });
    })
  }

  useEffect(() => {
    if (viewDetalle) {
      setLoaderMessages(true);
      apiProjectsService.getMessages(Project.id, selectedClause.id).then((res) => {
        let msg = res.data;
        msg = msg.sort((a, b) => new Date(b.expeditionDate) - new Date(a.expeditionDate));
        setLoaderMessages(false);
        setMessages(msg)
      }).catch((error) => {
        setLoaderMessages(false);
        enqueueSnackbar("Ha ocurrido un error al cargar los mensajes", { variant: "error" })

      })
    }
  }, [viewDetalle])

  const onUpload = (event) => {
    try {
      const file = event.target.files[0];

      const sanitizedFilename = sanitizeFilename(file.name);
      const truncatedFilename = truncateFilename(sanitizedFilename);
      const sanitizedFile = new File([file], truncatedFilename, { type: file.type });

      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];

          if (wsname === 'Clausulas') { // Corregido el nombre de la hoja de cálculo
            setFile(sanitizedFile); // Utilizamos sanitizedFile en lugar de file
          } else {
            enqueueSnackbar('Esta plantilla no corresponde a Cláusulas', { variant: "error" });
          }
        } catch (error) {
          console.log(error);
          enqueueSnackbar('Por favor, ingrese una plantilla con datos válidos', { variant: "error" });
        }
      };

      reader.readAsBinaryString(sanitizedFile); // Corregido para leer el archivo correcto

    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error al subir el archivo, revisa y vuelve a intentarlo', { variant: "error" });
    }
  }



  const uploadFiles = () => {
    if (file) {
      setLoadingClauses(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          let data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            blankrows: false,
          });

          data = data.slice(2); // Eliminar las dos primeras filas que probablemente sean encabezados

          if (data.length === 0) {
            setLoadingClauses(false);
            return enqueueSnackbar('El archivo no contiene datos', { variant: "error" });
          }


          const requiredFields = {
            "Acción de una única vez": [0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 16, 17],
            "Acción ligada a otra acción o fecha": [0, 1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17],
            "Acción recurrente": [0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 15, 16, 17],
            "No necesita gestión": [0, 1, 2, 3, 4, 5, 7, 8, 10, 11, 12]
          };

          let errors = [];

          for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
            const row = data[rowIndex];
            const tipo = row[3];
            const clauseNumber = row[2];

            if (requiredFields[tipo]) {
              for (const fieldIndex of requiredFields[tipo]) {
                if (row[fieldIndex] === undefined || row[fieldIndex] === null || row[fieldIndex] === "") {
                  errors.push(`Falta el campo de la columna ${headers[fieldIndex]} de la cláusula ${clauseNumber && clauseNumber !== undefined ? clauseNumber : ""} en la línea ${rowIndex + 3}`);

                }
              }
            } else {
              errors.push(`Tipo de cláusula desconocido: ${tipo && tipo !== undefined ? tipo : ""} en la línea ${rowIndex + 3}`);
            }
          }

          if (errors.length > 0) {
            setErrors(errors);
            setLoadingClauses(false);
            return;
          }

          let transformedData = data.map((row, rowIndex) => {
            let finalUsers = [];
            const clauseName = row[0];
            const clauseDetails = row[1];
            const clauseNumber = row[2];
            const tipo = row[3];
            const belongingGroup = row[4];
            let startDate = excelDateToJSDate(row[5], Project.timeZone);
            let expirationDate = row[6] ? excelDateToJSDate(row[6], Project.timeZone) : null;
            const criticality = row[7];
            const category = row[8];
            const state = row[9];
            const userApp = JSON.parse(row[10]);
            const userGest = JSON.parse(row[11]);
            const userResp = JSON.parse(row[12]);
            if (userApp) {
              finalUsers.push({
                roleId: 3,
                roleName: "Aprobador",
                userId: userApp.id,
                username: userApp.nombre
              })
            }
            if (userGest) {
              finalUsers.push({
                roleId: 4,
                roleName: "Gestionador",
                userId: userGest.id,
                username: userGest.nombre
              })
            }
            if (userResp) {
              finalUsers.push({
                roleId: 6,
                roleName: "Responsable",
                userId: userResp.id,
                username: userResp.nombre
              })
            }

            let preExpirationNotification = Number.parseInt(row[16]);
            const postExpirationNotification = Number.parseInt(row[17]);

            if (expirationDate && startDate) {
              const daysDifference = Math.floor((expirationDate - startDate) / (1000 * 60 * 60 * 24));

              if (daysDifference <= 1) {
                preExpirationNotification = 0;
              } else if (daysDifference < 7) {
                preExpirationNotification = daysDifference - 1;
              } else {
                preExpirationNotification = 7;
              }
            }

            if (tipo === "Acción de una única vez") {
              return {
                clauseName: clauseName,
                clauseNumber: clauseNumber,
                clauseDetails: clauseDetails,
                clauseType: tipo,
                belongingGroup: belongingGroup,
                startDate: startDate,
                expirationDate: expirationDate || null,
                users: finalUsers,
                criticality: criticality,
                category: category,
                state: state,
                preExpirationNotification: expirationDate ? preExpirationNotification : 0,
                postExpirationNotification: expirationDate ? postExpirationNotification : 0,
                jobId: "",
                jobPre: "",
                jobPost: ""
              };
            } else if (tipo === "Acción ligada a otra acción o fecha") {
              const clauseLinked = row[13];
              return {
                clauseName: clauseName,
                clauseNumber: clauseNumber.toString(),
                clauseDetails: clauseDetails,
                clauseType: tipo,
                startDate: null,
                expirationDate: null,
                belongingGroup: belongingGroup,
                criticality: criticality,
                category: category,
                linkedClause: clauseLinked,
                undefinedExpiration: false,
                expirationDays: Number.parseInt(row[14]),
                state: state,
                users: finalUsers,
                preExpirationNotification: expirationDate ? preExpirationNotification : 0,
                postExpirationNotification: expirationDate ? postExpirationNotification : 0,
                jobId: "",
                jobPre: "",
                jobPost: ""
              };
            } else if (tipo === "Acción recurrente") {
              const recurrencePeriod = Number.parseInt(row[15]);
              return {
                clauseName: clauseName,
                clauseNumber: clauseNumber,
                clauseDetails: clauseDetails,
                clauseType: tipo,
                belongingGroup: belongingGroup,
                startDate: startDate,
                expirationDate: AddMonthsToDate(startDate, recurrencePeriod, Project.timeZone),
                criticality: criticality,
                category: category,
                recurrencePeriod: recurrencePeriod,
                state: state,
                users: finalUsers,
                preExpirationNotification: expirationDate ? preExpirationNotification : 0,
                postExpirationNotification: expirationDate ? postExpirationNotification : 0,
                jobId: "",
                jobPre: "",
                jobPost: "",
                jobRecurrent: ""
              };
            } else if (tipo === "No necesita gestión") {
              return {
                clauseName: clauseName,
                clauseNumber: clauseNumber,
                clauseDetails: clauseDetails,
                clauseType: tipo,
                belongingGroup: belongingGroup,
                startDate: startDate,
                criticality: criticality,
                category: category,
                state: "Sin gestión",
                users: finalUsers
              };
            }
            return null;
          });

          transformedData = transformedData?.filter(item => item !== null);
          setErrors([]);
      
          apiClausesService.import(transformedData, Project.id)
            .then((res) => {
              setLoadingClauses(false);
              if (res.status === 201) {
                setErrors(res.data.dataError);
                enqueueSnackbar(res.data.message, { variant: "error" });
              } else {
                handleToggleImport();
                enqueueSnackbar(res.data.message, { variant: "success" });
              }
            }).catch((error) => {
              setLoadingClauses(false);
              enqueueSnackbar('Ha ocurrido un error al cargar la plantilla', { variant: "error" });
              console.error(error);
            }); 

        } catch (error) {
          console.error(error);
          setLoadingClauses(false);
          enqueueSnackbar('Por favor, ingrese una plantilla con datos válidos', { variant: "error" });
        }
      };
      reader.readAsBinaryString(file);
    } else {
      setLoadingClauses(false);
      enqueueSnackbar('Por favor, ingrese un archivo', { variant: "error" });
    }
  };


  const importClauses = (
    <Dialog open={importDialog} onClose={handleToggleImport} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.dialogTitle}>Importar Cláusulas</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container className="mb-5">
          <Grid item xs={6} >
            <Typography variant="subtitle2" color="GrayText">
              Para ver el formato de Excel requerido para importar cláusulas, ver el ejemplo
            </Typography>
          </Grid>
          <Grid item xs={6} justifyContent="end" display="flex">
            <Button variant="contained" disabled={!(settingsProjects && users.length > 0)} onClick={() => clausesTemplate()} className={classes.downloadButton}>
              Descargar Ejemplo
            </Button>
          </Grid>


        </Grid>
        <Divider
          variant="fullWidth"
          orientation="horizontal"
          sx={{ backgroundColor: 'gray' }}
        />
        <Grid container className="mt-2">


          <Grid item xs={12} display="flex" justifyContent="center" my={2} >
            <Typography variant="subtitle2" color="GrayText">
              Para importar cláusulas, cargue un archivo de Excel (.xls, .xlsx)
            </Typography>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" my={2}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              onChange={(e) => onUpload(e)}
              startIcon={<FileUpload />}
            >
              {file?.name || 'Seleccionar archivo'}
              <VisuallyHiddenInput type="file" accept=".xls,.xlsx,.mpp,.mppx" />
            </Button>

          </Grid>

          {errors && errors.length > 0 && <Grid item xs={12} display="flex" justifyContent="center" my={2}>
            <Typography variant="subtitle2" color="red" gutterBottom>
              ERRORES
            </Typography>
            <List>
              {errors.map((error, index) => (
                <ListItem key={index}>
                  <Typography variant="body2" color="error">
                    {error}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>}


        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleToggleImport} variant="contained" color="primary">
          Cancelar
        </Button>
        <LoadingButton loading={loadingClauses} disabled={!file} onClick={() => uploadFiles()} variant="contained" color="primary">
          Importar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  const handleToDefine = () => {
    const form = formikRef.current;
    form.setFieldValue("undefinedExpiration", true);
    form.setFieldValue("expirationDate", null);
    form.setFieldValue("preExpirationNotification", "0");
    form.setFieldValue("postExpirationNotification", "0");
    setOpenWarningDialog(false);
  }


  const WarningDialog = (
    <Dialog open={openWarningDialog} onClose={() => setOpenWarningDialog(false)}>
      <DialogTitle color="primary">¿Estás seguro de no colocar fecha de vencimiento?</DialogTitle>
      <DialogContent>
        <p>No existe una fecha de vencimiento, por lo que no se enviarán notificaciones hasta que exista una.</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenWarningDialog(false)}>Cerrar</Button>
        <Button variant="contained" onClick={() => handleToDefine()}>Aceptar</Button>
      </DialogActions>
    </Dialog>
  );


  const handleClose = () => {
    setSelectedClause([]);
    setAnchorEl(null);
  };

  const handleCloseClause = () => {
    setAnchorElClause(null);
  };

  const handleClick = (clause) => (event) => {
    setSelectedClause(clause);
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClause = (event, groupName) => {
    setAnchorElClause(event.currentTarget);
  };

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };


  useEffect(() => {
    const fetchClausesByProject = async () => {
      try {
        setLoading(true);
        const fetchedClauses = await apiClauseServiceRealTime.getClauses(Project.id);
        const estructureData = groupBy(fetchedClauses, 'belongingGroup');
        setClauses(estructureData);
        setRawClauses(fetchedClauses);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener datos:', error);
        enqueueSnackbar('Error al obtener los datos', { variant: 'error' });
        setLoading(false);
      }
    };

    fetchClausesByProject();

    const unsubscribe = apiClauseServiceRealTime.subscribeToClauses(Project.id, (updatedClauses) => {
      const estructureData = groupBy(updatedClauses, 'belongingGroup');
      setClauses(estructureData);
      setRawClauses(updatedClauses);
    });


    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [Project.id]);


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleShow = (rowId) => {
    setShowFullClause({ ...showFullClause, [rowId]: !showFullClause[rowId] });
  };

  const handleNewClause = (type) => {
    if (type === "new") {
      setSelectedClause([])
    }
    fetchSettingsProjects();
      fetchUsersByProject();
      getRoles();
      setOpenDialogCreate(true);
      setSearch("");
      setAnchorEl(false);
  }

  const fetchSettingsProjects = async () => {
    try {
      const data = await apiUserService.getSettings(User.id);
      setSettingsProjects(data.data);
    } catch (error) {
      if (error?.response?.data === "No settings found for the user") {
        enqueueSnackbar('No se han agregado industrias o tipos, agrégalas en el módulo de AJUSTES', { variant: 'info' });

      } else {
        enqueueSnackbar('Error al obtener las configuraciones', { variant: 'error' });

      }
    }
  };

  const handleToggleDelete = () => {
    setOpenDelete(!openDelete)
  }

  const handleConfirmDelete = () => {
    setLoadingDeleteClause(true);
    apiClausesService.delete(Project.id, selectedClause.id).then(() => {
      enqueueSnackbar("Cláusula eliminada con éxito", { variant: "success" });
      handleToggleDelete();
      setLoadingDeleteClause(false);

    }).catch(() => {
      enqueueSnackbar("Ha ocurrido un error al eliminar la cláusula", { variant: "error" })
      setLoadingDeleteClause(false);

    })

  };

  const handleManage = () => {
    if (selectedClause.state !== "Aprobado" && selectedClause.state !== "Pendiente de Aprobación" && selectedClause.state !== "Presentada Tarde" && selectedClause.state !== "Aprobado Tarde" && permissionsService("clauses", myRol?.role?.label, "manage")) {
      setOpenDialogSend(true);
    } else {
      if (selectedClause.state === "Aprobado" || selectedClause.state === "Aprobado Tarde") {
        enqueueSnackbar("La cláusula ya se encuentra aprobada", { variant: "success" })
      } else {
        if ((selectedClause.state === "Pendiente de Aprobación" || selectedClause.state === "Presentada Tarde") && permissionsService("clauses", myRol?.role?.label, "approve")) {
          apiClausesService.getByReview(Project.id, selectedClause.id).then((res) => {
            setSelectedReview(res?.data?.data)
            setOpenDialogApprove(true);
          }).catch((res) => {
            enqueueSnackbar(res?.response?.data?.error || "Ha ocurrido un error al gestionar esta cláusula.", { variant: "error" })
          })

        }else{
          enqueueSnackbar("No dispone los permisos necesarios.", { variant: "error" })

        }
      }
    }
    setAnchorEl(false);

  }


  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const fetchUsersByProject = async () => {
    try {
      await apiProjectsService.getUsers(Project.id).then((data) => {
        setFilteredUsers(data.data)
        setUsers(data.data);
      });
    } catch (error) {
      enqueueSnackbar('Error al obtener los usuarios', { variant: 'error' })
    }
  };



  const DeleteDialog = (
    <Dialog
      open={openDelete}
      onClose={handleToggleDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          ¿Estás seguro de que quieres eliminar esta cláusula?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleToggleDelete} color="primary">
          Cancelar
        </Button>
        <LoadingButton loading={loadingDeleteClause} onClick={() => handleConfirmDelete()}  color="error" autoFocus>
          Eliminar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )

  const handleEdit = () => {
    setAnchorEl(false)
    setViewDetalle(true);
  }

  const validationSchema = Yup.object().shape({
    clauseName: Yup.string().required('Nombre de la cláusula es obligatorio'),
    clauseNumber: Yup.string()
      .required('Número de la cláusula es obligatorio'),
    clauseDetails: Yup.string().required('Detalle de la cláusula es obligatorio'),
    belongingGroup: Yup.string().required('Grupo perteneciente es obligatorio'),
    startDate: Yup.date()
      .max(new Date(new Date().setFullYear(new Date().getFullYear() + 100)), "La fecha de inicio no puede pasar los 100 años desde la actualidad")
      .when('clauseType', {
        is: (value) => value === "Acción de una única vez" || value === "Acción recurrente",
        then: (schema) => schema.required("La fecha de inicio es obligatoria"),
      }),
    expirationDate: Yup.date()
      .min(Yup.ref('startDate'), "La fecha de finalización debe ser después de la fecha de inicio")
      .max(new Date(new Date().setFullYear(new Date().getFullYear() + 100)), "La fecha de expiración no debe superar los 100 años desde hoy")
      .when('clauseType', {
        is: (value) => value === "Acción de una única vez",
        then: (schema) => schema.required("La fecha de vencimiento es obligatoria")
          .nullable(),
      })
      .nullable(),
    clauseType: Yup.string().required('Tipo de cláusula es obligatorio'),
    expirationDays: Yup.number()
      .integer("El plazo de vencimiento debe ser un número entero")
      .min(0, "El plazo de vencimiento no puede ser negativo")
      .when('clauseType', {
        is: "Acción ligada a otra acción o fecha",
        then: (schema) => schema.required('Plazo de vencimiento es obligatorio')
      }),
    recurrencePeriod: Yup.number()
      .integer("El periodo de recurrencia debe ser un número entero")
      .min(0, "El periodo de recurrencia no puede ser negativo")
      .when('clauseType', {
        is: "Acción recurrente",
        then: (schema) => schema.required('Periodo de recurrencia es obligatorio'),
      }),
    linkedClause: Yup.object()
      .nullable()
      .when('clauseType', {
        is: "Acción ligada a otra acción o fecha",
        then: (schema) => schema.required('Cláusula ligada es obligatorio'),
      }),
    criticality: Yup.string().required('Criticidad es obligatorio'),
    category: Yup.string().required('Categoría es obligatorio'),
    postExpirationNotification:
      Yup.number()
        .integer("El periodo de recurrencia debe ser un número entero")
        .min(0, "El periodo de recurrencia no puede ser negativo")
        .when('clauseType', {
          is: (value) => value !== "No necesita gestión",
          then: (schema) => schema.required('Frecuencia de post-vencimiento es obligatorio'),
        }),
    preExpirationNotification:
      Yup.number()
        .integer("El periodo de recurrencia debe ser un número entero")
        .min(0, "El periodo de recurrencia no puede ser negativo").when('clauseType', {
          is: (value) => value !== "No necesita gestión",
          then: (schema) => schema.required('Frecuencia de pre-vencimiento es obligatorio'),
        }),
    users: Yup.array().min(1, "Otorgue al menos 1 rol a un usuario")
  });


  const TableClauses = (
    <TableContainer component={Paper} elevation={1} sx={{ maxHeight: '500px' }}>
      {Object.entries(clauses).length > 0 ? Object.entries(clauses).map(([groupName, clauses], groupIndex) => (

        <Table
          sx={{ minWidth: 650, fontSize: '14px' }}
          aria-label="simple table"
          stickyHeader
          style={{ backgroundColor: "#F0F4F7" }}
          key={groupIndex}
        >
          <TableHead >
            <TableRow>
              <TableCell align="center" sx={{ backgroundColor: '#F0F4F7' }} style={{ color: "#476AC2", fontSize: '13px' }}>
                <Grid container display="inline-flex" alignItems="center"  >

                  {/*    <Box>
                    <IconButton aria-label="settingsClause"
                      aria-controls="settingsClauses-menu"
                      aria-haspopup="true"
                      className="me-3"
                      onClick={(e) => handleSettingsClause(e, groupName)}>
                      <MoreVertIcon color="primary" />
                    </IconButton>

                    <Menu
                      id="settingsClauses-menu"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorEl={anchorElClause}
                      keepMounted
                      open={openClause}
                      onClose={handleCloseClause}
                      elevation={1}
                      sx={{ "& .MuiMenuItem-root": { fontSize: '12px', color: "rgb(88,102,111)" } }} // Cambia el tamaño de letra aquí
                    >
                      <MenuItem onClick={() => handleNewClause()}>
                        <ListItemIcon className={classes.menuItemIcon}>
                          <Add sx={{ fontSize: '20px !important' }} />
                        </ListItemIcon>
                        Agregar
                      </MenuItem>
                      <MenuItem disabled onClick={() => setOpenDialogSend(true)}>
                        <ListItemIcon className={classes.menuItemIcon}>
                          <DriveFileRenameOutline sx={{ fontSize: '20px !important' }} />
                        </ListItemIcon>
                        Renombrar
                      </MenuItem>
                      <MenuItem disabled onClick={() => setViewDetalle(true)}>
                        <ListItemIcon className={classes.menuItemIcon}>
                          <Archive sx={{ fontSize: '20px !important' }} />
                        </ListItemIcon>
                        Archivar
                      </MenuItem>
                      <MenuItem disabled onClick={() => setOpenDialogDuplicate(true)}>
                        <ListItemIcon className={classes.menuItemIcon}>
                          <Delete sx={{ fontSize: '20px !important' }} />
                        </ListItemIcon>
                        Eliminar
                      </MenuItem>
                    </Menu>
                  </Box> */}

                  <Box>
                    <b>Grupo: </b> {groupName}

                  </Box>

                </Grid>
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#F0F4F7' }} style={{ color: "#476AC2", fontSize: '13px' }}>
                <b>Detalle</b>
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#F0F4F7' }} style={{ color: "#476AC2", fontSize: '13px' }}>
                <b>N° Cláusula</b>
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#F0F4F7' }} style={{ color: "#476AC2", fontSize: '13px' }}>
                <b>Estado</b>
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#F0F4F7' }} style={{ color: "#476AC2", fontSize: '13px' }}>
                <b>Criticidad</b>
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#F0F4F7' }} style={{ color: "#476AC2", fontSize: '13px' }}>
                <b>Fecha Inicio</b>
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#F0F4F7' }} style={{ color: "#476AC2", fontSize: '13px' }}>
                <b>Fecha Vencimiento</b>
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#F0F4F7' }} style={{ color: "#476AC2", fontSize: '13px' }}>
                <b>Fecha Aprob/Rechaz</b>
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#F0F4F7' }} style={{ color: "#476AC2", fontSize: '13px' }}>
                <b>Responsable</b>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {clauses?.length > 0 ? clauses.map((row, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: "0" }, fontSize: '12px' }}>
                <TableCell
                  style={{ backgroundColor: "#EDF4F5", fontSize: '13px' }}
                  width="450px !important"
                  component="th"
                  scope="row"
                  sx={{ color: "#5a6770", fontWeight: "500", textAlign: "justify" }}
                >
                  <Grid container>
                    <Grid item xs={11} display="flex" alignItems="center">
                      <Box>
                        {showFullClause[row.id] ? row?.clauseName : row?.clauseName.length > 100 ? (row?.clauseName || '').slice(0, 100) + '...' : row.clauseName}
                        {row?.clauseName.length > 100 && (
                          <span onClick={() => handleShow(row.id)} style={{ color: 'GrayText', cursor: 'pointer', fontSize: '10px', marginLeft: '5px' }}>
                            {showFullClause[row.id] ? 'Ver menos' : 'Ver más'}
                          </span>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="settings" aria-controls="clauses-menu" aria-haspopup="true" onClick={handleClick(row)}>
                        <MoreVertIcon color="inherit" align="right" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell sx={{ color: "#5a6770", fontSize: '13px', width: '300px !important', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} width="450px !important" align="center" >
                  <Box width={175} textAlign="justify">
                    {showFullClause[row.id] ? row?.clauseDetails : row?.clauseDetails?.length > 100 ? (row?.clauseDetails || '').slice(0, 100) + '...' : row?.clauseDetails}
                    {row?.clauseDetails?.length > 100 && (
                      <span onClick={() => handleShow(row.id)} style={{ color: 'GrayText', cursor: 'pointer', fontSize: '10px', marginLeft: '5px' }}>
                        {showFullClause[row.id] ? 'Ver menos' : 'Ver más'}
                      </span>
                    )}
                  </Box>

                </TableCell>
                <TableCell sx={{ color: "#5a6770", fontSize: '13px' }} align="center">{row.clauseNumber}</TableCell>
                <TableCell align="center" sx={{ fontSize: '12px' }} width="80px !important">
                  <Box align="center" style={{ color: getStateParams(row.state), fontSize: '11px' }}>
                    <b>{row.state}</b>
                  </Box>
                </TableCell>
                <TableCell align="center" style={{ color: getCriticalityColor(row.criticality), fontSize: '13px' }}>
                  <b>{row.criticality}</b>
                </TableCell>
                <TableCell sx={{ color: "#5a6770", fontSize: '13px' }} align="center" width="350px !important">{row.startDate && row.startDate != null ? dateFormatEs(row.startDate) : <Typography variant="body1" fontSize="9px" color="GrayText"> N/A <ReportProblem color="error" className="ms-3" sx={{ fontSize: "15px" }} /> </Typography>}</TableCell>
                <TableCell sx={{ color: "#5a6770", fontSize: '13px' }} align="center" width="200px !important">
                  {row.expirationDate && row.expirationDate !== null ? dateFormatEs(row?.expirationDate) : row.clauseType !== 'No necesita gestión' ? <Typography variant="body1" fontSize="9px" color="GrayText"> N/A  <ReportProblem color="error" className="ms-3" sx={{ fontSize: "15px" }} /> </Typography> : <Typography variant="body1" fontSize="9px" color="GrayText"> N/A </Typography>}
                </TableCell>
                <TableCell sx={{ color: "#5a6770", fontSize: '13px' }} align="center" width="200px !important">{row.approvalDate && row.approvalDate !== null ? dateFormatEs(row.approvalDate) : <Typography variant="body1" fontSize="9px" color="GrayText"> N/A  </Typography>}</TableCell>

                <TableCell sx={{ color: "#5a6770", fontSize: '13px' }} width="180px !important" align="center">  {row?.users?.filter(user => user.roleId === 6).map(user => user.username).join(", ") || "Sin responsable"}</TableCell>



                {/*  <TableCell width="275px !important" align="center" sx={{ fontSize: '13px' }}>
                  <Tooltip title="Ver detalles">
                    <IconButton aria-label="details" onClick={() => { setSelectedClause(row); setDialogDetails(!dialogDetails) }}>
                      <Info color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Documento">
                    <IconButton aria-label="details" onClick={() => setDialogDetails(!dialogDetails)}>
                      <FileCopy color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Historial">
                    <IconButton aria-label="details" onClick={() => setDialogDetails(!dialogDetails)}>
                      <History color="primary" />
                    </IconButton>
                  </Tooltip>
                </TableCell> */}
              </TableRow>
            )) : (
              <TableRow>
                <TableCell colSpan={8} align="center" sx={{ fontSize: '12px' }}>
                  <Box textAlign="center">
                    <img src={EmptyIcon} alt="Imagen de no data" width={150} />
                    <Typography variant="h6" color="GrayText">
                      No hay datos disponibles.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
           
          </TableBody>

        </Table>))
        :
        <Box textAlign="center" m={2}>
          <img src={EmptyIcon} alt="Imagen de no data" width={150} />
          <Typography variant="h6" color="GrayText">
            No hay datos disponibles.
          </Typography>
        </Box>
      }

      {/* Dialogs */}
      <Dialog open={dialogDetails} onClose={() => setDialogDetails(!dialogDetails)}>
        <DialogTitle>Detalles</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} rowGap={1} className="mt-2">
            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Cláusula:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{selectedClause?.clauseName || '-'}</p>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Número de cláusula:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{selectedClause?.clauseNumber || '-'}</p>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Fecha de inicio:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{dateFormat2(selectedClause?.startDate) || 'Por definir'}</p>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Fecha de vencimiento:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{dateFormat2(selectedClause?.expirationDate) || 'Por definir'}</p>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Fecha de aprobación / rechazo:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{dateFormat2(selectedClause?.approvalDate) || '-'}</p>
            </Grid>
            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Tiempo de retraso:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{selectedClause?.delayTime || '-'}</p>
            </Grid>
            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Tiempo de aprobación:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{selectedClause?.approvalTime || '-'}</p>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Responsable:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{(selectedClause?.users)?.map(user => user.username)?.join(", ")}</p>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Estado:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{selectedClause?.state || '-'}</p>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Criticidad:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{selectedClause?.criticality || '-'}</p>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.blueColumn}>
              <p>Categoría:</p>
            </Grid>
            <Grid item xs={6} sm={8} className={classes.blueColumn2}>
              <p>{selectedClause?.category || '-'}</p>
            </Grid>




          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogDetails(!dialogDetails)}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Menu
              id="clauses-menu"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              elevation={1}
              sx={{ "& .MuiMenuItem-root": { fontSize: '12px', color: "rgb(88,102,111)" } }} // Cambia el tamaño de letra aquí
            >
              <MenuItem disabled={selectedClause.clauseType === 'No necesita gestión'} onClick={handleManage}>
                <ListItemIcon className={classes.menuItemIcon}>
                  <ArchiveIcon sx={{ fontSize: '20px !important' }} />
                </ListItemIcon>
                Gestionar
              </MenuItem>
              <MenuItem onClick={() => handleEdit()}>
                <ListItemIcon className={classes.menuItemIcon}>
                  <Visibility sx={{ fontSize: '20px !important' }} />
                </ListItemIcon>
                Detalles
              </MenuItem>
              {permissionsService("clauses", myRol?.role?.label, "edit") && <MenuItem onClick={() => handleNewClause("edit")}>
                <ListItemIcon className={classes.menuItemIcon}>
                  <DriveFileRenameOutline sx={{ fontSize: '20px !important' }} />
                </ListItemIcon>
                Editar
              </MenuItem>}
              {/*  <MenuItem onClick={() => setOpenDialogDuplicate(true)}>
                <ListItemIcon className={classes.menuItemIcon}>
                  <ContentCopyIcon sx={{ fontSize: '20px !important' }} />
                </ListItemIcon>
                Duplicar
              </MenuItem> */}
               {permissionsService("clauses", myRol?.role?.label, "delete") && <MenuItem onClick={() => { handleToggleDelete(); setAnchorEl(null) }}>
                <ListItemIcon className={classes.menuItemIcon}>
                  <Delete sx={{ fontSize: '20px !important' }} />
                </ListItemIcon>
                Eliminar
              </MenuItem>}
            </Menu>

    </TableContainer>
  )



  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const getRoles = async () => {
    try {
      apiUserService.getRoles(User.id).then((data) => {
        const rolesUsers = data?.data?.filter(role =>
          ['Aprobador', 'Gestionador', 'Responsable'].includes(role.label)
        );
        setRoles(rolesUsers);
      });
    } catch (error) {
      enqueueSnackbar('Error al obtener los roles', { variant: 'error' })
    }
  };

  const DialogNewClause = (
    <Dialog open={openDialogCreate} onClose={() => {
      handleCloseDialog();
      setSelectedClause({});
    }} fullWidth maxWidth="md">
      <DialogTitle className={classes.dialogTitle}>
        {Object.keys(selectedClause)?.length > 0 ? "Editar" : "Crear"} Cláusula
      </DialogTitle>
      <Divider />
      <Formik
        validateOnMount
        validateOnChange
        innerRef={formikRef}

        enableReinitialize
        initialValues={{
          clauseName: selectedClause.clauseName || '',
          clauseNumber: selectedClause.clauseNumber || null,
          clauseDetails: selectedClause.clauseDetails || '',
          belongingGroup: selectedClause.belongingGroup || "",
          startDate: dateFormat2(selectedClause.startDate) || selectedClause.startDate || formatDateToDateTimeLocal(Project?.startDate),
          expirationDate: selectedClause.expirationDate || null,
          expirationDays: selectedClause.expirationDays || 7,
          recurrencePeriod: selectedClause.recurrencePeriod || 1,
          state: selectedClause.state || "En gestión",
          criticality: selectedClause.criticality || settingsProjects?.criticality[0] || '',
          category: selectedClause.category || settingsProjects?.category[0] || '',
          postExpirationNotification: selectedClause.postExpirationNotification || 7,
          preExpirationNotification: selectedClause.preExpirationNotification || 7,
          clauseType: selectedClause.clauseType || 'Acción de una única vez',
          linkedClause: selectedClause.linkedClause || null,
          users: selectedClause.users || [],
          undefinedExpiration: selectedClause.undefinedExpiration || false
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            // Handle different clause types
            if (values.clauseType === "Acción ligada a otra acción o fecha") {
              values.startDate = values.linkedClause.expirationDate || null;
              values.expirationDate = values.linkedClause.expirationDate
                ? AddDaysToDate(values.linkedClause.expirationDate, values.expirationDays, Project.timeZone)
                : null;
            } else if (values.clauseType === "Acción recurrente") {
              values.expirationDate = AddMonthsToDate(values.startDate, values.recurrencePeriod, Project.timeZone);
            } else if (values.clauseType === "No necesita gestión") {
              values.state = "Sin gestión";
            }

            // Format start date if present
            if (values.startDate) {
              values.startDate = values?.startDate?.replace(" ", "T");
            }
            // Update or create clause based on the presence of selectedClause
            const action = Object.keys(selectedClause)?.length > 0
              ? apiClausesService.update(Project.id, selectedClause.id, values)
              : apiClausesService.post(values, Project.id);

            // Execute action and handle notifications
            await action;
            enqueueSnackbar(
              `Cláusula ${Object.keys(selectedClause)?.length > 0 ? 'actualizada' : 'añadida'} con éxito`,
              { variant: "success" }
            ); 
            handleCloseDialog()
            setTabValue(0);
          } catch (error) {
            console.log(error);
            enqueueSnackbar("Ha ocurrido un error al procesar la cláusula", { variant: "error" });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isValid,
          isSubmitting,
          touched,
          values,
        }) => {

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (values.expirationDate && values.startDate) {
              const startDate = new Date(values.startDate);
              const expirationDate = new Date(values.expirationDate);
              const daysDifference = Math.floor((expirationDate - startDate) / (1000 * 60 * 60 * 24));

              if (daysDifference <= 1) {
                setFieldValue('preExpirationNotification', 0);
              } else if (daysDifference < 7) {
                setFieldValue('preExpirationNotification', daysDifference - 1);
              } else {
                setFieldValue('preExpirationNotification', 7);
              }
            }
          }, [values.expirationDate, values.startDate, setFieldValue]);

          return (<form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <InputLabel shrink htmlFor="clauseName">
                    Nombre de la cláusula: <span className="text-danger"> * </span>
                  </InputLabel>
                  <TextField
                    id="clauseName"
                    name="clauseName"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.clauseName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.clauseName && Boolean(errors.clauseName)}
                    helperText={touched.clauseName && errors.clauseName}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <InputLabel shrink htmlFor="clauseNumber">
                    Número de la cláusula: <span className="text-danger"> * </span>
                  </InputLabel>
                  <TextField
                    id="clauseNumber"
                    name="clauseNumber"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.clauseNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.clauseNumber && Boolean(errors.clauseNumber)}
                    helperText={touched.clauseNumber && errors.clauseNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel shrink htmlFor="clauseDetails">
                    Detalle: <span className="text-danger"> * </span>
                  </InputLabel>
                  <TextField
                    id="clauseDetails"
                    name="clauseDetails"
                    type="text"
                    fullWidth
                    multiline
                    rows={2}
                    variant="standard"
                    value={values.clauseDetails}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.clauseDetails && Boolean(errors.clauseDetails)}
                    helperText={touched.clauseDetails && errors.clauseDetails}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{ backgroundColor: "#EEF0F2", padding: "1em" }}
                marginTop={2}
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <Tabs
                  indicatorColor="transparent"
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="project tabs"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab
                    className={classes.tabStyle}
                    label="Información General"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={classes.tabStyle}
                    label="Usuarios"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={tabValue} index={0}>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <InputLabel shrink htmlFor="clauseType">
                      Tipo de cláusula: <span className="text-danger"> * </span>
                    </InputLabel>
                    <Select
                      id="clauseType"
                      name="clauseType"
                      fullWidth
                      variant="standard"
                      value={values.clauseType}
                      onChange={(e) => {
                        if (e.target.value !== "Acción ligada a otra acción o fecha" || e.target.value !== "No necesita gestión") {
                          setFieldValue("expirationDate", null);
                        }
                        if (e.target.value !== "Acción de una única vez" || e.target.value !== "Acción recurrente") {
                          setFieldValue("startDate", formatDateToDateTimeLocal(Project?.startDate));
                        }
                        if (e.target.value !== "Acción ligada a otra acción o fecha") {
                          setFieldValue("linkedClause", null);
                          setFieldValue("expirationDays", 7);
                        }
                        if (e.target.value !== "Acción recurrente") {
                          setFieldValue("recurrencePeriod", 1);
                        }
                        setFieldValue("clauseType", e.target.value)
                      }}
                      onBlur={handleBlur}
                      error={touched.clauseType && Boolean(errors.clauseType)}
                      displayEmpty
                    >
                      {(typesClauses)?.map((option, index) => (
                        <MenuItem key={index} value={option}>{option}</MenuItem>
                      ))}
                    </Select>
                    {touched.clauseType && errors.clauseType && (
                      <Typography color="error" variant="caption">
                        {errors.clauseType}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink htmlFor="belongingGroup">
                      Grupo de pertenencia: <span className="text-danger"> * </span>
                      <Tooltip title="Indica el nombre del grupo al que pertenece cada cláusula">
                        <IconButton size="small">
                          <Info fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </InputLabel>

                    <Autocomplete
                      fullWidth
                      variant="standard"
                      value={values.belongingGroup}
                      options={Object.keys(clauses) ? Object.keys(clauses) : []}
                      onChange={(e, value) => {
                        setFieldValue("belongingGroup", value || '');
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          id="belongingGroup"
                          name="belongingGroup"
                          value={values.belongingGroup}
                          onChange={handleChange}
                          error={touched.belongingGroup && Boolean(errors.belongingGroup)}
                          helperText={touched.belongingGroup && errors.belongingGroup}
                        />
                      )}
                    />
                  </Grid>

                  {values.clauseType !== "Acción ligada a otra acción o fecha" && (
                    <Grid item xs={12} sm={5}>
                      <InputLabel shrink htmlFor="startDate">
                        Fecha de inicio: <span className="text-danger"> * </span>
                      </InputLabel>
                      <TextField
                        id="startDate"
                        name="startDate"
                        type="datetime-local"
                        fullWidth
                        variant="standard"
                        value={values.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.startDate && Boolean(errors.startDate)}
                        helperText={touched.startDate && errors.startDate}
                      />
                    </Grid>
                  )}

                  {values.clauseType !== "Acción ligada a otra acción o fecha" && values.clauseType !== "No necesita gestión" && values.clauseType !== "Acción recurrente" && (
                    <><Grid item xs={12} sm={5}>
                      <InputLabel shrink htmlFor="expirationDate">
                        Fecha de vencimiento: <span className="text-danger"> * </span>
                      </InputLabel>

                      <TextField
                        id="expirationDate"
                        name="expirationDate"
                        type="datetime-local"
                        fullWidth
                        variant="standard"
                        value={values.undefinedExpiration ? "Por definir" : values.expirationDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.expirationDate && Boolean(errors.expirationDate)}
                        helperText={touched.expirationDate && errors.expirationDate}
                        disabled={values.undefinedExpiration} />
                    </Grid>
                      <Grid item xs={12} sm={2} justifyContent="center" alignItems="end" display="flex">
                        <FormControlLabel
                          control={<Checkbox
                            checked={values.undefinedExpiration}
                            onClick={(e) => {
                              const value = e.target.checked;
                              if (value) {
                                setOpenWarningDialog(true);
                              } else {
                                setFieldValue("undefinedExpiration", false);
                                setFieldValue("preExpirationNotification", "7");
                                setFieldValue("postExpirationNotification", "7");
                              }
                            }}
                            name="undefinedExpiration"
                            color="primary" />}
                          label={
                            <InputLabel className="mt-2" shrink style={{ fontSize: "0.875rem", display: "flex", alignSelf: "end" }}>
                              Por definir
                            </InputLabel>
                          }

                        />


                      </Grid></>
                  )}

                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink htmlFor="criticality">
                      Criticidad: <span className="text-danger"> * </span>
                      <Tooltip title="Indique la importancia o nivel de criticidad de la cláusula">
                        <IconButton size="small">
                          <Info fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </InputLabel>

                    <Select
                      id="criticality"
                      name="criticality"
                      fullWidth
                      variant="standard"
                      value={values.criticality}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.criticality && Boolean(errors.criticality)}
                      displayEmpty
                    >
                      {(settingsProjects?.criticality)?.map((option, index) => (
                        <MenuItem key={index} value={option}>{option}</MenuItem>
                      ))}
                    </Select>
                    {touched.criticality && errors.criticality && (
                      <Typography color="error" variant="caption">
                        {errors.criticality}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink htmlFor="category">
                      Categoría: <span className="text-danger"> * </span>
                    </InputLabel>
                    <Autocomplete
                      fullWidth
                      variant="standard"
                      value={values.category}
                      options={settingsProjects?.category ? settingsProjects?.category : []}
                      onChange={(e, value) => {
                        setFieldValue("category", value || '');
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          id="category"
                          name="category"
                          value={values.category}
                          onChange={handleChange}
                          error={touched.category && Boolean(errors.category)}
                          helperText={touched.category && errors.category}
                        />
                      )}
                    />
                  </Grid>

                  {values.clauseType === "Acción ligada a otra acción o fecha" && (
                    <>
                      <Grid item xs={12} sm={8}>
                        <InputLabel shrink htmlFor="linkedClause">
                          Cláusula Ligada: <span className="text-danger"> * </span>
                        </InputLabel>
                        <Autocomplete
                          value={values.linkedClause}
                          options={rawClauses.filter(clause => clause.clauseType !== "No necesita gestión")
                            .sort((a, b) => a.clauseName.localeCompare(b.clauseName))}
                          groupBy={(option) => option.belongingGroup}
                          onChange={(e, value) => {
                            setFieldValue("linkedClause", value);
                          }}
                          getOptionLabel={(option) => `${option?.clauseNumber} - ${option?.clauseName && (option?.clauseName)?.length > 50 ? ((option?.clauseName).slice(0, 50) + "...") : option?.clauseName}` || ''}
                          renderInput={(params) =>
                            <TextField {...params} variant="standard" className="w-100"
                              id="linkedClause"
                              name="linkedClause"
                              value={values.linkedClause}
                              onChange={handleChange}
                              error={touched.linkedClause && Boolean(errors.linkedClause)}
                              helperText={touched.linkedClause && errors.linkedClause}
                            />}
                          noOptionsText="No se han encontrado cláusulas aprobadas"
                          onBlur={handleBlur}
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <InputLabel shrink htmlFor="expirationDays">
                          Plazo de vencimiento: <span className="text-danger"> * </span>
                          <Tooltip title="Número de días después de la fecha de la cláusula aprobada vencerá">
                            <IconButton size="small">
                              <Info fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </InputLabel>

                        <TextField
                          id="expirationDays"
                          name="expirationDays"
                          type="text"
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="start">días</InputAdornment>,
                            inputProps: {
                              style: { textAlign: 'center' }
                            }
                          }}
                          variant="standard"
                          value={values.expirationDays}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.expirationDays && Boolean(errors.expirationDays)}
                          helperText={touched.expirationDays && errors.expirationDays}
                        />
                      </Grid>
                    </>
                  )}

                  {values.clauseType === "Acción recurrente" && (
                    <Grid item xs={12} sm={6}>
                      <InputLabel shrink htmlFor="recurrencePeriod">
                        Periodo de repetición (en meses): <span className="text-danger"> * </span>
                        <Tooltip title="Frecuencia con la que esta acción se repetirá, expresada en meses">
                          <IconButton size="small">
                            <Info fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </InputLabel>
                      <TextField
                        id="recurrencePeriod"
                        name="recurrencePeriod"
                        type="text"
                        fullWidth
                        InputProps={{
                          endAdornment: <InputAdornment position="start">meses</InputAdornment>,
                          inputProps: {
                            style: { textAlign: 'center' }
                          }
                        }}
                        variant="standard"
                        value={values.recurrencePeriod}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.recurrencePeriod && Boolean(errors.recurrencePeriod)}
                        helperText={touched.recurrencePeriod && errors.recurrencePeriod}
                      />
                    </Grid>
                  )}

                  {values.clauseType !== "No necesita gestión" && (
                    <>

                      <Grid item sm={4.5}>
                        <InputLabel shrink htmlFor="preExpirationNotification">
                          Notificación pre-vencimiento: <span className="text-danger"> * </span>
                          <Tooltip title="Número de días antes del vencimiento en que se notificará al usuario">
                            <IconButton size="small">
                              <Info fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </InputLabel>
                        <TextField
                          id="preExpirationNotification"
                          name="preExpirationNotification"
                          type="text"
                          disabled={values.preExpirationNotification === "0" || values.preExpirationNotification === 0}
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="start">días</InputAdornment>,
                            inputProps: {
                              style: { textAlign: 'center' }
                            }
                          }}
                          variant="standard"
                          value={values.preExpirationNotification}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.preExpirationNotification && Boolean(errors.preExpirationNotification)}
                          helperText={touched.preExpirationNotification && errors.preExpirationNotification}
                        />
                      </Grid>
                      <Grid item sm={4.5}>
                        <InputLabel shrink htmlFor="postExpirationNotification">
                          Notificación post-vencimiento: <span className="text-danger"> * </span>
                          <Tooltip title="Número de días después del vencimiento en que se notificará al usuario">
                            <IconButton size="small">
                              <Info fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </InputLabel>

                        <TextField
                          id="postExpirationNotification"
                          name="postExpirationNotification"
                          type="text"
                          fullWidth
                          disabled={values.postExpirationNotification === "0"}
                          InputProps={{
                            endAdornment: <InputAdornment position="start">días</InputAdornment>,
                            inputProps: {
                              style: { textAlign: 'center' }
                            },

                          }}
                          variant="standard"
                          value={values.postExpirationNotification}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.postExpirationNotification && Boolean(errors.postExpirationNotification)}
                          helperText={touched.postExpirationNotification && errors.postExpirationNotification}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item sm={3}>
                    <InputLabel shrink htmlFor="state">
                      Estado: <span className="text-danger"> * </span>
                      <Tooltip title="Estado actual de la cláusula">
                        <IconButton size="small">
                          <Info fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </InputLabel>
                    <Select
                      id="state"
                      name="state"
                      fullWidth
                      variant="standard"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.state && Boolean(errors.state)}
                      displayEmpty
                    >
                      {(states).map((option, index) => (
                        <MenuItem key={index} value={option}>{option}</MenuItem>
                      ))}
                    </Select>
                    {touched.state && errors.state && (
                      <Typography color="error" variant="caption">
                        {errors.state}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <TextField
                  id="search"
                  label="Buscar usuario..."
                  variant="standard"
                  className="w-100"
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  value={search}
                  autoFocus
                  onChange={handleSearch}
                />
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  {filteredUsers?.map((user) => (
                    <FieldArray
                      key={user.id}
                      name="users"
                      render={(arrayHelpers) => (
                        <ListItem
                          disablePadding
                          disableGutters
                          secondaryAction={
                            <Select
                              id={`role-${user.id}`}
                              name={`role-${user.id}`}
                              fullWidth
                              variant="standard"
                              value={
                                values.users.find((u) => u.userId === user.id)?.roleId || ''
                              }
                              sx={{ width: "15em" }}
                              onChange={(e) => {
                                const selectedRoleId = e.target.value;
                                const selectedRole = roles.find(
                                  (role) => role.value === selectedRoleId
                                );
                                const userIndex = values.users.findIndex(
                                  (u) => u.userId === user.id
                                );

                                if (userIndex >= 0) {
                                  arrayHelpers.replace(userIndex, {
                                    userId: user.id,
                                    username: user.fullname,
                                    roleId: selectedRoleId,
                                    roleName: selectedRole?.label,
                                  });
                                } else {
                                  arrayHelpers.push({
                                    userId: user.id,
                                    username: user.fullname,
                                    roleId: selectedRoleId,
                                    roleName: selectedRole?.label,
                                  });
                                }
                              }}
                              onBlur={handleBlur}
                            >
                              {roles.map((role) => (
                                <MenuItem key={role.value} value={role.value}>
                                  {role.label}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                        >
                          <ListItemText
                            primary={user.fullname}
                            primaryTypographyProps={{ noWrap: true }}
                          />
                        </ListItem>
                      )}
                    />
                  ))}
                </List>
              </CustomTabPanel>
            </DialogContent>

            <DialogActions className="mb-3">
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Typography variant="caption" component="ul">
                    <li>Los campos marcados con * son obligatorios</li>
                    <li>Recuerda llenar la pestaña de <b>Usuarios</b></li>
                    <li>Recuerda de configurar previamente las opciones para elegir la <b>criticidad</b>, <b>categoría</b>,<b>frecuencia</b>, tipo. En el módulo de <b>Ajustes</b></li>

                  </Typography>
                </Grid>

                <Grid item sm={6} display="flex" alignItems="center" justifyContent="end">
                  <Button variant="contained" className='rounded-4 me-3' onClick={() => {
                    handleCloseDialog();
                    setSelectedClause({});
                  }
                  }>Cerrar</Button>
                  <LoadingButton
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    startIcon={Object.keys(selectedClause)?.length > 0 ? <Edit /> : <Save />}
                    loading={isSubmitting}
                    loadingPosition="start"
                    className='rounded-4 me-3'
                    variant="contained"
                  >
                    {Object.keys(selectedClause)?.length > 0 ? "Editar" : "Guardar"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </DialogActions>


          </form>)
        }}
      </Formik>
    </Dialog >
  );

  const validationSchemaManage = Yup.object().shape({
    generalFiles: Yup.array().min(1, 'Seleccione al menos un archivo.').required('Campo requerido.'),
    comment: Yup.string().required('Campo requerido.'),
    issuer: Yup.string().email('Ingrese un correo electrónico válido.').required('Campo requerido.'),
    recipient: Yup.string().email('Ingrese un correo electrónico válido.').required('Campo requerido.'),
    notifyTo: Yup.string().email('Ingrese un correo electrónico válido.'),
    documentName: Yup.string().required('Campo requerido.'),
    documentDate: Yup.date().required('Campo requerido.'),
    documentNumber: Yup.string().required('Campo requerido.'),
    keywords: Yup.string().required('Campo requerido.'),
    clause: Yup.string().required('Campo requerido.'),
    response: Yup.number().required('Campo requerido.'),
    oficioFile: Yup.mixed().required('Seleccione un archivo.'),
  });

  function findUserByRoleId(users, roleId) {
    const user = users.find(user => user.roleId === roleId);
    return user ? user.username : null;
  }



  const DialogManage = (
    <Dialog open={openDialogSend} onClose={handleCloseDialog} fullWidth>
      <DialogTitle className={classes.dialogTitle}>Envia a Revisión</DialogTitle>
      <Divider />
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          generalFiles: [],
          comment: '',
          issuer: '',
          recipient: '',
          notifyTo: '',
          documentName: '',
          documentDate: '',
          documentNumber: '',
          keywords: '',
          clause: selectedClause?.id,
          state: selectedClause?.state,
          response: '',
          oficioFile: null,
          userId: User.id
        }}
        validationSchema={validationSchemaManage}
        onSubmit={(values, { setSubmitting }) => {
          if (!values.undefinedExpiration && values.expirationDate !== null) {
            setSubmitting(true)
            apiClausesService.newReview(values, Project.id, selectedClause.id)
              .then((res) => {
                enqueueSnackbar("Revisión enviada con éxito", { variant: "success" });
                setOpenDialogSend(false);
                setSubmitting(false);
              })
              .catch((error) => {
                const errorMessage = error.response?.data?.message || "Ha ocurrido un error en la revisión";
                enqueueSnackbar(errorMessage, { variant: "error" });
                setSubmitting(false);
              });
          } else {
            enqueueSnackbar("ERROR: Porfavor ingrese una fecha de vencimiento o seleccione 'Por definir'", { variant: "error" })
          }

        }}

      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isValid,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Box sx={{ backgroundColor: '#EEF0F2', padding: '1em', alignItems: 'center' }}>
                <Tabs
                  indicatorColor="transparent"
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="project tabs"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab className={classes.tabStyle} label="General" {...a11yProps(0)} />
                  <Tab className={classes.tabStyle} label="Oficio" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={tabValue} index={0}>
                <Box style={{ display: 'block', gap: '10px' }} marginBottom={4}>
                  <Typography variant="caption" className="me-1">
                    La cláusula con el nombre <b>"{selectedClause?.clauseName}"</b> se envía para aprobación.
                  </Typography>
                  <Typography variant="caption" color="initial">
                    {findUserByRoleId(selectedClause.users, 6) !== null ?
                      `Con el nombre del encargado "${findUserByRoleId(selectedClause.users, 6)}".` :
                      "Ésta cláusula no contiene un nombre de encargado."}
                  </Typography>
                </Box>

                <Grid container>
                  <Grid item sm={12} md={2}>
                    <Typography variant="caption">
                      <b>Documento:</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={12} justifyContent="center" display="flex">
                    <Button
                      size="small"
                      variant="contained"
                      className="rounded-4 me-2"
                      startIcon={<FileUpload />}
                      onClick={() => document.getElementById('generalFiles').click()}
                    >
                      Seleccionar un archivo
                    </Button>
                    <input
                      id="generalFiles"
                      name="generalFiles"
                      type="file"
                      accept=".pdf, .xls, .xlsx"
                      multiple
                      hidden
                      onChange={(event) => {
                        const files = Array.from(event.currentTarget.files).map(file => {
                          // Sanitizar y truncar el nombre del archivo
                          const sanitizedFilename = sanitizeFilename(file.name);
                          const truncatedFilename = truncateFilename(sanitizedFilename);
                          // Crear un nuevo objeto de archivo con el nombre modificado
                          return new File([file], truncatedFilename, { type: file.type });
                        });
                        setFieldValue('generalFiles', files);
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={12} justifyContent="center" display="flex" margin={3}>
                    <Typography variant="caption">
                      {values.generalFiles.length === 0 ? 'Ningun archivo seleccionado.' : `${values.generalFiles.length} archivo(s) seleccionado(s).`}
                      <span className="text-danger"> *</span>
                    </Typography>
                    {touched.generalFiles && errors.generalFiles && (
                      <Typography variant="caption" color="error">{errors.generalFiles}</Typography>
                    )}
                  </Grid>
                </Grid>

                <Typography variant="caption">
                  Descripción o comentario. <span className="text-danger"> *</span>
                </Typography>
                <TextField
                  fullWidth
                  id="comment"
                  name="comment"
                  placeholder="Añada una descripción o comentario"
                  className="mb-3"
                  multiline
                  rows={3}
                  variant="standard"
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.comment && Boolean(errors.comment)}
                  helperText={touched.comment && errors.comment}
                />

              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputLabel shrink id="issuer-label">
                      Emisor: <span className="text-danger"> *</span>
                    </InputLabel>
                    <TextField
                      id="issuer"
                      name="issuer"
                      type="email"
                      fullWidth
                      variant="standard"
                      value={values.issuer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.issuer && Boolean(errors.issuer)}
                      helperText={touched.issuer && errors.issuer}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel shrink id="recipient-label">
                      Dirigido a: <span className="text-danger"> *</span>
                    </InputLabel>
                    <TextField
                      id="recipient"
                      name="recipient"
                      type="email"
                      fullWidth
                      variant="standard"
                      value={values.recipient}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.recipient && Boolean(errors.recipient)}
                      helperText={touched.recipient && errors.recipient}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel shrink id="notifyTo-label">
                      Notificar a:
                    </InputLabel>
                    <TextField
                      id="notifyTo"
                      name="notifyTo"
                      type="email"
                      fullWidth
                      variant="standard"
                      value={values.notifyTo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.notifyTo && Boolean(errors.notifyTo)}
                      helperText={touched.notifyTo && errors.notifyTo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel shrink id="documentName-label">
                      Nombre del Oficio: <span className="text-danger"> *</span>
                    </InputLabel>
                    <TextField
                      id="documentName"
                      name="documentName"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={values.documentName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.documentName && Boolean(errors.documentName)}
                      helperText={touched.documentName && errors.documentName}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel shrink id="documentDate-label">
                      Fecha: <span className="text-danger"> *</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="documentDate"
                      name="documentDate"
                      type="date"
                      variant="standard"
                      value={values.documentDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.documentDate && Boolean(errors.documentDate)}
                      helperText={touched.documentDate && errors.documentDate}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel shrink id="documentNumber-label">
                      Número de Oficio: <span className="text-danger"> *</span>
                    </InputLabel>
                    <TextField
                      id="documentNumber"
                      name="documentNumber"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={values.documentNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.documentNumber && Boolean(errors.documentNumber)}
                      helperText={touched.documentNumber && errors.documentNumber}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel shrink id="keywords-label">
                      Palabra clave(s): <span className="text-danger"> *</span>
                    </InputLabel>
                    <TextField
                      id="keywords"
                      name="keywords"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={values.keywords}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.keywords && Boolean(errors.keywords)}
                      helperText={touched.keywords && errors.keywords}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel shrink id="clause-label">
                      Cláusula: <span className="text-danger"> *</span>
                    </InputLabel>
                    <Select
                      labelId="clause-label"
                      id="clause"
                      name="clause"
                      value={values.clause}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.clause && Boolean(errors.clause)}
                      variant="standard"
                      fullWidth
                    >
                      {(rawClauses)?.map((option, index) => (
                        <MenuItem key={index} value={option.id}>{option.clauseNumber}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel shrink id="response-label">
                      Respuesta: <span className="text-danger"> *</span>
                    </InputLabel>
                    <Select
                      labelId="response-label"
                      id="response"
                      name="response"
                      value={values.response}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.response && Boolean(errors.response)}
                      variant="standard"
                      fullWidth
                    >
                      <MenuItem value={10}>Si</MenuItem>
                      <MenuItem value={20}>No</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="center" marginTop={3} >
                    <Button
                      size="small"
                      variant="contained"
                      className="rounded-4 me-2"
                      startIcon={<FileUpload />}
                      onClick={() => document.getElementById('oficioFile').click()}
                    >
                      Seleccionar un Archivo
                    </Button>
                    <input
                      id="oficioFile"
                      name="oficioFile"
                      type="file"
                      accept=".pdf, .xls, .xlsx"
                      hidden
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        // Sanitizar y truncar el nombre del archivo
                        const sanitizedFilename = sanitizeFilename(file.name);
                        const truncatedFilename = truncateFilename(sanitizedFilename);
                        // Crear un nuevo objeto de archivo con el nombre modificado
                        const modifiedFile = new File([file], truncatedFilename, { type: file.type });
                        setFieldValue('oficioFile', modifiedFile);
                      }}
                    />
                    <Typography variant="caption">
                      {values.oficioFile ? values.oficioFile.name : 'Ningun archivo seleccionado.'}
                      <span className="text-danger"> *</span>
                    </Typography>
                    {touched.oficioFile && errors.oficioFile && (
                      <Typography variant="caption" color="error">{errors.oficioFile}</Typography>
                    )}
                  </Grid>
                </Grid>
              </CustomTabPanel>

            </DialogContent>
            <Divider />
            <DialogActions className="mb-3">
              <Grid container spacing={2}>
                <Grid item sm={6} className="text-center">
                  <Typography variant="caption">
                    Los campos marcados con <span className="text-danger"> *</span> son obligatorios
                  </Typography>
                </Grid>
                <Grid item sm={6} className="text-end">
                  <Button
                    size="medium"
                    variant="contained"
                    className="rounded-4 me-2"
                    onClick={handleCloseDialog}
                  >
                    Cancelar
                  </Button>
                  <LoadingButton
                    size="medium"
                    variant="contained"
                    className="rounded-4 me-2"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isValid || isSubmitting}
                  >
                    Enviar
                  </LoadingButton>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );

  const DialogApproval = (
    <Dialog
      open={openDialogApprove}
      onClose={handleCloseDialog}
      maxWidth="100%"
    >
      <DialogTitle className={classes.dialogTitle}>Aprobación</DialogTitle>
      <Divider />
      <DialogContent>
        <InputLabel shrink id="client-name-company-label">
          La cláusula <b>{selectedClause.clauseName}</b> se encuentra en el proceso de aprobación.
        </InputLabel>
        <Box sx={{ backgroundColor: "#e8f2fb", padding: "1em" }} display="block">
          <Typography variant="body2" color="primary" fontWeight="bold">
            Comentarios enviados:
          </Typography>

          <Typography variant="caption" color="black" >
            {selectedReview?.comment || ''}
          </Typography>

          <Typography variant="body2" color="primary" fontWeight="bold" className="mt-2" >
            Oficio:
          </Typography>
          <Typography variant="caption" component="a" color="primary" href={selectedReview?.oficioFile[0]?.url} target="_blank">
            {selectedReview?.documentName || ''}
          </Typography>
          <Typography variant="body2" color="primary" fontWeight="bold" className="mt-2" >
            Documentos Generales:
          </Typography>
          {selectedReview?.generalFiles.map((data, index) =>
            <Typography variant="caption" component="a" color="primary" href={data.url} target="_blank" >
              {index + 1}. {data.name || ''}
            </Typography>
          )
          }

        </Box>
      </DialogContent>
      <Divider />
      <DialogActions className="mb-3">
        <Grid container spacing={2}>
          <Grid item sm={12} className="text-center">
            <Button
              variant="contained"
              className="rounded-4 me-3"
              onClick={() => {
                setOpenDialogDecline(true);
                setOpenDialogApprove(false);
              }}
            >
              Rechazar
            </Button>
            <LoadingButton
              variant="contained"
              className="rounded-4 me-3"
              onClick={handleApprove}
            >
              Aprobar
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
  /* Start Duplicate Section */
  const DialogConfirmDuplicate = (
    <Dialog
      open={openDialogConfir}
      onClose={handleCloseDialog}
      maxWidth="100%"
    >
      <DialogContent>
        <img
          alt="Confirmar"
          className={classes.image}
          img
          src={confirmar}
          width={200}
          height={200}
        />
        <br />
        <br />
        <InputLabel shrink id="project-type-label">
          La cláusula ha sido duplicada.
        </InputLabel>
      </DialogContent>
      <DialogActions className="mb-3">
        <Button
          variant="contained"
          className="rounded-4 me-3"
          onClick={handleCloseDialog}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  const DialogDuplicate = (
    <Dialog
      open={openDialogDuplicate}
      onClose={handleCloseDialog}
      maxWidth="100%"
    >
      <DialogTitle className={classes.dialogTitle}>Duplicar</DialogTitle>
      <Divider />
      <DialogContent>
        <InputLabel shrink id="project-type-label">
          ¿Desea duplicar esta cláusula?
        </InputLabel>
      </DialogContent>
      <Divider />
      <DialogActions className="mb-3">
        <Grid container spacing={2}>
          <Grid item sm={12} className="text-center">
            <Button
              variant="contained"
              className="rounded-4 me-3"
              onClick={() => {
                setOpenDialogNewId(true);
                setOpenDialogDuplicate(false);
              }}
            >
              Aceptar
            </Button>
            <Button
              variant="contained"
              className="rounded-4 me-3"
              onClick={handleCloseDialog}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  const handleDetail = () => {
    setViewDetalle(false)
  }

  const DialogDuplicateId = (
    <Dialog
      open={openDialogNewId}
      onClose={handleCloseDialog}
      maxWidth="100%"
    >
      <DialogTitle className={classes.dialogTitle}>
        Duplicar Cláusula
      </DialogTitle>
      <Divider />
      <DialogContent>
        <InputLabel shrink id="project-type-label">
          ¡Inserte un ID de cláusula diferente!
        </InputLabel>
        <TextField fullWidth id="filled-multiline-static" />
      </DialogContent>
      <Divider />
      <DialogActions className="mb-3">
        <Grid container spacing={2}>
          <Grid item sm={12} className="text-center">
            <Button
              variant="contained"
              className="rounded-4 me-3"
              onClick={() => setOpenDialogConfir(true)}
            >
              Guardar
            </Button>
            <Button
              variant="contained"
              className="rounded-4 me-3"
              onClick={handleCloseDialog}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
  /* End Duplicate Section*/
  const DialogDecline = (
    <Dialog
      open={openDialogDecline}
      onClose={handleCloseDialog}
      maxWidth="100%"
    >
      <DialogTitle className={classes.dialogTitle}>Rechazar</DialogTitle>
      <Divider />
      <DialogContent>
        <InputLabel shrink id="project-type-label">
          Añadir un comentario o nota sobre la solicitud rechazada.
          <br />
          Descripción o comentario
        </InputLabel>
        <TextField
          fullWidth
          id="commentDecline"
          name="commentDecline"
          onChange={(e) => setSelectedDeclineComment(e.target.value)}
          placeholder="Añada una descripción o comentario"
          multiline
          rows={4}
        />
      </DialogContent>
      <Divider />
      <DialogActions className="mb-3">
        <Grid container spacing={2}>
          <Grid item sm={12} className="text-center">
            <Button
              variant="contained"
              className="rounded-4 me-3"
              onClick={handleCloseDialog}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className="rounded-4 me-3"
              onClick={handleDecline}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  const DialogRename = (
    <Dialog
      open={openDialogNewName}
      onClose={handleCloseDialog}
      maxWidth="100%"
    >
      <DialogTitle className={classes.dialogTitle}>
        Renombrar Cláusula
      </DialogTitle>
      <Divider />
      <DialogContent>
        <InputLabel shrink id="project-type-label">
          ¿Cuál es el nombre de la cláusula?
        </InputLabel>
        <TextField fullWidth id="filled-multiline-static" />
      </DialogContent>
      <Divider />
      <DialogActions className="mb-3">
        <Grid container spacing={2}>
          <Grid item sm={12} className="text-center">
            <Button
              variant="contained"
              className="rounded-4 me-3"
              onClick={handleCloseDialog}
            >
              Guardar
            </Button>
            <Button
              variant="contained"
              className="rounded-4 me-3"
              onClick={handleCloseDialog}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  const extractFileName = (fileName) => {
    const parts = fileName.split('_');
    parts.pop(); // Elimina el último elemento que es el identificador
    return parts.join('_'); // Vuelve a unir las partes restantes con guion bajo
  };


  return (
    <>
      {/* -------Start Dialogs Section------- */}
      {/* Dialogo de Aprobación*/}
      {DialogApproval}
      {/* Dialogo para duplicar una cláusula*/}
      {DialogDuplicate}
      {DialogConfirmDuplicate}
      {/* Dialogo de para agregar nuevo id a una cláusula duplicada*/}
      {DialogDuplicateId}
      {/* Dialogo para rechazar una cláusula*/}
      {DialogDecline}
      {/* Dialogo para gestionar una cláusula*/}
      {DialogManage}
      {/* Dialogo de para crear una cláusula*/}
      {DialogNewClause}
      {/* Dialogo de para renombrar cláusula*/}
      {DialogRename}
      {/* Dialogo de advertencia */}
      {WarningDialog}
      {/* -------End Dialogs Section------- */}

      <Container>
        <Grid item sm={12} xs={12}>
          {viewDetalle && (
            <>
              <Box bgcolor="rgb(236,240,243)" my={2} py={2} justifyContent="flex-start" alignItems="center" display="flex" >
                <Tooltip title="Regresar">
                  <IconButton aria-label="" className="me-2" onClick={() => handleDetail()}>
                    <ChevronLeft color="primary" fontSize="large" />
                  </IconButton>
                </Tooltip>

                <Check className={selectedClause.state !== "Aprobado" && selectedClause.state !== "Aprobado Tarde" ? classes.DefaultCheck : classes.ApprovalCheck} />

                <Typography className="ms-4" variant="body1" fontWeight="bold" color="rgb(171,178,184)" >{(selectedClause?.clauseName).length < 40 ? selectedClause?.clauseName : (selectedClause?.clauseName).slice(0,50) + '...'}</Typography>
              </Box>

              <Card variant="elevation" elevation={0}>
                <CardHeader
                  avatar={
                    <TextSnippet color="primary" />
                  }
                  title={"Información General de la cláusula"}
                  titleTypographyProps={{ color: "primary", fontWeight: "bold" }}
                  subheaderTypographyProps={{
                    color: "GrayText",
                    className: "mt-4",
                    fontWeight: "bolder",
                  }} />
                <Divider orientation="horizontal" flexItem />
                <CardContent>
                  <Grid container={2} rowGap={2}>
                    <Grid item sm={12} md={3}>
                      <b>Grupo perteneciente</b>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      {selectedClause.belongingGroup}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <b>Responsable</b>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      {
                        selectedClause?.users?.filter(user => user.roleName === "Responsable").map(user => user.username)?.join(", ") || "Sin asignar"
                      }
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <b>Encargado de aprobación</b>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      {
                        selectedClause?.users?.filter(user => user.roleName === "Aprobador").map(user => user.username)?.join(", ") || "Sin asignar"
                      }
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <b>Fecha de inicio</b>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      {dateFormat2(selectedClause?.startDate) || 'Por definir'}
                    </Grid>

                    <Grid item sm={12} md={3}>
                      <b>Fecha de vencimiento</b>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      {dateFormat2(selectedClause?.expirationDate) || 'Por definir'}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <b>Tipo de Cláusula</b>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      {selectedClause.clauseType}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <b>Categoría</b>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      {selectedClause.category}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <b>Estatus</b>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      {selectedClause.state}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <b>Criticidad</b>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      {selectedClause.criticality}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <b>Número de Cláusula</b>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      {selectedClause.clauseNumber}
                    </Grid>
                    {selectedClause?.clauseType === "Acción ligada a otra acción o fecha" &&
                      <><Grid item sm={12} md={3}>
                        <b>Plazo de vencimiento</b>
                      </Grid><Grid item sm={12} md={3}>
                          {selectedClause?.expirationDays ? selectedClause?.expirationDays + ' días' : ' - '}
                        </Grid>
                        <Grid item sm={12} md={3}>
                          <b>Cláusula Ligada</b>
                        </Grid><Grid item sm={12} md={3}>
                          {selectedClause?.linkedClause?.clauseNumber} - {selectedClause?.linkedClause?.clauseName}
                        </Grid>
                      </>
                    }
                    {selectedClause?.clauseType === "Acción recurrente" &&
                      <><Grid item sm={12} md={3}>
                        <b>Periodo de repetición (en meses)</b>
                      </Grid><Grid item sm={12} md={3}>
                          {selectedClause?.recurrencePeriod ? selectedClause?.recurrencePeriod + ' meses' : ' - '}
                        </Grid>
                      </>
                    }
                  </Grid>
                </CardContent>
              </Card>

              <Card variant="elevation" elevation={0} className="mt-2">
                <CardHeader
                  avatar={<Message color="primary" />}
                  title="Comentarios"
                  titleTypographyProps={{ color: "primary", fontWeight: "bold" }}
                  subheaderTypographyProps={{ color: "GrayText", className: "mt-4", fontWeight: "bolder" }}
                />
                <Divider orientation="horizontal" flexItem />
                <CardContent>
                  <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto', mt: 4 }}>
                    {!loaderMessages && messages && messages.length > 0 ? messages.map((comment, index) => (
                      <Card key={index} sx={{ mb: 2 }}>
                        <CardHeader
                          avatar={<Avatar aria-label="recipe">{comment.userInfo.fullname.charAt(0)}</Avatar>}
                          action={
                            <Box display="" mx="" my="" sx="">
                              <Chip label={comment.state} style={{ background: getStateParams(comment.state), color: "white" }} />

                              <IconButton aria-label="settings">
                                <MoreVert />
                              </IconButton>
                            </Box>
                          }
                          title={comment.userInfo.fullname}
                          subheader={new Date(comment.expeditionDate).toLocaleString()} 
                        />
                        <CardContent>
                          <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} className="mb-3">
                            {comment?.signedFiles.map((file, index) => (
                              <Grid item xs={4} key={index} >
                                <ButtonGroup
                                  variant="outlined"
                                  sx={{
                                    ".MuiButtonGroup-grouped:not(:last-of-type)": {
                                      borderColor: "#EBEBEB",
                                    },
                                    ".MuiButtonGroup-groupedHorizontal": {
                                      border: "1px solid #EBEBEB"
                                    }
                                  }}
                                  fullWidth
                                  ref={anchorRef}
                                  aria-label="Button group with a nested menu"
                                >
                                  <Grid item xs={10}>
                                    <Button href={file.url} target="_blank" style={{ color: "#424242", borderRight: "1px solid white", textTransform: "none" }} onClick={handleClick}>
                                      <Grid container alignItems="center" rowGap={1} columnGap={1}>
                                        <Grid item xs={2} rowSpacing={2}>
                                          <img
                                            alt="Icono tipo"
                                            src={file.type === '.pdf' ? PdfIcon : ExcelIcon}
                                            height={30}
                                          />
                                        </Grid>
                                        <Grid item xs={9} textAlign="start">
                                          <Typography variant="body2" sx={{ ml: 1 }} fontSize="12px">
                                            {extractFileName(file?.name)}
                                          </Typography>
                                          <Typography variant="body2" sx={{ ml: 1 }} fontSize="10px">
                                            {file.sizeKB} KB
                                          </Typography>
                                        </Grid>
                                      </Grid>

                                    </Button>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Button
                                      style={{ color: "#808080", borderLeft: "1px solid white", height: "100%" }}
                                      size="small"

                                      aria-controls={open ? 'split-button-menu' : undefined}
                                      aria-expanded={open ? 'true' : undefined}
                                      aria-label="select merge strategy"
                                      aria-haspopup="menu"
                                      onClick={handleToggle}
                                    >
                                      <ExpandMore fontWeight="12px" />
                                    </Button>
                                  </Grid>
                                </ButtonGroup>
                              </Grid>
                            ))}
                          </Grid>

                          <Typography variant="body2" color="text.secondary">
                            {comment.comment}
                          </Typography>
                          <Box sx={{ mt: 2 }}>
                            <Typography variant="body2" color="text.primary">Notificados:</Typography>
                            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                              {comment.issuer ? (
                                <>
                                  <Chip key={0} label={comment?.issuer} deleteIcon={<CheckCircle />} onDelete={() => { }} />
                                  <Chip key={2} label={comment?.recipient} deleteIcon={<CheckCircle />} onDelete={() => { }} />
                                  {comment?.notifyTo && <Chip key={1} label={comment?.notifyTo} deleteIcon={<CheckCircle />} onDelete={() => { }} />}

                                </>
                              ) : <Chip key={0} label={"Usuarios de la cláusula"} deleteIcon={<CheckCircle />} onDelete={() => { }} />}
                            </Box>

                          </Box>
                        </CardContent>
                      </Card>
                    )) : !loaderMessages && messages.length === 0 && (
                      <>
                        <Typography variant="h5" className="ms-4" color="GrayText">
                          No se han encontrado resultados
                        </Typography>
                        <Divider variant="fullWidth" orientation="horizontal" />
                      </>
                    )
                    }

                    {loaderMessages && (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress />
                      </Box>
                    )}


                    {/* Para ser usado más tarde */}
                    {/* <Box sx={{ mt: 2 }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Escribe un comentario"
                        variant="outlined"
                      />
                      <Box sx={{ display: 'flex', gap: 1, mt: 2, alignItems: 'center' }}>
                        <Typography variant="body2" color="text.primary">¿Quién debe ser notificado? (Eliminar todo):</Typography>
                        <Chip label="Daniel Rojas" onDelete={() => { }} />
                        <Chip label="Julio Marín" onDelete={() => { }} />
                        <Button variant="outlined" size="small">Añadir más</Button>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<AttachFile />}
                        >
                          Seleccionar archivo
                          <input type="file" hidden />
                        </Button>
                      </Box>
                      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Button variant="contained" color="primary" endIcon={<Send />}>
                          Publicar
                        </Button>
                        <Button variant="outlined" color="secondary">
                          Cancelar
                        </Button>
                      </Box>
                    </Box> */}
                    {/*                     <Divider sx={{ my: 2 }} />
                    <Typography variant="h6" color="text.primary">Documentos</Typography>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AttachFile />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Documento ejemplo 1" secondary="Subido por Mauricio Blanco, hace 3 días" />
                        <ListItemSecondaryAction>
                          <Typography variant="caption">12.55MB</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Typography variant="body2" color="text.secondary" sx={{ ml: 7, mt: 1 }}>Noviembre 17</Typography>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AttachFile />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Documento ejemplo 2" secondary="Subido por Mauricio Blanco, hace 3 días" />
                        <ListItemSecondaryAction>
                          <Typography variant="caption">22.55MB</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AttachFile />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Documento ejemplo 3" secondary="Subido por Mauricio Blanco, hace 3 días" />
                        <ListItemSecondaryAction>
                          <Typography variant="caption">45.55MB</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List> */}
                    {/*   <Button variant="contained" startIcon={<AttachFile />}>
                      Subir documento
                    </Button> */}
                  </Box>
                </CardContent>
              </Card>
            </>
          )}

          {!viewDetalle && (
            <>
              <Grid item sm={12} className={classes.NewButton} >
              {permissionsService("clauses", myRol?.role?.label, "add") && <><Button
                  onClick={() => {
                    fetchSettingsProjects();
                    fetchUsersByProject();
                    handleToggleImport();
                  } }
                  variant="contained"
                  style={{ backgroundColor: "#2196F3" }}
                  className="me-2"
                >
                  Importar Cláusulas
                </Button><Button
                  onClick={() => handleNewClause("new")}
                  variant="contained"

                >
                    Nueva Cláusula
                  </Button></>}

              </Grid>

              <br />
              {!loading ? TableClauses : <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>}

            </>
          )}
        </Grid>
        {importClauses}
        {DeleteDialog}
      </Container>
    </>
  );
};

export default Clauses;
