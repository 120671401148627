const setUserLogin = (state, action) => {
  if (action.type === "persist/REHYDRATE") {
    return {
      user: action.payload?.setUserLogin?.user ,
      project: action.payload?.setUserLogin?.project,
    };
  } else {
    return {
      user: action?.User || action?.payload || {},
      project:action?.payload||''
    };
  }
};

export default setUserLogin;
