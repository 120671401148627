import axiosInstance from "src/utils/axios";

const api = '/api/projects'

const apiProjectsService = {
  post: (data, contract, documents) => {
    const formData = new FormData();
    formData.append('contract', contract, contract.name);
    formData.append('data', JSON.stringify(data));

    if (documents.length > 0) {
      for (const file of documents) {
        formData.append('documents', file, file.name);
      }
    }

    return axiosInstance.post(api + '/', formData);
  },
  get: (userId) => {
    return axiosInstance.get(api + '/data/' + userId);
  },
  getUsers: (projectId) => {
    return axiosInstance.get(api + '/users/' + projectId);
  },
  getById: (id) => {
    return axiosInstance.get(api + '/' + id)
  },
  delete: (id) => {
    return axiosInstance.delete(api + '/' + id);
  },
  toggleStatus: (id, status) => {
    return axiosInstance.put(api + '/status/' + id + '/' + status)
  },
  update: (data, contract, documents, deletedFiles, id) => {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (contract) {
      formData.append('contract', contract, contract.name);
    }

    if (documents.length > 0) {
      for (const file of documents) {
        formData.append('documents', file, file.name);
      }
    }
    if (deletedFiles.length > 0) {
      const formattedDeletedFiles = deletedFiles.map(file => ({
        name: file.name,
        url: file.url
      }));

      formData.append('deletedFiles', JSON.stringify(formattedDeletedFiles));
    }

    return axiosInstance.put(api + '/' + id, formData)
  },
  rename: (id, data) => {
    return axiosInstance.put(api + '/rename/' + id,data)
  },
  getMessages: (projectId, clauseId) => {
    return axiosInstance.get(api + '/messages/' + projectId + '/' + clauseId)
  }
};

export default apiProjectsService;
