import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
      padding: 2,
      position: 'absolute',
      top: 5,
    },
    dialogTitle: {
      paddingRight: '0px',
      textAlign: 'center', 
      fontSize: '1.5em', 
      color: '#99B4D1', 
      fontWeight: 'bold' 
    },
    tabStyle: {
        width: '13em !important',
        textTransform: 'none !important',
        borderRadius: '1.5em !important', 
        backgroundColor: 'white !important', 
        color: '#99B4D1 !important', 
        marginRight: '1em !important', 
        '&.Mui-selected': 
        {
            backgroundColor: '#2162C0 !important',
            color: 'white !important'
        }
    },
    buttonStyle:{
      marginRight: '1em !important', 
      textTransform: 'none !important',
       background: '#2162C0 !important', 
       borderRadius: '10em !important'
    },
    projectStyle: {
      display: 'flex', 
      justifyContent: 'end', 
      alignItems: 'center', 
      paddingRight: '1em'
    },
    selectedItem: {
      color: '#a7bcd5 !important',
      '&.Mui-selected': {
        backgroundColor: '#1976D2 !important', 
        color: 'white !important', 
        '&:hover': {
          backgroundColor: theme.palette.primary.dark, 
        },
        '& .MuiListItemIcon-root': {
          color: 'white !important',
        },
      },
    },
    icon: {
      color: '#a7bcd5 !important'
     
    }
  }));

export default useStyles