import React, { useState, useEffect, useCallback, useContext, useMemo } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  useTheme,
  useMediaQuery,
  Typography, Tooltip,
  Badge,
  ListItemAvatar,
  Avatar,
  Divider,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItem,
  CircularProgress,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import NavigationIcon from "@mui/icons-material/Navigation";
import PersonIcon from "@mui/icons-material/Person";
import AssessmentIcon from "@mui/icons-material/Assessment";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InfoIcon from "@mui/icons-material/Info";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../../assets/logo.png";
import { useStyles } from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector, useDispatch } from "react-redux";
import { getNewUserLogin } from "../../redux/actions/loginUserAction.js";
import { useNotificationContext } from "src/redux/contexts/notificationContext";
import { Comment, NotificationsOff } from "@mui/icons-material";
import apiUserService from "src/services/usersService";
import { VariableSizeList } from 'react-window';
import moment from "moment";
import permissionsService from "src/services/permissionsService";

const TopBar = () => {
  const User = useSelector((state) => state?.setUserLogin?.user);
  const Usuario =
    User && User !== "undefined" && User !== undefined ? User : null;
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [notificationsGroup, setNotificationsGroup] = useState([])
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const theme = useTheme();
  const classes = useStyles();
  let navigate = useNavigate();
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const isMobile = useMediaQuery(theme?.breakpoints?.down("lg"));
  const dispatch = useDispatch();
  const { notificationsCount, notifications, setNotificationsCount } = useNotificationContext();



  const handleClick = (event) => {
    setAnchorElNotifications(event.currentTarget);
    apiUserService.markNotifications(User.id).then((res) => {
      setNotificationsCount(0)
    })
  };

  const handleCloseNotification = () => {
    setAnchorElNotifications(null);
  };

  /*  const fetchPartNotifications = async () => {
     fetchNotifications(Usuario.id)
   } */

  useEffect(() => {
    const path = location.pathname.split("/");
    switch (path[1]) {
      case "home":
        setSelectedItem(0);
        break;
      case "customers":
        setSelectedItem(1);
        break;
      case "projects":
        setSelectedItem(2);
        break;
      case "users":
        setSelectedItem(3);
        break;
      case "reports":
        setSelectedItem(4);
        break;
      case "settings":
        setSelectedItem(5);
        break;
      default:
        setSelectedItem(0);
    }
  }, [location]);


  useEffect(() => {
    const groupedNotifications = {};

    if (notifications && notifications.length > 0) {
      notifications.forEach((obj) => {
        const timeAgo = getTimeAgo(obj.date);

        if (!groupedNotifications[timeAgo]) {
          groupedNotifications[timeAgo] = [];
        }

        groupedNotifications[timeAgo].push(obj);
      });
    }
    setNotificationsGroup(groupedNotifications)
  }, [notifications])


  const handleMobileOpen = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (index) => {
    setSelectedItem(index);
  };

  const mobileMenu = (
    <Drawer
      anchor="left"
      open={mobileMenuOpen}
      onClose={handleMobileOpen}
      classes={{
        paper: classes.drawer,
      }}

    >
      <Box role="presentation" className="text-center p-3">
        <img src={LogoIcon} width="120px" alt="Logo" className="m-4" />
        <MenuItem
          selected={selectedItem === 0}
          onClick={() => handleSelect(0)}
          tabIndex={0}
          className={classes.menuItem}
          component={Link}
          to="/home"
        >
          <ListItemIcon>
            <HomeIcon className={classes.menuIcon} />
          </ListItemIcon>
          <Typography variant="inherit">Inicio</Typography>
        </MenuItem>
        {permissionsService("clients", User?.role?.label, "view") && <MenuItem
          selected={selectedItem === 1}
          onClick={() => handleSelect(1)}
          tabIndex={1}
          className={classes.menuItem}
          component={Link}
          to="/customers"
        >
          <ListItemIcon>
            <BusinessCenterIcon className={classes.menuIcon} />
          </ListItemIcon>
          <Typography variant="inherit">Clientes</Typography>
        </MenuItem>}
        <MenuItem
          selected={selectedItem === 2}
          onClick={() => handleSelect(2)}
          tabIndex={2}
          className={classes.menuItem}
          component={Link}
          to="/projects"
        >
          <ListItemIcon>
            <NavigationIcon className={classes.menuIcon} />
          </ListItemIcon>
          <Typography variant="inherit">Proyectos</Typography>
        </MenuItem>
        {permissionsService("users", User?.role?.label, "view") && <MenuItem
          selected={selectedItem === 3}
          onClick={() => handleSelect(3)}
          tabIndex={3}
          className={classes.menuItem}
          component={Link}
          to="/users"
        >
          <ListItemIcon>
            <PersonIcon className={classes.menuIcon} />
          </ListItemIcon>
          <Typography variant="inherit">Usuarios</Typography>
        </MenuItem>}
        <MenuItem
          selected={selectedItem === 4}
          onClick={() => handleSelect(4)}
          tabIndex={4}
          className={classes.menuItem}
          component={Link}
          to="/reports"
        >
          <ListItemIcon>
            <AssessmentIcon className={classes.menuIcon} />
          </ListItemIcon>
          <Typography variant="inherit">Reportes</Typography>
        </MenuItem>
        <MenuItem
          selected={selectedItem === 5}
          onClick={() => handleSelect(5)}
          tabIndex={5}
          className={classes.menuItem}
          component={Link}
          to="/settings"
        >
          <ListItemIcon>
            <SettingsIcon className={classes.menuIcon} />
          </ListItemIcon>
          <Typography variant="inherit">Ajustes</Typography>
        </MenuItem>
      </Box>
    </Drawer>
  );


  const handleExit = (event) => {
    event.preventDefault();
    sessionStorage.clear();
    localStorage.removeItem('user');
    dispatch(getNewUserLogin([]));
    navigate("/login");
  };

  const handleSettings = (event, index) => {
    event.preventDefault();
    navigate("/settings/" + index);
  };

  const getTimeAgo = (timestamp) => {
    const today = moment();
    const yourDate = moment(timestamp); // Convertir el timestamp a objeto moment

    // Calcular la diferencia en días, semanas, meses o años
    const diffYears = today.diff(yourDate, 'years');
    const diffMonths = today.diff(yourDate, 'months');
    const diffWeeks = today.diff(yourDate, 'weeks');
    const diffDays = today.diff(yourDate, 'days');

    // Determinar el tiempo relativo
    if (diffYears > 1) {
      return `Hace ${diffYears} años`;
    } else if (diffMonths > 1) {
      return `Hace ${diffMonths} meses`;
    } else if (diffWeeks > 1) {
      return `Hace ${diffWeeks} semanas`;
    } else if (diffDays > 1) {
      return `Hace ${diffDays} días`;
    } else if (diffDays === 1) {
      return `Ayer`;
    } else {
      return `Hoy`;
    }
  };

  const renderRow = useCallback(({ index, style }) => {
    const keys = Object.keys(notificationsGroup);
    let item = null;

    let counter = 0;
    for (let i = 0; i < keys.length; i++) {
      if (index < counter + notificationsGroup[keys[i]].length + 1) {
        if (index === counter) {
          item = (
            <ListSubheader key={keys[i]} sx={{ bgcolor: 'background.paper' }}  >
              {keys[i]}
            </ListSubheader>
          );
        } else {
          const obj = notificationsGroup[keys[i]][index - counter - 1];
          const listItemClass = obj.read ? classes.readNotification : classes.unreadNotification;
          item = (
            <ListItemButton key={obj.id} style={style} className={listItemClass}>
              <ListItemAvatar>
                <Avatar>
                  <Comment />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<div style={{
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  fontSize: "14px",
                  display: "block"
                }}>{obj.module}</div>}
                secondary={
                  <Typography
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: obj.type !== "Plan maestro" && obj.type !== "Cláusulas" ? (`${obj.user !== User.id
                        ? `El usuario <b>${obj.userName}</b> ha realizado el `
                        : `Haz realizado el`
                        } ${obj.message}`) : obj.message,
                    }}
                    fontSize="12px"
                    variant="body2"
                    color="GrayText"
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      display: "block",
                    }}
                  />
                }
              />
            </ListItemButton>
          );
        }
        break;
      }
      counter += notificationsGroup[keys[i]].length + 1;
    }
    return item;
  }, [notificationsGroup, User?.id]);



  const getItemSize = useCallback((index) => {
    if (index === 0) {
      return 50; // Tamaño del primer elemento
    }
    return 90; // Tamaño de todos los demás elementos
  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <div>
      <Tooltip title="Notificaciones">
        <IconButton size="large" color="inherit" onClick={handleClick}>
          <Badge badgeContent={notificationsCount ? (notificationsCount < 10 ? notificationsCount : "+10") : 0} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElNotifications}
        open={Boolean(anchorElNotifications)}
        onClose={handleCloseNotification}

      >
        <MenuItem disableTouchRipple disableRipple disableGutters style={{ width: 400, height: 400, justifyContent: "center", display: "flex", margin: 0, padding: 0 }}>
          {Object.keys(notificationsGroup).length === 0 ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
            <NotificationsOff style={{ fontSize: 40, color: '#ccc', marginBottom: '10px' }} />
            <Typography color='#848484'>No hay notificaciones</Typography>
          </div>
          ) : (
            <VariableSizeList
              height={400}
              width={400}
              itemSize={getItemSize}
              itemCount={Object.keys(notificationsGroup).reduce((acc, key) => acc + notificationsGroup[key].length + 1, 0)}
              overscanCount={10}
            >
              {renderRow}
            </VariableSizeList>
          )}
        </MenuItem>

      </Menu>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AccountCircleIcon />
      </IconButton>

      <Button
        id="basic-button"
        className="me-2"
        color="inherit"
        style={{ fontSize: "0.8em" }}
        aria-controls={isMenuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
        onClick={handleProfileMenuOpen}
      >
        {Usuario?.fullname || ""}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={(e) => handleSettings(e, 1)}>
          <ListItemIcon className={classes.menuItemIcon}>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText
            primary="Información personal"
            className={classes.menuItemText}
          />
        </MenuItem>
        <MenuItem onClick={(e) => handleSettings(e, 3)}>
          <ListItemIcon className={classes.menuItemIcon}>
            <LockIcon />
          </ListItemIcon>
          <ListItemText
            primary="Cambiar contraseña"
            className={classes.menuItemText}
          />
        </MenuItem>
        <MenuItem onClick={(e) => handleSettings(e, 2)}>
          <ListItemIcon className={classes.menuItemIcon}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Preferencias"
            className={classes.menuItemText}
          />
        </MenuItem>
        <MenuItem onClick={handleExit}>
          <ListItemIcon className={classes.menuItemIcon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText
            primary="Cerrar sesión"
            className={classes.menuItemText}
          />
        </MenuItem>
      </Menu>
    </div>
  );

  return (
    <div className={classes.marginBottom}>
      <AppBar position="fixed" >
        <Toolbar className={classes.toolBar}>
          {isMobile && (
            <>
              {" "}
              <IconButton
                style={{ marginLeft: "1em" }}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => handleMobileOpen()}
              >
                <MenuIcon />
              </IconButton>
              {mobileMenu}
            </>
          )}

          {!isMobile && (
            <>
              <Box className={classes.boxItem}>
                <img
                  src={LogoIcon}
                  height="100%"
                  width={155}
                  alt="Logo"
                  className={classes.imgStyle}
                />
              </Box>
              <Button
                color="inherit"
                className={`${selectedItem === 0 ? classes.selected : null
                  } pe-3 ps-3 me-2`}
                onClick={() => handleSelect(0)}
                component={Link}
                to="/home"
              >
                <HomeIcon className={classes.iconText} /> INICIO
              </Button>
              {permissionsService("clients", User?.role?.label, "view") && <Button
                color="inherit"
                className={`${selectedItem === 1 ? classes.selected : null
                  } pe-3 ps-3 me-2`}
                onClick={() => handleSelect(1)}
                component={Link}
                to="/customers"
              >
                <BusinessCenterIcon className={classes.iconText} /> CLIENTES
              </Button>}
              <Button
                color="inherit"
                className={`${selectedItem === 2 ? classes.selected : null
                  } pe-3 ps-3 me-2`}
                onClick={() => handleSelect(2)}
                component={Link}
                to="/projects"
              >
                <NavigationIcon className={classes.iconText} /> PROYECTOS
              </Button>
              {permissionsService("users", User?.role?.label, "view") && <Button
                color="inherit"
                className={`${selectedItem === 3 ? classes.selected : null
                  } pe-3 ps-3 me-2`}
                onClick={() => handleSelect(3)}
                component={Link}
                to="/users"
              >
                <PersonIcon className={classes.iconText} /> USUARIOS
              </Button>}
              {/*  <Button
                color="inherit"
                className={`${selectedItem === 4 ? classes.selected : null
                  } pe-3 ps-3 me-2`}
                onClick={() => handleSelect(4)}
                component={Link}
                to="/reports"
              >
                <AssessmentIcon className={classes.iconText} /> REPORTES
              </Button> */}
              <Button
                color="inherit"
                className={`${selectedItem === 5 ? classes.selected : null
                  } pe-3 ps-3 me-2`}
                onClick={() => handleSelect(5)}
                component={Link}
                to="/settings"
              >
                <SettingsIcon className={classes.iconText} /> AJUSTES
              </Button>

              <Box sx={{ flexGrow: 1 }} />
            </>
          )}
          {renderMenu}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopBar;
