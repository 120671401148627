export const projectsPermissions = {
    "Super Administrador": [
        'manage',
        "information",
        'add', 
        'edit', 
        'edit_users',
        'delete',
        'rename',
        'archived',
        'completed'
    ],
    "Administrador TPIG": [
        'manage',
        "information",
        'add', 
        'edit', 
        'edit_users',
        'delete',
        'rename',
        'archived',
        'completed'
    ],
    "Administrador Proyecto": [
        "edit",
        'manage',
        'edit_users',
        'archived',
        'completed',
    ],
    "Aprobador": [
        'manage',
        'completed'
    ],
    "Gestionador": [
        'manage'
    ],
    "Usuario": []
};

