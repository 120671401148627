import { clientsPermissions } from '../permissions/clientsPermissions';
import { usersPermissions } from '../permissions/usersPermissions';
import { projectsPermissions } from '../permissions/projectsPermissions';
import { clausesPermissions } from '../permissions/clausesPermissions';
import { kpisPermissions } from '../permissions/kpisPermissions';
import { masterPlanPermissions } from '../permissions/masterPlanPermissions';
import { settingsPermissions } from '../permissions/settingsPermissions';

const permissions = {
    clients: clientsPermissions,
    users: usersPermissions,
    projects: projectsPermissions,
    clauses: clausesPermissions,
    kpis: kpisPermissions,
    masterPlan: masterPlanPermissions,
    settings: settingsPermissions
};

const permissionsService = (module2, role, action) => {
    if (permissions[module2] && permissions[module2][role] && permissions[module2][role].includes(action)) {
        return true
    }

    return false
}

export default permissionsService