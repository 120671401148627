import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    projectStyle: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        paddingRight: '1em'
    }, dialogTitle: {
        paddingRight: '0px',
        textAlign: 'center',
        fontSize: '1.5em',
        color: '#99B4D1',
        fontWeight: 'bold'
    },
    selectedItem: {
        color: '#a7bcd5 !important',
        '&.Mui-selected': {
            backgroundColor: '#1976D2 !important',
            color: 'white !important',
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            '& .MuiListItemIcon-root': {
                color: 'white !important',
            },
        },
    },
    icon: {
        color: '#a7bcd5 !important'

    },
    cell: {
        fontSize: '16px !important',
        fontWeight: 'bolder !important',
        color: 'rgb(97,101,105) !important',
    }

}));

export default useStyles