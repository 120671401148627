import * as Yup from "yup";
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import TopBar from "../../../components/topbar/topbar";
import { Delete, FilterAlt, Group, MoreVert, Save } from "@mui/icons-material";
import FormUsers from "./formUsers";
import useStyles from "./styles";
import { Formik } from "formik";
import { Tabs, Tab, DialogActions, Select, Grid, Typography, Divider, Dialog, DialogTitle, DialogContent, FormControl, ListItem, InputLabel, ListItemAvatar, Button, Tooltip, ListItemButton, ListItemIcon, Avatar, ListItemText, IconButton, Menu, MenuItem, List, useMediaQuery, useTheme, Container, Box, TextField, LinearProgress, DialogContentText } from '@mui/material';
import moment from 'moment';
import countries from "src/utils/countries";
import EmptyIcon from "../../../../src/assets/emptyBox.png"
import { InputAdornment } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import apiUserService from "src/services/usersService";
import { flags } from "src/utils/flags";
import apiProjectsService from "src/services/projectsService";
import { useSelector } from "react-redux";
import permissionsService from "src/services/permissionsService";

const listItems = [
    { id: 0, text: 'Mis usuarios', Icon: Group }
];

const Users = ({ type }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const Project = useSelector((state) => state?.setUserLogin?.project);
    const User = useSelector((state) => state?.setUserLogin?.user);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [dataUser, setDataUser] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [isEdit, setEdit] = useState(false)
    const [roles, setRoles] = useState([])
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [toggleDialog, setToggleDialog] = useState(false);
    const classes = useStyles()


    const handleToggleDelete = () => {
        setDeleteDialog(!deleteDialog)
    }



    const confirmDeleteDialog = (
        <Dialog
            open={deleteDialog}
            onClose={handleToggleDelete}
            aria-labelledby="alert-delete-dialog-title"
            aria-describedby="alert-delete-dialog-description"
        >
            <DialogTitle id="alert-delete-dialog-title">
                {"¿Estás seguro de eliminar el usuario?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-delete-dialog-description">
                    Si das click en borrar, se eliminará el acceso y toda la información del usuario:
                    <b>{selectedUser.fullname}</b>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleToggleDelete()} >Cancelar</Button>
                <LoadingButton size="small" loading={loading} variant="contained" loadingPosition="end" endIcon={<Delete />} autoFocus onClick={() => deleteUser()} >
                    <span>Eliminar</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )



    const deleteUser = () => {
        setLoading(true)
        apiUserService.delete(selectedUser).then((res) => {
            if (res.status === 200) {
                let indiceAEliminar = dataUser.findIndex(objeto => objeto.id === selectedUser.id);
                if (indiceAEliminar !== -1) {
                    dataUser.splice(indiceAEliminar, 1);
                }
                enqueueSnackbar('Usuario eliminado con éxito', { variant: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                setLoading(false)
                handleToggleDelete()
            } else {
                setLoading(false)

                enqueueSnackbar('Ha ocurrido un error al eliminar el usuario', { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
        }).catch((err) => {
            setLoading(false)
            enqueueSnackbar(`Ha ocurrido un error al eliminar el usuario ${err.message}`, { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        })
    }




    useEffect(() => {
        if (type !== "component") {
            fetchUsers();
        } else {
            fetchUsersByProject()
        }
    }, [isEdit]);

    const fetchUsers = async () => {
        try {
            setLoading(true)
            await apiUserService.get().then((data) => {
                const usersData = data.data;
                usersData.sort((a, b) => new Date(a.expeditionDate) - new Date(b.expeditionDate));
                setDataUser(usersData);
            });
            setLoading(false)
        } catch (error) {
            enqueueSnackbar('Error al obtener los usuarios', { variant: 'error' })
        }
    };

    const fetchUsersByProject = async () => {
        try {
            setLoading(true)
            await apiProjectsService.getUsers(Project.id).then((data) => {
                setDataUser(data.data);
            });
            setLoading(false)
        } catch (error) {
            enqueueSnackbar('Error al obtener los usuarios', { variant: 'error' })
        }
    };


    const getRoles = async () => {
        try {
            apiUserService.getRoles(User.id).then((data) => {
                setRoles(data.data);
            });
        } catch (error) {
            enqueueSnackbar('Error al obtener los roles', { variant: 'error' })
        }
    };

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const usersSidebar = (
        <Box width={'100%'}>
            <List component="nav" aria-label="main mailbox folders" style={{
                width: "100%",
                position: "sticky",
                top: "140px",
                height: "fit-content"
            }} >
                {listItems.map((item, index) => (
                    <ListItemButton
                        key={item.id}
                        classes={{ root: classes.selectedItem }}
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}
                    >
                        <ListItemIcon className={classes.icon}>
                            <item.Icon />
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    )

    useEffect(() => {
        const originalTitle = document.title;
        document.title = "MGC - Usuarios";
        return () => {
            document.title = originalTitle;
        };
    }, []);



    const handleToggleDialog = () => {
        getRoles();
        setToggleDialog(!toggleDialog);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }


    const usersView = (
        <Container>
            <Box display="flex" justifyContent="space-between" my={1}>
                <Typography variant="body2" color="gray" className="m-2 fw-bold">{dataUser?.length} Usuarios</Typography>

                {/* <Tooltip title="Filtrar">
                    <IconButton
                        aria-label="settings"
                        aria-controls="project-menu"
                        aria-haspopup="true"
                    >
                        <FilterAlt color="primary" />
                    </IconButton>
                </Tooltip> */}
            </Box>

            <List>
                {!loading && dataUser?.length > 0 && dataUser.map((obj, index) => (
                    <CustomerListItem
                        key={index}
                        obj={obj}
                        onEdit={() => {
                            if (roles.length === 0) {
                                getRoles()
                            }
                            setData(obj);
                            setEdit(true);

                        }}
                        onDelete={() => { setSelectedUser(obj); handleToggleDelete() }}
                        isMatch={matches}
                    />
                ))}
                {
                    loading && <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
                {
                    !loading && dataUser?.length === 0 &&
                    <Box textAlign="center">
                        <img src={EmptyIcon} alt="Imagen de no data" width={150} />
                        <Typography variant="h6" color="GrayText">
                            No hay datos disponibles.
                        </Typography>
                    </Box>

                }

            </List>

        </Container>

    )

    const validateDate = (value) => {
        const date = moment(value, 'YYYY-MM-DD', true); // Parsing date using Moment.js
        const age = moment().diff(date, 'years');

        if (!date.isValid()) {
            return false; // Invalid date format
        }

        return age >= 18 && age < 100;
    };

    const dialogNewUser = (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
                email: "",
                role: {
                    label: "Usuario",
                    value: 5
                },
                fullname: "",
                identification: "",
                birthdate: null,
                phone: {
                    country: "EC",
                    value: "",
                    areaCode: "593"
                },
                cellphone: {
                    country: "EC",
                    value: "",
                    areaCode: "593"
                },
                position: "",
                institution: "",
                password: "",
                confirmPassword: ""
            }}


            validationSchema={Yup.object({
                email: Yup.string()
                    .email("Por favor ingresa un correo electrónico válido")
                    .required("El correo electrónico es obligatorio"),
                fullname: Yup.string().required(
                    "El nombre completo es obligatorio"
                ),
                identification: Yup.string().required(
                    "La identificación es obligatoria"
                ),
                birthdate: Yup.date()
                    .max(new Date(), "La fecha de nacimiento no puede estar en el futuro")
                    .test('valid-date', 'Fecha inválida, debes tener entre 18 y 100 años', (value) => validateDate(value))
                    .required("La fecha de nacimiento es obligatoria"),
                phone: Yup.object().shape({
                    country: Yup.string().required("El país del teléfono es obligatorio"),
                    value: Yup.number()
                        .typeError("Solo se admiten números")
                        .required("El número de teléfono es obligatorio")
                }),
                cellphone: Yup.object().shape({
                    country: Yup.string().required("El país del celular es obligatorio"),
                    value: Yup.number()
                        .typeError("Solo se admiten números")
                        .required("El número de celular es obligatorio")
                }),
                charge: Yup.string().required(
                    "El cargo es obligatorio"
                ),
                institution: Yup.string().required(
                    "La empresa es obligatoria"
                ),
                password: Yup.string()
                    .matches(/^(?=.*[a-zA-Z])(?=.*\d).{8,}$/, "La contraseña debe tener al menos 8 caracteres compuestos por números y letras")
                    .required("La contraseña es obligatoria"),
                confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
                    .required('La confirmación de la contraseña es obligatoria')
            })}

            onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(true)
                if(type === 'component'){
                    values.sourceProject = Project;
                }
                apiUserService.postData(values)
                    .then((res) => {
                        const { data, message } = res.data
                        dataUser.push(data)
                        enqueueSnackbar(message, { variant: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        setSubmitting(false)
                        handleToggleDialog()
                    }
                    )
                    .catch((res) => {
                        const { message, details } = res.response.data
                        const errorMessage = details ? `${message} ${details}` : message;
                        enqueueSnackbar(errorMessage, { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        setSubmitting(false);
                    })

            }}


        >
            {
                ({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    isValid,
                    touched,
                    values
                }) => (

                    <Dialog open={toggleDialog} onClose={handleToggleDialog} maxWidth="md" fullWidth>
                        <form onSubmit={handleSubmit}>

                            <DialogTitle className={classes.dialogTitle}>Nuevo Usuario</DialogTitle>

                            <DialogContent>
                                <Box
                                    sx={{ backgroundColor: "#EEF0F2", padding: "1em" }}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Tabs
                                        indicatorColor="transparent"
                                        value={tabValue}
                                        onChange={handleTabChange}
                                        aria-label="project tabs"
                                        variant="scrollable"
                                        scrollButtons
                                        allowScrollButtonsMobile
                                    >
                                        <Tab
                                            className={classes.tabStyle}
                                            label="Invitar Nuevo"
                                            {...a11yProps(0)}
                                        />
                                        <Tab
                                            className={classes.tabStyle}
                                            label="Información General"
                                            {...a11yProps(1)}
                                        />
                                    </Tabs>
                                </Box>

                                <CustomTabPanel value={tabValue} index={0}>
                                    <Grid container spacing={2} className="mb-3">
                                        <Grid item sm={12} md={6}>
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel shrink id="emailLabel" >Correo electrónico: *</InputLabel>
                                                <TextField
                                                    margin="dense"
                                                    id="email"
                                                    name="email"
                                                    className="pt-3"
                                                    fullWidth
                                                    variant="standard"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.email && errors.email)}
                                                    helperText={touched.email && errors.email}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12}>
                                            <FormControl fullWidth margin="dense" variant="outlined" required>
                                                <InputLabel shrink id="role-label">Rol:</InputLabel>
                                                <Select
                                                    labelId="role-label"
                                                    id="role"
                                                    name="role"
                                                    value={values.role.value} // Utiliza directamente el valor de `values.role`
                                                    onChange={(e) => {
                                                        const selectedRole = roles.find(role => role.value === e.target.value);
                                                        setFieldValue("role", selectedRole);
                                                    }}
                                                    required
                                                    variant="standard"
                                                >
                                                    {roles.map((role) => (
                                                        <MenuItem key={role.value} value={role.value}>
                                                            <ListItemText primary={role.label} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Grid>

                                    </Grid>
                                </CustomTabPanel>

                                <CustomTabPanel value={tabValue} index={1}>
                                    <Grid container spacing={2} className="mb-3">
                                        <Grid item sm={12}>
                                            <FormControl fullWidth margin="dense" className="mb-3">
                                                <InputLabel shrink id="fullnameLabel">Nombre Completo: *</InputLabel>
                                                <TextField
                                                    margin="dense"
                                                    id="fullname"
                                                    name="fullname"
                                                    fullWidth
                                                    variant="standard"
                                                    value={values.fullname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.fullname && errors.fullname)}
                                                    helperText={touched.fullname && errors.fullname}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} lg={6}>
                                            <FormControl fullWidth margin="dense" className="mb-3">
                                                <InputLabel shrink id="identification-label">No. Identificación: </InputLabel>
                                                <TextField
                                                    margin="dense"
                                                    id="identification"
                                                    name="identification"
                                                    value={values.identification}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.identification && errors.identification)}
                                                    helperText={touched.identification && errors.identification}
                                                    fullWidth
                                                    variant="standard"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={12} lg={6}>
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel shrink id="birthdate-label">Fecha de nacimiento: </InputLabel>
                                                <TextField
                                                    id="birthdate"
                                                    name="birthdate"
                                                    type="date"
                                                    className="w-100 pt-2"
                                                    value={values.birthdate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.birthdate && errors.birthdate)}
                                                    helperText={touched.birthdate && errors.birthdate}
                                                    variant="standard"

                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={12} lg={6}>
                                            <FormControl fullWidth margin="dense" required>
                                                <InputLabel shrink id="phone-country-label" className="mb-4">Teléfono</InputLabel>
                                                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '16px' }}>
                                                    <FormControl variant="standard" style={{ width: '5em' }}>
                                                        <Select
                                                            labelId="phone-country"
                                                            id="phone.country"
                                                            value={values.phone}
                                                            onChange={(e) => {
                                                                const selectedCountry = countries.find(country => country.code === e.target.value);
                                                                setFieldValue("phone.country", e.target.value);
                                                                setFieldValue("phone.areaCode", selectedCountry.areaCode);

                                                            }}
                                                            renderValue={(selected) => (
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={flags(selected.country, 20)}
                                                                        srcSet={flags(selected.country, 40)}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            )}

                                                        >
                                                            {countries.map((country) => (
                                                                <MenuItem key={country.code} value={country.code}>
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={flags(country.code, 20)}
                                                                        srcSet={flags(country.code, 40)}
                                                                        alt={country.name}
                                                                        style={{ marginRight: '10px' }}
                                                                    />
                                                                    {country.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <TextField
                                                        id="phone.value"
                                                        name="phone.value"
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">+{values.phone.areaCode}</InputAdornment>,
                                                        }}

                                                        value={values.phone.value}
                                                        onChange={(e) => setFieldValue("phone.value", e.target.value)}
                                                        style={{ flexGrow: 1 }}
                                                        onBlur={handleBlur}
                                                        variant="standard"
                                                        error={Boolean(touched.phone?.value && errors.phone?.value)}
                                                        helperText={touched.phone?.value && errors.phone?.value}
                                                    />
                                                </Box>
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={12} lg={6}>
                                            <FormControl fullWidth margin="dense" required>
                                                <InputLabel shrink id="cellphone-country" className="mb-4">Celular</InputLabel>
                                                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '16px' }}>
                                                    <FormControl variant="standard" style={{ width: '5em' }}>
                                                        <Select
                                                            labelId="cellphone-country"
                                                            id="cellphone.country"
                                                            value={values.cellphone}
                                                            onChange={(e) => {
                                                                const selectedCountry = countries.find(country => country.code === e.target.value);
                                                                setFieldValue("cellphone.country", e.target.value);
                                                                setFieldValue("cellphone.areaCode", selectedCountry.areaCode);

                                                            }}
                                                            renderValue={(selected) => (
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={flags(selected.country, 20)}
                                                                        srcSet={flags(selected.country, 40)}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            )}
                                                        >
                                                            {countries.map((country) => (
                                                                <MenuItem key={country.code} value={country.code}>
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={flags(country.code, 20)}
                                                                        srcSet={flags(country.code, 40)}
                                                                        alt={country.name}
                                                                        style={{ marginRight: '10px' }}
                                                                    />
                                                                    {country.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <TextField
                                                        id="cellphone.value"
                                                        name="cellphone.value"
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">+{values.cellphone.areaCode}</InputAdornment>,
                                                        }}
                                                        value={values.cellphone.value}
                                                        onChange={(e) => setFieldValue("cellphone.value", e.target.value)}
                                                        style={{ flexGrow: 1 }}
                                                        onBlur={handleBlur}
                                                        variant="standard"
                                                        error={Boolean(touched.cellphone?.value && errors.cellphone?.value)}
                                                        helperText={touched.cellphone?.value && errors.cellphone?.value}

                                                    />
                                                </Box>
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={12} lg={6}>
                                            <FormControl fullWidth margin="dense" required>
                                                <InputLabel shrink id="charge">Cargo: </InputLabel>
                                                <TextField
                                                    margin="dense"
                                                    id="charge"
                                                    name="charge"
                                                    value={values.charge}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    variant="standard"
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.charge && errors.charge)}
                                                    helperText={touched.charge && errors.charge}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={12} lg={6}>
                                            <FormControl fullWidth margin="dense" required>
                                                <InputLabel shrink id="institution">Empresa: </InputLabel>
                                                <TextField
                                                    margin="dense"
                                                    id="institution"
                                                    name="institution"
                                                    value={values.institution}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    variant="standard"
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.institution && errors.institution)}
                                                    helperText={touched.institution && errors.institution}
                                                />
                                            </FormControl>
                                        </Grid>


                                        <Grid item sm={12} lg={6}>
                                            <FormControl fullWidth margin="dense" required>
                                                <InputLabel shrink id="institution">Contraseña: </InputLabel>
                                                <TextField
                                                    margin="dense"
                                                    id="password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    type="password"
                                                    fullWidth
                                                    variant="standard"
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.password && errors.password)}
                                                    helperText={touched.password && errors.password}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={12} lg={6}>
                                            <FormControl fullWidth margin="dense" required>
                                                <InputLabel shrink id="institution">Confirmar Contraseña: </InputLabel>
                                                <TextField
                                                    margin="dense"
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    type="password"
                                                    fullWidth
                                                    variant="standard"
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                                />
                                            </FormControl>
                                        </Grid>


                                    </Grid>
                                </CustomTabPanel>
                            </DialogContent>
                            <DialogActions className="mb-3">
                                <Grid container spacing={2}>
                                    <Grid item sm={6}>
                                        <Typography variant="caption" component="ul">
                                            <li>Los campos marcados con * son obligatorios</li>
                                            <li>Recuerda llenar la pestaña de <b>Información General</b></li>
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={6} className="text-end">
                                        <Button variant="contained" className='rounded-4 me-3' onClick={handleToggleDialog}>Cerrar</Button>
                                        <LoadingButton size="medium" type="submit" variant="contained"
                                            loading={isSubmitting}
                                            disabled={!isValid}
                                            endIcon={<Save />} className='rounded-4 me-3' loadingPosition="end"><span>Crear nuevo</span></LoadingButton>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </form>
                    </Dialog>

                )
            }
        </Formik>
    )


    function CustomerListItem({ obj, onEdit, onDelete, isMatch }) {
        const [anchorEl, setAnchorEl] = useState(null);

        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <ListItem className="shadow-sm p-4">
                <ListItemAvatar>
                    <Avatar src="/broken-image.jpg" />
                </ListItemAvatar>
                {!isMatch ? (
                    <ListItemText
                        primary={obj?.fullname}
                        primaryTypographyProps={{ color: '#808389', fontWeight: '500' }}
                        secondary={
                            <>
                                <Typography component="span" variant="body2" color="text.primary">
                                    {obj?.location}
                                </Typography>
                                <br />
                                <Typography component="span" variant="caption" color="text.primary">
                                    {obj?.email}
                                </Typography>
                            </>
                        }
                    />
                ) : (
                    <>
                        <ListItemText
                            primary={
                                <>
                                    <Typography component="span" variant="body2" color="text.primary">
                                        {obj?.fullname}
                                    </Typography>
                                    <br />
                                    <Typography component="span" variant="caption" color="text.primary">
                                        Compañía: {obj?.institution}
                                    </Typography>
                                </>
                            }
                            primaryTypographyProps={{ color: '#808389', fontWeight: '500' }}
                        />
                        <ListItemText
                            secondary={<>
                                <Typography component="span" variant="caption" color="text.primary">
                                    Correo: {obj?.email}
                                </Typography>
                                <br />
                                <Typography component="span" variant="caption" color="text.primary">
                                    Celular: {obj?.cellphone?.value}
                                </Typography>
                            </>}
                            secondaryTypographyProps={{ fontSize: '13px' }}
                        />
                        <ListItemText
                            secondary={<>
                                <Typography component="span" variant="caption" color="text.primary">
                                    Teléfono: {obj?.phone?.value}
                                </Typography>
                                <br />
                               {type !== 'component' ? <Typography component="span" variant="caption" color="text.primary">
                                    ROL: {obj?.role?.label}
                                </Typography>: <Typography component="span" variant="caption" color="text.primary">
                                    ROL: {obj?.projects.find((obj) => obj?.project?.id === Project.id)?.role?.label}
                                </Typography>}
                            </>}
                            secondaryTypographyProps={{ fontSize: '13px' }}
                        />
                    </>
                )}
                {(permissionsService("users", User.role.label, "edit") || permissionsService("users", User.role.label, "delete") || (type === "component" && permissionsService("projects", User.role.label, "edit_users") )  ) && <><IconButton aria-label="settings" onClick={handleClick}>
                    <MoreVert color="primary" />
                </IconButton><Menu
                    id="customer-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                        {type !== "component" ? permissionsService("users", User.role.label, "edit") && <MenuItem onClick={onEdit}>Editar</MenuItem> : permissionsService("projects", User.role.label, "edit_users") && <MenuItem onClick={onEdit}>Editar</MenuItem>}
                        {permissionsService("users", User.role.label, "delete") && type !== "component" && <MenuItem onClick={onDelete}>Eliminar</MenuItem>}

                    </Menu></>}
            </ListItem>
        );
    }

    return (

        <div>
            {type !== 'component' && <TopBar />}

            <Grid container >
                {
                    !isEdit && <><Grid item sm={12} className={classes.stickyHeader} top={60}>
                        <Box display="flex" justifyContent="space-between" >
                            {type !== 'component' && <Typography variant="h4" component="h1" gutterBottom style={{ color: '#1976D2', margin: '0.5em' }}>
                                Usuarios
                            </Typography>}
                           {permissionsService("settings", User.role.label, "add") && <Box display="flex" alignItems="center" justifyContent="end" margin="auto" width="100%" >
                                <Button onClick={() => handleToggleDialog()} className="me-4" variant="contained">Crear usuario</Button>
                            </Box>}

                        </Box>
                    </Grid>

                      {type !== 'component' && <><Grid item lg={2} md={3} sm={4} xs={12} className="d-flex">
                            {usersSidebar}
                            <Divider orientation="vertical" />
                        </Grid><Divider className="mb-1" /></>}
                </>}

                {!isEdit &&
                    <Grid item lg={type !== 'component' ? 10 : 12} md={9} sm={8} xs={12}>
                        {usersView}
                    </Grid>}

                {isEdit && <FormUsers key={data.id} data={data} setEdit={setEdit} isEdit={true} roles={roles} />}

                {toggleDialog && dialogNewUser}
                {/*                 {errorConnection && netWorkConnection}
 */}            {selectedUser && confirmDeleteDialog}
            </Grid>

        </div>
    )
}

export default Users;
