import { Delete, Edit, FilterAlt, Visibility, Save } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  Divider,
  TablePagination,
  Box,
  DialogContentText,
  Chip,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  LinearProgress,
  TableBody,
} from "@mui/material";
import moment from "moment";
import { fileDateToDate } from "src/utils/timeLocal";
import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import useStyles from "../styles";
import { Formik } from "formik";
import FormKPI from "./formKPI";
import KPISInfo from "./infoKPI";
import apiKPIService from "src/services/kpisService";
import Autocomplete from "@mui/material/Autocomplete";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { LoadingButton } from "@mui/lab";
import apiUserService from "src/services/usersService";
import EmptyIcon from "src/assets/emptyBox.png";
const KPISView = ({ kpis }) => {
  const Project = useSelector((state) => state?.setUserLogin?.project?.id);
  const [toggleDialog, setToggleDialog] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [KPIFrequency, setKPIFrequency] = useState([]);
  const [Users, setUsers] = useState([]);
  const [KPIType, setKPIType] = useState([]);
  const [KPIDeviation, setKPIDeviation] = useState([]);
  const [allKPIs, setAllKPIs] = useState([]);
  const [selectKPI, setSelectKPI] = useState([]);
  const [view, setView] = useState(false);
  const [loadingKPI, setLoadingKPI] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();
  const columns = [
    { id: "name", label: "Nombre" },
    { id: "projectStart", label: "Inicio KPI" },
    { id: "responsible", label: "Responsable" },
    { id: "frequency", label: "Frecuencia" },
    { id: "closingPeriod", label: "Periodo de cierre" },
    { id: "actions", label: "Acciones" },
  ];
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleToggleDialog = () => {
    setToggleDialog(!toggleDialog);
  };

  const SaveNewPKI = (values) => {
    setLoadingKPI(true);
    try {
      apiKPIService.postCreate(values).then(() => {
        enqueueSnackbar("KPI creada con exito", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        allProjectKPIs();
        handleToggleDialog(false);
        setLoadingKPI(false);
      });
    } catch (error) {
      setLoadingKPI(false);
      handleToggleDialog(false);
      enqueueSnackbar("Error al crear KPI", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchKPI = async () => {
      try {
        const response = await apiKPIService.get();
        setKPIFrequency(response.data?.frequency || []);
        setKPIType(response.data?.type || []);
        setKPIDeviation(response.data?.deviation || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchKPI();
  }, []);

  useEffect(() => {
    allProjectKPIs();
  }, [loadingKPI, update, selectKPI, view]);

  const allProjectKPIs = async () => {
    setLoading(true);
    try {
      const response = await apiKPIService.postByProject({
        project: Project,
      });
      setAllKPIs(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      apiUserService.get().then((data) => {
        setUsers(data.data);
      });
    } catch (error) {
      enqueueSnackbar("Error al obtener los usuarios", { variant: "error" });
    }
  };

  const handleToggleDelete = () => {
    setDeleteDialog(!deleteDialog);
  };
  const KPIDialog = (
    <Dialog
      open={toggleDialog}
      onClose={handleToggleDialog}
      aria-labelledby="nuevo-kpi-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>Nuevo KPI</DialogTitle>
      <Divider variant="middle" orientation="horizontal" />
      <DialogContent>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            name: "",
            type: "",
            frequency: "",
            responsible: "",
            assignment: [],
            unit: "",
            closingPeriod: "",
            typeDeviation: "",
            startDate: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("El nombre es requerido"),
            type: Yup.string().required("El tipo es requerido"),
            frequency: Yup.string().required("La frecuencia es requerida"),
            responsible: Yup.string().required("El responsable es requerido"),
            assignment: Yup.array().required("El campo es requerido"),
            typeDeviation: Yup.string().required(
              "El tipo de desviación es requerido"
            ),
            startDate: Yup.string().required("La fecha de inicio es requerido"),
            unit: Yup.string().required("La unidad de medida es requerida"),
            closingPeriod: Yup.string().required(
              "El Periodo de cierre requerido"
            ),
          })}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            values.project = Project;
            SaveNewPKI(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} rowSpacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="name"
                    label="Nombre de cuadro de KPI: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    name="name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    className="border-0"
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                    onBlur={handleBlur}
                  >
                    <InputLabel shrink htmlFor="type">
                      Tipo:
                    </InputLabel>
                    <Select
                      id="type"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                    >
                      {KPIType.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                    {touched.type && errors.type ? (
                      <FormHelperText>{errors.type}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    className="border-0"
                    error={Boolean(touched.frequency && errors.frequency)}
                    helperText={touched.frequency && errors.frequency}
                  >
                    <InputLabel shrink htmlFor="frequency">
                      Frecuencia:
                    </InputLabel>
                    <Select
                      id="frequency"
                      name="frequency"
                      value={values.frequency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {KPIFrequency.map((frequency) => (
                        <MenuItem value={frequency}>{frequency}</MenuItem>
                      ))}
                    </Select>
                    {touched.frequency && errors.frequency ? (
                      <FormHelperText>{errors.frequency}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    className="border-0"
                    error={Boolean(touched.responsible && errors.responsible)}
                    helperText={touched.responsible && errors.responsible}
                  >
                    <InputLabel shrink htmlFor="responsible">
                      Responsable:{" "}
                    </InputLabel>
                    <br />
                    <Autocomplete
                      options={Users}
                      id="responsible"
                      name="responsible"
                      size="small"
                      noOptionsText="sin opciones"
                      onBlur={handleBlur}
                      onChange={(e, user) => {
                        if (user) {
                          const { id } = user;
                          values.responsible = id;
                        } else {
                          values.responsible = "";
                        }
                      }}
                      getOptionLabel={(user) => user.fullname}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          size="small"
                          fullWidth
                          error={
                            touched.responsible && Boolean(errors.responsible)
                          }
                          helperText={touched.responsible && errors.responsible}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    className="border-0"
                    error={Boolean(touched.assignment && errors.assignment)}
                    helperText={touched.assignment && errors.assignment}
                  >
                    <InputLabel shrink htmlFor="assignment">
                      Asignación:{" "}
                    </InputLabel>
                    <Select
                      id="assignment"
                      name="assignment"
                      value={values.assignment || []}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiple
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected?.map((value) => (
                            <Chip label={value.fullname} />
                          ))}
                        </Box>
                      )}
                    >
                      {Users.map((user) => (
                        <MenuItem value={user}>{user.fullname}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="unit"
                    label="Unidad de medida: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    name="unit"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.unit}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.unit && errors.unit)}
                    helperText={touched.unit && errors.unit}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="closingPeriod"
                    label="Periodo de cierre: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="number"
                    name="closingPeriod"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.closingPeriod}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.closingPeriod && errors.closingPeriod
                    )}
                    helperText={touched.closingPeriod && errors.closingPeriod}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    className="border-0"
                    error={Boolean(
                      touched.closingPeriod && errors.closingPeriod
                    )}
                    helperText={touched.closingPeriod && errors.closingPeriod}
                  >
                    <InputLabel shrink htmlFor="typeDeviation">
                      Tipo de Desviación:{" "}
                    </InputLabel>
                    <Select
                      id="typeDeviation"
                      name="typeDeviation"
                      value={values.typeDeviation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {KPIDeviation.map((deviation) => (
                        <MenuItem value={deviation}>{deviation}</MenuItem>
                      ))}
                    </Select>
                    {touched.closingPeriod && errors.closingPeriod ? (
                      <FormHelperText>{errors.closingPeriod}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="startDate"
                    label="Fecha de inicio: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="date"
                    name="startDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.startDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                </Grid>
              </Grid>
              <Divider variant="middle" orientation="horizontal" />
              <Grid item sm={6}>
                <Typography variant="caption">
                  Los campos marcados con{" "}
                  <span className="text-danger"> * </span> son obligatorios
                </Typography>
              </Grid>

              <Grid
                container={2}
                item
                className="text-end"
                spacing={2}
                rowSpacing={3}
              >
                <Grid
                  item
                  sm={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    className="rounded-4 me-2"
                    onClick={handleToggleDialog}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    startIcon={<Save />}
                    color="primary"
                    variant="contained"
                    type="submit"
                    autoFocus
                    className="rounded-4 me-2"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    Crear nuevo
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );

  const confirmDeleteDialog = (
    <>
      <Dialog
        open={deleteDialog}
        onClose={handleToggleDelete}
        aria-labelledby="alert-delete-dialog-title"
        aria-describedby="alert-delete-dialog-description"
      >
        <DialogTitle id="alert-delete-dialog-title">
          {"¿Estás seguro de eliminar el KPI?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-dialog-description">
            Si das click en borrar, se eliminará y toda su configuración
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleToggleDelete()}>Cancelar</Button>
          <LoadingButton
            size="small"
            loading={loading}
            variant="contained"
            loadingPosition="end"
            endIcon={<Delete />}
            autoFocus
            onClick={() => {
              setLoadingKPI(true);
              apiKPIService.delete(Project, selectKPI.id).then(() => {
                enqueueSnackbar("KPI eliminado con exito", {
                  variant: "success",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
                handleToggleDelete();
                setLoadingKPI(false);
              });
            }}
          >
            <span>Eliminar</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );

  return (
    <Container>
      {!update && !view && (
        <>
          {!loading ? (
            <>
              <Grid container>
                <Grid item sm={12} className={classes.projectStyle}>
                  <Button
                    onClick={() => handleToggleDialog()}
                    className="mb-2"
                    variant="contained"
                  >
                    Crear KPI
                  </Button>
                </Grid>
              </Grid>
              <Divider className="mb-3" />
              <Grid container={2} className="mt-3">
                <Container maxWidth="lg">
                  <div className="d-flex justify-content-between">
                    <Typography
                      variant="body2"
                      color="rgb(138,142,146)"
                      className="mb-2 fw-bold"
                    >
                      {allKPIs?.length || 0} KPI's
                    </Typography>
                    <Divider />

                    <Box display="inline-flex">
                      <IconButton
                        aria-label="settings"
                        aria-controls="project-menu"
                        aria-haspopup="true"
                      >
                        <FilterAlt color="primary" />
                      </IconButton>
                    </Box>
                  </div>

                  {allKPIs.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell className={classes.cell}>
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allKPIs.map((row) => (
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {row.name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {moment(fileDateToDate(row.startDate)).format(
                                    "YYYY-MM-DD"
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {Users.find(
                                    (snp) => snp.id === row.responsible
                                  )?.fullname || row.responsible}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {row.frequency}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {moment(fileDateToDate(row.deadline)).format(
                                    "YYYY-MM-DD"
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Tooltip title="Actualizar">
                                  <IconButton
                                    style={{ background: "none" }}
                                    aria-label="edit"
                                    color="primary"
                                    onClick={() => {
                                      setUpdate(true);
                                      setSelectKPI(row);
                                    }}
                                  >
                                    <Edit color="primary" fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Ver detalles">
                                  <IconButton
                                    style={{ background: "none" }}
                                    aria-label="edit"
                                    color="primary"
                                    onClick={() => {
                                      setView(true);
                                      setSelectKPI(row);
                                    }}
                                  >
                                    <Visibility
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Eliminar KPI">
                                  <IconButton
                                    style={{ background: "none" }}
                                    aria-label="edit"
                                    color="primary"
                                    onClick={() => {
                                      setSelectKPI(row);
                                      handleToggleDelete();
                                    }}
                                  >
                                    <DeleteForeverIcon
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        labelRowsPerPage="Filas por página"
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allKPIs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableContainer>
                  ) : (
                    <Box textAlign="center">
                      <img
                        src={EmptyIcon}
                        alt="Imagen de no data"
                        width={150}
                      />
                      <Typography variant="h6" color="GrayText">
                        No hay datos disponibles.
                      </Typography>
                    </Box>
                  )}
                </Container>
              </Grid>
            </>
          ) : (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </>
      )}

      {update && (
        <FormKPI
          kpis={selectKPI}
          isUpdate={true}
          setUpdate={setUpdate}
          KPIDeviation={KPIDeviation}
          KPIFrequency={KPIFrequency}
          KPIType={KPIType}
          Users={Users}
        />
      )}
      {view && <KPISInfo kpis={selectKPI} isView={true} setView={setView} />}

      {KPIDialog}
      {confirmDeleteDialog}
    </Container>
  );
};

export default KPISView;
