import axiosInstance from "src/utils/axios";

const api = "/api/customers";

const apiCustomersService = {
  postCreate: (data) => {
    return axiosInstance.post(api + "/", data);
  },
  postUpdate: (data) => {
    return axiosInstance.post(api + "/update", data);
  },

  get: () => {
    return axiosInstance.get(api);
  },

  delete: (id) => {
    return axiosInstance.delete(api + "/" + id);
  },
};

export default apiCustomersService;
