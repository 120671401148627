import { useEffect, useState } from "react";
import { FilterAlt } from "@mui/icons-material";
import {
  Paper,
  DialogActions,
  Dialog,
  Typography,
  Button,
  DialogContent,
  InputLabel,
  DialogTitle,
  IconButton,
  TableRow,
  MenuItem,
  Table,
  Select,
  Grid,
  Divider,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  TableHead,
  TablePagination,
  FormControl,
  Input,
  Tooltip,
  Container,
} from "@mui/material";
import useStyles from "./styles";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import esLocale from "date-fns/locale/es";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
const Trades = () => {
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogHistory, setOpenDialogHistory] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();
  const rows = [
    {
      tradesNumber: "EC001",
      topic: "Entrega de orden de Inicio del Proyecto Prueba 1",
      delayTime: "12/Oct/2021 02:21:24 pm",
      state: "Abierto",
      documents: "ver",
      historic: "ver",
    },
    {
      tradesNumber: "EC002",
      topic: "Prueba de Oficio General",
      delayTime: "12/Oct/2021 02:50:16 pm",
      state: "Abierto",
      documents: "ver",
      historic: "ver",
    },
    {
      tradesNumber: "001",
      topic: "Prueba de aprobación",
      delayTime: "12/Oct/2021 06:36:59 pm",
      state: "Abierto",
      documents: "ver",
      historic: "ver",
    },
    {
      tradesNumber: "005",
      topic: "Prueba de aprobación 25",
      delayTime: "10/Nov/2021 04:28:31 pm",
      state: "Abierto",
      documents: "ver",
      historic: "ver",
    },
  ];
  const rows1 = [
    {
      tradesNumber: "EC001",
      topic: "Entrega de orden de Inicio del Proyecto Prueba 1",
      delayTime: "12/Oct/2021 02:21:24 pm",
      deadline: "-",
      state: "Abierto",
      documents: "ver",
    },
  ];
  const handleCloseDialog = () => {
    setOpenDialogCreate(false);
    setOpenDialogHistory(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Oficios";
    return () => {
      document.title = originalTitle;
    };
  }, []);
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  return (
    <>
      {/* Dialogo de para crear correspondencia*/}
      <Dialog
        open={openDialogCreate}
        onClose={handleCloseDialog}
        maxWidth="100%"
      >
        <DialogTitle className={classes.dialogTitle}>
          Correspondencia
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container={3}>
            <Grid item sm={12} md={12}>
              <FormControl
                variant="standard"
                fullWidth
                margin="dense"
                className="border-0"
              >
                <InputLabel shrink id="client-name-clauses-label">
                  Emisor: <span className="text-danger"> * </span>
                </InputLabel>
                <Input
                  margin="dense"
                  id="clauses-scope"
                  label="Nombre de la cláusula"
                  type="text"
                  fullWidth
                  required
                  variant="standard"
                />
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl
                variant="standard"
                fullWidth
                margin="dense"
                className="border-0"
              >
                <InputLabel shrink id="client-number-clauses-label">
                  Dirigido a: <span className="text-danger"> * </span>
                </InputLabel>
                <Input
                  margin="dense"
                  id="clauses-scope"
                  label="Nombre de la cláusula"
                  type="text"
                  fullWidth
                  required
                  variant="standard"
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} md={12}>
              <FormControl
                variant="standard"
                fullWidth
                margin="dense"
                className="border-0"
              >
                <InputLabel shrink id="client-name-clauses-label">
                  Notificar a: <span className="text-danger"> * </span>
                </InputLabel>
                <Input
                  margin="dense"
                  id="clauses-scope"
                  label="Grupo perteneciente"
                  type="text"
                  fullWidth
                  required
                  variant="standard"
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} md={12}>
              <FormControl
                variant="standard"
                fullWidth
                margin="dense"
                className="border-0"
              >
                <InputLabel shrink id="client-name-clauses-label">
                  Nombre del Oficio: <span className="text-danger"> * </span>
                </InputLabel>
                <Input
                  margin="dense"
                  id="clauses-scope"
                  label="Grupo perteneciente"
                  type="text"
                  fullWidth
                  required
                  variant="standard"
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} md={4}>
              <FormControl
                variant="standard"
                fullWidth
                margin="dense"
                className="border-0"
              >
                <InputLabel shrink id="client-number-clauses-label">
                  Fecha: <span className="text-danger"> * </span>
                </InputLabel>
                <br />

                <Input
                  label="Fecha"
                  id="fecha"
                  name="fecha"
                  type="date"
                  className="w-100"
                  inputFormat="dd/MM/yyyy"
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} md={1}></Grid>
            <Grid item sm={12} md={3}>
              <FormControl
                variant="standard"
                fullWidth
                margin="dense"
                className="border-0"
              >
                <InputLabel shrink id="client-name-clauses-label">
                  Número de Oficio: <span className="text-danger"> * </span>
                </InputLabel>
                <Input
                  margin="dense"
                  id="clauses-scope"
                  label="Grupo perteneciente"
                  type="text"
                  fullWidth
                  required
                  variant="standard"
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} md={1} />
            <Grid item sm={12} md={3}>
              <InputLabel shrink id="client-name-clauses-label">
                Palabra clave(s): <span className="text-danger"> * </span>
              </InputLabel>
              <Input
                margin="dense"
                id="clauses-scope"
                label="Grupo perteneciente"
                type="text"
                fullWidth
                required
                variant="standard"
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <FormControl fullWidth margin="dense">
                <InputLabel shrink id="client-number-clauses-label">
                  Cláusula:
                </InputLabel>
                <Select
                  labelId="respuesta-cluases-label"
                  id="respuesta-cluases-label"
                  label="Criticidad"
                  required
                  variant="standard"
                >
                  <MenuItem value={10}>Datos prueba</MenuItem>
                  <MenuItem value={20}>Datos prueba</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={1} />
            <Grid item sm={12} md={3}>
              <FormControl fullWidth margin="dense">
                <InputLabel shrink id="client-number-clauses-label">
                  Respuesta: <span className="text-danger"> * </span>
                </InputLabel>
                <Select
                  labelId="respuesta-cluases-label"
                  id="respuesta-cluases-label"
                  label="Criticidad"
                  required
                  variant="standard"
                >
                  <MenuItem value={10}>Datos prueba</MenuItem>
                  <MenuItem value={20}>Datos prueba</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={1} />
            <Grid item sm={12} md={3}>
              <InputLabel shrink id="client-name-clauses-label">
                Documento: <span className="text-danger"> * </span>
              </InputLabel>
              <Button>Ver</Button>
            </Grid>
            <Grid item sm={12} md={12}>
              <InputLabel shrink id="client-name-clauses-label">
                Respuesta a oficio: <span className="text-danger"> * </span>
              </InputLabel>
              <Input
                margin="dense"
                id="clauses-scope"
                label="Grupo perteneciente"
                type="text"
                fullWidth
                required
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions className="mb-3">
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography variant="caption">
                Los campos marcados con <span className="text-danger"> * </span>{" "}
                son obligatorios
              </Typography>
            </Grid>
            <Grid item sm={6} className="text-end">
              <Button
                size="small"
                variant="contained"
                className="rounded-4 me-2"
                onClick={handleCloseDialog}
              >
                Cerrar
              </Button>
              <Button
                size="small"
                variant="contained"
                className="rounded-4 me-2"
                onClick={handleCloseDialog}
              >
                Responder
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* Dialogo de para ver historial*/}
      <Dialog
        open={openDialogHistory}
        onClose={handleCloseDialog}
        maxWidth="100%"
      >
        <DialogTitle className={classes.dialogTitle}>
          Historial de Respuestas
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TableContainer component={Paper}>
            <Table
              style={{
                backgroundColor: "#F0F4F7",
              }}
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Número de Oficio</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Tema</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Ingresado</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Cerrado al</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Estatus</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Doc</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows1.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.tradesNumber}</TableCell>
                    <TableCell align="center">{row.topic}</TableCell>
                    <TableCell align="center">{row.delayTime}</TableCell>
                    <TableCell align="center">{row.deadline}</TableCell>
                    <TableCell align="center">{row.state}</TableCell>
                    <TableCell align="center">
                      <Button onClick={() => setOpenDialogCreate(true)}>
                        {row.documents}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              labelRowsPerPage="Filas por página"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows1.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </DialogContent>
        <Divider />
        <DialogActions className="mb-3">
          <Grid container spacing={2}>
            <Grid item sm={12} className="text-end">
              <Button
                variant="contained"
                className="rounded-4 me-3"
                onClick={handleCloseDialog}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Container>
        <Grid item sm={12} xs={12}>
          <Grid item container="2">
            <Grid item sm={12} className="text-end">
              <Tooltip title="Filtrar">
                <IconButton aria-label="" color="primary">
                  <FilterAlt />
                </IconButton>
              </Tooltip>
              <Button
                onClick={() => setOpenDialogCreate(true)}
                variant="contained"
              >
                Crear Oficio
              </Button>
            </Grid>
          </Grid>
          <br />
          <TableContainer component={Paper}>
            <Table
              style={{
                backgroundColor: "#F0F4F7",
              }}
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Número de Oficio</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Tema</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Ingresado</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Estatus</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Doc</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#476AC2",
                    }}
                  >
                    <b>Historial</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.tradesNumber}</TableCell>
                    <TableCell align="center">{row.topic}</TableCell>
                    <TableCell align="center">{row.delayTime}</TableCell>
                    <TableCell align="center">{row.state}</TableCell>
                    <TableCell align="center">
                      <Button onClick={() => setOpenDialogCreate(true)}>
                        {row.documents}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button onClick={() => setOpenDialogHistory(true)}>
                        {row.historic}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              labelRowsPerPage="Filas por página"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Container>
    </>
  );
};

export default Trades;
