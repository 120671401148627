import { FormControl, MenuItem, Select } from "@material-ui/core";
import { Save } from "@mui/icons-material";
import { Container, Typography, Box, Switch, Grid, Button } from "@mui/material"
import { useState } from "react";
import { useSelector } from "react-redux";

const periods = ["Semanal", "Mensual", "Semestral", "Anual"]

const General = () => {
    const User = useSelector((state) => state?.setUserLogin?.user);
    const [selectedPeriod, setSelectedPeriod] = useState('Mensual');
    const [selectedPeriodPendient, setSelectedPeriodPendient] = useState('Mensual');

    return (
        <Container className="m-4">

            <Box display="flex" justifyContent="end" mx="" my="" sx="">
                <Button
                    variant="contained"
                    className="rounded-4 me-3"
                    startIcon={<Save />}
                >
                    Guardar
                </Button>

            </Box>




            <Grid container spacing={2}>
                <Grid item sm={6} >
                    <Typography variant="body1" fontWeight="bold" color="primary">
                        ¿Recibir correos electrónicos de cláusulas y proyectos?
                    </Typography>
                </Grid>

                <Grid item sm={6}  >
                    <Switch defaultChecked />
                </Grid>

                <Grid item sm={6}>
                    <Typography variant="body2" fontWeight="bold" color="gray">
                        Recibir reportes del proyecto al correo electrónico
                    </Typography>
                </Grid>
                <Grid item sm={6}>
                    <Typography variant="body2" color="gray">
                        {User?.email}
                    </Typography>
                </Grid>

                <Grid item sm={6}>
                    <Typography variant="body2" fontWeight="bold" color="gray">
                        ¿Cada cuánto deseas recibir los reportes generales?
                    </Typography>
                </Grid>
                <Grid item sm={6}>
                    <FormControl fullWidth>
                        <Select
                            labelId="period-id"
                            id="period"
                            MenuProps={periods}
                            value={selectedPeriod}
                            onChange={(e) => { setSelectedPeriod(e.target.value) }}
                            label="Periodo"
                            variant="standard"
                            renderValue={(selected) => selected}
                        >
                            {periods.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item sm={6}>
                    <Typography variant="body2" fontWeight="bold" color="gray">
                        ¿Cada cuánto deseas recibir los reportes sobre cláusulas pendientes?
                    </Typography>
                </Grid>
                <Grid item sm={6}>
                    <FormControl fullWidth>
                        <Select
                            labelId="period-id2"
                            id="period2"
                            MenuProps={periods}
                            value={selectedPeriodPendient}
                            onChange={(e) => { setSelectedPeriodPendient(e.target.value) }}
                            label="Periodo"
                            variant="standard"
                            renderValue={(selected) => selected}
                        >
                            {periods.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>


                <Grid item sm={6}>
                    <Typography variant="body1" fontWeight="bold" color="primary">
                        Mi usuario es
                    </Typography>
                </Grid>
                <Grid item sm={6}>
                    <Typography variant="body1" color="gray" fontWeight="bold">
                        {User?.username}
                    </Typography>
                </Grid>




            </Grid>

        </Container>
    )
}

export default General