import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: '0px',
    textAlign: 'center',
    fontSize: '1.5em',
    color: '#99B4D1',
    fontWeight: 'bold'
  },
  tabStyle: {
    width: '13em !important',
    textTransform: 'none !important',
    borderRadius: '1.5em !important',
    backgroundColor: 'white !important',
    color: '#99B4D1 !important',
    marginRight: '1em !important',
    '&.Mui-selected':
    {
      backgroundColor: '#2162C0 !important',
      color: 'white !important'
    }
  },
  buttonStyle: {
    marginRight: '1em !important',
    textTransform: 'none !important',
    background: '#2162C0 !important',
    borderRadius: '10em !important'
  },
  projectStyle: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingRight: '1em'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardActionArea: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  stickyHeader: {
    backgroundColor: "white",
    width: "100%",
    position: "sticky",
    top: "60px",
    height: "fit-content",
    zIndex: 1,
    paddingBottom: "5px"
  }

}));

export default useStyles