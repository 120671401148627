import { Delete, ChevronLeft, Save } from "@mui/icons-material";
import moment from "moment-timezone";
import {
  Button,
  Divider,
  Container,
  Paper,
  DialogActions,
  DialogContentText,
  LinearProgress,
  TablePagination,
  Table,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  IconButton,
  Box,
  Typography,
  styled,
} from "@mui/material";
import labelDisplayedRows from "src/utils/pagination";
import { fileDateToDate } from "src/utils/timeLocal";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { LoadingButton } from "@mui/lab";
import useStyles from "../styles";
import { enqueueSnackbar } from "notistack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import EmptyIcon from "src/assets/emptyBox.png";
import { Formik } from "formik";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { formatCurrency } from "src/utils/toDecimal";
import MasterPlanInfoWorks from "./infoMasterPlanWorks";
import { useSelector } from "react-redux";
import apiMasterPlanService from "src/services/masterPlanService";
import * as Yup from "yup";
import permissionsService from "src/services/permissionsService";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2162C0",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const MasterPlanInfo = ({ masterPlan, isView, setView }) => {
  const classes = useStyles();
  const [toggleDialog, setToggleDialog] = useState(false);
  const [toggleDialogUpdate, setToggleDialogUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMasterPlan, setLoadingMasterPlan] = useState(false);
  const [selectWork, setSelectwork] = useState([]);
  const [allWorks, setAllWorks] = useState(false);
  const Project = useSelector((state) => state?.setUserLogin?.project?.id);
  const User = useSelector((state) => state?.setUserLogin?.user);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [viewInfoWorks, setViewInfoWorks] = useState(false);
  let columns = [
    { id: "name", label: "Nombre de la Obra" },
    { id: "payments", label: "Responsable financiero" },
    { id: "startDate", label: "Fecha Est. de Inicio" },
    { id: "endDate", label: "Fecha Est. de Terminación" },
    { id: "total", label: "Monto Total en $" },
    { id: "action", label: "Acciones" },
  ];
  const maxDate = new Date();
  const originalDate = fileDateToDate(masterPlan?.startDate);
  const newDate = new Date(originalDate);

  newDate.setDate(newDate.getDate() - 1);
  maxDate.setFullYear(maxDate.getFullYear() + 100); // 100 years from today
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleToggleDialog = () => {
    setToggleDialog(!toggleDialog);
  };

  const handleToggleDialogUpdate = () => {
    setToggleDialogUpdate(!toggleDialogUpdate);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    try {
      masterPlan.project = Project;
      apiMasterPlanService.postByMasterPlan(masterPlan).then((data) => {
        setAllWorks(data?.data?.newMasterPlanOrder);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Error al obtener las obras", { variant: "error" });
    }
  }, [Project, masterPlan, loadingMasterPlan, viewInfoWorks]);

  const SaveNewMasterPlanWorks = (values) => {
    setLoadingMasterPlan(true);

    try {
      apiMasterPlanService.postByMasterPlanWork(values).then(() => {
        enqueueSnackbar("Obra del Plan maestro creada con exito", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });

        handleToggleDialog(false);
        setLoadingMasterPlan(false);
      });
    } catch (error) {
      enqueueSnackbar("Error al crear la Obra del Plan maestro", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setLoadingMasterPlan(true);
      console.error("Error fetching data:", error);
    }
  };

  const UpdateMasterPlanWorks = (values) => {
    setLoadingMasterPlan(true);
    try {
      const startDateEst =
        moment(selectWork.startDateEst, "DD/MM/YYYY").format("YYYY-MM-DD") +
        " 00:00:01";

      const endDateEst =
        moment(selectWork.endDateEst, "DD/MM/YYYY").format("YYYY-MM-DD") +
        " 23:59:59";

      const startDateReal =
        moment(selectWork.startDateReal, "DD/MM/YYYY").format("YYYY-MM-DD") +
        " 00:00:01";

      const endDateReal =
        moment(selectWork.endDateReal, "DD/MM/YYYY").format("YYYY-MM-DD") +
        " 23:59:59";
      if (
        startDateEst !== values.startDateEst ||
        endDateEst !== values.endDateEst
      ) {
        values.PMEstimateUP = true;
      } else {
        values.PMEstimateUP = false;
      }

      if (
        startDateReal !== values.startDateReal ||
        endDateReal !== values.endDateReal
      ) {
        values.PMRealUP = true;
      } else {
        values.PMRealUP = false;
      }

      apiMasterPlanService.postUpdateMPWorks(values).then(() => {
        enqueueSnackbar("Obra del plan maestro actualizada con exito", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
   
        handleToggleDialogUpdate(false);
        setLoadingMasterPlan(false);
   
      });
    } catch (error) {
      enqueueSnackbar("Error al actualizar la obra del plan maestro", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });

      handleToggleDialogUpdate(false);
      setLoadingMasterPlan(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleToggleDelete = () => {
    setDeleteDialog(!deleteDialog);
  };

  const MasterPlanWorksDialog = (
    <Dialog
      open={toggleDialog}
      onClose={handleToggleDialog}
      aria-labelledby="nuevo-masterPlan-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>Nueva Obra</DialogTitle>
      <Divider variant="middle" orientation="horizontal" />
      <DialogContent>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            name: "",
            amount: "",
            payments: "",
            endDate: "",
            startDate: "",
            description: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("El nombre es requerido"),
            payments: Yup.string().required("El nombre es requerido"),
            startDate: Yup.date()
              .required("La fecha de inicio es requerido")
              .min(
                newDate,
                "La fecha de inicio no puede ser antes de la fecha de inicio del plan maestro"
              ),
            endDate: Yup.date()
              .required("La fecha de finalización es obligatoria")
              .min(
                Yup.ref("startDate"),
                "La fecha de cierrre debe ser después de la fecha de inicio"
              )
              .max(
                maxDate,
                "La fecha de cierre no puede pasar los 100 años desde la actualidad"
              ),
          })}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            values.project = Project;
            values.masterPlanId = masterPlan.id;
            values.endDate = values.endDate + " 23:59:59";
            values.startDate = values.startDate + " 00:00:01";
            SaveNewMasterPlanWorks(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} rowSpacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="name"
                    label="Nombre de la obra: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    name="name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <FormControl
                    fullWidth
                    margin="dense"
                    error={Boolean(touched.payments && errors.payments)}
                    helperText={touched.payments && errors.payments}
                    onBlur={handleBlur}
                  >
                    <InputLabel shrink id="client-number-clauses-label">
                      Responsable financiero:
                    </InputLabel>
                    <Select
                      id="payments"
                      name="payments"
                      variant="standard"
                      value={values.payments}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Estado"}>Estado</MenuItem>
                      <MenuItem value={"Privado"}>Privado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    id="startDate"
                    label="Fecha inicio: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="date"
                    name="startDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.startDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="endDate"
                    label="Fecha cierre: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="date"
                    name="endDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.endDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="amount"
                    label="Monto Total: *"
                    fullWidth
                    type="number"
                    margin="dense"
                    variant="standard"
                    name="amount"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Descripción"
                    name="description"
                    fullWidth
                    multiline
                    rows={4}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <Divider variant="middle" orientation="horizontal" />
              <Grid item sm={6}>
                <Typography variant="caption">
                  Los campos marcados con{" "}
                  <span className="text-danger"> * </span> son obligatorios
                </Typography>
              </Grid>

              <Grid
                container={2}
                item
                className="text-end"
                spacing={2}
                rowSpacing={3}
              >
                <Grid
                  item
                  sm={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    className="rounded-4 me-2"
                    onClick={handleToggleDialog}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    startIcon={<Save />}
                    color="primary"
                    variant="contained"
                    type="submit"
                    autoFocus
                    className="rounded-4 me-2"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    Crear nuevo
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );

  const MasterPlanWorksDialogUpdate = (
    <Dialog
      open={toggleDialogUpdate}
      onClose={handleToggleDialogUpdate}
      aria-labelledby="nuevo-masterPlan-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>Actualizar Obra</DialogTitle>
      <Divider variant="middle" orientation="horizontal" />
      <DialogContent>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            name: selectWork.name || "",
            payments: selectWork.payments || "",
            description: selectWork.description || "",
            endDateReal:
              moment(selectWork.endDateReal, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ) || "",
            startDateReal:
              moment(selectWork.startDateReal, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ) || "",
            endDateEst:
              moment(selectWork.endDateEst, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ) || "",
            startDateEst:
              moment(selectWork.startDateEst, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ) || "",
            totalValueEst: selectWork.totalValueEst || "",
            totalValueReal: selectWork.totalValueReal || "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("El nombre es requerido"),
            payments: Yup.string().required("El nombre es requerido"),
            startDateEst: Yup.date()
              .required("La fecha de inicio es requerido")
              .min(
                newDate,
                "La fecha de inicio no puede ser antes de la fecha de inicio del plan maestro"
              ),
            endDateEst: Yup.date()
              .required("La fecha de finalización es obligatoria")
              .min(
                Yup.ref("startDateEst"),
                "La fecha de finalización debe ser después de la fecha de inicio"
              )
              .max(
                maxDate,
                "La fecha de finalización no puede pasar los 100 años desde la actualidad"
              ),

            startDateReal: Yup.date()
              .required("La fecha de inicio es requerido")
              .min(
                newDate,
                "La fecha de inicio no puede ser antes de la fecha de inicio del plan maestro"
              ),
            endDateReal: Yup.date()
              .required("La fecha de finalización es obligatoria")
              .min(
                Yup.ref("startDateReal"),
                "La fecha de finalización debe ser después de la fecha de inicio"
              )
              .max(
                maxDate,
                "La fecha de finalización no puede pasar los 100 años desde la actualidad"
              ),
          })}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            values.project = Project;
            values.masterPlan = masterPlan.id;
            values.id = selectWork.id;
            values.idUser = User.id;
            values.responsible = masterPlan.responsible;
            values.endDateEst = values.endDateEst + " 23:59:59";
            values.endDateReal = values.endDateReal + " 23:59:59";
            values.startDateEst = values.startDateEst + " 00:00:01";
            values.startDateReal = values.startDateReal + " 00:00:01";
            values.PMEstimateOld = selectWork.PMEstimate;
            values.PMRealOld = selectWork.PMReal;
            values.masterPlanName = masterPlan.name;

            UpdateMasterPlanWorks(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            touched,
            values,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} rowSpacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="name"
                    label="Nombre de la obra: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    name="name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <FormControl
                    fullWidth
                    margin="dense"
                    error={Boolean(touched.payments && errors.payments)}
                    helperText={touched.payments && errors.payments}
                    onBlur={handleBlur}
                  >
                    <InputLabel shrink id="client-number-clauses-label">
                      Responsable financiero:
                    </InputLabel>
                    <Select
                      id="payments"
                      name="payments"
                      variant="standard"
                      value={values.payments}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Estado"}>Estado</MenuItem>
                      <MenuItem value={"Privado"}>Privado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="startDateEst"
                    label="Fecha de inicio Estimada: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="date"
                    name="startDateEst"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.startDateEst}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.startDateEst && errors.startDateEst)}
                    helperText={touched.startDateEst && errors.startDateEst}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="endDateEst"
                    label="Fecha de finalización Estimada: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="date"
                    name="endDateEst"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.endDateEst}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.endDateEst && errors.endDateEst)}
                    helperText={touched.endDateEst && errors.endDateEst}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="startDateReal"
                    label="Fecha de inicio Real: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="date"
                    name="startDateReal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.startDateReal}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.startDateReal && errors.startDateReal
                    )}
                    helperText={touched.startDateReal && errors.startDateReal}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="endDateReal"
                    label="Fecha de finalización Real: *"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    type="date"
                    name="endDateReal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.endDateReal}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.endDateReal && errors.endDateReal)}
                    helperText={touched.endDateReal && errors.endDateReal}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    id="totalValueEst"
                    label="Monto Total Estimado: *"
                    fullWidth
                    type="number"
                    margin="dense"
                    variant="standard"
                    name="totalValueEst"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.totalValueEst}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.totalValueEst && errors.totalValueEst
                    )}
                    helperText={touched.totalValueEst && errors.totalValueEst}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="totalValueReal"
                    label="Monto Total Real: *"
                    fullWidth
                    type="number"
                    margin="dense"
                    variant="standard"
                    name="totalValueReal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.totalValueReal}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.totalValueReal && errors.totalValueReal
                    )}
                    helperText={touched.totalValueReal && errors.totalValueReal}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Descripción"
                    name="description"
                    fullWidth
                    multiline
                    rows={4}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <Divider variant="middle" orientation="horizontal" />
              <Grid item sm={6}>
                <Typography variant="caption">
                  Los campos marcados con{" "}
                  <span className="text-danger"> * </span> son obligatorios
                </Typography>
              </Grid>

              <Grid
                container={2}
                item
                className="text-end"
                spacing={2}
                rowSpacing={3}
              >
                <Grid
                  item
                  sm={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    className="rounded-4 me-2"
                    onClick={handleToggleDialogUpdate}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    startIcon={<Save />}
                    color="primary"
                    variant="contained"
                    type="submit"
                    autoFocus
                    className="rounded-4 me-2"
                    loading={isSubmitting}
                    disabled={!dirty}
                  >
                    Actualizar Obra
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );

  const confirmDeleteDialog = (
    <>
      <Dialog
        open={deleteDialog}
        onClose={handleToggleDelete}
        aria-labelledby="alert-delete-dialog-title"
        aria-describedby="alert-delete-dialog-description"
      >
        <DialogTitle id="alert-delete-dialog-title">
          {"¿Estás seguro de eliminar la obra del Plan Maestro?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-dialog-description">
            Si das click en borrar, se eliminará y toda su configuración
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleToggleDelete()}>Cancelar</Button>
          <LoadingButton
            size="small"
            loading={loading}
            variant="contained"
            loadingPosition="end"
            endIcon={<Delete />}
            autoFocus
            onClick={() => {
              setLoadingMasterPlan(true);

              apiMasterPlanService.deleteWork(
                Project,
                masterPlan.id,
                selectWork.id
              );

              enqueueSnackbar("Obras del Plan maestro eliminado con exito", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              handleToggleDelete();
              setLoadingMasterPlan(false);

            }}
          >
            <span>Eliminar</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );

  return (
    <Container>
      {!viewInfoWorks && (
        <>
          <div className="d-flex justify-content-between  mb-4">
            <IconButton
              style={{ background: "none" }}
              aria-label="edit"
              disableRipple
              color="primary"
              onClick={() => {
                setView(false);
              }}
            >
              <ChevronLeft color="primary" fontSize="large" />
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                color="primary"
                className="m-1"
              >
                Plan Maestro - Obras
              </Typography>
            </IconButton>

            {/* <Tooltip title="Filtrar">
              <IconButton aria-label="" color="primary">
                <FilterAlt />
              </IconButton>
            </Tooltip> */}
          </div>
          {!loading ? (
            <>
              <Grid container>
                {permissionsService(
                  "masterPlan",
                  User.role.label,
                  "add_works"
                ) && (
                  <Grid item sm={12} className={classes.projectStyle}>
                    <Button
                      onClick={() => handleToggleDialog()}
                      className="mb-2"
                      variant="contained"
                    >
                      Agregar Obras
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Divider className="mb-3" />
              <Grid container={2} className="mt-3">
                <Container maxWidth="lg">
                  <div className="d-flex justify-content-between">
                    <Typography
                      variant="body2"
                      color="rgb(138,142,146)"
                      className="mb-2 fw-bold"
                    >
                      {allWorks?.length || 0} Obras
                    </Typography>
                    <Divider />
                  </div>

                  {allWorks?.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <StyledTableCell
                                width={400}
                                style={{ border: "1px solid white" }}
                              >
                                <b> {column.label}</b>
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allWorks
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => (
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {row.name}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {row.payments}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {row.startDateEst}
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {row.endDateEst}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    color="rgb(97,101,105)"
                                  >
                                    {formatCurrency(row.totalValueEst)}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {permissionsService(
                                    "masterPlan",
                                    User.role.label,
                                    "update_works"
                                  ) && (
                                    <Tooltip title="Actualizar Obra">
                                      <IconButton
                                        style={{ background: "none" }}
                                        aria-label="edit"
                                        color="primary"
                                        onClick={() => {
                                          handleToggleDialogUpdate();
                                          setSelectwork(row);
                                        }}
                                      >
                                        <ModeEditIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}

                                  <Tooltip title="Gestionar Obra">
                                    <IconButton
                                      style={{ background: "none" }}
                                      aria-label="edit"
                                      color="primary"
                                      onClick={() => {
                                        setViewInfoWorks(true);
                                        setSelectwork(row);
                                      }}
                                    >
                                      <BorderColorIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  {permissionsService(
                                    "masterPlan",
                                    User.role.label,
                                    "delete_works"
                                  ) && (
                                    <Tooltip title="Eliminar Obras del Plan Maestro">
                                      <IconButton
                                        style={{ background: "none" }}
                                        aria-label="edit"
                                        color="primary"
                                        onClick={() => {
                                          setSelectwork(row);
                                          handleToggleDelete();
                                        }}
                                      >
                                        <DeleteForeverIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        labelRowsPerPage="Filas por página"
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allWorks.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={labelDisplayedRows}
                      />
                    </TableContainer>
                  ) : (
                    <Box textAlign="center">
                      <img
                        src={EmptyIcon}
                        alt="Imagen de no data"
                        width={150}
                      />
                      <Typography variant="h6" color="GrayText">
                        No hay datos disponibles.
                      </Typography>
                    </Box>
                  )}
                </Container>
              </Grid>
            </>
          ) : (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </>
      )}

      {MasterPlanWorksDialog}
      {MasterPlanWorksDialogUpdate}
      {confirmDeleteDialog}

      {viewInfoWorks && (
        <MasterPlanInfoWorks
          masterPlan={masterPlan}
          work={selectWork}
          isView={true}
          setView={setViewInfoWorks}
        />
      )}
    </Container>
  );
};

export default MasterPlanInfo;
