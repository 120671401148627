export const kpisPermissions = {
    "Super Administrador": [
        'add',
        "add_reals",
        'edit',
        'delete'
    ],
    "Administrador TPIG": [
        'add',
        "add_reals",
        'edit',
        'delete'
    ],
    "Administrador Proyecto": [
        "add_reals",
        'edit_reals'
    ],
    "Aprobador": [
        "add_reals",
        'edit_reals'
    ],
    "Gestionador": [
        "add_reals"
    ],
    "Usuario": []
};
