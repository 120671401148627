import { useEffect, useState } from "react";
import { Delete, Save } from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemButton,
  IconButton,
  Menu,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  InputLabel,
  Grid,
  Typography,
  Divider,
  Button,
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme,
  Tooltip,
  LinearProgress,
  Container,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useStyles from "./styles";
import TopBar from "../../../components/topbar/topbar";
import FormCustomers from "./formCustomer";
import { BusinessCenter, FilterAlt } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import apiCustomersService from "src/services/customersService";
import { InputAdornment } from "@material-ui/core";
import apiUserService from "src/services/usersService";
import { enqueueSnackbar } from "notistack";
import { flags } from 'src/utils/flags'
import countries from "src/utils/countries";

const useInputState = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => setValue(e.target.value);
  return [value, handleChange];
};

const listItems = [{ id: 0, text: "Mis clientes", Icon: BusinessCenter }];

const Customers = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [settings, setSettings] = useState({});
  const [selectCustomers, setSelectCustomers] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [dataCustomers, setDataCustomers] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const fetchKPI = async () => {
      setLoading(true);
      try {
        const data = await apiUserService.getSettings();
        setSettings(data.data);
        const response = await apiCustomersService.get();
        const customersData = response.data;
        customersData.sort((a, b) => (a.expeditionDate > b.expeditionDate) ? 1 : -1);
        setAllCustomers(customersData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchKPI();
  }, [loadingCustomers, isEdit]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleToggleDelete = () => {
    setDeleteDialog(!deleteDialog);
  };

  const handleToggleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const customersSidebar = (
    <Box width={"100%"}>
      <List component="nav" aria-label="main mailbox folders" style={{
        width: "100%",
        position: "sticky",
        top: "140px",
        height: "fit-content"
      }}>
        {listItems.map((item, index) => (
          <ListItemButton
            key={item.id}
            classes={{ root: classes.selectedItem }} // Usamos la clase definida para estilizar
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemIcon className={classes.icon}>
              <item.Icon />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  const customerDialog = (
    <Dialog
      open={openDialog}
      onClose={handleToggleDialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>Nuevo cliente</DialogTitle>

      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          companyName: "",
          contactName: "",
          phone: {
            country: "EC",
            value: "",
            areaCode: "593",
          },
          website: "",
          email: "",
          industry: "",
          country: "",
          city: "",
          address: "",
          note: "",
        }}
        validationSchema={Yup.object({
          companyName: Yup.string().required("El campo es requerido"),
          contactName: Yup.string().required("El campo es requerido"),
          phone: Yup.object().shape({
            country: Yup.string().required(
              "El país del teléfono es obligatorio"
            ),
            value: Yup.number()
              .typeError("Solo se admiten números")
              .required("El número de teléfono es obligatorio"),
          }),
          email: Yup.string()
            .email("Debe ser un correo electrónico válido")
            .required("El campo es requerido")
            .trim()
            .test("isMailValid", "¡Correo ya registrado!", async (val) => {
              if (val) {
                const data = allCustomers.find((snp) => snp.email === val);
                if (data === undefined) {
                  return true;
                }
              }
            }),
          industry: Yup.string().required("El campo es requerido"),
          country: Yup.string().required("El campo es requerido"),

          address: Yup.string().required("El campo es requerido"),
        })}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setLoadingCustomers(true);
          apiCustomersService.postCreate(values);
          enqueueSnackbar("Cliente creado con exito", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });


          handleToggleDialog(false);
          setLoadingCustomers(false);
      
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      id="companyName"
                      label="Nombre de la empresa: *"
                      fullWidth
                      margin="dense"
                      variant="standard"
                      name="companyName"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.companyName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.companyName && errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      id="contactName"
                      label="Nombre del contacto: *"
                      fullWidth
                      margin="dense"
                      variant="standard"
                      name="contactName"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.contactName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.contactName && errors.contactName)}
                      helperText={touched.contactName && errors.contactName}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={12} lg={6}>
                  <FormControl fullWidth margin="dense" required>
                    <InputLabel
                      shrink
                      id="phone-country-label"
                      className="mb-4"
                    >
                      Teléfono
                    </InputLabel>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: "16px",
                      }}
                    >
                      <FormControl variant="standard" style={{ width: "5em" }}>
                        <Select
                          labelId="phone-country"
                          id="phone.country"
                          value={values.phone}
                          onChange={(e) => {
                            const selectedCountry = countries.find(
                              (country) => country?.code === e.target.value
                            );
                            setFieldValue("phone.country", e.target.value);
                            setFieldValue(
                              "phone.areaCode",
                              selectedCountry?.areaCode
                            );
                          }}
                          renderValue={(selected) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={flags(selected.country,20)}
                                srcSet={flags(selected.country,40)}
                                alt=""
                              />
                            </div>
                          )}
                        >
                          {countries.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                              <img
                                loading="lazy"
                                width="20"
                                src={flags(country.code,20)}
                                srcSet={flags(country.code,40)}
                                alt={country.name}
                                style={{ marginRight: "10px" }}
                              />
                              {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        id="phone.value"
                        name="phone.value"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +{values.phone.areaCode}
                            </InputAdornment>
                          ),
                        }}
                        value={values.phone.value}
                        onChange={(e) =>
                          setFieldValue("phone.value", e.target.value)
                        }
                        style={{ flexGrow: 1 }}
                        onBlur={handleBlur}
                        variant="standard"
                        error={Boolean(
                          touched.phone?.value && errors.phone?.value
                        )}
                        helperText={touched.phone?.value && errors.phone?.value}
                      />
                    </Box>
                  </FormControl>
                </Grid>

                <Grid item sm={6}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      id="website"
                      label="Sitio web:"
                      fullWidth
                      margin="dense"
                      variant="standard"
                      name="website"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.website}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6}>
                  <FormControl fullWidth margin="dense" required>
                    <TextField
                      id="email"
                      label="Correo electrónico: *"
                      fullWidth
                      margin="dense"
                      variant="standard"
                      name="email"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    className="border-0"
                    error={Boolean(touched.industry && errors.industry)}
                    helperText={touched.industry && errors.industry}
                    onBlur={handleBlur}
                  >
                    <InputLabel shrink htmlFor="industry">
                      Industria: *
                    </InputLabel>
                    <Select
                      id="industry"
                      name="industry"
                      value={values.industry}
                      onChange={handleChange}
                    >
                      {settings?.industry.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                    {touched.industry && errors.industry ? (
                      <FormHelperText>{errors.industry}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    margin="dense"
                    className="border-0"
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    onBlur={handleBlur}
                  >
                    <InputLabel shrink htmlFor="country">
                      País *:
                    </InputLabel>
                    <Select
                      id="country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                    >
                      {countries.map((type) => (
                        <MenuItem value={type.code}>{type.name}</MenuItem>
                      ))}
                    </Select>
                    {touched.country && errors.country ? (
                      <FormHelperText>{errors.country}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                <Grid item sm={6}>
                  <FormControl fullWidth margin="dense" required>
                    <TextField
                      id="city"
                      label="Ciudad: "
                      fullWidth
                      margin="dense"
                      variant="standard"
                      name="city"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl fullWidth margin="dense" required>
                    <TextField
                      id="address"
                      label="Ubicación exacta: *"
                      fullWidth
                      margin="dense"
                      variant="standard"
                      name="address"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="note"
                    label="Nota:"
                    multiline
                    rows={2}
                    value={values.note}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="mb-3">
              <Grid container spacing={2}>
                <Grid item sm={4} className="text-center">
                  <Typography variant="caption">
                    Los campos marcados con{" "}
                    <span className="text-danger"> * </span> son obligatorios
                  </Typography>
                </Grid>

                <Grid
                  item
                  sm={8}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  className="text-end"
                >
                  <Button
                    variant="contained"
                    className="rounded-4 me-3"
                    onClick={handleToggleDialog}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    startIcon={<Save />}
                    variant="contained"
                    className="rounded-4 me-2"
                    loading={isSubmitting}
                    disabled={!isValid}
                    type="submit"
                  >
                    Crear cliente
                  </LoadingButton>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );

  function CustomerListItem({ obj, onEdit, onDelete, isMatch }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <ListItem className="shadow-sm p-4">
        <ListItemAvatar>
          <Avatar src="/broken-image.jpg" />
        </ListItemAvatar>
        {!isMatch ? (
          <ListItemText
            primary={obj.name}
            primaryTypographyProps={{ color: "#808389", fontWeight: "500" }}
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {obj.location}
                </Typography>
                <br />
                <Typography
                  component="span"
                  variant="caption"
                  color="text.primary"
                >
                  {obj.website}
                </Typography>
              </>
            }
          />
        ) : (
          <>
            <ListItemText
              primary={obj.companyName}
              primaryTypographyProps={{ color: "#808389", fontWeight: "500" }}
            />
            <ListItemText
              className="text-center"
              secondary={obj.contactName}
              secondaryTypographyProps={{ fontSize: "13px" }}
            />
            <ListItemText
              className="text-end me-4"
              secondary={obj.website}
              secondaryTypographyProps={{ fontSize: "13px" }}
            />
          </>
        )}
        <IconButton aria-label="settings" onClick={handleClick}>
          <MoreVertIcon color="primary" />
        </IconButton>
        <Menu
          id="customer-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={onEdit}>Editar</MenuItem>
          <MenuItem onClick={onDelete}>Eliminar</MenuItem>
        </Menu>
      </ListItem>
    );
  }

  const tableUser = (
    <Grid item xs={12} md={10}>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="space-between" my={1}>
          <Typography variant="body2" color="gray" className="m-2 fw-bold">
            {allCustomers.length} Clientes
          </Typography>

          {/* <Tooltip title="Filtrar">
            <IconButton
              aria-label="settings"
              aria-controls="project-menu"
              aria-haspopup="true"
            >
              <FilterAlt color="primary" />
            </IconButton>
          </Tooltip> */}
        </Box>
        {!loading ? (
          <>
            <List>
              {allCustomers.map((obj, index) => (
                <CustomerListItem
                  key={obj.id}
                  obj={obj}
                  onEdit={() => {
                    setDataCustomers(obj);
                    setEdit(true);
                  }}
                  onDelete={() => {
                    handleToggleDelete();
                    setSelectCustomers(obj);
                  }}
                  isMatch={matches}
                />
              ))}
            </List>
          </>
        ) : (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Container>
    </Grid>
  );

  const confirmDialog = (
    <Dialog
      fullScreen={fullScreen}
      open={deleteDialog}
      onClose={handleToggleDelete}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">
        {"¿Estás seguro de eliminar el cliente?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Si das click en borrar, se eliminará permanentemente el cliente
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleToggleDelete}>
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<Delete />}
          onClick={() => {
            setLoadingCustomers(true);
            apiCustomersService.delete(selectCustomers.id);
            enqueueSnackbar("cliente eliminado con exito", {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
 
            handleToggleDelete();
            setLoadingCustomers(false);
          
          }}
          autoFocus
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Clientes";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  return (
    <div>
      <TopBar />
      {!isEdit && (
        <>
          <Grid container className={classes.stickyHeader} top="4em">
            <Grid item sm={6}>
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                color="primary"
                className="m-3"
              >
                Clientes
              </Typography>
            </Grid>
            <Grid item sm={6} className={classes.projectStyle}>
              <Button onClick={() => setOpenDialog(true)} variant="contained">
                Crear cliente
              </Button>
            </Grid>
          </Grid>
{/*           <Divider className="mb-1" />
 */}
          <Grid container >
            <Grid item lg={2} md={3} sm={4} xs={12} className="d-flex">
              {customersSidebar}
              <Divider orientation="vertical" />
            </Grid>
            {tableUser}
          </Grid>
{/*           <Divider className="mb-1" />
 */}
          {customerDialog}
          {confirmDialog}
        </>
      )}

      {isEdit && (
        <FormCustomers
          key={dataCustomers.id}
          data={dataCustomers}
          settings={settings}
          allCustomers={allCustomers}
          setEdit={setEdit}
        />
      )}
    </div>
  );
};

export default Customers;
