import { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  Divider,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Menu,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import TopBar from "../../../components/topbar/topbar";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useStyles from "./styles";
import {
  Archive,
  Article,
  BarChart,
  CheckCircle,
  ChevronLeft,
  ContentPasteGoTwoTone,
  Dashboard,
  Delete,
  DriveFileRenameOutline,
  EventNote,
  FileCopy,
  FileUpload,
  Folder,
  Lock,
  Person,
  UploadFile,
} from "@mui/icons-material";
import MasterPlanViewView from "./masterPlan/masterPlan";
import Users from "../users/users";
import Clauses from "./clauses/clauses";
import GeneralView from "./general/general";
import InformationView from "./information/information";
import DocumentsViews from "./documents/documents";
import KPISView from "./KPIs/kpis";
import Reports from "../reports/reports";
import Trades from "./trades/trades";
import Records from "./records/records";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import apiProjectsService from "src/services/projectsService";
import CalculateIcon from "@mui/icons-material/Calculate";
import permissionsService from "src/services/permissionsService";


const documentRows = [
  {
    id: 1,
    type: "Directory",
    name: "Directorio Univer...",
    uploadedBy: "Susanne Hoch",
    date: "10/Nov/2021",
    location: "Cláusula 02",
    size: "22.91 KB",
  },
];

const reportsRows = [
  {
    id: 1,
    name: "Reporte de cláusulas con Criticidad Alta",
    date: "10/Nov/2021, 02:47:24 pm",
    location: "amazons3location.com",
  },
];

const kpis = {
  id: 1,
  name: "Frecuencia Mensual",
  value: "November/21",
  numberEstimados: 0,
  numberReales: 0,
  varNormal: 0,
  varPercent: "0%",
  semaforo: "N/A",
};
const ProjectsDetails = () => {
  const navigate = useNavigate();
  const User = useSelector((state) => state?.setUserLogin?.user);
  const Project = useSelector((state) => state?.setUserLogin?.project);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [newProjectName, setNewProjectName] = useState(Project?.name);
  const open = Boolean(anchorEl);
  const myRol = User?.role?.label !== "Super Administrador" && User?.role?.label !== "Administrador TPIG" ? User?.projects?.find(user => user?.project?.id === Project?.id) : User;
 
  const listItems = [
    { id: 0, text: "General", Icon: ContentPasteGoTwoTone, blocked: false},
    { id: 1, text: "Información", Icon: Article, blocked: false },
    { id: 2, text: "Usuarios", Icon: Person, blocked: false},
    { id: 3, text: "Cláusulas", Icon: CheckCircle, blocked: false},
    { id: 4, text: "Plan Maestro", Icon: CalculateIcon, blocked: false},
    { id: 5, text: "Documentos", Icon: Folder, blocked: true},
    { id: 6, text: "KPIs", Icon: Dashboard, blocked: true},
    { id: 7, text: "Reportes", Icon: BarChart, blocked: true},
    { id: 8, text: "Oficios", Icon: FileCopy, blocked: true},
    { id: 9, text: "Registros", Icon: EventNote, blocked: true},
  ];

  
  const [dataClause, setDataClause] = useState({
    state: {
      series: [
        {
          data: [6, 2, 10, 5, 1],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            vertical: true,
            distributed: true,
          },
        },
        colors: ["#35486a", "#44648b", "#6292bb", "#84b0ce", "#c3dcea"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
            "Aprobado",
            "Rechazado",
            "Pendiente",
            "En revisión",
            "Atrasadas",
          ],
        },
      },
    },
    responsible: {
      series: [44, 55, 20, 6],
      options: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [
          "Kristian Fallas",
          "Esmith Pérez",
          "Christian Alejandro",
          "Michael Robles",
        ],
        colors: ["#41729F", "#5885AF", "#274472", "#7EB2D9", "#C3E0E5"],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    },
    category: {
      series: [44, 55, 15],
      options: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Operacional", "Seguro", "Plazo"],
        colors: ["#3C5A99", "#40739C", "#56ACEE"],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    },
    critic: {
      series: [
        {
          data: [
            { x: "Sin aprobación", y: 124 },
            { x: "Aprobadas", y: 172 },
            { x: "Aprobadas con retraso", y: 95 },
          ],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            distributed: true,
          },
        },
        colors: ["#3C5A99", "#40739C", "#56ACEE"],
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ["Alto", "Medio", "Bajo"],
        },
      },
    },
  });

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReturn = () => {
    navigate("/projects");
  };
  const handleOpenRenameModal = () => {
    setOpenRenameModal(true);
  };

  const handleCloseRenameModal = () => {
    setOpenRenameModal(false);
  };

  const handleRenameProject = async () => {
    await apiProjectsService
      .rename(Project.id, { newProjectName: newProjectName })
      .then((res) => {
        Project.name = newProjectName;
        enqueueSnackbar("Proyecto renombrado con éxito", {
          variant: "success",
        });
        handleCloseRenameModal();
      })
      .catch((res) => {
        enqueueSnackbar("Ha ocurrido un error al renombrar el proyecto", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Proyectos";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  const getCurrentProjectList = () => {
    switch (selectedIndex) {
      case 0: // General
        return <GeneralView dataClause={dataClause} />;
      case 1: // Información
        return <InformationView User={User} />;
      case 2: // Usuarios
        return <Users type="component" />;
      case 3: // Cláusulas
        return <Clauses type="component" />;
      case 4: // Plan maestro
        return <MasterPlanViewView type="component" />;
      case 5: // Documentos
        return <DocumentsViews documentRows={documentRows} />;
      case 6: // KPIS
        return <KPISView kpis={kpis} />;
      case 7: // Reports
        return <Reports reportsRows={reportsRows} isEdit={true} />;
      case 8: // Oficios
        return <Trades type="component" />;
      case 9: // Registros
        return <Records type="component" />;
      default:
        return [];
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const DialogRename = (
    <Dialog open={openRenameModal} onClose={handleCloseRenameModal}>
      <DialogTitle>Renombrar Proyecto</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Nuevo Nombre del Proyecto"
          type="text"
          fullWidth
          value={newProjectName}
          onChange={(e) => setNewProjectName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseRenameModal} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleRenameProject} color="primary">
          Renombrar
        </Button>
      </DialogActions>
    </Dialog>
  );

  const projectSidebar = (
    <Box width={"100%"}>
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{
          width: "100%",
          position: "sticky",
          top: "175px",
          height: "fit-content",
        }}
      >
        {listItems.map((item, index) => (
          <ListItemButton
            key={item?.id}
            classes={{ root: classes.selectedItem }}
            selected={selectedIndex === item?.id}
            onClick={(event) => handleListItemClick(event, item?.id)}
            disabled={item.blocked}
            className={item.blocked ? classes.disabledItem : ""}
          >
            <ListItemIcon className={classes.icon}>
              {item.blocked ? <Lock /> : <item.Icon />}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        )) }
      </List>
    </Box>
  );

  return (
    <div>
      <TopBar />

      <Grid container className={classes.stickyHeader} top={60}>
        <Grid item sm={9}>
          <IconButton
            style={{ background: "none !important" }}
            disableRipple
            aria-label="edit"
            color="primary"
            onClick={() => handleReturn()}
          >
            <ChevronLeft color="primary" fontSize="large" />
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              color="primary"
              textAlign="left"
              className="m-3"
            >
              {Project ? (Project?.name).length < 60 ? (Project?.name) : ((Project?.name)?.slice(0,60) + '...' ) : "Regresar"}
            </Typography>
          </IconButton>
        </Grid>
        {permissionsService("projects", myRol?.role?.label, "rename") && <Grid item sm={3} className={classes.projectStyle}>
          <IconButton
            aria-label="settings"
            aria-controls="project-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon color="primary" />
          </IconButton>

           <Menu
            id="settingsClauses-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            elevation={1}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "12px",
                color: "rgb(88,102,111)",
              },
            }} // Cambia el tamaño de letra aquí
          >
            <MenuItem onClick={() => handleOpenRenameModal()}>
              <ListItemIcon className={classes.menuItemIcon}>
                <DriveFileRenameOutline sx={{ fontSize: "20px !important" }} />
              </ListItemIcon>
              Renombrar
            </MenuItem>
          </Menu>
        </Grid>}
      </Grid>
      {/*       <Divider />
       */}
      <Grid container className="mt-1" spacing={2}>
        {/* Barra Lateral */}
        <Grid item lg={2} md={3} sm={4} xs={12} className="d-flex">
          {projectSidebar}
          {/*           <Divider orientation="vertical" />
           */}{" "}
        </Grid>
        <Grid item lg={10} md={9} sm={8} xs={12}>
          {getCurrentProjectList()}
        </Grid>
        {DialogRename}
      </Grid>
    </div>
  );
};

export default ProjectsDetails;
