export const clausesPermissions = {
    "Super Administrador": [
        'add', 
        'edit', 
        'manage',
        'approve',
        'delete'
    ],
    "Administrador TPIG": [
       'add', 
        'edit', 
        'manage',
        'approve',
        'delete'
    ],
    "Administrador Proyecto": [
        "manage",
        "approve"
    ],
    "Aprobador": [
        "approve"
    ],
    "Gestionador": [
        "manage"
    ],
    "Usuario": []
};

