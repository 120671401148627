import { ChevronLeft } from "@mui/icons-material";
import {
  Divider,
  Container,
  Paper,
  LinearProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  IconButton,
  Box,
  Typography,
  styled,
  TableFooter,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmptyIcon from "src/assets/emptyBox.png";
import { useSelector } from "react-redux";
import apiMasterPlanService from "src/services/masterPlanService";
import { toDecimal, formatCurrency } from "src/utils/toDecimal";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ErrorIcon from "@mui/icons-material/Error";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2162C0",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const MasterPlanInfo = ({ masterPlan, isView, setView }) => {
  const [loading, setLoading] = useState(true);
  const [matrizSummaryPMEst, setMatrizSummaryPMEst] = useState([]);
  const [matrizSummaryPMReal, setMatrizSummaryPMReal] = useState([]);
  const [matrizSumary, setMatrizSumary] = useState([]);
  const [mastrizDetailMount, setMastrizDetailMount] = useState([]);
  const [mastrizDetailMountAcc, setMastrizDetailMountAcc] = useState([]);

  const [colmDetalle, setColDetalle] = useState([]);
  const [keysIds, setKeysIds] = useState([]);

  const Project = useSelector((state) => state?.setUserLogin?.project?.id);

  const colResumen = [
    { id: "name", label: "Descripción de la Obra" },
    { id: "startDate", label: "Fecha Est. de Inicio" },
    { id: "endDate", label: "Fecha Est. de Terminación" },
    { id: "monto", label: "Monto Total en $" },
    { id: "percentage", label: "% Part" },
  ];

  const colComparative = [
    { id: "name", label: "Descripción de la Obra" },
    { id: "startDate", label: "Fecha Est. de Inicio" },
    { id: "endDate", label: "Fecha Est. de Terminación" },
    { id: "monto", label: "Monto Total en $" },
    { id: "percentage", label: "% de Desv en USD" },
    { id: "dateIni", label: "Fech Inic" },
    { id: "dateEnd", label: "Fech Fin" },
    { id: "IndDate", label: "Ind Fecha" },
    { id: "IndValue", label: "Ind Monto" },
  ];

  useEffect(() => {
    try {
      const colDetalle = [
        { id: "name", label: "Descripción de la Obra" },
        { id: "total", label: "Total" },
      ];
      const keysId = [];
      masterPlan.project = Project;
      apiMasterPlanService.postByMasterPlan(masterPlan).then((data) => {
        setMatrizSummaryPMEst(data?.data?.matrizSummaryPMEstComp || []);
        setMatrizSummaryPMReal(data?.data?.matrizSummaryPMRealComp || []);
        setMatrizSumary(data?.data?.matrizSumary || []);
        setMastrizDetailMount(data?.data?.mastrizDetailMount || []);
        setMastrizDetailMountAcc(data?.data?.mastrizDetailMountAcc || []);

        if (data?.data?.mastrizDetailEst?.length > 0) {
          const keys = Object.keys(data?.data?.mastrizDetailEst[0] || []);
          for (let i = 2; i < keys.length; i++) {
            keysId.push(keys[i]);
            colDetalle.push({
              id: keys[i],
              label: keys[i],
            });
          }
        }
        setKeysIds(keysId);
        setColDetalle(colDetalle);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Error al obtener las obras", { variant: "error" });
    }
  }, [Project, masterPlan]);
  return (
    <Container>
      <div className="d-flex justify-content-between  mb-4">
        <IconButton
          style={{ background: "none" }}
          aria-label="edit"
          color="primary"
          onClick={() => {
            setView(false);
          }}
        >
          <ChevronLeft color="primary" fontSize="large" />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            color="primary"
            className="m-1"
          >
            Comparativo del Resumen del Plan Maestro
          </Typography>
        </IconButton>

        {/* <Tooltip title="Filtrar">
          <IconButton aria-label="" color="primary">
            <FilterAlt />
          </IconButton>
        </Tooltip> */}
      </div>
      {!loading ? (
        <>
          <Divider className="mb-3" />
          <Grid container={2} className="mt-3">
            <Container maxWidth="lg">
              <div className="d-flex justify-content-between">
                <Divider />
              </div>

              {matrizSummaryPMEst.length > 0 ? (
                <>
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    color="primary"
                    className="mb-2 fw-bold"
                  >
                    Estimado Inicial
                  </Typography>
                  <Divider />
                  <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {colResumen.map((column) => (
                            <StyledTableCell
                              width={400}
                              style={{ border: "1px solid white" }}
                            >
                              <b> {column.label}</b>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {matrizSummaryPMEst?.slice(0, -1)?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSummaryPMEst.length - 1 === index ? (
                                  <b> {row.name}</b>
                                ) : (
                                  <>{row.name}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSummaryPMEst.length - 1 === index ? (
                                  <b> {row.startDateEst}</b>
                                ) : (
                                  <>{row.startDateEst}</>
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSummaryPMEst.length - 1 === index ? (
                                  <b> {row.endDateEst}</b>
                                ) : (
                                  <>{row.endDateEst}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSummaryPMEst.length - 1 === index ? (
                                  <b> {formatCurrency(row.Total)}</b>
                                ) : (
                                  <> {formatCurrency(row.Total)}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSummaryPMEst.length - 1 === index ? (
                                  <b> {toDecimal(row.percentage) + "%"}</b>
                                ) : (
                                  <> {toDecimal(row.percentage) + "%"}</>
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter style={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 800 }}>
                        <TableRow>
                          <TableCell width={225}>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSummaryPMEst[matrizSummaryPMEst.length - 1].name}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSummaryPMEst[matrizSummaryPMEst.length - 1].startDateEst}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSummaryPMEst[matrizSummaryPMEst.length - 1].endDateEst}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{formatCurrency(matrizSummaryPMEst[matrizSummaryPMEst.length - 1].Total)}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{toDecimal(matrizSummaryPMEst[matrizSummaryPMEst.length - 1].percentage) + "%"}</b>
                            </Typography>
                          </TableCell>

                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>

                  <br />
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    color="primary"
                    className="mb-2 fw-bold"
                  >
                    Estimado con reales
                  </Typography>
                  <Divider />
                  <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {colResumen.map((column) => (
                            <StyledTableCell
                              width={400}
                              style={{ border: "1px solid white" }}
                            >
                              <b>{column.label}</b>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {matrizSummaryPMReal?.slice(0, -1)?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSummaryPMReal.length - 1 === index ? (
                                  <b> {row.name}</b>
                                ) : (
                                  <> {row.name}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSummaryPMReal.length - 1 === index ? (
                                  <b> {row.startDateReal}</b>
                                ) : (
                                  <> {row.startDateReal}</>
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSummaryPMReal.length - 1 === index ? (
                                  <b> {row.endDateReal}</b>
                                ) : (
                                  <> {row.endDateReal}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSummaryPMReal.length - 1 === index ? (
                                  <b> {formatCurrency(row.Total)}</b>
                                ) : (
                                  <> {formatCurrency(row.Total)}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSummaryPMReal.length - 1 === index ? (
                                  <b> {toDecimal(row.percentage) + "%"}</b>
                                ) : (
                                  <> {toDecimal(row.percentage) + "%"}</>
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter style={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 800 }}>
                        <TableRow>
                          <TableCell width={225}>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSummaryPMReal[matrizSummaryPMReal.length - 1].name}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSummaryPMReal[matrizSummaryPMReal.length - 1].startDateReal}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSummaryPMReal[matrizSummaryPMReal.length - 1].endDateReal}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{formatCurrency(matrizSummaryPMReal[matrizSummaryPMReal.length - 1].Total)}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{toDecimal(matrizSummaryPMReal[matrizSummaryPMReal.length - 1].percentage) + "%"}</b>
                            </Typography>
                          </TableCell>

                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>

                  <br />
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    color="primary"
                    className="mb-2 fw-bold"
                  >
                    Variaciones, Indicadores de Fechas, Indicadores de Monto
                  </Typography>
                  <Divider />
                  <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {colComparative.map((column) => (
                            <StyledTableCell
                              width={400}
                              style={{ border: "1px solid white" }}
                            >
                              <b>{column.label}</b>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {matrizSumary?.slice(0, -1)?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSumary.length - 1 === index ? (
                                  <b> {row.name}</b>
                                ) : (
                                  <> {row.name}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSumary.length - 1 === index ? (
                                  <b> {row.startDate}</b>
                                ) : (
                                  <> {row.startDate}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSumary.length - 1 === index ? (
                                  <b> {row.endDate}</b>
                                ) : (
                                  <> {row.endDate}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSumary.length - 1 === index ? (
                                  <b> {formatCurrency(row.Total)}</b>
                                ) : (
                                  <> {formatCurrency(row.Total)}</>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {matrizSumary.length - 1 === index ? (
                                  <b> {toDecimal(row.percentage) + "%"}</b>
                                ) : (
                                  <> {toDecimal(row.percentage) + "%"}</>
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.FechInic === "Verde" && (
                                  <CheckCircleIcon style={{ color: "green" }} />
                                )}
                                {row.FechInic === "Amarillo" && (
                                  <ErrorIcon style={{ color: "#E5D921" }} />
                                )}
                                {row.FechInic === "Rojo" && (
                                  <CancelIcon style={{ color: "#C21D1D" }} />
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.FechFin === "Verde" && (
                                  <CheckCircleIcon style={{ color: "green" }} />
                                )}
                                {row.FechFin === "Amarillo" && (
                                  <ErrorIcon style={{ color: "#E5D921" }} />
                                )}
                                {row.FechFin === "Rojo" && (
                                  <CancelIcon style={{ color: "#C21D1D" }} />
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.IndFecha === "Verde" && (
                                  <CheckCircleIcon style={{ color: "green" }} />
                                )}
                                {row.IndFecha === "Amarillo" && (
                                  <ErrorIcon style={{ color: "#E5D921" }} />
                                )}
                                {row.IndFecha === "Rojo" && (
                                  <CancelIcon style={{ color: "#C21D1D" }} />
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.IndMonto === "Verde" && (
                                  <CheckCircleIcon style={{ color: "green" }} />
                                )}
                                {row.IndMonto === "Amarillo" && (
                                  <ErrorIcon style={{ color: "#E5D921" }} />
                                )}
                                {row.IndMonto === "Rojo" && (
                                  <CancelIcon style={{ color: "#C21D1D" }} />
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter style={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 800 }}>
                        <TableRow>
                          <TableCell width={225}>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSumary[matrizSumary.length - 1].name}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSumary[matrizSumary.length - 1].startDate}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{matrizSumary[matrizSumary.length - 1].endDate}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{formatCurrency(matrizSumary[matrizSumary.length - 1].Total)}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              <b>{toDecimal(matrizSumary[matrizSumary.length - 1].percentage) + "%"}</b>
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              {matrizSumary[matrizSumary.length - 1].FechInic === "Verde" && (
                                <CheckCircleIcon style={{ color: "green" }} />
                              )}
                              {matrizSumary[matrizSumary.length - 1].FechInic === "Amarillo" && (
                                <ErrorIcon style={{ color: "#E5D921" }} />
                              )}
                              {matrizSumary[matrizSumary.length - 1].FechInic === "Rojo" && (
                                <CancelIcon style={{ color: "#C21D1D" }} />
                              )}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              {matrizSumary[matrizSumary.length - 1].FechFin === "Verde" && (
                                <CheckCircleIcon style={{ color: "green" }} />
                              )}
                              {matrizSumary[matrizSumary.length - 1].FechFin === "Amarillo" && (
                                <ErrorIcon style={{ color: "#E5D921" }} />
                              )}
                              {matrizSumary[matrizSumary.length - 1].FechFin === "Rojo" && (
                                <CancelIcon style={{ color: "#C21D1D" }} />
                              )}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              {matrizSumary[matrizSumary.length - 1].IndFecha === "Verde" && (
                                <CheckCircleIcon style={{ color: "green" }} />
                              )}
                              {matrizSumary[matrizSumary.length - 1].IndFecha === "Amarillo" && (
                                <ErrorIcon style={{ color: "#E5D921" }} />
                              )}
                              {matrizSumary[matrizSumary.length - 1].IndFecha === "Rojo" && (
                                <CancelIcon style={{ color: "#C21D1D" }} />
                              )}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body1"
                              color="rgb(97,101,105)"
                            >
                              {matrizSumary[matrizSumary.length - 1].IndMonto === "Verde" && (
                                <CheckCircleIcon style={{ color: "green" }} />
                              )}
                              {matrizSumary[matrizSumary.length - 1].IndMonto === "Amarillo" && (
                                <ErrorIcon style={{ color: "#E5D921" }} />
                              )}
                              {matrizSumary[matrizSumary.length - 1].IndMonto === "Rojo" && (
                                <CancelIcon style={{ color: "#C21D1D" }} />
                              )}
                            </Typography>
                          </TableCell>

                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>

                  <br />
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    color="primary"
                    className="mb-2 fw-bold"
                  >
                    Detalle Mensual
                  </Typography>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {colmDetalle.map((column, index) => (
                            <StyledTableCell
                              key={index}
                              style={index === 0 ? {
                                left: 0,
                                zIndex: 800,
                              } : {}}
                            >
                              <b> {column.label}</b>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mastrizDetailMount.map((row, index) => (
                          <TableRow>
                            <TableCell style={{
                              position: 'sticky',
                              left: 0,
                              background: 'white',
                              zIndex: 700,
                            }}>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                <b> {row.name}</b>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {formatCurrency(row.total)}
                              </Typography>
                            </TableCell>
                            {keysIds.map((snp) => (
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {row[snp] !== 0
                                    ? formatCurrency(row[snp])
                                    : ""}
                                </Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <br />
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    color="primary"
                    className="mb-2 fw-bold"
                  >
                    Detalle Mensual Acumulado
                  </Typography>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {colmDetalle.map((column, index) => (
                            <StyledTableCell
                              key={index}
                              style={index === 0 ? {
                                left: 0,
                                zIndex: 800,
                              } : {}}
                            >
                              <b>{column.label}</b>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mastrizDetailMountAcc.map((row) => (
                          <TableRow>
                            <TableCell  style={{
                                position: 'sticky',
                                left: 0,
                                background: 'white',
                                zIndex: 700,
                              }}>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              ><b>
                                  {row.name}
                                </b>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                color="rgb(97,101,105)"
                              >
                                {row.total}
                              </Typography>
                            </TableCell>
                            {keysIds.map((snp) => (
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  color="rgb(97,101,105)"
                                >
                                  {row[snp] !== 0
                                    ? formatCurrency(row[snp])
                                    : ""}
                                </Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Box textAlign="center">
                  <img src={EmptyIcon} alt="Imagen de no data" width={150} />
                  <Typography variant="h6" color="GrayText">
                    No hay datos disponibles.
                  </Typography>
                </Box>
              )}
            </Container>
          </Grid>
        </>
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Container>
  );
};

export default MasterPlanInfo;
