import axiosInstance from "src/utils/axios";

const api = "/api/kpi";

const apiKPIService = {
  postCreate: (data) => {
    return axiosInstance.post(api + "/", data);
  },
  postUpdate: (data) => {
    return axiosInstance.post(api + "/updateKPI", data);
  },
  postUpdateNumbers: (data) => {
    return axiosInstance.post(api + "/updateKPINumbers", data);
  },
  postByProject: (data) => {
    return axiosInstance.post(api + "/kpis", data);
  },
  get: () => {
    return axiosInstance.get(api);
  },

  delete: (projectId, id) => {
    return axiosInstance.delete(api + "/" + projectId + "/" + id);
  },
};
export default apiKPIService;