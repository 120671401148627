import { useEffect, useState } from "react";
import { FilterAlt } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Paper,
  IconButton,
  TableRow,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Tooltip,
  Container,
} from "@mui/material";

const Records = () => {
  const rows = [
    {
      responsibleUser: "Susanne Hoch",
      time: "12/Oct/2021 02:21:24 pm",
      action: "ha agregado un nuevo KPI con el nombre",
      task: "RSU",
    },
    {
      responsibleUser: "Susanne Hoch",
      time: "12/Oct/2021 02:50:16 pm",
      action: "ha duplicado la cláusula con el nombre",
      task: "cláusula 303",
    },
    {
      responsibleUser: "Susanne Hoch",
      time: "12/Oct/2021 06:36:59 pm",
      action: "ha actualizado la cláusula con el nombre",
      task: "creación de nueva cláusula 303 por cláusula",
    },
    {
      responsibleUser: "Susanne Hoch",
      time: "10/Nov/2021 04:28:31 pm",
      action: "ha agregado una nueva cláusula con el nombre",
      task: "creación de nueva cláusula 303",
    },
    {
      responsibleUser: "Susanne Hoch",
      time: "10/Nov/2021 04:28:31 pm",
      action: "ha agregado una nueva cláusula con el nombre",
      task: "creación de nueva cláusula 303",
    },
    {
      responsibleUser: "Susanne Hoch",
      time: "10/Nov/2021 04:28:31 pm",
      action: "ha enviado a revisión la clausula con el nombre",
      task: "Plazo de Construcción",
    },
    {
      responsibleUser: "Susanne Hoch",
      time: "10/Nov/2021 04:28:31 pm",
      action: "ha rechazado la cláusula con el nombre",
      task: "Seguros",
    },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Registros";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  return (
    <>
      <Container>
        <label
          style={{
            color: "#476AC2",
          }}
        >
          <h3>Registros Recientes</h3>
        </label>
        <Grid item sm={12} xs={12}>
          <Grid item container="2">
            <Grid item sm={12} className="text-end">
              <Tooltip title="Filtrar">
                <IconButton aria-label="" color="primary">
                  <FilterAlt />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <><TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    style={{
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    <TableCell>
                      <label
                        style={{
                          color: "#476AC2",
                        }}
                      >
                        <b>{row.responsibleUser}</b>
                      </label>
                      {` ${row.action} `}
                      <label
                        style={{
                          color: "#476AC2",
                        }}
                      >
                        <b>{row.task}</b>
                      </label>
                    </TableCell>
                    <TableCell align="center">
                      <AccessTimeIcon />
                      {row.time}
                    </TableCell>
                  </TableRow><br /></>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              labelRowsPerPage="Filas por página"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Container>
    </>
  );
};

export default Records;
