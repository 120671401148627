import { db } from '../config/firebaseConfig';
import { collection, query, orderBy, onSnapshot, getDocs } from 'firebase/firestore';

const subscribeToClauses = (projectId, onUpdate) => {
  const clausesRef = collection(db, `projects/${projectId}/clauses`);

  const q = query(clausesRef, orderBy('expeditionDate', 'desc'));

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const updatedClauses = [];
    snapshot.forEach((doc) => {
      const clauseData = doc.data();
      clauseData.id = doc.id;
      updatedClauses.push(clauseData);
    });

    onUpdate(updatedClauses);
  });

  return unsubscribe;
};

const getClauses = async (projectId) => {
  try {
    const clausesRef = collection(db, `projects/${projectId}/clauses`);
    const q = query(clausesRef, orderBy('expeditionDate', 'desc'));

    const querySnapshot = await getDocs(q);
    const clauses = [];

    querySnapshot.forEach((doc) => {
      const clauseData = doc.data();
      clauseData.id = doc.id;
      clauses.push(clauseData);
    });

    return clauses;
  } catch (error) {
    console.error('Error getting clauses:', error);
    throw error;
  }
};

const apiClauseServiceRealTime = {
  subscribeToClauses,
  getClauses,
};

export default apiClauseServiceRealTime;
