const countries = [
    { "name": "Argentina", "code": "AR", "areaCode": "54" },
    { "name": "Bangladesh", "code": "BD", "areaCode": "880" },
    { "name": "Bolivia", "code": "BO", "areaCode": "591" },
    { "name": "Brasil", "code": "BR", "areaCode": "55" },
    { "name": "Canadá", "code": "CA", "areaCode": "1" },
    { "name": "Chile", "code": "CL", "areaCode": "56" },
    { "name": "China", "code": "CN", "areaCode": "86" },
    { "name": "Colombia", "code": "CO", "areaCode": "57" },
    { "name": "Costa Rica", "code": "CR", "areaCode": "506" },
    { "name": "Cuba", "code": "CU", "areaCode": "53" },
    { "name": "República Democrática del Congo", "code": "CD", "areaCode": "243" },
    { "name": "República Dominicana", "code": "DO", "areaCode": "1-809" },
    { "name": "Ecuador", "code": "EC", "areaCode": "593" },
    { "name": "Egipto", "code": "EG", "areaCode": "20" },
    { "name": "El Salvador", "code": "SV", "areaCode": "503" },
    { "name": "Etiopía", "code": "ET", "areaCode": "251" },
    { "name": "Francia", "code": "FR", "areaCode": "33" },
    { "name": "Alemania", "code": "DE", "areaCode": "49" },
    { "name": "Guatemala", "code": "GT", "areaCode": "502" },
    { "name": "Honduras", "code": "HN", "areaCode": "504" },
    { "name": "India", "code": "IN", "areaCode": "91" },
    { "name": "Indonesia", "code": "ID", "areaCode": "62" },
    { "name": "Irán", "code": "IR", "areaCode": "98" },
    { "name": "Italia", "code": "IT", "areaCode": "39" },
    { "name": "Japón", "code": "JP", "areaCode": "81" },
    { "name": "México", "code": "MX", "areaCode": "52" },
    { "name": "Nicaragua", "code": "NI", "areaCode": "505" },
    { "name": "Nigeria", "code": "NG", "areaCode": "234" },
    { "name": "Pakistán", "code": "PK", "areaCode": "92" },
    { "name": "Panamá", "code": "PA", "areaCode": "507" },
    { "name": "Paraguay", "code": "PY", "areaCode": "595" },
    { "name": "Perú", "code": "PE", "areaCode": "51" },
    { "name": "Filipinas", "code": "PH", "areaCode": "63" },
    { "name": "Polonia", "code": "PL", "areaCode": "48" },
    { "name": "Puerto Rico", "code": "PR", "areaCode": "1" },
    { "name": "Rusia", "code": "RU", "areaCode": "7" },
    { "name": "Sudáfrica", "code": "ZA", "areaCode": "27" },
    { "name": "Corea del Sur", "code": "KR", "areaCode": "82" },
    { "name": "España", "code": "ES", "areaCode": "34" },
    { "name": "Sudán", "code": "SD", "areaCode": "249" },
    { "name": "Turquía", "code": "TR", "areaCode": "90" },
    { "name": "Ucrania", "code": "UA", "areaCode": "380" },
    { "name": "Reino Unido", "code": "GB", "areaCode": "44" },
    { "name": "Estados Unidos", "code": "US", "areaCode": "1" },
    { "name": "Venezuela", "code": "VE", "areaCode": "58" },
    { "name": "Vietnam", "code": "VN", "areaCode": "84" }
];

export default countries;
