import { React, useState, useEffect } from "react";
import {
  Paper,
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Box,
  Divider,
  Link,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useStyles } from "./styles.js";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { enqueueSnackbar } from "notistack";
import { Formik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { getNewUserLogin } from "../../redux/actions/loginUserAction.js";
import axiosInstance from "src/utils/axios.js";
import apiUserService from "src/services/usersService.js";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isSendCode, setIsSendCode] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmitLogin = (values, setSubmitting) => {
    axiosInstance
      .post("api/users/auth", {
        email: values?.email || "",
        password: values?.password || "",
      })
      .then((res) => {
        if (res.data.user.emailVerified) {
          dispatch(getNewUserLogin(res.data.data));
          enqueueSnackbar("Bienvenido al sistema ", {
            variant: "success",
          });
          setSubmitting(false);
          navigate("/home");
        } else {
          enqueueSnackbar(
            "Tu cuenta aún no ha sido verificada. Por favor, revisa tu correo electrónico incluyendo la bandeja de SPAM.",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          setSubmitting(false);
          apiUserService
            .verificationEmail({ email: values?.email })
            .then((res) => {
              enqueueSnackbar(
                "Nuevo mensaje de verificación enviado al correo",
                {
                  variant: "info",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                }
              );
            })
            .catch((res) => {
              enqueueSnackbar(
                "Ya ha sido enviado el mensaje de verificación.",
                {
                  variant: "info",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                }
              );
            });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.code === "auth/invalid-credential") {
          const attempts = 3 - (error?.response?.data?.attempts + 1);
          enqueueSnackbar(
            "Contraseña incorrecto, Te quedan: " + attempts + " intentos",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
        } else {
          const { message, details } = error?.response?.data || {};

          if (message || details) {
            enqueueSnackbar(details ? `${message} ${details}` : message, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          } else {
            enqueueSnackbar(
              "Ha ocurrido un error inesperado. Porfavor espere unos segundos y vuelva a intentarlo",
              {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              }
            );
          }
        }
        setSubmitting(false);
        navigate("/login");
      });
  };

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Inicia sesión";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  return (
    <Container maxWidth={false} disableGutters className={classes.root}>
      <Box className={classes.logoPanel}>
        <img src={logo} alt="Logo" width={150} height={50} />
      </Box>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12} className={classes.header} />
        {matches && (
          <Grid item md={6} className={classes.textArea}>
            <Typography className={classes.welcomeText}>
              Bienvenido a la Plataforma de <br /> Monitoreo y Gestión
              Centralizada
            </Typography>
            <Typography className={classes.communicationText}>
              Nuestra manera inteligente <br /> de comunicarnos
            </Typography>

            <Typography
              className={classes.PoweredbyText}
              style={{
                marginTop: "2em",
              }}
            >
              Powered by The Project International Group
            </Typography>
          </Grid>
        )}
        {!isResettingPassword && !isSendCode && !isChangePassword && (
          <Grid item md={6} sm={12} xs={12}>
            <Paper elevation={3} className={classes.loginPanel}>
              <Box className={classes.headerLogin}>
                <Typography component="h1" color="textSecondary" variant="h5">
                  Iniciar Sesión
                </Typography>
              </Box>
              <Divider
                orientation="horizontal"
                style={{ marginBottom: "1em" }}
              />

              <Formik
                enableReinitialize
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Por favor ingresa un correo electrónico válido")
                    .required("El correo electrónico es obligatorio"),
                  password: Yup.string().required(
                    "La contraseña es obligatorio"
                  ),
                })}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  handleSubmitLogin(values, setSubmitting);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setSubmitting,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      label="Correo electrónico"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      name="email"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.email}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />

                    <TextField
                      label="Contraseña"
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      name="password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={values.password}
                      onChange={handleChange}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />
                    <Box
                      display="flex"
                      mx=""
                      my="1"
                      justifyContent="space-between"
                    >
                      <Link
                        href="#"
                        onClick={() => {
                          setIsResettingPassword(true);
                        }}
                        variant="body2"
                        style={{
                          display: "flex",
                          marginTop: "1em",
                          marginBottom: "1em",
                        }}
                      >
                        ¿Olvidó su contraseña?
                      </Link>
                    </Box>

                    <LoadingButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="mb-3"
                      loading={isSubmitting}
                      loadingPosition="end"
                      type="submit"
                    >
                      Iniciar Sesión
                    </LoadingButton>

                    <Typography color="textSecondary">
                      <center>
                        {" "}
                        ¿Necesitas ayuda? <br />
                        Contáctanos: soporte@mgc-tp.com
                      </center>
                    </Typography>
                  </form>
                )}
              </Formik>
            </Paper>
          </Grid>
        )}

        {isResettingPassword &&
          ResetPassword(classes, setIsResettingPassword, setIsSendCode)}
        {isSendCode && sendCode(classes, setIsSendCode, setIsChangePassword)}
        {isChangePassword && changePassword(classes, setIsChangePassword)}
      </Grid>
    </Container>
  );
};
const ResetPassword = (classes, setIsResettingPassword, setIsSendCode) => {
  const errorTrad = {
    "auth/invalid-email": "Correo electrónico inválido.",
    "auth/user-not-found": "Este usuario no existe.",
    "auth/too-many-requests":
      "Limite de correos enviados por favor intente más tarde",
  };
  const sendEmailResetPassword = (values) => {
    axiosInstance
      .post("api/users/forgot", {
        email: values?.email || "",
      })
      .then((res) => {
        enqueueSnackbar("Se envió un correo para restablecer la contraseña", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(
          errorTrad[error?.response?.data?.error?.code]
            ? errorTrad[error?.response?.data?.error?.code]
            : "Sucedió un error intente más tarde ",
          {
            variant: "error",
          }
        );
      });
  };
  return (
    <Grid item md={6} sm={12} xs={12}>
      <Paper elevation={3} className={classes.loginPanel}>
        <Formik
          enableReinitialize
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Por favor ingresa un correo electrónico válido")
              .required("El correo electrónico es obligatorio"),
          })}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            sendEmailResetPassword(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className={classes.headerLogin}>
                <Typography component="h1" color="textSecondary" variant="h5">
                  Cambiar contraseña
                </Typography>
              </Box>
              <Divider
                orientation="horizontal"
                style={{ marginBottom: "1em" }}
              />
              <Typography color="textSecondary">
                Le enviaremos un correo electrónico para restablecer su
                contraseña.
              </Typography>

              <TextField
                label="Correo electrónico"
                fullWidth
                margin="normal"
                variant="outlined"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <Link
                href="#"
                onClick={() => setIsResettingPassword(false)}
                variant="body2"
                style={{
                  display: "flex",
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
              >
                Volver a iniciar sesión
              </Link>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Enviar correo
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
};

const sendCode = (classes, setIsSendCode, setIsChangePassword) => {
  return (
    <Grid item md={6} sm={12} xs={12}>
      <Paper elevation={3} className={classes.loginPanel}>
        <Box className={classes.headerLogin}>
          <Typography component="h1" color="textSecondary" variant="h5">
            Código de verificación
          </Typography>
        </Box>
        <Divider orientation="horizontal" style={{ marginBottom: "1em" }} />
        <Typography color="textSecondary">
          Ingresa el código de verificación enviado a tú correo electrónico.
        </Typography>
        <form noValidate autoComplete="off">
          <TextField
            label="Código de verificación"
            type="text"
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Link
            href="#"
            onClick={() => setIsSendCode(false)}
            variant="body2"
            style={{ display: "flex", marginTop: "1em", marginBottom: "1em" }}
          >
            Volver a iniciar sesión
          </Link>

          <Button
            onClick={() => {
              setIsSendCode(false);
              setIsChangePassword(true);
            }}
            variant="contained"
            color="primary"
            fullWidth
          >
            Verificar Código
          </Button>
        </form>
      </Paper>
    </Grid>
  );
};

const changePassword = (classes, setIsChangePassword) => {
  return (
    <Grid item md={6} sm={12} xs={12}>
      <Paper elevation={3} className={classes.loginPanel}>
        <Box className={classes.headerLogin}>
          <Typography component="h1" color="textSecondary" variant="h5">
            Cambia tú contraseña
          </Typography>
        </Box>
        <Divider orientation="horizontal" style={{ marginBottom: "1em" }} />
        <Typography color="textSecondary">
          Ingresa los datos para cambiar la contraseña.
        </Typography>
        <form noValidate autoComplete="off">
          <TextField
            label="Contraseña antigua"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <TextField
            label="Contraseña nueva"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <TextField
            label="Verifica la contraseña nueva"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <Link
            href="#"
            onClick={() => setIsChangePassword(false)}
            variant="body2"
            style={{ display: "flex", marginTop: "1em", marginBottom: "1em" }}
          >
            Volver a iniciar sesión
          </Link>

          <Button
            onClick={() => setIsChangePassword(false)}
            variant="contained"
            color="primary"
            fullWidth
          >
            Cambiar contraseña
          </Button>
        </form>
      </Paper>
    </Grid>
  );
};

export default LoginPage;
