import { Archive, ChevronLeft, ContentCopy, Mode, MoreVert as MoreVertIcon, Settings } from '@mui/icons-material'
import { Box, Chip, FormControl, IconButton, Input, InputLabel, Button, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, Typography, styled, Divider, Menu, TablePagination, Container } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Grid from '@mui/material/Grid'
import { Formik } from 'formik'
import ReportsPdf from "../../../components/reports/clauses";
import { useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#2162C0",
        color: theme.palette.common.white

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    },
}));

const rows = [
    {
        initial: "Orden de Inicio",
        clauseNumber: "01",
        dueDate: "13/Oct/2021",
        approvalRejectionDate: "Aprob: 19/Oct/2021",
        delayTime: "6 días",
        approvalTime: "22 días",
        responsible: "Kristian",
        state: "Aprobado Con Retraso",
        criticality: "Alta",
        category: "Operación y mantenimiento",
        documents: "ver",
        historic: "ver",
    },
    {
        initial: "Plazo de Construcción",
        clauseNumber: "02",
        dueDate: "14/Oct/2021",
        approvalRejectionDate: "-",
        delayTime: "27 días",
        approvalTime: "0 días",
        responsible: "Susanne Hoch",
        state: "Pendiente Con Retraso",
        criticality: "Alta",
        category: "Plazo",
        documents: "ver",
        historic: "ver",
    },
    {
        initial: "Seguros",
        clauseNumber: "03",
        dueDate: "13/Oct/2021",
        approvalRejectionDate: "-",
        delayTime: "28 días",
        approvalTime: "0 días",
        responsible: "Susanne Hoch",
        state: "En Revisión Con Retraso",
        criticality: "Media",
        category: "Seguro",
        documents: "ver",
        historic: "ver",
    },
    {
        initial: "Los puntos Clave deben ser a",
        clauseNumber: "04",
        dueDate: "13/Oct/2021",
        approvalRejectionDate: "-",
        delayTime: "28 días",
        approvalTime: "0 días",
        responsible: "Susanne Hoch",
        state: "En Revisión Con Retraso",
        criticality: "Media",
        category: "Administrativo",
        documents: "ver",
        historic: "ver",
    },
];

const data2 = [
    {
        "Clause": "Orden de Inicio",
        "NumClause": "01",
        "DateExpiration": "2021-10-13",
        "DateStatus": "-",
        "TimeExpiration": "0 días",
        "Responsible": "Susanne Hoch",
        "Status": "En Revisión",
        "StatusCritic": "Alta",
        "Category": "Operación y Mantenimiento"
    },
    {
        "Clause": "Plazo de Construcción",
        "NumClause": "02",
        "DateExpiration": "2021-10-14",
        "DateStatus": "-",
        "TimeExpiration": "0 días",
        "Responsible": "Kristian",
        "Status": "Pendiente",
        "StatusCritic": "Alta",
        "Category": "Plazo Crítico"
    }
];




const FormReports = ({ handleToggleDialog }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>

                <Box display="flex" mx="" my="" sx="" justifyContent="space-between">
                    <IconButton style={{ background: 'none' }} aria-label="edit" color="primary" onClick={handleToggleDialog} >
                        <ChevronLeft color="primary" fontSize="large" />
                        <Typography variant="h4" component="h1" gutterBottom color='primary' className="m-1">
                            Nuevo Reporte
                        </Typography>
                    </IconButton>

                    <Box display="flex" alignItems="center" mx={2}>
                        <Button onClick={() => ReportsPdf({ data2 })} variant="contained">Crear reporte</Button>

                    </Box>

                </Box>


            </Grid>

            <Grid item xs={3} className='overflow-y-scroll'>
                <Formik
                    initialValues={{
                        nameReport: '',
                        nameProject: [],
                        dateExpiration: [],
                        dateAcceptation: [],
                        statusAcceptation: [],
                        userResponsible: [],
                        criticity: [],
                        status: [],
                        category: [],
                        industry: [],
                        type: [],
                        country: [],
                        view: []
                    }}
                >
                    {
                        ({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                            touched,
                            values
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Container maxWidth="lg">
                                    <FormControl fullWidth margin="dense" required>
                                        <InputLabel variant="standard" shrink htmlFor="nameReport">Nombre del Reporte:</InputLabel>
                                        <Input id="nameReport" name="nameReport" value={values.nameReport} onChange={handleChange} />
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="nameProject">Nombre de los proyectos:</InputLabel>
                                        <Select id="nameProject" name="nameProject" placeholder='Selecciona una opción' value={values.nameProject} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="dateExpiration">Fecha de Vencimiento / Periodo:</InputLabel>
                                        <Select id="dateExpiration" name="dateExpiration" placeholder='Selecciona una opción' value={values.dateExpiration} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="dateAcceptation">Fecha de Aprob / Rechaz: </InputLabel>
                                        <Select id="dateAcceptation" name="dateAcceptation" value={values.dateAcceptation} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="statusAcceptation">Estado de Aprobación:</InputLabel>
                                        <Select id="statusAcceptation" name="statusAcceptation" placeholder='Selecciona una opción' value={values.statusAcceptation} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="userResponsible">Por usuario responsable:</InputLabel>
                                        <Select id="userResponsible" name="userResponsible" placeholder='Selecciona una opción' value={values.userResponsible} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="criticity">Criticidad:</InputLabel>
                                        <Select id="criticity" name="criticity" placeholder='Selecciona una opción' value={values.criticity} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="status">Estado: </InputLabel>
                                        <Select id="status" name="status" placeholder='Selecciona una opción' value={values.status} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="category">Categoría:</InputLabel>
                                        <Select id="category" name="category" placeholder='Selecciona una opción' value={values.category} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="industry">Industrias:</InputLabel>
                                        <Select id="industry" name="industry" placeholder='Selecciona una opción' value={values.industry} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="type">Tipo:</InputLabel>
                                        <Select id="type" name="type" placeholder='Selecciona una opción' value={values.type} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>


                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="country">Por país:</InputLabel>
                                        <Select id="country" name="country" placeholder='Selecciona una opción' value={values.country} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth margin="dense" required className="border-0">
                                        <InputLabel shrink htmlFor="view">Mostrar:</InputLabel>
                                        <Select id="view" name="view" placeholder='Selecciona una opción' value={values.view} onChange={handleChange}>
                                            <MenuItem value="">Datos Prueba</MenuItem>
                                            <MenuItem value="tech">Datos Prueba</MenuItem>
                                            <MenuItem value="finance">Datos Prueba</MenuItem>
                                        </Select>
                                    </FormControl>


                                </Container>
                            </form>
                        )
                    }

                </Formik>
            </Grid>
            <Grid item xs={9}>
                <Container maxWidth="lg">
                <Grid container spacing={2} className="mb-4">
                    <div style={{ background: 'rgb(236,240,243)', textAlign: 'center', width: '100%' }} >
                        <Typography variant="h5" component="h1" gutterBottom color='primary' className='m-1 fw-bold'>
                            Nombre del Reporte
                        </Typography>
                    </div>
                    <Typography variant="h6" component="h1" gutterBottom color='primary' className='m-1 ms-3 fw-bold mt-4'>
                        Nombre del Proyecto Completo
                    </Typography>

                </Grid>
                <Divider variant="middle" color='rgb(128,128,128)' className="mt-2 mb-2 w-100 m-0" />
                <Box display="flex" justifyContent="space-between" mx="" my="" sx="">
                    <caption style={{ color: 'rgb(128,128,128)' }}>
                        The Project International Group Prueba 1
                    </caption>
                    <caption style={{ color: 'rgb(128,128,128)' }}>
                        Número de contrato: 001
                    </caption>
                    <caption style={{ color: 'rgb(128,128,128)' }} className='me-3'>
                        Zona Horaria/País: América/Costa Rica

                    </caption>
                </Box>

                <Divider variant="middle" color='rgb(128,128,128)' className="mt-2  w-100 m-0" />

                <Box display="flex" justifyContent="space-between" mx="" my="" sx="">
                    <Typography variant="p" gutterBottom color='primary' className='fw-bold mt-4'>
                        Nombre Grupo de Cláusulas
                    </Typography>

                    <Typography variant="p" gutterBottom color='primary' className='fw-bold mt-4 me-3'>
                        Generado el:
                        <Typography variant="p" gutterBottom color='gray' className='ms-2'>
                            12 de Octubre, 2021 Hora: 14:45
                        </Typography>
                    </Typography>

                </Box>

                <TableContainer component={Paper} >
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        style={{
                            backgroundColor: "#F0F4F7",
                        }}
                        className='mt-4'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: "#476AC2",
                                    }}
                                >
                                    <b>Cláusula</b>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: "#476AC2",
                                    }}
                                >
                                    <b> Número de Cláusula</b>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: "#476AC2",
                                    }}
                                >
                                    <b> Fecha de vencimiento</b>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: "#476AC2",
                                    }}
                                >
                                    <b> Fecha de Aprob/Rechaz</b>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: "#476AC2",
                                    }}
                                >
                                    <b> Tiempo de Retraso</b>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: "#476AC2",
                                    }}
                                >
                                    <b> Tiempo de Aprobación</b>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: "#476AC2",
                                    }}
                                >
                                    <b> Responsable</b>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: "#476AC2",
                                    }}
                                >
                                    <b> Estado</b>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: "#476AC2",
                                    }}
                                >
                                    <b> Criticidad</b>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: "#476AC2",
                                    }}
                                >
                                    <b> Categoría</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                    }}
                                >
                                    <TableCell
                                        style={{
                                            backgroundColor: "#EDF4F5",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        <Grid container="3">
                                            {row.initial}
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">{row.clauseNumber}</TableCell>
                                    <TableCell align="center">{row.dueDate}</TableCell>
                                    <TableCell align="center">
                                        {row.approvalRejectionDate}
                                    </TableCell>
                                    <TableCell align="center">{row.delayTime}</TableCell>
                                    <TableCell align="center">{row.approvalTime}</TableCell>
                                    <TableCell align="center">{row.responsible}</TableCell>

                                    {row.state === "Aprobado Con Retraso" ? (
                                        <TableCell align="center"
                                            style={{
                                                color: "#9B4AFD",
                                            }}
                                        >
                                            <b>{row.state}</b>
                                        </TableCell>
                                    ) : (
                                        <TableCell align="center"
                                            style={{
                                                color: "#4FA2F9",
                                            }}
                                        >
                                            <b>{row.state}</b>
                                        </TableCell>
                                    )}
                                    {row.criticality === "Alta" ? (
                                        <TableCell
                                            style={{
                                                color: "#FC4343",
                                            }}
                                            align="center"
                                        >
                                            <b> {row.criticality}</b>
                                        </TableCell>
                                    ) : (
                                        <TableCell
                                            style={{
                                                color: "#FDE84C",
                                            }}
                                            align="center"
                                        >
                                            <b> {row.criticality}</b>
                                        </TableCell>
                                    )}

                                    <TableCell align="center">{row.category}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Filas por página"
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
                  
                </Container>

            </Grid>
        </Grid>
    )
}

export default FormReports