import { useState } from "react";
import { FilterAlt, Visibility } from "@mui/icons-material"
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Container, Box, FormControl, Select, MenuItem } from "@mui/material"
import NoBorderInput from "../general/styles";
import PDFIcon from '../../../../assets/icons/pdf-icon.svg';

const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];


const DocumentsViews = ({ documentRows }) => {
    const [selectedMonth, setSelectedMonth] = useState('Por mes');

    const ITEM_HEIGHT = 30;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 150,
            },
        },
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };


    return (
        <Container>
            <div className="d-flex justify-content-between">
                <Typography variant="body2" color="initial" className="mb-2">{documentRows.length} Documentos</Typography>
                <Box display="inline-flex">
                    <FormControl fullWidth>
                        <Select
                            labelId="Month-select-label"
                            id="Month-select"
                            MenuProps={MenuProps}
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            label="Año"
                            variant="standard"
                            input={<NoBorderInput />}
                            renderValue={(selected) =>  selected}
                        >
                            {months.map((month) => (
                                <MenuItem key={month} value={month}>
                                    {month}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <IconButton aria-label="settings" aria-controls="project-menu" aria-haspopup="true">
                        <FilterAlt color="primary" />
                    </IconButton>
                </Box>
            </div>




            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Nombre documento</TableCell>
                            <TableCell>Ubicación</TableCell>
                            <TableCell>Peso</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentRows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell align="center">
                                    <img src={PDFIcon} alt="Imagen PDF" width={30} />
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" color="initial">
                                        {row.name}
                                    </Typography>
                                    <Typography variant="caption" color="GrayText">
                                        Subido por <b variant="body1" color="initial">
                                            {row.uploadedBy}, {row.date}
                                        </b>
                                    </Typography>
                                </TableCell>
                                <TableCell><Typography variant="caption" color="initial">
                                    Subido en la <b> {row.location} </b>
                                </Typography> </TableCell>
                                <TableCell><Typography variant="caption" color="initial">
                                    {row.size}
                                </Typography> </TableCell>
                                <TableCell>
                                    <IconButton aria-label="view document">
                                        <Visibility color="primary" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}

export default DocumentsViews;