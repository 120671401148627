import { useEffect } from "react";
import { Grid, Typography, Divider, List, ListItemButton, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, InputLabel, Input, Chip, DialogActions } from '@mui/material';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Container, Button, Box, FormControl, Select, IconButton, MenuItem } from "@mui/material"
import { useState } from "react";
import { Description, FilterAlt } from "@mui/icons-material";
import TopBar from "../../../components/topbar/topbar";
import NoBorderInput from "../projects/general/styles";
import ReportsPdf from "../../../components/reports/clauses";
import useStyles from "./styles";
import FormReports from "./formReports";
import { Formik } from "formik";

const data2 = [
    {
        "Clause": "Orden de Inicio",
        "NumClause": "01",
        "DateExpiration": "2021-10-13",
        "DateStatus": "-",
        "TimeExpiration": "0 días",
        "Responsible": "Susanne Hoch",
        "Status": "En Revisión",
        "StatusCritic": "Alta",
        "Category": "Operación y Mantenimiento"
    },
    {
        "Clause": "Plazo de Construcción",
        "NumClause": "02",
        "DateExpiration": "2021-10-14",
        "DateStatus": "-",
        "TimeExpiration": "0 días",
        "Responsible": "Kristian",
        "Status": "Pendiente",
        "StatusCritic": "Alta",
        "Category": "Plazo Crítico"
    }
];

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;

const rows = [
    { id: 1, name: 'Reporte de cláusulas con Criticidad Alta', date: '10/Nov/2021, 02:47:24 pm', location: 'amazons3location.com' }
];

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
        },
    },
};

const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];




const Reports = ({ reportsRows, isEdit }) => {
    const classes = useStyles()
    const [selectedMonth, setSelectedMonth] = useState('Por mes');
    const [toggleDialog, setToggleDialog] = useState(false)
    const data = isEdit ? reportsRows : rows

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };
    const handleToggleDialog = () => {
        setToggleDialog(!toggleDialog)
    }

    const listItems = [
        { id: 0, text: 'Mis reportes', Icon: Description }
    ];

    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };


    const reportsSidebar = (
        <Box width={'100%'}>
            <List component="nav" aria-label="main mailbox folders" style={{
        width: "100%",
        position: "sticky",
        top: "20px",
        height: "fit-content"
      }}>
                {listItems.map((item, index) => (
                    <ListItemButton
                        key={item.id}
                        classes={{ root: classes.selectedItem }} // Usamos la clase definida para estilizar
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}
                    >
                        <ListItemIcon className={classes.icon}>
                            <item.Icon />
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    )


    useEffect(() => {
        const originalTitle = document.title;
        document.title = "MGC - Reportes";
        return () => {
            document.title = originalTitle;
        };
    }, []);

    return (
        <div>
            {!isEdit && <TopBar />}
            {!toggleDialog && <><Grid container>
                {!isEdit && <Grid item sm={6}>
                    <Typography variant="h4" component="h1" gutterBottom color='primary' className="m-3">
                        Reportes
                    </Typography>
                </Grid>}
                <Grid item sm={isEdit ? 12 : 6} className={classes.projectStyle}>
                    <Button onClick={() => handleToggleDialog()} className="m-2" variant="contained">Crear reporte</Button>
                </Grid>
            </Grid><Divider className="mb-1" /><Grid container spacing={2}>
                    {!isEdit && <Grid item lg={2} md={3} sm={4} xs={12} className="d-flex">
                        {reportsSidebar}
                        <Divider orientation="vertical" />
                    </Grid>}
                    <Grid item lg={isEdit ? 12 : 10} md={9} sm={8} xs={12}>
                        <Container maxWidth="lg">
                            <Box display="flex" justifyContent="space-between" my={1}>
                                <Typography variant="body2" color="rgb(138,142,146)" className="m-1 fw-bold">{data?.length} reportes</Typography>
                                <Divider />

                                <Box display="inline-flex">
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="Month-select-label"
                                            id="Month-select"
                                            MenuProps={MenuProps}
                                            value={selectedMonth}
                                            onChange={handleMonthChange}
                                            label="Año"
                                            variant="standard"
                                            input={<NoBorderInput />}
                                            renderValue={(selected) => selected}
                                        >
                                            {months.map((month) => (
                                                <MenuItem key={month} value={month}>
                                                    {month}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <IconButton aria-label="settings" aria-controls="project-menu" aria-haspopup="true">
                                        <FilterAlt color="primary" />
                                    </IconButton>
                                </Box>
                            </Box>

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.cell}>Nombre documento</TableCell>
                                            <TableCell className={classes.cell}>Fecha del reporte</TableCell>
                                            <TableCell className={classes.cell}>Documento</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <Typography variant="body1" color="rgb(97,101,105)">
                                                        {row.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body1" color="rgb(97,101,105)">
                                                        {row.date}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Button onClick={() => ReportsPdf({ data2 })}>
                                                        <Typography variant="body1" color="primary" textTransform='capitalize'>
                                                            Descargar
                                                        </Typography>
                                                    </Button>

                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </Grid>

                </Grid></>}

            {toggleDialog && <FormReports handleToggleDialog={handleToggleDialog} />}



        </div>
    )
}

export default Reports;



