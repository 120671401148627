import { ChevronLeft } from "@mui/icons-material";
import {
  Divider,
  Container,
  LinearProgress,
  Grid,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import EmptyIcon from "src/assets/emptyBox.png";
import { useSelector } from "react-redux";
import apiMasterPlanService from "src/services/masterPlanService";
import ReactApexChart from "react-apexcharts";
import { toDecimal } from "src/utils/toDecimal";
const MasterPlanInfo = ({ masterPlan, isView, setView }) => {
  const [loading, setLoading] = useState(true);
  const [matrizSummaryPMEst, setMatrizSummaryPMEst] = useState([]);
  const [keysIdsMount, setKeysIdsMount] = useState([]);
  const [keysIds, setKeysIds] = useState([]);
  const [grafEst, setGrafEst] = useState([]);
  const [grafReal, setGrafReal] = useState([]);
  const [grafEstAcc, setGrafEstAcc] = useState([]);
  const [grafRealAcc, setGrafRealAcc] = useState([]);

  const Project = useSelector((state) => state?.setUserLogin?.project?.id);
  console.log(grafEst, grafReal);
  const lineChart = {
    series: [
      {
        name: "Estimado",
        data: grafEst || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Reales",
        data: grafReal || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3],
        curve: "straight",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - <strong>" +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            "</strong>"
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: keysIdsMount || [],
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (mins)";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " per session";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };

  const lineChartAcc = {
    series: [
      {
        name: "Estimado",
        data: grafEstAcc || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Reales",
        data: grafRealAcc || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3],
        curve: "straight",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - <strong>" +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            "</strong>"
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: keysIds || [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (mins)";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " per session";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };
  useEffect(() => {
    try {
      const colDetalle = [
        { id: "name", label: "Descripción de la Obra" },
        { id: "total", label: "Total" },
      ];
      const keysId = ["Total"];
      const keysGrafic = ["Total"];
      let keysGraficId = [];
      let keysGraficIdMount = [];
      masterPlan.project = Project;

      apiMasterPlanService.postByMasterPlan(masterPlan).then((data) => {
        setMatrizSummaryPMEst(data?.data?.matrizSummaryPMEstComp || []);
        if (data?.data?.mastrizDetailMount?.length > 0) {
          const dateKeysEst = Object.keys(
            data?.data?.mastrizDetailMount[0]
          ).filter((key) => key !== "total" && key !== "name");
          dateKeysEst.sort((a, b) => new Date(a) - new Date(b));

          const resultArrayEst = [
            toDecimal(data?.data?.mastrizDetailMount[0].total),
            ...dateKeysEst.map((date) =>
              toDecimal(data?.data?.mastrizDetailMount[0][date])
            ),
          ];
          resultArrayEst?.shift();
          setGrafEst(resultArrayEst);
          const dateKeysReal = Object.keys(
            data?.data?.mastrizDetailMount[1]
          ).filter((key) => key !== "total" && key !== "name");
          dateKeysReal.sort((a, b) => new Date(a) - new Date(b));
          const resultArrayReal = [
            toDecimal(data?.data?.mastrizDetailMount[1].total),
            ...dateKeysReal.map((date) =>
              toDecimal(data?.data?.mastrizDetailMount[1][date])
            ),
          ];
          resultArrayReal?.shift();
          setGrafReal(resultArrayReal);
          const dateKeysEstAcc = Object.keys(
            data?.data?.mastrizDetailMountAcc[0]
          ).filter((key) => key !== "total" && key !== "name");
          dateKeysEstAcc.sort((a, b) => new Date(a) - new Date(b));
          const resultArrayEstAcc = [
            toDecimal(data?.data?.mastrizDetailMountAcc[0].total),
            ...dateKeysEstAcc.map((date) =>
              toDecimal(data?.data?.mastrizDetailMountAcc[0][date])
            ),
          ];

          setGrafEstAcc(resultArrayEstAcc);

          const dateKeysRealAcc = Object.keys(
            data?.data?.mastrizDetailMountAcc[1]
          ).filter((key) => key !== "total" && key !== "name");
          dateKeysRealAcc.sort((a, b) => new Date(a) - new Date(b));
          const resultArrayRealAcc = [
            toDecimal(data?.data?.mastrizDetailMountAcc[1].total),
            ...dateKeysRealAcc.map((date) =>
              toDecimal(data?.data?.mastrizDetailMountAcc[1][date])
            ),
          ];
          setGrafRealAcc(resultArrayRealAcc);

          const keys = Object.keys(data?.data?.mastrizDetailEst[0] || []);
          for (let i = 2; i < keys.length; i++) {
            keysId.push(keys[i]);
            keysGrafic.push(keys[i]);
            colDetalle.push({
              id: keys[i],
              label: keys[i],
            });
          }

          const keysReal = Object.keys(data?.data?.mastrizDetailReal[0] || []);
          for (let i = 2; i < keysReal.length; i++) {
            keysGrafic.push(keysReal[i]);
            colDetalle.push({
              id: keysReal[i],
              label: keysReal[i],
            });
          }
          keysGraficId = [...new Set(keysGrafic)];
          keysGraficIdMount = [...new Set(keysGrafic)];
        }
        keysGraficIdMount?.shift();
        setKeysIds(keysGraficId);
        setKeysIdsMount(keysGraficIdMount);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Error al obtener las obras", { variant: "error" });
    }
  }, [Project, masterPlan]);
  return (
    <Container>
      <div className="d-flex justify-content-between  mb-4">
        <IconButton
          style={{ background: "none" }}
          aria-label="edit"
          color="primary"
          onClick={() => {
            setView(false);
          }}
        >
          <ChevronLeft color="primary" fontSize="large" />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            color="primary"
            className="m-1"
          >
            Comparativo del Resumen del Plan Maestro
          </Typography>
        </IconButton>

        {/* <Tooltip title="Filtrar">
          <IconButton aria-label="" color="primary">
            <FilterAlt />
          </IconButton>
        </Tooltip> */}
      </div>
      {!loading ? (
        <>
          <Divider className="mb-3" />
          <Grid container={2} className="mt-3">
            <Container maxWidth="lg">
              <div className="d-flex justify-content-between">
                <Divider />
              </div>

              {matrizSummaryPMEst.length > 0 ? (
                <>
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    color="primary"
                    className="mb-2 fw-bold"
                  >
                    Detalle Mensual
                  </Typography>
                  <Divider />
                  <ReactApexChart
                    options={lineChart.options}
                    series={lineChart.series}
                    type="line"
                    height={300}
                    width={1200}
                  />
                  <br />
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    color="primary"
                    className="mb-2 fw-bold"
                  >
                    Detalle Mensual Acumulado
                  </Typography>
                  <Divider />
                  <ReactApexChart
                    options={lineChartAcc.options}
                    series={lineChartAcc.series}
                    type="line"
                    height={300}
                    width={1200}
                  />
                </>
              ) : (
                <Box textAlign="center">
                  <img src={EmptyIcon} alt="Imagen de no data" width={150} />
                  <Typography variant="h6" color="GrayText">
                    No hay datos disponibles.
                  </Typography>
                </Box>
              )}
            </Container>
          </Grid>
        </>
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Container>
  );
};

export default MasterPlanInfo;
