import { Close, Delete, DriveFolderUpload, Edit, FileDownload, FileUpload, Folder, Save } from "@mui/icons-material";
import { Checkbox, Divider, Grid, IconButton, Input, ListItemText, MenuItem, Select, Typography, Button, Container, TextField, Autocomplete, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, LinearProgress } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { styled } from '@mui/styles';
import timezones from "src/utils/timezones";
import moment from 'moment';
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import apiUserService from "src/services/usersService";
import apiProjectsService from "src/services/projectsService";
import apiCustomersService from "src/services/customersService";
import { useSelector } from "react-redux";
import permissionsService from "src/services/permissionsService";

const MAX_FILENAME_LENGTH = 100;


const InformationView = ({ User }) => {
    const Project = useSelector((state) => state?.setUserLogin?.project);
    const [isEdit, setEdit] = useState(false);
    const [projectData, setProjectData] = useState(null);
    const [contractFile, setContractFile] = useState(null);
    const [documentsFiles, setDocumentsFiles] = useState([]);
    const [customers, setCustomers] = useState([])
    const [settingsProjects, setSettingsProjects] = useState({
        type: [],
        industry: [],
        criticality: []
    });
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState({
        index: null,
        type: null
    });
    const [showFullClause, setShowFullClause] = useState({});
    const [newFiles, setNewFiles] = useState([]);
    const [textDialog, setTextDialog] = useState(false);
    const [deleteFiles, setDeleteFiles] = useState([])
    const [newContract, setNewContract] = useState(null)
    const [users, setUsers] = useState([]);
    const [textMessage, setTextMessage] = useState({
        title: "",
        text: ""
    })
    const [checkData, setCheckData] = useState(false)
    const [showDialog, setShowDialog] = useState(false);
    const formikRef = useRef(null);
    const myRol = User?.role?.label !== "Super Administrador" && User?.role?.label !== "Administrador TPIG" ? User?.projects?.find(user => user?.project?.id === Project?.id) : User;

    const handleClickOpen = (index, type) => {
        setSelectedIndex({
            index,
            type
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDialog = () => {
        setShowDialog(!showDialog);
    };


    const handleDelete = (index) => {
        let documents;
        if (index.type === 'old') {
            // Actualiza los archivos eliminados en deleteFiles
            const deletedFile = documentsFiles[index.index];
            setDeleteFiles(prevDocuments => [...prevDocuments, deletedFile])

            // Filtra los archivos en la categoría "old"
            const updatedDocumentsFiles = documentsFiles.filter((_, i) => i !== index.index);
            setDocumentsFiles(updatedDocumentsFiles);

        }

        if (index.type === 'new') {
            documents = newFiles.filter((_, i) => i !== index.index);
            setNewFiles(documents);
        }

    };

    const handleConfirmDelete = () => {
        handleDelete(selectedIndex);
        setOpen(false);
    };


    const fetchProyectId = async () => {
        await apiProjectsService.getById(Project.id).then((res) => {
            if (res.data?.files && res?.data?.files?.length > 0) {
                organizeFiles(res.data?.files);
            }
            setProjectData(res.data)
        }).catch((res) => {
            enqueueSnackbar("Ha ocurrido un error al obtener los datos del proyecto", { variant: "error" })
        })
    }

    const handleToggleTextDialog = (title, message) => {
        setTextMessage({
            title: title,
            text: message
        })
        setTextDialog(true);
    };

    const DialogText = (
        <Dialog
            onClose={() => setTextDialog(false)}
            aria-labelledby="customized-dialog-title"
            open={textDialog}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" color="primary">
                {textMessage?.title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setTextDialog(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
            <DialogContent dividers>
                <Typography gutterBottom textAlign="justify">
                    {textMessage?.text}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => setTextDialog(false)}>
                    Salir
                </Button>
            </DialogActions>
        </Dialog>
    )



    useEffect(() => {
        fetchProyectId();
        fetchSettingsProjects();
        fetchCustomers();
        fetchUsers()
    }, [Project.id, checkData]);




    const fetchCustomers = async () => {
        try {
            apiCustomersService.get().then((data) => {
                setCustomers(data.data);
            });
        } catch (error) {
            enqueueSnackbar("Error al obtener los datos", { variant: "error" });
        }
    };

    const fetchSettingsProjects = async () => {
        try {
            await apiUserService
                .getSettings(User.id).then((res) => {
                    setSettingsProjects(res?.data);
                });
        } catch (error) {
            if (error?.response?.data === "No settings found for the user") {
                enqueueSnackbar('No se han agregado industrias o tipos, agrégalas en el módulo de AJUSTES', { variant: 'info' });

            } else {
                enqueueSnackbar('Error al obtener las configuraciones', { variant: 'error' });

            }
        }
    };

    const fetchUsers = async () => {
        try {
            apiUserService
                .get().then((data) => {
                    setUsers(data.data);
                });
        } catch (error) {
            enqueueSnackbar('Error al obtener los datos', { variant: 'error' })
        }
    };


    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });


    const handleDocumentsFileChange = (selectedFiles) => {
        if (selectedFiles.length > 10 || newFiles.length > 10) {
            enqueueSnackbar("Se ha superado el límite de 10 archivos permitidos por carga.", {
            variant: "error",
            });
            return;
        }

        const sanitizedFiles = Array.from(selectedFiles).map(file => {
            const sanitizedFilename = sanitizeFilename(file.name);
            const truncatedFilename = truncateFilename(sanitizedFilename);
            return new File([file], truncatedFilename, { type: file.type });
        });

        setNewFiles(prevDocuments => [...prevDocuments, ...sanitizedFiles]);
    };

    const handleCancelEdit = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        setNewFiles([]);
        setNewContract(null)
        setEdit(false);
        handleDialog()
    }


    const organizeFiles = (files) => {
        setDocumentsFiles([])
        files.forEach(file => {
            const pathParts = file.name.split('/');
            const fileName = pathParts.pop();
            const type = pathParts.includes('contratos') ? 'contract' : 'document';

            if (type === 'contract') {
                setContractFile({
                    name: fileName,
                    url: file.url
                });
            } else {
                setDocumentsFiles(prevDocuments => [
                    ...prevDocuments,
                    {
                        name: fileName,
                        url: file.url || ''
                    }
                ]);
            }
        });
    };

    function relativeDate(startDate, endDate) {
        const start = moment(startDate);
        const end = moment(endDate);

        // Calcular la diferencia total en años, meses y días
        const yearsTotal = end.diff(start, "years");

        // Calcular el residuo de años
        const startPlusYears = moment(startDate).add(yearsTotal, 'years');
        const monthsAfterYears = end.diff(startPlusYears, 'months');

        // Calcular el residuo de meses
        const startPlusYearsAndMonths = startPlusYears.add(monthsAfterYears, 'months');
        const daysAfterMonths = end.diff(startPlusYearsAndMonths, 'days');

        // Construir la cadena de tiempo con los residuos
        let time = "";
        if (yearsTotal > 0) {
            time += `${yearsTotal} año(s), `;
        }
        if (monthsAfterYears > 0) {
            time += `${monthsAfterYears} mes(es), `;
        }
        if (daysAfterMonths > 0) {
            time += `${daysAfterMonths} día(s)`;
        }

        // Eliminar la coma adicional si existe
        if (time.endsWith(', ')) {
            time = time.slice(0, -2);
        }

        return time;
    }

    const handleContractFileChange = (event) => {
        const file = event;
        if (file) {
            const sanitizedFilename = sanitizeFilename(file.name);
            const truncatedFilename = truncateFilename(sanitizedFilename);
            const sanitizedFile = new File([file], truncatedFilename, { type: file.type });
            setNewContract(sanitizedFile);
        }
    };


    const sanitizeFilename = (filename) => {
        return filename.replace(/[^a-zA-Z0-9._-]/g, '_');
    };

    const truncateFilename = (filename) => {
        if (filename.length > MAX_FILENAME_LENGTH) {
            const extensionIndex = filename.lastIndexOf('.');
            const extension = extensionIndex !== -1 ? filename.substring(extensionIndex) : '';
            const baseName = extensionIndex !== -1 ? filename.substring(0, extensionIndex) : filename;
            return baseName.substring(0, MAX_FILENAME_LENGTH - extension.length) + extension;
        }
        return filename;
    };


    const handleDirty = (dirty) => {
        if (dirty || newFiles.length > 0 || newContract) {
            handleDialog()
        } else {
            setEdit(false)
            setNewContract(null)
            setNewFiles([])
        }

    }



    const DialogReturn = (
        <Dialog open={showDialog} onClose={handleDialog}>
            <DialogTitle>Tiene cambios sin guardar</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    ¿Está seguro de cancelar los cambios?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialog} color="primary">
                    Seguir editando
                </Button>
                <Button onClick={() => handleCancelEdit()} color="primary">
                    Cancelar cambios
                </Button>
            </DialogActions>
        </Dialog>
    )

    const handleShow = (rowId) => {
        setShowFullClause({ ...showFullClause, [rowId]: !showFullClause[rowId] });
    };


    return (<Container>

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Estás seguro de que quieres eliminar este archivo?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleConfirmDelete} color="error" autoFocus>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>

        {projectData ? <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={{
                name: projectData?.name || "",
                projectScope: projectData?.projectScope || "",
                clientNameCompany: projectData?.clientNameCompany || "",
                contractNumber: projectData?.contractNumber || "",
                timeZone: projectData?.timeZone || "",
                projectType: projectData?.projectType || "",
                generalAdmin: projectData?.generalAdmin || "",
                industry: projectData?.industry || "",
                startDate: projectData?.startDate || null,
                endDate: projectData?.endDate || null,
                delegationPeriod: projectData?.delegationPeriod || "",
                responsible: projectData?.responsible || ""
            }}
            onSubmit={async (values) => {
                const infoProject = {
                    ...values,
                    responsible: values.responsible.id,
                    generalAdmin: values.generalAdmin.id
                }
                
                await apiProjectsService.update(infoProject, newContract, newFiles, deleteFiles, Project.id).then((res) => {
                    if (deleteFiles.length > 0 || newContract || newFiles.length > 0) {
                        setDeleteFiles([]);
                        setNewContract(null);
                        setNewFiles([]);
                        setCheckData(!checkData)
                    }
                    enqueueSnackbar("Proyecto actualizado con éxito", { variant: "success" })
                    setProjectData(values)
                }).catch((res) => {
                    console.log(res);
                    enqueueSnackbar("Ha ocurrido un error al actualizar", { variant: "error" })

                })
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isValid,
                isSubmitting,
                touched,
                values,
                dirty
            }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} className="p-4">
                        <Grid item lg={6}>
                            <Typography variant="h5" color="primary">
                                Información General
                            </Typography>
                        </Grid>
                        <Grid item lg={6} justifyContent="end" display="flex">
                            {isEdit && <LoadingButton
                                size="medium"
                                variant="contained"
                                color="error"
                                className="me-2"
                                startIcon={<Close />}
                                onClick={() => handleDirty(dirty)}
                            >
                                Cancelar
                            </LoadingButton>
                            }
                            {!isEdit && permissionsService("projects", User?.role?.label, "edit") &&  <Button variant="contained" color="primary" startIcon={<Edit />} onClick={() => setEdit(true)}>
                                Editar
                            </Button>}
                            {isEdit && <LoadingButton
                                size="medium"
                                variant="contained"
                                type="submit"
                                startIcon={<Save />}
                                disabled={((!dirty) && newFiles.length === 0 && newContract === null && deleteFiles.length === 0)}
                                loading={isSubmitting}
                                loadingPosition="start"

                            >
                                {"Guardar cambios"}
                            </LoadingButton>}
                        </Grid>
                    </Grid>
                    <Divider
                        variant="middle"
                        style={{ backgroundColor: "gray !important" }}
                        className="m-0"
                    />
                    {/* Información General */}
                    <Grid container spacing={2} className="p-4">
                        <Grid item lg={6}>
                            <Grid container spacing={2}>
                                <Grid item lg={4}>
                                    <Typography variant="subtitle1" color="black" fontWeight="bold">
                                        Alcance del proyecto
                                    </Typography>
                                </Grid>
                                <Grid item lg={8}>
                                    {isEdit ? (
                                        <TextField
                                            rows={8}
                                            multiline
                                            id="projectScope"
                                            name="projectScope"
                                            fullWidth
                                            variant="standard"
                                            value={values.projectScope}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.projectScope && errors.projectScope)}
                                            helperText={touched.projectScope && errors.projectScope}
                                        />
                                    ) : (
                                        <Box>
                                            <Typography variant="subtitle1" color="gray" textAlign="justify">
                                                {values?.projectScope.length > 200 ? (values?.projectScope || '').slice(0, 200) + '...' : values?.projectScope}
                                            </Typography>
                                            {values?.projectScope.length > 200 && (
                                                <span
                                                    onClick={() => handleToggleTextDialog("Alcance del proyecto", values?.projectScope)}
                                                    style={{ color: 'GrayText', cursor: 'pointer', fontSize: '10px', marginLeft: '5px' }}
                                                >
                                                    Ver más
                                                </span>
                                            )}
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={6}>
                            <Grid container spacing={2}>
                                <Grid item lg={4}>
                                    <Typography variant="subtitle1" color="black" fontWeight="bold">
                                        Nombre de firmantes
                                    </Typography>
                                </Grid>
                                <Grid item lg={8}>
                                    {isEdit ? (
                                        <Autocomplete
                                            multiple
                                            freeSolo
                                            options={[]}
                                            autoSelect
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip key={index} label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            value={values.clientNameCompany}
                                            onChange={(e, newValue) => {
                                                setFieldValue("clientNameCompany", newValue);
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value.length === 0) {
                                                    setFieldTouched("clientNameCompany", true);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    name="clientNameCompany"
                                                    id="clientNameCompany"
                                                    className="w-100"
                                                    error={touched.clientNameCompany && Boolean(errors.clientNameCompany)}
                                                    helperText={touched.clientNameCompany && errors.clientNameCompany}
                                                />
                                            )}
                                            sx={{ width: '100%' }}
                                        />
                                    ) : (
                                        <Typography variant="subtitle1" color="gray">
                                            {(values?.clientNameCompany.length > 0 && values?.clientNameCompany?.join(", ")) || ''}
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item lg={4}>
                                    <Typography variant="subtitle1" color="black" fontWeight="bold">
                                        Número de contrato
                                    </Typography>
                                </Grid>
                                <Grid item lg={8}>
                                    {isEdit ? (
                                        <TextField
                                            id="contractNumber"
                                            name="contractNumber"
                                            fullWidth
                                            variant="standard"
                                            value={values.contractNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.contractNumber && errors.contractNumber)}
                                            helperText={touched.contractNumber && errors.contractNumber}
                                        />
                                    ) : (
                                        <Typography variant="subtitle1" color="gray">
                                            {values?.contractNumber}
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item lg={4}>
                                    <Typography variant="subtitle1" color="black" fontWeight="bold">
                                        Zona Horaria / País
                                    </Typography>
                                </Grid>
                                <Grid item lg={8}>
                                    {isEdit ? (
                                        <Autocomplete
                                            clearIcon={false}
                                            options={timezones}
                                            autoHighlight
                                            noOptionsText="No se han encontrado resultados"
                                            defaultValue={(timezones.find((time) => time.name === values.timeZone)) || ''}
                                            onChange={(e, value) => {
                                                setFieldValue("timeZone", value?.name || '')
                                            }}
                                            onBlur={handleBlur}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps
                                                    }}
                                                    id="timeZone"
                                                    name="timeZone"
                                                    variant="standard"
                                                    error={touched.timeZone && Boolean(errors.timeZone)}
                                                    helperText={touched.timeZone && errors.timeZone}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <Typography variant="subtitle1" color="gray">
                                            {values?.timeZone}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider
                        variant="middle"
                        style={{ backgroundColor: "gray !important" }}
                        className="m-0"
                    />
                    {/* Sobre el proyecto */}
                    <Grid container spacing={2} className="p-4">
                        <Grid item lg={12}>
                            <Typography variant="h5" color="primary">
                                Sobre el proyecto
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider
                        variant="middle"
                        style={{ backgroundColor: "gray !important" }}
                        className="m-0"
                    />

                    <Grid container spacing={2} className="p-4">
                        <Grid item lg={2}>
                            <Typography variant="subtitle1" color="black" fontWeight="bold">
                                Tipo de proyecto
                            </Typography>
                        </Grid>
                        <Grid item lg={4}>
                            {isEdit ? (
                                <Autocomplete
                                    className="mt-2"
                                    clearIcon={false}
                                    options={settingsProjects?.type}
                                    value={values.projectType}
                                    autoHighlight
                                    noOptionsText="No se han encontrado resultados"
                                    onChange={(e, value) => {
                                        setFieldValue("projectType", value || "")
                                    }}
                                    onBlur={handleBlur}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps
                                            }}
                                            id="projectType"
                                            name="projectType"
                                            variant="standard"
                                            error={
                                                touched.projectType && Boolean(errors.projectType)
                                            }
                                            helperText={
                                                touched.projectType && errors.projectType
                                            }
                                        />
                                    )}
                                >
                                </Autocomplete>
                            ) : (
                                <Typography variant="subtitle1" color="gray">
                                    {values?.projectType}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item lg={2}>
                            <Typography variant="subtitle1" color="black" fontWeight="bold">
                                Responsable
                            </Typography>
                        </Grid>
                        <Grid item lg={4}>
                            {isEdit ? (
                                <Autocomplete
                                    className="mt-2"
                                    clearIcon={false}
                                    options={customers}
                                    autoHighlight
                                    noOptionsText="No se han encontrado resultados"
                                    value={customers.find((obj) => obj.id === values?.responsible?.id) || null}
                                    onChange={(e, value) => {
                                        setFieldValue("responsible", value || "");
                                    }}
                                    onBlur={handleBlur}
                                    getOptionLabel={(option) => option?.contactName || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{ ...params.inputProps }}
                                            id="responsible"
                                            name="responsible"
                                            value={customers.find((obj) => obj.id === values?.responsible?.id) || null}
                                            variant="standard"
                                            error={touched.responsible && Boolean(errors.responsible)}
                                            helperText={touched.responsible && errors.responsible}
                                        />
                                    )}
                                />
                            ) : (
                                <Typography variant="subtitle1" color="gray">
                                    {values?.responsible?.contactName}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item lg={2}>
                            <Typography variant="subtitle1" color="black" fontWeight="bold">
                                Tipo de industria
                            </Typography>
                        </Grid>
                        <Grid item lg={4}>
                            {isEdit ? (
                                <Autocomplete
                                    className="mt-2"
                                    clearIcon={false}
                                    options={settingsProjects?.industry}
                                    value={values.industry}
                                    autoHighlight
                                    noOptionsText="No se han encontrado resultados"
                                    onChange={(e, value) => {
                                        setFieldValue("industry", value || "")
                                    }}
                                    onBlur={handleBlur}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps
                                            }}
                                            id="industry"
                                            name="industry"
                                            variant="standard"
                                            error={
                                                touched.industry && Boolean(errors.industry)
                                            }
                                            helperText={
                                                touched.industry && errors.industry
                                            }
                                        />
                                    )}
                                >
                                </Autocomplete>
                            ) : (
                                <Typography variant="subtitle1" color="gray">
                                    {values?.industry}
                                </Typography>
                            )}
                        </Grid>
                        {/*                 <Grid item lg={6}></Grid> */}
                        <Grid item lg={2}>
                            <Typography variant="subtitle1" color="black" fontWeight="bold">
                                Administrador General
                            </Typography>
                        </Grid>
                        <Grid item lg={3}>
                            {isEdit ? (
                                <Autocomplete
                                    className="mt-2"
                                    clearIcon={false}
                                    options={users} // Modifica las opciones para que solo contengan id y fullname
                                    autoHighlight
                                    noOptionsText="No se han encontrado resultados"
                                    value={users.find((obj) => obj.id === values?.generalAdmin?.id) || null}
                                    onChange={(e, value) => {
                                        setFieldValue("generalAdmin", value || "");
                                    }}
                                    onBlur={handleBlur}
                                    getOptionLabel={(option) => option?.fullname || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                            id="generalAdmin"
                                            name="generalAdmin"
                                            variant="standard"
                                            error={
                                                touched.generalAdmin && Boolean(errors.generalAdmin)
                                            }
                                            helperText={
                                                touched.generalAdmin && errors.generalAdmin
                                            }
                                        />
                                    )}
                                ></Autocomplete>
                            ) : (
                                <Typography variant="subtitle1" color="gray">
                                    {values?.generalAdmin?.fullname}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item lg={6}></Grid>
                    </Grid>
                    <Divider
                        variant="middle"
                        style={{ backgroundColor: "gray !important" }}
                        className="m-0"
                    />

                    {/* Fechas y alcances */}
                    <Grid container spacing={2} className="p-4">
                        <Grid item lg={12}>
                            <Typography variant="h5" color="primary">
                                Fechas y alcances
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider
                        variant="middle"
                        style={{ backgroundColor: "gray !important" }}
                        className="m-0"
                    />

                    <Grid container spacing={2} className="p-4">
                        <Grid item lg={2}>
                            <Typography variant="subtitle1" color="black" fontWeight="bold">
                                Fecha de inicio
                            </Typography>
                        </Grid>
                        <Grid item lg={2}>
                            {isEdit ? (
                                <TextField
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    className="w-100"
                                    value={values.startDate}
                                    onChange={(e) => {
                                        setFieldValue("startDate", e.target.value)
                                        if (values.endDate !== '') {
                                            setFieldValue("delegationPeriod", relativeDate(e.target.value, values.endDate))
                                        }

                                    }}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    error={Boolean(touched.startDate && errors.startDate)}
                                    helperText={touched.startDate && errors.startDate}
                                />
                            ) : (
                                <Typography variant="subtitle1" color="gray">
                                    {values?.startDate}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item lg={2}>
                            <Typography variant="subtitle1" color="black" fontWeight="bold">
                                Fecha cierre
                            </Typography>
                        </Grid>

                        <Grid item lg={2}>
                            {isEdit ? (
                                <TextField
                                    id="endDate"
                                    name="endDate"
                                    value={values.endDate}
                                    onChange={(e) => {
                                        setFieldValue("endDate", e.target.value)
                                        if (values.startDate !== '') {
                                            setFieldValue("delegationPeriod", relativeDate(values.startDate, e.target.value))

                                        }

                                    }}
                                    className="w-100"
                                    onBlur={handleBlur}
                                    type="date"
                                    variant="standard"
                                    error={Boolean(touched.endDate && errors.endDate)}
                                    helperText={touched.endDate && errors.endDate}
                                />
                            ) : (
                                <Typography variant="subtitle1" color="gray">
                                    {values?.endDate}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item lg={1}>
                            <Typography variant="subtitle1" color="black" fontWeight="bold">
                                Plazo
                            </Typography>
                        </Grid>
                        <Grid item lg={2}>
                            <Typography variant="subtitle1" color="gray">
                                {values?.delegationPeriod}
                            </Typography>
                        </Grid>

                        <Grid item lg={5}>
                            <Typography variant="subtitle1" color="black" fontWeight="bold">
                                Contrato
                            </Typography>
                        </Grid>
                        <Grid item lg={6}>
                            <Typography variant="subtitle1" color="black" fontWeight="bold">
                                Documentos asociados y enmiendas
                            </Typography>
                        </Grid>

                        <Grid item lg={5}>
                            {newContract ? (
                                <Typography variant="subtitle1" color="black" fontWeight="bold" style={{ display: 'flex', alignItems: 'center' }}>
                                    <a href={newContract?.url} target="_blank" rel="noreferrer" style={{ flexGrow: 1 }}>
                                        {newContract?.url ? <FileDownload /> : <FileUpload />} {newContract?.name}

                                    </a>
                                </Typography>) : <Typography variant="subtitle1" color="black" fontWeight="bold" style={{ display: 'flex', alignItems: 'center' }}>
                                <a href={contractFile?.url} target="_blank" rel="noreferrer" style={{ flexGrow: 1 }}>
                                    <FileDownload /> {contractFile?.name}
                                </a>
                            </Typography>
                            }

                            {isEdit && <Button
                                className="mt-2"
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                onChange={(e) => handleContractFileChange(e.target.files[0])}
                                startIcon={<FileUpload />}
                            >
                                {'Selecciona un archivo'}
                                <VisuallyHiddenInput type="file" accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf" />
                            </Button>}



                        </Grid>
                        <Grid item lg={6}>
                            {
                                documentsFiles.length > 0 && documentsFiles?.map((obj, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <Typography variant="subtitle1" color="black" fontWeight="bold" style={{ flexGrow: 1 }}>
                                            <a href={obj.url} target="_blank" rel="noreferrer">
                                                {obj.url ? <Folder /> : <FileUpload />} {obj.name}
                                            </a>
                                        </Typography>
                                        {isEdit && <Button
                                            variant="text"
                                            color="error"
                                            onClick={() => handleClickOpen(index, "old")}
                                            startIcon={<Delete />}
                                        />
                                        }
                                    </div>
                                ))

                            }

                            {
                                newFiles.length > 0 ? newFiles?.map((obj, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <Typography variant="subtitle1" color="black" fontWeight="bold" style={{ flexGrow: 1 }}>
                                            <a href={obj.url} target="_blank" rel="noreferrer">
                                                {obj.url ? <Folder /> : <FileUpload />} {obj.name}
                                            </a>
                                        </Typography>
                                        {isEdit && <Button
                                            variant="text"
                                            color="error"
                                            onClick={() => handleClickOpen(index, "new")}
                                            startIcon={<Delete />}
                                        />
                                        }
                                    </div>
                                )) : null

                            }


                            {
                                isEdit && (
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<FileUpload />}
                                    >
                                        {'Selecciona archivos'}
                                        <input
                                            type="file"
                                            accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                                            multiple
                                            hidden
                                            onChange={(e) => handleDocumentsFileChange(e.target.files)}
                                        />
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik> : <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>}

        {DialogReturn}
        {DialogText}

    </Container>)
};
export default InformationView;