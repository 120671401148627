import { AddCircle, Delete } from "@mui/icons-material";
import { Box, Container, Grid, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, LinearProgress } from "@mui/material";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import apiUserService from "src/services/usersService";
import permissionsService from "src/services/permissionsService";

const Projects = () => {
    const User = useSelector((state) => state?.setUserLogin?.user);
    const [settingsProjects, setSettingsProjects] = useState({
        type: [],
        category: [],
        industry: [],
        criticality: []
    });
    const [loading, setLoading] = useState(false)
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newItem, setNewItem] = useState("");
    const [currentSection, setCurrentSection] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [itemToDelete, setItemToDelete] = useState({ section: "", index: -1 });

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        borderRadius: '0px',
        borderLeft: '4px solid #1976D2',
        padding: theme.spacing(2),
        color: "#1976D2",
        fontWeight: 'bold'
    }));

    const ItemButton = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        backgroundColor: '#e9edff',
        borderRadius: '0px',
        borderLeft: '4px solid #1976D2',
        padding: theme.spacing(2),
        color: "#1976D2",
        cursor: 'pointer',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#d6dcfa'
        }
    }));

    const handleAddDialogOpen = (section) => {
        setCurrentSection(section);
        setOpenAddDialog(true);
    };

    const handleAddDialogClose = () => {
        setNewItem("");
        setOpenAddDialog(false);
    };

    const normalizeString = (str) => str.toLowerCase().trim();

    const handleAddItem = async () => {
        if (newItem.trim() !== "") {
            const normalizedNewItem = normalizeString(newItem);
            const itemExists = settingsProjects[currentSection]?.some(item => normalizeString(item) === normalizedNewItem);
            if (itemExists) {
                enqueueSnackbar('El elemento ya existe', {variant: 'warning'});
            } else {
                const updatedProjects = {
                    ...settingsProjects,
                    [currentSection]: [...settingsProjects[currentSection], newItem.trim()]
                };
                setSettingsProjects(updatedProjects);
                try {
                    await apiUserService.updateSettings(User.id, updatedProjects);
                    enqueueSnackbar('Elemento añadido con éxito', {variant: 'success'});
                } catch (error) {
                    enqueueSnackbar('Ha ocurrido un error al intentar añadir el documento', {variant: 'error'});
                }
                handleAddDialogClose();
            }
        } else {
            enqueueSnackbar('Ingresa el nombre en el campo', {variant: 'error'});
        }
    };

    const handleDeleteDialogOpen = (section, index) => {
        setItemToDelete({ section, index });
        setOpenDeleteDialog(true);
    };

    const handleDeleteDialogClose = () => {
        setItemToDelete({ section: "", index: -1 });
        setOpenDeleteDialog(false);
    };

    const handleDeleteItem = async () => {
        const { section, index } = itemToDelete;
        const updatedProjects = {
            ...settingsProjects,
            [section]: settingsProjects[section].filter((_, i) => i !== index)
        };
        setSettingsProjects(updatedProjects);
        try {
            await apiUserService.updateSettings(User.id, updatedProjects);
            enqueueSnackbar('Elemento eliminado con éxito', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Ha ocurrido un error al intentar eliminar el documento', {variant: 'error'});
        }
        handleDeleteDialogClose();
    };

    const Section = ({ title, items, addItemLabel, sectionKey }) => (
        <Container maxWidth="lg">
            <Typography variant="body1" color="initial" fontWeight="bold" margin={3}>{title}</Typography>
            <Grid container spacing={2}>
                {items && items.length > 0 && items.map((item, index) => (
                    <Grid item xs={4} key={index}>
                        <Item variant="outlined" elevation={1}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="body2" color="primary" fontWeight="bold">
                                    {item}
                                </Typography>
                               {permissionsService("settings", User.role.label, "delete") && <Delete color="primary" onClick={() => handleDeleteDialogOpen(sectionKey, index)} style={{ cursor: 'pointer' }} />}
                            </Box>
                        </Item>
                    </Grid>
                ))}
               {permissionsService("settings", User.role.label, "add") && <Grid item xs={4}>
                    <ItemButton variant="outlined" elevation={1} onClick={() => handleAddDialogOpen(sectionKey)}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="body2" color="primary" fontWeight="bold">
                                {addItemLabel}
                            </Typography>
                            <AddCircle color="primary" />
                        </Box>
                    </ItemButton>
                </Grid>}
            </Grid>
        </Container>
    );

    const fetchSettingsProjects = async () => {
        try {
            setLoading(true)
            const data = await apiUserService.getSettings(User.id);
            setLoading(false)
            setSettingsProjects(data.data);
        } catch (error) {
            enqueueSnackbar('Error al obtener los usuarios', {variant: 'error'});
            setLoading(false)

        }
    };

    useEffect(() => {
        if (User.id) {
            fetchSettingsProjects();
        }
    }, [User.id]);

    return (
        <>
              {!loading ? 
                <><Section title="Tipo" items={settingsProjects.type} addItemLabel="Añadir tipo" sectionKey="type" /><Section title="Categoría" items={settingsProjects.category} addItemLabel="Añadir categoría" sectionKey="category" /><Section title="Industria" items={settingsProjects.industry} addItemLabel="Añadir industria" sectionKey="industry" /><Section title="Criticidad" items={settingsProjects.criticality} addItemLabel="Añadir criticidad" sectionKey="criticality" /></>
                : <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>}
            {/* Add Item Dialog */}
            <Dialog open={openAddDialog} onClose={handleAddDialogClose}>
                <DialogTitle>Añadir elemento</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Por favor, introduce el nuevo elemento que deseas añadir.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={`Nuevo elemento`}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newItem}
                        onChange={(e) => setNewItem(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddDialogClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleAddItem} color="primary">
                        Añadir
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
                <DialogTitle>Confirmar Eliminación</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar este elemento?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteItem} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Projects;
