import { useEffect, useState } from "react";
import {
  IconButton,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Grid,
  Typography,
  Divider,
  Button,
  Box,
} from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useStyles from "./styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import apiCustomersService from "src/services/customersService";
import countries from "src/utils/countries";
import { flags } from "src/utils/flags";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";

const FormCustomers = ({ data, settings, allCustomers, setEdit }) => {
  const newAllCustomers = allCustomers.filter(
    (objeto) => objeto.email !== data?.email
  );
  const classes = useStyles();

  const formUser = (
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          companyName: data?.companyName || "",
          contactName: data?.contactName || "",
          phone: data?.phone || {
            country: "EC",
            value: "",
            areaCode: "593",
          },
          website: data?.website || "",
          email: data?.email || "",
          industry: data?.industry || "",
          country: data?.country || "",
          city: data?.city || "",
          address: data?.address || "",
          note: data?.note || "",
          id: data?.id || "",
        }}
        validationSchema={Yup.object({
          companyName: Yup.string().required("El campo es requerido"),
          contactName: Yup.string().required("El campo es requerido"),
          phone: Yup.object().shape({
            country: Yup.string().required(
              "El país del teléfono es obligatorio"
            ),
            value: Yup.number()
              .typeError("Solo se admiten números")
              .required("El número de teléfono es obligatorio"),
          }),
          email: Yup.string()
            .email("Debe ser un correo electrónico válido")
            .required("El campo es requerido")
            .trim()
            .test("isMailValid", "¡Correo ya registrado!", async (val) => {
              if (val) {
                const verEmail = newAllCustomers.find(
                  (snp) => snp?.email === val
                );
                if (verEmail === undefined) {
                  return true;
                }
              }
            }),

          industry: Yup.string().required("El campo es requerido"),
          country: Yup.string().required("El campo es requerido"),
          city: Yup.string().required("El campo es requerido"),
          address: Yup.string().required("El campo es requerido"),
        })}
        onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
          apiCustomersService.postUpdate(values).then((res)=> {
            enqueueSnackbar("Cliente actualizado con exito", {
              variant: "success"
            });
            resetForm({ values });
            setSubmitting(false)
          }).catch((error) => {
            enqueueSnackbar("Ha ocurrido un error al actualizar el usuari", {
              variant: "error"
            });
            setSubmitting(false)
          })
         
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
          touched,
          values,
          dirty
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid item sm={4} className={classes.projectStyle}>
              <LoadingButton
                size="medium"
                variant="contained"
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={<Save />}
                disabled={!dirty}
                type="submit"
              >
                Editar cliente
              </LoadingButton>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Nombre de la empresa: *
                </Typography>
              </Grid>

              <Grid item lg={4}>
                <TextField
                  id="companyName"
                  fullWidth
                  margin="dense"
                  variant="standard"
                  name="companyName"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.companyName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.companyName && errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Nombre del contacto: *
                </Typography>
              </Grid>

              <Grid item sm={4} xs={12}>
                <TextField
                  id="contactName"
                  fullWidth
                  margin="dense"
                  variant="standard"
                  name="contactName"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.contactName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.contactName && errors.contactName)}
                  helperText={touched.contactName && errors.contactName}
                />
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Teléfono: *
                </Typography>
              </Grid>

              <Grid item sm={6} lg={4}>
                <FormControl fullWidth margin="dense" required>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "16px",
                    }}
                  >
                    <FormControl variant="standard" style={{ width: "5em" }}>
                      <Select
                        labelId="phone-country"
                        id="phone.country"
                        value={values.phone}
                        onChange={(e) => {
                          const selectedCountry = countries.find(
                            (country) => country?.code === e.target.value
                          );
                          setFieldValue("phone.country", e.target.value);
                          setFieldValue(
                            "phone.areaCode",
                            selectedCountry.areaCode
                          );
                        }}
                        renderValue={(selected) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={flags(selected.country,20)}
                              srcSet={flags(selected.country,40)}
                              alt=""
                            />
                          </div>
                        )}
                      >
                        {countries.map((country) => (
                          <MenuItem key={country?.code} value={country?.code}>
                            <img
                              loading="lazy"
                              width="20"
                              src={flags(country.code,20)}
                              srcSet={flags(country.code,40)}
                              alt={country.name}
                              style={{ marginRight: "10px" }}
                            />
                            {country?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      id="phone.value"
                      name="phone.value"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            +{values.phone.areaCode}
                          </InputAdornment>
                        ),
                      }}
                      value={values.phone.value}
                      onChange={(e) =>
                        setFieldValue("phone.value", e.target.value)
                      }
                      style={{ flexGrow: 1 }}
                      onBlur={handleBlur}
                      variant="standard"
                      error={Boolean(
                        touched.phone?.value && errors.phone?.value
                      )}
                      helperText={touched.phone?.value && errors.phone?.value}
                    />
                  </Box>
                </FormControl>
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Sitio web:
                </Typography>
              </Grid>

              <Grid item sm={4} xs={12}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    id="website"
                    fullWidth
                    margin="dense"
                    variant="standard"
                    name="website"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.website}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Correo electrónico: *
                </Typography>
              </Grid>

              <Grid item sm={4} xs={12}>
                <TextField
                  id="email"
                  fullWidth
                  margin="dense"
                  variant="standard"
                  name="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Industria:
                </Typography>
              </Grid>

              <Grid item sm={4} xs={12}>
                <FormControl
                  variant="standard"
                  fullWidth
                  margin="dense"
                  className="border-0"
                  error={Boolean(touched.industry && errors.industry)}
                  helperText={touched.industry && errors.industry}
                  onBlur={handleBlur}
                >
                  <Select
                    id="industry"
                    name="industry"
                    value={values.industry}
                    onChange={handleChange}
                  >
                    {settings?.industry.map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                  {touched.industry && errors.industry ? (
                    <FormHelperText>{errors.industry}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  País: *
                </Typography>
              </Grid>

              <Grid item sm={4} xs={12}>
                <FormControl
                  variant="standard"
                  fullWidth
                  margin="dense"
                  className="border-0"
                  error={Boolean(touched.country && errors.country)}
                  helperText={touched.country && errors.country}
                  onBlur={handleBlur}
                >
                  <Select
                    id="country"
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                  >
                    {countries.map((type) => (
                      <MenuItem value={type.code}>{type.name}</MenuItem>
                    ))}
                  </Select>
                  {touched.country && errors.country ? (
                    <FormHelperText>{errors.country}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Ciudad: *
                </Typography>
              </Grid>

              <Grid item sm={4} xs={12}>
                <TextField
                  id="city"
                  fullWidth
                  margin="dense"
                  variant="standard"
                  name="city"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.city}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Ubicación exacta: *
                </Typography>
              </Grid>

              <Grid item sm={10} xs={12}>
                <TextField
                  id="address"
                  fullWidth
                  margin="dense"
                  variant="standard"
                  name="address"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Grid>

              <Grid item lg={2}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                  Notas:
                </Typography>
              </Grid>

              <Grid item sm={10} xs={12}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="note"
                  label="Nota:"
                  multiline
                  rows={3}
                  value={values.note}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
  );

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Editar Clientes";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item sm={8} xs={12}>
          <IconButton
            style={{ background: "none" }}
            aria-label="edit"
            color="primary"
            onClick={() => {
              setEdit(false);
            }}
          >
            <ChevronLeftIcon color="primary" fontSize="large" />
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              color="primary"
              className="m-3"
            >
              Editar {data.institution}
            </Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Divider className="mb-1" />
      <Box className="p-4">{formUser}</Box>
    </div>
  );
};

export default FormCustomers;
