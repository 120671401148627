import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import TopBar from "../../../components/topbar/topbar";
import { AccountCircle, Feed, Info, Password, Tune } from "@mui/icons-material";
import useStyles from "../projects/styles";
import Projects from "./aboutProjects";
import General from "./general";
import { useParams } from "react-router-dom";
import Help from "./help";
import Security from "./security";
import FormUsers from "../users/formUsers";
import { useSelector } from "react-redux";
import apiUserService from "src/services/usersService";
import { enqueueSnackbar } from "notistack";
import permissionsService from "src/services/permissionsService";

const listItems = [
  { id: 0, text: "Sobre Proyectos", Icon: Feed },
  { id: 1, text: "Mi perfil", Icon: AccountCircle },
  { id: 2, text: "Configuraciones", Icon: Tune },
  { id: 3, text: "Seguridad", Icon: Password },
  { id: 4, text: "Ayuda", Icon: Info },
];


const Settings = () => {
  const User = useSelector((state) => state?.setUserLogin?.user);
  const { index } = useParams();
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(
    Number.parseInt(index) || 1
  );
  const [dataUsers, setDataUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    getUserById();
  }, [selectedIndex]);

  const getUserById = async () => {
    try {
      if (User && User.id) {
        apiUserService
          .getById(User.id)
          .then((res) => {
            setDataUsers(res.data);
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar("Error al obtener el usuario", { variant: "error" });
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRoles = async () => {
    try {
      apiUserService.getRoles(User.id).then((data) => {
        setRoles(data.data);
      });
    } catch (error) {
      enqueueSnackbar('Error al obtener los roles', { variant: 'error' });
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const getCurrentProjectList = () => {
    switch (selectedIndex) {
      case 0: // Sobre proyectos
        return permissionsService("projects", User?.role?.label, "add") ? <Projects /> : null;
      case 1: // Mi perfil
        return dataUsers && Object.keys(dataUsers).length > 0 && (
          <FormUsers
            roles={roles}
            data={dataUsers}
            setEdit={setEdit}
            isEdit={isEdit}
          />
        );
      case 2: // Configuraciones
        return <General />;
      case 3: // Cambio de contraseña
        return <Security />;
      case 4: // Ayuda
        return <Help />;
      default:
        return null;
    }
  };

  // Filtrar ítems de la lista según permisos
  const filteredListItems = listItems.filter((item) => {
    if (item.id === 0) {
      return permissionsService("projects", User?.role?.label, "add");
    }
    return true; // Otros ítems se muestran siempre
  });

  const settingsSidebar = (
    <Box width={"100%"}>
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{
          width: "100%",
          position: "sticky",
          top: "140px",
          height: "fit-content",
        }}
      >
        {filteredListItems.map((item, index) => (
          <ListItemButton
            key={item.id}
            classes={{ root: classes.selectedItem }}
            selected={selectedIndex === item.id}
            onClick={(event) => handleListItemClick(event, item.id)}
          >
            <ListItemIcon className={classes.icon}>
              <item.Icon />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (index) {
      setSelectedIndex(Number.parseInt(index));
    }
  }, [index]);

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "MGC - Configuraciones";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  return (
    <div>
      <TopBar />
      <Grid container className={classes.stickyHeader} top={60}>
        <Grid item sm={6}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            style={{ color: "#1976D2", margin: "0.5em" }}
          >
            Ajustes
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mb-4">
        <Grid item lg={2} md={3} sm={4} xs={12} className="d-flex">
          {settingsSidebar}
          <Divider orientation="vertical" />
        </Grid>
        <Grid item lg={10} md={9} sm={8} xs={12}>
          {getCurrentProjectList()}
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
