import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./pages/login/login";
import Home from "./pages/superAdmin/home/home";
import Customers from "./pages/superAdmin/customers/customers";
import Users from "./pages/superAdmin/users/users";
import Projects from "./pages/superAdmin/projects/projects";
import Settings from "./pages/superAdmin/settings/settings";
import Reports from "./pages/superAdmin/reports/reports";
import ProjectsDetails from "./pages/superAdmin/projects/details";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { NotificationProvider } from "./redux/contexts/notificationContext";
import ProtectedRoute from "./utils/protectedRoute";
import SessionRoute from "./utils/sessionRoute";

function App() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={3000}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <NotificationProvider>
            <BrowserRouter>
              <Routes>
                <Route element={<SessionRoute />}>
                  <Route path="/login" element={<SignIn />} />
                </Route>
                <Route element={<ProtectedRoute />}>
                  <Route path="/home" element={<Home />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/projects" element={<Projects />} />
                  <Route
                    path="/projects/details"
                    element={<ProjectsDetails />}
                  />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/settings/:index" element={<Settings />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/customers" element={<Customers />} />
                  <Route path="*" element={<Navigate to="/home" replace />} />
                </Route>
                <Route path="/" element={<Navigate to="/login" replace />} />
              </Routes>
            </BrowserRouter>
          </NotificationProvider>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;