const moment = require('moment-timezone');

// Obtener todas las zonas horarias disponibles con sus descripciones y desplazamientos
const timezones = moment.tz.names().map(name => {
    const zone = moment.tz.zone(name);
    return {
        name: name,
        description: zone.abbr(moment()),
        offset: zone.utcOffset(moment())
    };
});

module.exports = timezones;
