import axiosInstance from "src/utils/axios";

const api = '/api/users'

const apiUserService = {
  get: () => {
    return axiosInstance.get(api);
  },
  markNotifications: (userId) => {
    return axiosInstance.get(api + '/notifications/' + userId);
  },
  getById: (id) => {
    return axiosInstance.get(api + '/info/' + id);
  },
  post: (data) => {
    return axiosInstance.post(api +'/auth', data);
  },
  forgot: (data) => {
    return axiosInstance.post(api +'/forgot', data);
  },
  verificationEmail: (data) => {
    return axiosInstance.post(api +'/verification', data);
  },
  getRoles: (userId) => {
    return axiosInstance.get(api + '/roles/' + userId);
  },
  postData: (data) => {
    return axiosInstance.post(api, data);
  },
  updateData: (data) => {
    return axiosInstance.put(api, data);
  },
  delete: (data) => {
    return axiosInstance.delete(api + '/' + data.email + '/' + data.id);
  },
  block: (data) => {
    return axiosInstance.post(api + '/block', data);
  },
  getSettings: (userId) => {
    return axiosInstance.get(api + '/settings/' + userId);
  },
  updateSettings: (userId, data) => {
    return axiosInstance.put(api + '/settings/' + userId, data);
  }
};

export default apiUserService;