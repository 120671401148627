import { createContext, useContext, useEffect, useState } from 'react';
import apiNotificationService from '../../services/notificationsService';
import { useSelector } from 'react-redux';

const NotificationContext = createContext();

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const User = useSelector((state) => state?.setUserLogin?.user);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);

  useEffect(() => {
    if (!User || !User?.id) return;

    const fetchNotifications = async () => {
      try {
        const { totalNotifications, notifications } = await apiNotificationService.getNotifications(User?.id); 
        setNotifications(notifications);
        setNotificationsCount(totalNotifications);
      } catch (error) {
        console.error('Error obteniendo notificaciones:', error);
      }
    };

    fetchNotifications();

    // Suscripción para actualizar en tiempo real
    const unsubscribe = apiNotificationService.subscribeToNotifications(User.id, (updatedNotifications) => {
      const totalNotifications = updatedNotifications.filter(notification => !notification.read)?.length;
      setNotifications(updatedNotifications);
      setNotificationsCount(totalNotifications);
    });

    return () => unsubscribe();
  }, [User?.id]);

  return (
    <NotificationContext.Provider value={{ notifications, notificationsCount, setNotificationsCount}}>
      {children}
    </NotificationContext.Provider>
  );
};
