/* eslint-disable react-hooks/exhaustive-deps */
import { TrendingDown, TrendingUp, ReportProblem } from "@mui/icons-material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import MoreVert from "@mui/icons-material/MoreVert";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  styled,
  Box,
  Container,
  CardActions,
  CardActionArea,
  FormControl,
  Select,
  LinearProgress,
  MenuItem,
  Button,
  Tooltip,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  IconButton,
  TablePagination,
} from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import NoBorderInput from "./styles";
import EmptyIcon from "src/assets/emptyBox.png";
import apiClausesService from "src/services/clausesService";
import { useSelector } from "react-redux";
import { dateFormat2 } from "src/utils/timeLocal";
import CloseIcon from "@mui/icons-material/Close";
import labelDisplayedRows from "src/utils/pagination";
const currentYear = new Date().getFullYear();
const years = Array.from(
  { length: currentYear - 1990 + 1 },
  (v, k) => currentYear - k
);

const GeneralView = ({ dataClause }) => {
  const [viewDetails, setViewDetails] = useState(false);
  const Project = useSelector((state) => state?.setUserLogin?.project?.id);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [filterState, setFilterState] = useState("");
  const [countState, setCountState] = useState([]);
  const [allClauses, setAllClauses] = useState([]);
  const [allCriticalityGrafic, setAllCriticalityGrafic] = useState([]);
  const [allCategoryGrafic, setAllCatgoryGrafic] = useState([]);
  const [allStateGrafic, setAllStateGrafic] = useState([]);
  const [allUsersGrafic, setAllUsersGrafic] = useState([]);
  const [showFullClause, setShowFullClause] = useState({});
  const [loading, setLoading] = useState(false);
  const [tittleDetails, setTittleDetails] = useState("");
  const [tittleSelect, setTittleSelect] = useState("");
  const handleShow = (rowId) => {
    setShowFullClause({ ...showFullClause, [rowId]: !showFullClause[rowId] });
  };
  const category = {
    series: allCategoryGrafic?.series || [],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: allCategoryGrafic?.labels || [],
      colors: ["#3C5A99", "#40739C", "#56ACEE"],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const state = {
    series: [
      {
        data: allStateGrafic?.series || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          vertical: true,
          distributed: true,
        },
      },
      colors: ["#35486a", "#44648b", "#6292bb", "#84b0ce", "#c3dcea"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: allStateGrafic?.labels || [],
      },
    },
  };
  const critic = {
    series: [
      {
        data: allCriticalityGrafic.series || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          distributed: true,
        },
      },
      colors: ["#3C5A99", "#40739C", "#56ACEE"],
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: allCriticalityGrafic.labels || [],
      },
    },
  };

  const responsible = {
    series: allUsersGrafic.series || [],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: allUsersGrafic.labels || [],
      colors: ["#41729F", "#5885AF", "#274472", "#7EB2D9", "#C3E0E5"],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  const getStateColor = (state) => {
    switch (state) {
      case "Atrasada":
        return "#CC8319";
      case "Rechazada":
        return "#FC4343";
      case "Presentada Tarde":
        return "#5645A1";
      case "Pendiente de Aprobación":
        return "#20629C";
      case "Por vencer":
        return "#7C65E3";
      case "Aprobado Tarde":
        return "#248236";
      case "Aprobado":
        return "#42B555";
      case "En gestión":
        return "rgb(23,59,109)";
      default:
        return "#000";
    }
  };
  const getCriticalityColor = (criticality) => {
    switch (criticality) {
      case "Alta":
        return "#FC4343";
      case "Media":
        return "#EAB137";
      case "Baja":
        return "#42B555";
      default:
        return "#000";
    }
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const ITEM_HEIGHT = 30;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 80,
      },
    },
  };

  const filterGrafic = (dataFilter) => {
    const clauses = allClauses.filter(
      (snp) =>
        snp.state === dataFilter ||
        (snp.state === "Aprobado" && dataFilter === "Aprobado Tarde")
    );
    const newGrafic = [];
    const categoryCounts = {};
    clauses.forEach((item) => {
      if (categoryCounts[item?.category]) {
        categoryCounts[item?.category]++;
      } else {
        categoryCounts[item?.category] = 1;
      }
    });

    const criticalityCounts = clauses?.reduce((acc, clause) => {
      const { criticality } = clause;
      if (acc[criticality]) {
        acc[criticality]++;
      } else {
        acc[criticality] = 1;
      }
      return acc;
    }, {});

    const stateCounts = clauses?.reduce((acc, clause) => {
      const { state } = clause;
      if (acc[state]) {
        acc[state]++;
      } else {
        acc[state] = 1;
      }
      return acc;
    }, {});

    const userCountMap = new Map();

    clauses.forEach((item) => {
      item.users.forEach((user) => {
        if (userCountMap?.has(user?.userId)) {
          userCountMap.get(user?.userId).count++;
        } else {
          userCountMap?.set(user?.userId, {
            userId: user?.userId,
            username: user?.username,
            count: 1,
          });
        }
      });
    });
    const result = Array.from(userCountMap.values());
    newGrafic.push({ Users: result });
    newGrafic.push({ criticality: criticalityCounts });
    newGrafic.push({ category: categoryCounts });
    newGrafic.push({ state: stateCounts });
    allDataGrafic(newGrafic);
  };

  useEffect(() => {
    allProjectGrafic();
  }, [selectedYear]);

  const allDataGrafic = (response) => {
    const newCriticality = {};
    newCriticality.series = Object.values(response[1].criticality);
    newCriticality.labels = Object.keys(response[1].criticality);
    setAllCriticalityGrafic(newCriticality);

    const newCategory = {};
    newCategory.series = Object?.values(response[2]?.category);
    newCategory.labels = Object?.keys(response[2]?.category);
    setAllCatgoryGrafic(newCategory);
    const newState = {};
    newState.series = Object.values(response[3].state);
    newState.labels = Object.keys(response[3].state);
    setAllStateGrafic(newState);

    let newArray = response[0]?.Users?.map((item) => {
      return {
        username: item.username,
        count: item.count,
      };
    });
    const newUsers = {};
    newUsers.series = newArray.map((item) => item?.count);
    newUsers.labels = newArray.map((item) => item?.username);
    setAllUsersGrafic(newUsers);
  };

  const allProjectGrafic = async () => {
    setLoading(true);
    try {
      const response = await apiClausesService.getByProjectGrafic({
        projectId: Project,
        year: selectedYear,
      });
      setAllClauses(response?.data[4]?.allData);

      const newCriticality = {};
      newCriticality.series = Object.values(response.data[1].criticality);
      newCriticality.labels = Object.keys(response.data[1].criticality);

      const newCategory = {};
      newCategory.series = Object?.values(response.data[2]?.category);
      newCategory.labels = Object?.keys(response.data[2]?.category);

      setCountState(response.data[3].state);
      const newState = {};
      newState.series = Object.values(response.data[3].state);
      newState.labels = Object.keys(response.data[3].state);

      let newArray = response.data[0]?.Users?.map((item) => {
        return {
          username: item.username,
          count: item.count,
        };
      });
      const newUsers = {};
      newUsers.series = newArray.map((item) => item?.count);
      newUsers.labels = newArray.map((item) => item?.username);
      allDataGrafic(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const ScrollableBox = styled(Box)({
    width: "100% !important",
    display: "flex",
    overflowX: "auto",
    gap: 16,
    padding: 8,
    // Asegura que los ítems dentro del contenedor no se envuelvan:
    "& > *": {
      flex: "2 2 auto",
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDialog = (tittle) => {
    setTittleDetails(tittle)
    setPage(0)
    setViewDetails(!viewDetails);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const dialogDetails = (
    <Box variant="outlined" style={{ width: "1200px" }}>
      <Grid container>
        <Grid item sm={11}>
          <CardHeader
            title={tittleDetails || "Cláusulas"}
            titleTypographyProps={{ color: "primary" }}
            subheaderTypographyProps={{
              color: "GrayText",
              className: "mt-4",
              fontWeight: "bolder",
            }}
          />
        </Grid>

        <Grid item sm={1}>
          <Button
            className="mt-3"
            onClick={() => {
              setViewDetails(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>

      <Divider orientation="horizontal" flexItem />

      {allClauses.filter(
        (snp) =>
          snp.state === filterState ||
          (snp.state === "Aprobado" && filterState === "Aprobado Tarde")
      ).length > 0 ? (
        <CardContent>
          <Grid container="2">
            <Grid item sm={6}>
              <h4>Información de cláusulas</h4>
            </Grid>
            {/*
            <Grid item sm={2} md={2}>
              <FormControl fullWidth>
                <InputLabel shrink id="client-number-clauses-label">
                  Por criticidad
                </InputLabel>
                <Select
                  labelId="respuesta-cluases-label"
                  id="respuesta-cluases-label"
                  label="Categoría"
                  required
                  variant="standard"
                >
                  <MenuItem value={10}>Datos prueba</MenuItem>
                  <MenuItem value={20}>Datos prueba</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={1} md={1} />
            <Grid item sm={2} md={2}>
              <FormControl fullWidth>
                <InputLabel shrink id="client-number-clauses-label">
                  Por usuario
                </InputLabel>
                <Select
                  labelId="respuesta-cluases-label"Zz
                  id="respuesta-cluases-label"
                  label="Categoría"
                  required
                  variant="standard"
                >
                  <MenuItem value={10}>Datos prueba</MenuItem>
                  <MenuItem value={20}>Datos prueba</MenuItem>
                </Select>
              </FormControl>
            </Grid>
               */}
            {/*
            <Grid item sm={2} md={2}>
              <Button variant="contained">Descargar PDF </Button>
            </Grid>
            <Grid item sm={2} md={2}>
              <Button variant="contained">Descargar Excel </Button>
            </Grid>
            <Grid item sm={1} md={1}>
              <Button variant="contained">Imprimir</Button>
            </Grid>
      */}
          </Grid>

          <Grid item sm={12}>
            <br />
            <TableContainer component={Paper}>
              <Table
                style={{
                  backgroundColor: "#F0F4F7",
                }}
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: "#476AC2", fontSize: "13px" }}>
                      <b>Cláusula</b>
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ color: "#476AC2", fontSize: "13px" }}
                    >
                      <b>N° Cláusula</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "#476AC2", fontSize: "13px" }}
                    >
                      <b>Fecha Inicio</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "#476AC2", fontSize: "13px" }}
                    >
                      <b>Fecha Vencimiento</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "#476AC2", fontSize: "13px" }}
                    >
                      <b>Fecha Aprob/Rechaz</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "#476AC2", fontSize: "13px" }}
                    >
                      <b>Responsable</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "#476AC2", fontSize: "13px" }}
                    >
                      <b>Estado</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "#476AC2", fontSize: "13px" }}
                    >
                      <b>Criticidad</b>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {allClauses
                    .filter(
                      (snp) =>
                        snp.state === filterState ||
                        (snp.state === "Aprobado" &&
                          filterState === "Aprobado Tarde")
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row?.clauseName || ""}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{
                            backgroundColor: "#EDF4F5",
                            fontSize: "13px",
                          }}
                          width="450px !important"
                          component="th"
                          scope="row"
                          sx={{
                            color: "#5a6770",
                            fontWeight: "500",
                            textAlign: "justify",
                          }}
                        >
                          {showFullClause[row.id]
                            ? row.clauseName
                            : row.clauseName.length > 100
                            ? (row.clauseName || "").slice(0, 100) + "..."
                            : row.clauseName}
                          {row.clauseName.length > 100 && (
                            <span
                              onClick={() => handleShow(row.id)}
                              style={{
                                color: "GrayText",
                                cursor: "pointer",
                                fontSize: "10px",
                                marginLeft: "5px",
                              }}
                            >
                              {showFullClause[row.id] ? "Ver menos" : "Ver más"}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ color: "#5a6770", fontSize: "13px" }}
                          align="center"
                        >
                          {row.clauseNumber}
                        </TableCell>
                        <TableCell
                          sx={{ color: "#5a6770", fontSize: "13px" }}
                          align="center"
                          width="250px !important"
                        >
                          {row.startDate && row.startDate != null ? (
                            dateFormat2(row.startDate)
                          ) : (
                            <Typography
                              variant="body1"
                              fontSize="9px"
                              color="GrayText"
                            >
                              {" "}
                              N/A{" "}
                              <ReportProblem
                                color="error"
                                className="ms-3"
                                sx={{ fontSize: "15px" }}
                              />{" "}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ color: "#5a6770", fontSize: "13px" }}
                          align="center"
                          width="200px !important"
                        >
                          {row.expirationDate && row.expirationDate !== null ? (
                            dateFormat2(row?.expirationDate)
                          ) : row.clauseType !== "No necesita gestión" ? (
                            <Typography
                              variant="body1"
                              fontSize="9px"
                              color="GrayText"
                            >
                              {" "}
                              N/A{" "}
                              <ReportProblem
                                color="error"
                                className="ms-3"
                                sx={{ fontSize: "15px" }}
                              />{" "}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body1"
                              fontSize="9px"
                              color="GrayText"
                            >
                              {" "}
                              N/A{" "}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ color: "#5a6770", fontSize: "13px" }}
                          align="center"
                          width="200px !important"
                        >
                          {row.approvalDate && row.approvalDate !== null ? (
                            dateFormat2(row.approvalDate)
                          ) : (
                            <Typography
                              variant="body1"
                              fontSize="9px"
                              color="GrayText"
                            >
                              {" "}
                              N/A{" "}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell
                          sx={{ color: "#5a6770", fontSize: "13px" }}
                          width="180px !important"
                          align="center"
                        >
                          {" "}
                          {row?.users
                            ?.filter((user) => user.roleId === 6)
                            .map((user) => user.username)
                            .join(", ") || "Sin responsable"}
                        </TableCell>
                        <TableCell
                          width="150px !important"
                          align="center"
                          style={{
                            color: getStateColor(row.state),
                            fontSize: "13px",
                          }}
                        >
                          <b>{row.state}</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: getCriticalityColor(row.criticality),
                            fontSize: "13px",
                          }}
                        >
                          <b>{row.criticality}</b>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                labelRowsPerPage="Filas por página"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  allClauses.filter(
                    (snp) =>
                      snp.state === filterState ||
                      (snp.state === "Aprobado" &&
                        filterState === "Aprobado Tarde")
                  ).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={labelDisplayedRows}
              />
            </TableContainer>
          </Grid>
        </CardContent>
      ) : (
        <Box textAlign="center">
          <img src={EmptyIcon} alt="Imagen de no data" width={150} />
          <Typography variant="h6" color="GrayText">
            No hay datos disponibles.
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Container>
      {!loading ? (
        <>
          <Grid container spacing={0}>
            <ScrollableBox className="p-0 mb-3">
              <Grid item minWidth={200}>
                <Card variant="outlined" className="shadow-sm">
                  <CardActionArea
                    onClick={() => {
                      filterGrafic("En gestión");
                      setTittleSelect("Cláusulas en gestión");
                    }}
                  >
                    <CardContent className="pb-0">
                      <Typography variant="body2" color="gray">
                        Cláusulas en gestión
                      </Typography>
                      <Typography variant="h3" color="rgb(95,99,108)">
                        {countState["En gestión"] || 0}{" "}
                        <TrendingUp
                          style={{ color: "rgb(137,209,21)", fontSize: "35px" }}
                        />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Divider />
                  <CardActions>
                    <Box
                      display="inline-flex"
                      justifyContent="end"
                      width="100%"
                    >
                      <Button
                        onClick={() => {
                          handleDialog("Cláusulas en gestión");
                          setFilterState("En gestión");
                        }}
                        component="a"
                        color="primary"
                        size="small"
                      >
                        Detalles
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item minWidth={200}>
                <Card variant="outlined" className="shadow-sm">
                  <CardActionArea
                    onClick={() => {
                      filterGrafic("Aprobado Tarde");
                      setTittleSelect("Cláusulas Aprobadas");
                    }}
                  >
                    <CardContent className="pb-0">
                      <Typography variant="body2" color="gray">
                        Cláusulas Aprobadas
                      </Typography>

                      <Typography variant="h3" color="rgb(95,99,108)">
                        {(countState["Aprobado"] || 0) +
                          (countState["Aprobado Tarde"] || 0) || 0}{" "}
                        <TrendingUp
                          style={{ color: "rgb(137,209,21)", fontSize: "35px" }}
                        />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Divider />
                  <CardActions>
                    <Box
                      display="inline-flex"
                      justifyContent="end"
                      width="100%"
                    >
                      <Button
                        onClick={() => {
                          handleDialog("Cláusulas Aprobadas");
                          setFilterState("Aprobado Tarde");
                        }}
                        component="a"
                        color="primary"
                        size="small"
                      >
                        Detalles
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item minWidth={200}>
                <Card variant="outlined" className="shadow-sm">
                  <CardActionArea
                    onClick={() => {
                      filterGrafic("Rechazada");
                      setTittleSelect("Cláusulas Rechazadas");
                    }}
                  >
                    <CardContent className="pb-0">
                      <Typography variant="body2" color="gray">
                        Cláusulas Rechazadas
                      </Typography>

                      <Typography variant="h3" color="rgb(95,99,108)">
                        {countState?.Rechazada || 0}{" "}
                        <TrendingDown
                          style={{ color: "rgb(200,74,84)", fontSize: "35px" }}
                        />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Divider />
                  <CardActions>
                    <Box
                      display="inline-flex"
                      justifyContent="end"
                      width="100%"
                    >
                      <Button
                        onClick={() => {
                          handleDialog("Cláusulas Rechazadas");
                          setFilterState("Rechazada");
                        }}
                        component="a"
                        color="primary"
                        size="small"
                      >
                        Detalles
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item minWidth={200}>
                <Card variant="outlined" className="shadow-sm">
                  <CardActionArea
                    onClick={() => {
                      filterGrafic("Pendiente de Aprobación");
                      setTittleSelect("Cláusulas Pendientes de Aprobación");
                    }}
                  >
                    <CardContent className="pb-0">
                      <Typography variant="body2" color="gray">
                        Cláusulas Pendientes de Aprobación
                      </Typography>

                      <Typography variant="h3" color="rgb(95,99,108)">
                        {countState["Pendiente de Aprobación"] || 0}{" "}
                        <TrendingUp
                          style={{ color: "rgb(137,209,21)", fontSize: "35px" }}
                        />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Divider />
                  <CardActions>
                    <Box
                      display="inline-flex"
                      justifyContent="end"
                      width="100%"
                    >
                      <Button
                        onClick={() => {
                          handleDialog("Cláusulas Pendientes de Aprobación");
                          setFilterState("Pendiente de Aprobación");
                        }}
                        component="a"
                        color="primary"
                        size="small"
                      >
                        Detalles
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            </ScrollableBox>

            <ScrollableBox className="p-0 mb-3">
              <Grid item minWidth={200}>
                <Card variant="outlined" className="shadow-sm">
                  <CardActionArea
                    onClick={() => {
                      filterGrafic("Presentada Tarde");
                      setTittleSelect("Cláusulas Presentadas Tarde");
                    }}
                  >
                    <CardContent className="pb-0">
                      <Typography variant="body2" color="gray">
                        Cláusulas Presentadas Tarde
                      </Typography>

                      <Typography variant="h3" color="rgb(95,99,108)">
                        {countState["Presentada Tarde"] || 0}{" "}
                        <TrendingDown
                          style={{ color: "rgb(200,74,84)", fontSize: "35px" }}
                        />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Divider />
                  <CardActions>
                    <Box
                      display="inline-flex"
                      justifyContent="end"
                      width="100%"
                    >
                      <Button
                        onClick={() => {
                          handleDialog("Cláusulas Presentadas Tarde");
                          setFilterState("Presentada Tarde");
                        }}
                        component="a"
                        color="primary"
                        size="small"
                      >
                        Detalles
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item minWidth={200}>
                <Card variant="outlined" className="shadow-sm">
                  <CardActionArea
                    onClick={() => {
                      filterGrafic("Sin gestión");
                      setTittleSelect("Cláusulas sin gestión");
                    }}
                  >
                    <CardContent className="pb-0">
                      <Typography variant="body2" color="gray">
                        Cláusulas sin gestión
                      </Typography>

                      <Typography variant="h3" color="rgb(95,99,108)">
                        {countState["Sin gestión"] || 0}{" "}
                        <TrendingDown
                          style={{ color: "rgb(200,74,84)", fontSize: "35px" }}
                        />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Divider />
                  <CardActions>
                    <Box
                      display="inline-flex"
                      justifyContent="end"
                      width="100%"
                    >
                      <Button
                        onClick={() => {
                          handleDialog("Cláusulas sin gestión");
                          setFilterState("Sin gestión");
                        }}
                        component="a"
                        color="primary"
                        size="small"
                      >
                        Detalles
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item minWidth={200}>
                <Card variant="outlined" className="shadow-sm">
                  <CardActionArea
                    onClick={() => {
                      filterGrafic("Atrasada");
                      setTittleSelect("Cláusulas Atrasada");
                    }}
                  >
                    <CardContent className="pb-0">
                      <Typography variant="body2" color="gray">
                        Cláusulas Atrasada
                      </Typography>

                      <Typography variant="h3" color="rgb(95,99,108)">
                        {countState["Atrasada"] || 0}{" "}
                        <TrendingDown
                          style={{ color: "rgb(200,74,84)", fontSize: "35px" }}
                        />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Divider />
                  <CardActions>
                    <Box
                      display="inline-flex"
                      justifyContent="end"
                      width="100%"
                    >
                      <Button
                        onClick={() => {
                          handleDialog("Cláusulas Atrasadas");
                          setFilterState("Atrasada");
                        }}
                        component="a"
                        color="primary"
                        size="small"
                      >
                        Detalles
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item minWidth={200}>
                <Card variant="outlined" className="shadow-sm">
                  <CardActionArea
                    onClick={() => {
                      filterGrafic("Por vencer");
                      setTittleSelect("Cláusulas Por vencer");
                    }}
                  >
                    <CardContent className="pb-0">
                      <Typography variant="body2" color="gray">
                        Cláusulas Por vencer
                      </Typography>

                      <Typography variant="h3" color="rgb(95,99,108)">
                        {countState["Por vencer"] || 0}{" "}
                        <TrendingDown
                          style={{ color: "rgb(200,74,84)", fontSize: "35px" }}
                        />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Divider />
                  <CardActions>
                    <Box
                      display="inline-flex"
                      justifyContent="end"
                      width="100%"
                    >
                      <Button
                        onClick={() => {
                          handleDialog("Cláusulas Por vencer");
                          setFilterState("Por vencer");
                        }}
                        component="a"
                        color="primary"
                        size="small"
                      >
                        Detalles
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            </ScrollableBox>
          </Grid>

          {/* Gráficas */}
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <h3>
                {tittleSelect !== "" ? tittleSelect : "Todas las cláusulas"}
              </h3>
            </Grid>
            <Grid item sm={6} className="text-end">
              <Tooltip title="Limpiar filtro">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    allProjectGrafic();
                    setTittleSelect("");
                  }}
                >
                  <SearchOffIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <Card variant="outlined">
                <CardHeader
                  action={
                    <>
                      <Box display="inline-flex">
                        <FormControl fullWidth>
                          <Select
                            labelId="year-select-label"
                            id="year-select"
                            MenuProps={MenuProps}
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Año"
                            variant="standard"
                            input={<NoBorderInput />}
                            renderValue={(selected) => `Por año: ${selected}`}
                          >
                            {years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <IconButton
                          aria-label="settings"
                          aria-controls="project-menu"
                          aria-haspopup="true"
                        >
                          <MoreVert color="primary" />
                        </IconButton>
                      </Box>
                    </>
                  }
                  subheader="Estado General"
                  subheaderTypographyProps={{
                    fontWeight: "bold",
                    color: "#7a7a7a",
                  }}
                />
                <Divider
                  variant="middle"
                  style={{ backgroundColor: "gray" }}
                  className="m-0"
                />
                <CardContent className="justify-content-center d-flex">
                  {allStateGrafic?.series?.length > 0 ? (
                    <ReactApexChart
                      options={state.options}
                      series={state.series}
                      type="bar"
                      width={380}
                      height={280}
                    />
                  ) : (
                    <Box textAlign="center">
                      <img
                        src={EmptyIcon}
                        alt="Imagen de no data"
                        width={150}
                      />
                      <Typography variant="h6" color="GrayText">
                        No hay datos disponibles.
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Card variant="outlined" style={{ height: 400 }}>
                <CardHeader
                  action={
                    <>
                      <Box display="inline-flex">
                        <FormControl fullWidth>
                          <Select
                            labelId="year-select-label"
                            id="year-select"
                            MenuProps={MenuProps}
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Año"
                            variant="standard"
                            input={<NoBorderInput />}
                            renderValue={(selected) => `Por año: ${selected}`}
                          >
                            {years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <IconButton
                          aria-label="settings"
                          aria-controls="project-menu"
                          aria-haspopup="true"
                        >
                          <MoreVert color="primary" />
                        </IconButton>
                      </Box>
                    </>
                  }
                  subheader="Cláusulas por categoría"
                  subheaderTypographyProps={{
                    fontWeight: "bold",
                    color: "#7a7a7a",
                  }}
                />
                <Divider
                  variant="middle"
                  style={{ backgroundColor: "gray" }}
                  className="m-0"
                />

                <CardContent className="justify-content-center d-flex align-items-center">
                  <div className="p-4">
                    {allCategoryGrafic?.series?.length > 0 ? (
                      <ReactApexChart
                        options={category?.options}
                        series={allCategoryGrafic?.series || []}
                        type="pie"
                        width={400}
                      />
                    ) : (
                      <Box textAlign="center">
                        <img
                          src={EmptyIcon}
                          alt="Imagen de no data"
                          width={150}
                        />
                        <Typography variant="h6" color="GrayText">
                          No hay datos disponibles.
                        </Typography>
                      </Box>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={6} xs={12}>
              <Card variant="outlined">
                <CardHeader
                  action={
                    <>
                      <Box display="inline-flex">
                        <FormControl fullWidth>
                          <Select
                            labelId="Year-select-label"
                            id="Year-select"
                            MenuProps={MenuProps}
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Año"
                            variant="standard"
                            input={<NoBorderInput />}
                            renderValue={(selected) => `Por año: ${selected}`}
                          >
                            {years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <IconButton
                          aria-label="settings"
                          aria-controls="project-menu"
                          aria-haspopup="true"
                        >
                          <MoreVert color="primary" />
                        </IconButton>
                      </Box>
                    </>
                  }
                  subheader="Cláusulas por responsable"
                  subheaderTypographyProps={{
                    fontWeight: "bold",
                    color: "#7a7a7a",
                  }}
                />

                <Divider
                  variant="middle"
                  style={{ backgroundColor: "gray" }}
                  className="m-0"
                />
                <CardContent className="justify-content-center d-flex">
                  <div className="p-3">
                    {allUsersGrafic?.series?.length > 0 ? (
                      <ReactApexChart
                        options={responsible.options || []}
                        series={responsible.series || []}
                        type="pie"
                        width={ responsible.series.length > 3 ? 600:400}
                      />
                    ) : (
                      <Box textAlign="center">
                        <img
                          src={EmptyIcon}
                          alt="Imagen de no data"
                          width={150}
                        />
                        <Typography variant="h6" color="GrayText">
                          No hay datos disponibles.
                        </Typography>
                      </Box>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={6} xs={12}>
              <Card variant="outlined">
                <CardHeader
                  action={
                    <>
                      <Box display="inline-flex">
                        <FormControl fullWidth>
                          <Select
                            labelId="year-select-label"
                            id="year-select"
                            MenuProps={MenuProps}
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Año"
                            variant="standard"
                            input={<NoBorderInput />}
                            renderValue={(selected) => `Por año: ${selected}`}
                          >
                            {years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <IconButton
                          aria-label="settings"
                          aria-controls="project-menu"
                          aria-haspopup="true"
                        >
                          <MoreVert color="primary" />
                        </IconButton>
                      </Box>
                    </>
                  }
                  subheader="Cláusulas por criticidad"
                  subheaderTypographyProps={{
                    fontWeight: "bold",
                    color: "#7a7a7a",
                  }}
                />

                <Divider
                  variant="middle"
                  style={{ backgroundColor: "gray" }}
                  className="m-0"
                />

                <CardContent className="justify-content-center d-flex">
                  {allCriticalityGrafic?.series?.length > 0 ? (
                    <ReactApexChart
                      options={critic.options}
                      series={critic.series}
                      type="bar"
                      width={500}
                      height={280}
                    />
                  ) : (
                    <Box textAlign="center">
                      <img
                        src={EmptyIcon}
                        alt="Imagen de no data"
                        width={150}
                      />
                      <Typography variant="h6" color="GrayText">
                        No hay datos disponibles.
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <div>
            <Fragment key={"right"}>
              <Drawer
                anchor={"right"}
                open={viewDetails}
                onClose={() => handleDialog("")}
              >
                {dialogDetails}
              </Drawer>
            </Fragment>
          </div>
        </>
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Container>
  );
};

export default GeneralView;
