import {
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Formik } from "formik";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import axiosInstance from "src/utils/axios";
import { LoadingButton } from "@mui/lab";

const Security = () => {
  const User = useSelector((state) => state?.setUserLogin?.user);
  return (
    <Container maxWidth="lg">
      <Typography
        variant="body1"
        fontWeight="bold"
        color="primary"
        className="mt-4 mb-4"
      >
        <InfoIcon /> Se recomienda usar una contraseña segura que no uses para
        ningún otro sitio, la contraseña debe contener como mínimo 8 dígitos
        compuestos por números y letras
      </Typography>

      <Formik
        enableReinitialize
        initialValues={{
          oldPassword: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object({
          oldPassword: Yup.string().required("La contraseña es requerida"),
          password: Yup.string()
            .required("La contraseña es requerida")
            .min(8, "La contraseña debe tener al menos 8 caracteres")
            .matches(
              /^(?=.*[a-zA-Z])(?=.*\d)/,
              "La contraseña debe contener al menos una letra y un número"
            ),
          confirmPassword: Yup.string()
            .oneOf(
              [Yup.ref("password"), null],
              "Las contraseñas deben coincidir"
            )
            .required("Debe repetir la contraseña"),
        })}
        onSubmit={(values, { setSubmitting, setErrors, resetForm}) => {
          setSubmitting(true)
          axiosInstance
            .post("api/users/auth", {
              email: User?.email || "",
              password: values?.oldPassword || "",
            })
            .then(() => {
                axiosInstance
                  .post("api/users/updatePassword", {
                    email: User?.email || "",
                    newPassword: values?.confirmPassword || "",
                  })
                  .then((resUp) => {
                    if (resUp.status === 200) {
                      enqueueSnackbar(
                        "La contraseña se actualizado correctamente",
                        {
                          variant: "success",
                        }
                      );
                    }
                    if (resUp.status === 201) {
                      enqueueSnackbar(
                        "La contraseña ya ha sido usada con anterioridad",
                        {
                          variant: "error",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                        }
                      );
                    }
                    resetForm();
                    setSubmitting(false);

                  }).catch(() => {                 
                    resetForm();
                    setSubmitting(false);
                  });
            })
            .catch((error) => {
              enqueueSnackbar("Error al cambiar la contraseña", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              resetForm();
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel shrink id="oldPasswordLabel">
                      Contraseña actual:{" "}
                    </InputLabel>
                    <TextField
                      margin="dense"
                      id="oldPassword"
                      name="oldPassword"
                      type="password"
                      fullWidth
                      variant="standard"
                      value={values.oldPassword}
                      onChange={handleChange}
                      error={Boolean(touched.oldPassword && errors.oldPassword)}
                      helperText={touched.oldPassword && errors.oldPassword}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <InputLabel shrink id="passwordLabel">
                    Contraseña nueva:{" "}
                  </InputLabel>
                  <TextField
                    margin="dense"
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    type="password"
                    fullWidth
                    variant="standard"
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <InputLabel shrink id="confirmPasswordLabel">
                    Vuelve a escribir la contraseña nueva:{" "}
                  </InputLabel>
                  <TextField
                    margin="dense"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    type="password"
                    fullWidth
                    variant="standard"
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <LoadingButton loading={isSubmitting} variant="contained" color="primary"  type="submit">
                  Actualizar
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default Security;
